<template>
	<mdb-container>
		<mdb-modal
			:show="popupStart"
			@close="() => $emit('close')"
			centered
			v-bind="$attrs"
			class="shadow"
			scrollable
		>
			<mdb-modal-header>
				<mdb-modal-title>
                    <h3 class="font-weight-bold text-uppercase">{{ title }}</h3>
                </mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body class="">
				<div>
					<p v-if="html" v-html="description">
					</p>
					<p v-else>
                        <slot></slot>
                        {{ description }}
					</p>
				</div>
			</mdb-modal-body>
			<mdb-modal-footer class="justify-content-center">
				<mdb-btn
					:color="'danger'"
					size="md"
					@click="() => $emit('close')"
					>{{
						$t("default.BUTTONS.ACCEPT")
					}}</mdb-btn
				>
			</mdb-modal-footer>
		</mdb-modal>
	</mdb-container>
</template>

<script>
export default {
	name: "SchudeleMonumentModal",
	props: {
		popupStart: Boolean,
		title: String,
        html: { type: Boolean, default: false, required: false },
        description: String,
		color: { type: String, default: "info", required: false },
        
	},
	data() {
		return {
			imagePath: process.env.VUE_APP_IMAGES_URL,
		};
	},
};
</script>
