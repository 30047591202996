<template>
    <div style="">
        <div class="d-flex flex-column">
            <div class="row gx-0">
                <div class="mapa position-relative d-flex">
                    <FilterMobile :idFilter="idFilter" style="position: absolute;" v-if="isMobile"
                        @hideFilter="toggleSearchbar" :searchBlockStatus="searchBlockStatus" />
                    <div v-else class="pb-0 col-12 col-xl-12 col-xxl-4 spainhn-gray-background"
                        v-bind:class="{ 'd-none': !searchBlockStatus, }">
                        <div style="position: absolute !important; max-height: 100%; overflow: scroll ; overflow-x: hidden ;z-index: 5;"
                            class="px-2">
                            <diV class="h-auto d-flex flex-column search-title">
                                <div class="px-0 mx-0 border-bottom mt-auto"
                                    style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;">
                                    <strong class="d-flex py-2 py-md-3">
                                        <span class="text-start spainhn-subtitle-text-white w-75">{{
                                            $t('default.HOME_SEARCHBOX_TITLE') }}</span>
                                        <font-awesome-icon role="button" class="my-auto ms-auto"
                                            @click="toggleSearchbar" icon="fa-circle-chevron-left" size="2x" />
                                    </strong>
                                </div>
                                <b-input-group class="py-2 d-flex flex-column">
                                    <div class="w-75 pe-lg-5 pt-lg-3 py-md-2">
                                        <p class="text-start spainhn-small-text"> {{
                                            $t('default.HOME_SEARCHBOX_SUBTITLE') }}
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <b-form-input @input="filtrarNombre" v-model="filtroPorNombre"
                                            v-on:keyup.enter="toggleSearchbar"
                                            :placeholder="$t('default.SELECT_BUSQUEDA')" class="w-100"
                                            style="border-radius: 20px; height:50px" size="sm" />
                                        <div class="ms-3 my-auto d-flex"
                                            style="border-radius: 25px; height:50px; width: 50px; background-color: var(--spainhn-rojo-corporativo);"
                                            role="button" @click="toggleSearchbar">
                                            <font-awesome-icon class="my-auto mx-auto" icon="fa-magnifying-glass"
                                                size="1x"></font-awesome-icon>
                                        </div>
                                    </div>
                                </b-input-group>
                                <div class="row py-3 text-start">
                                    <mdb-row>
                                        <mdb-col col="12" md="6" v-for="tipo in tiposDeMonumentos" :key="tipo.id">
                                            <mdb-input type="checkbox" :id="tipo.id" :name="`${tipo.name}-${tipo.id}`" 
                                            class=""
                                            :label="tipo.name"
                                            v-model="tipo.value"/>
                                        </mdb-col>
                                        <mdb-col col="12" md="6">
                                            <mdb-input type="checkbox" :id="`tipo-paquete`" :name="`tipo-paquete`" @change="infoWinOpen = false"
                                            :label="$t('default.FILTER.TOURS_AND_PACKS')"
                                            v-model="paquetes"/>
                                        </mdb-col>
                                        <mdb-col col="12" md="6">
                                            <mdb-input type="checkbox" :id="`tipo-servicio`" :name="`tipo-servicio`" 
                                            class=""
                                            :label="$t('default.FILTER.SERVICES')"
                                            v-model="servicios"/>
                                        </mdb-col>
                                    </mdb-row>

                                </div>
                                <div class="mt-3">
                                    <p class="d-flex fw-bold spainhn-text py-0">{{
                                        $t('default.HOME_SEARCHBOX_LOCALIZACION_OPTION') }}</p>
                                    <div class="border-bottom w-25 py-0 my-0"
                                        style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;">
                                    </div>
                                    <b-form-select v-model="localizacionesFilter" :options="_availableCommunities"
                                        class="w-100 mt-3" style="border-radius: 10px; height:2.5rem;" >
                                        <template #first>
                                            <b-form-select-option value="" disabled>{{$t("default.MINI_MAP.COMMUNITIES")}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="mt-3">
                                    <p class="d-flex fw-bold spainhn-text py-0">{{
                                        $t('default.HOME_SEARCHBOX_RUTA_OPTION') }}</p>
                                    <div class="border-bottom w-25 py-0 my-0"
                                        style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;">
                                    </div>
                                    <b-form-select v-model="rutaFilter" :options="_opcionesDeRutas"
                                        class="w-100 mt-3" style="border-radius: 10px; height:2.5rem;">
                                        <template #first>
                                            <b-form-select-option value="" disabled>{{ $t("default.MINI_MAP.RUTAS") }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="mt-3">
                                    <p class="d-flex fw-bold spainhn-text py-0">{{
                                        $t('default.HOME_SEARCHBOX_SERVICE_OPTION') }}</p>
                                    <div class="border-bottom w-25 py-0 my-0"
                                        style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;">
                                    </div>
                                    <b-form-select v-model="serviciosFilter" :options="serviciosOptions" @change="infoWinOpen = false"
                                        class="w-100 mt-3" style="border-radius: 10px; height:2.5rem;">
                                        <template #first>
                                            <b-form-select-option value="" disabled>{{ $t("default.MINI_MAP.SERVICIOS") }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="d-flex mx-auto mt-4">
                                    <div class="py-2 px-2">
                                        <mdb-btn color="indigo" size="lg" class="spainhn-button"
                                            @click="limpiarFiltros">{{
                                            $t('default.HOME_SEARCHBOX_CLEAR_BUTTON') }}</mdb-btn>
                                    </div>
                                    <div class="py-2 px-2">
                                        <mdb-btn color="indigo" @click="toggleSearchbar"
                                            class="spainhn-button d-xxl-none" size="lg">{{
                                            $t('default.HOME_SEARCHBOX_SEARCH_BUTTON') }} </mdb-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-0 mt-md-0 d-flex ms-sm-0 ms-0 pt-0 pt-md-0"
                        v-bind:class="{ 'd-none': searchBlockStatus }" style="position:relative; z-index:3;">
                        <!-- <mdb-btn color="indigo" class="spainhn-button px-5 mt-5" size="lg" @click="toggleSearchbar" style="position:absolute; z-index:3; margin-top:5rem !important;">
                            <strong>{{ $t('default.HOME_SEARCH_BUTTON')}}</strong>
                        </mdb-btn> -->
                        <button class="ml-3" @click="toggleSearchbar" v-if="variant"
                            style="position:absolute; z-index:3; margin-top:10px !important; border: none; height: 44px; width: 44px; background-color: #850B0B; color: white; border-radius: 50%;">
                            <font-awesome-icon icon="fa-filter" />
                        </button>
                        <mdb-btn class="ml-3 spainhn-button px-5 mt-5" size="lg" @click="toggleSearchbar" v-else
                            style="position:absolute; z-index:3; margin-top: 5rem !important; border: none;">
                            <strong>{{ $t('default.HOME_SEARCH_BUTTON')}}</strong>
                        </mdb-btn >
                    </div>
                    <div class="h-100 flex-grow-1">
                        <GmapMap 
                            ref="mapRef" 
                            autobindAllEvents:false 
                            :center="mapCenterComputed"
                            :zoom="mapZoomComputed" 
                            map-type-id="terrain" 
                            :styles="['hide']"
                            :options="{ disableDefaultUI: variant }"
                            style="height: 100%; margin-left: auto; margin-right: auto;">

                            <DirectionsRenderer 
                                travelMode="DRIVING" 
                                :origin="waypointsComputed.startLocation"
                                :waypoints="waypointsComputed.waypoints" 
                                :destination="waypointsComputed.endLocation"
                                :optimize_waypoint_order="true" />
                            <GmapInfoWindow 
                                :position="infoWindowPos" 
                                :opened="infoWinOpen"
                                @closeclick="infoWinOpen = false">
                                <InfoMapPopover v-if="currentMonument" :monumento="currentMonument" :link="currentMonumentLink" :currentLang="currentLang" :translations="$t" :currentFilter="serviciosFilter" :paqueteFilter="paquetes" :matchingPacks="currentMonumentPacks" :servicioCheck="servicios" />
                            </GmapInfoWindow>

                            <GmapCluster 
                                :gridSize="3" 
                                :zoomOnClick="true" 
                                :styles="[{
                                    'textColor': 'white', 'height': 60, 'width': 60, 'textSize': 10,
                                    'backgroundPosition':'0 0', 'url':mapClusterIcon}]" 
                                :maxZoom="16">
                                <GmapMarker 
                                    v-for="(m, index) in markers" 
                                    :key="index" 
                                    :position="m.position"
                                    :clickable="true" 
                                    :draggable="false" 
                                    @click="toggleInfoWindow(m,index)"
                                    :icon="m.icon"/>
                            </GmapCluster>

                        </GmapMap>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../services/ApiService';
import { gmapApi } from 'vue2-google-maps'
import DirectionsRenderer from "@/components/DirectionsRenderer";
import FilterMobile from "@/components/FilterMobile";
// import { uuid } from 'vue-uuid'
import { v4 as uuidv4 } from 'uuid';

import axios from 'axios'
import InfoMapPopover from "@/components/InfoMapPopover";


export default {
    name: 'BuscadorDeCastillos',
    props:{
        idFilter: {
            type: String,
            required: false,
            default: 'filter',
        },
        variant: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        DirectionsRenderer,
        FilterMobile,
        InfoMapPopover
    },
    data() {
        return {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            iconShowHide: 'fa-circle-chevron-up',
            currentLocation: { lat: 0, lng: 0 },
            searchBlockStatus: true,
            tipoFilter: null,
            markers: [],
            monumentos: [],
            currentMonument: null,
            threshold: 0.1, // En km, indica el umbral de distancia para que se ajusten los puntos
            currentMonumentLink: null, 
            currentMonumentPacks: [],
            currentLang: 0,
            rutaFilter: "",
            serviciosFilter: "",
            mapZoom: 5.3,
            localizacionesFilter: "",
            comunidadesAutonomas: [
            ],
            rutasOptions: [
                // { value: null, text: this.$t('default.SELECT_RUTA') }
            ],
            filtroPorNombre: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,

            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            castillosCheck: false,
            palaciosCheck: false,
            palaciosRealesCheck: false,
            monasteryCheck: false,
            recintosCheck: false,
            paradoresCheck: false,
            rutasOptionsFiltered: [],
            mapCenter: { lat: 39.952762719398464, lng: -5.057718553453691 },
            startLocation: null,
            endLocation: null,
            waypoints: [],

            allRoutes: [],
            rutasAutor: [],
            rutasColeccionista: [],
            rutasSenderismo: [],
            rutasHistoricas: [],
            tiposDeMonumentos: [],
            paquetes: false,
            allPacks: [],
            paquetesMonumentos: [],
            servicios: false,
            serviciosMonumentos: []
        }
    },
    methods: {
        updateType( evt ){
            console.log( evt );
        },
        toggleSearchbar() {
            let $vm = this;
            if ($vm.searchBlockStatus) {
                $vm.searchBlockStatus = false;
                $vm.iconShowHide = 'fa-circle-chevron-down';
            }
            else {
                $vm.searchBlockStatus = true;
                $vm.iconShowHide = 'fa-circle-chevron-up';
            }
        },

        goToMonument() {
            let $vm = this;
            $vm.$router.push('/castillos')
        },

        toggleInfoWindow: function (marker, idx) {
            this.infoWindowPos = marker.position;
            this.currentMonument = marker.currentMonument;
            this.currentMonumentLink = marker.link;
            this.currentLang = this.selectedIdiomaIndex;
            this.currentMonumentPacks = marker.matchingPacks;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;

            }
        },
        filtrarTipo() {
            let $vm = this;
            let filtro = $vm.tipoFilter;
            if (filtro == "Recintos") {
                filtro = "Recintos amurallados"
            }
            let tempMonumentos = $vm.tempMonumentos.filter(monumento => {

                if (filtro == "Paradores") {
                    return monumento.alojamiento == true;
                }
                else {
                    return monumento.tipoDeMonumento == filtro;
                }
            })

            if ( !$vm.filtroPorNombre ) {
                let filtroNombre = $vm.filtroPorNombre.toLowerCase();
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                    let tempName1 = monumento.nombres[0].texto.toLowerCase()
                    let include = tempName1.search(filtroNombre);
                    return include != -1;
                })

                tempMonumentos = tempMonumentos2;
            }

            if ( !$vm.localizacionesFilter ) {
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                    return monumento.comunidad == $vm.localizacionesFilter;
                    // return true
                })
                tempMonumentos = tempMonumentos2;
            }

            let mapTop = $vm.$refs['mapRef'].getBoundingClientRect().top - 200;
            window.scrollTo({ top: mapTop, left: 0 })
            $vm.setMarkers(tempMonumentos);

        },
        filtrarNombre( evt ) {
            let $vm = this;
            if( evt.length ){
                if( evt.length >= 3){
                    this.filtroPorNombre = evt;
                }else{
                    this.filtroPorNombre = ''
                }
            }else{
                this.filtroPorNombre = ''
            }
        },
        nameFiltering( monumentos, textToLookup ){
            if ( textToLookup ) {
                // first we have to convert to lowecase and remove the white spaces at the end and the start
                let filtroNombre = textToLookup.toLowerCase();
                filtroNombre = filtroNombre.trim()

                // We remove all accents and diatrics
                filtroNombre = filtroNombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                const monumentsResults = monumentos.filter(monumento => {
                    // Convert to lower case string
                    let tempName1 = monumento.nombres[this.selectedIdiomaIndex].texto.toLowerCase();
                    // Remove accents and diatrics to compare with the input
                    tempName1 = tempName1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                    // Check if the name includes the input field
                    let include = tempName1.search(filtroNombre);

                    return include != -1;
                })

                return monumentsResults?.map( m => m._id );
            }else{
                return monumentos?.map( m => m._id) ?? [];
            }
        },
        typeFiltering( monumentos, tiposDeMonumentos, servicios, paquetes ){
            const _tipos = tiposDeMonumentos.filter( t => t.value === true ).map( t => t.type );
            if( _tipos.length === 0 ){
                if( servicios || paquetes ){
                    return [];
                }else{
                    return monumentos?.map( m => m._id ) ?? [];
                }
            }else{
                const _matchMonuments = monumentos.filter( m => _tipos.includes( m.tipoDeMonumento ) );
                return _matchMonuments?.map( m => m._id);
            }
        },
        communityFiltering( monumentos, comunidadToLookup){
            if ( comunidadToLookup ) {
                const monumentsResults = monumentos.filter(monumento => monumento.comunidad === comunidadToLookup );

                return monumentsResults?.map( m => m._id );
            }else{
                return monumentos?.map( m => m._id) ?? [];
            }
        },
        serviceFiltering( monumentos, servicesToLookup){
            if ( servicesToLookup ) {
                const monumentsResults = monumentos.filter(monumento => {
                    if ( servicesToLookup.includes( 'alojamiento') && monumento.alojamiento ) {
                        return true;
                    }
                    if ( servicesToLookup.includes( 'restaurante') && monumento.restaurante ) {
                        return true;
                    }
                    if ( servicesToLookup.includes( 'eventos') && monumento.eventos ) {
                        return true;
                    }
                    if ( servicesToLookup.includes('experiencias') && monumento.experiencias ) {
                        return true;
                    }
                    if ( servicesToLookup.includes('boda') && monumento.boda ) {
                        return true;
                    }
                    if ( servicesToLookup.includes("espacios") && monumento.espacios ) {
                        return true;
                    }
                    if (servicesToLookup.includes("museo") && monumento.museo) {
                        return true;
                    }
                });

                return monumentsResults?.map( m => m._id );
            }else{
                return monumentos?.map( m => m._id) ?? [];
            }
        },
        getMatchedMonuments(allMonuments, allPacks) {
            const monumentoIds = new Set();
            allPacks.forEach(pack => {
                monumentoIds.add(pack.monumentoId);
            });

            const _matchedMonuments = allMonuments.filter(monument => 
                monumentoIds.has(monument._id)
            );

            return _matchedMonuments;
        },
        filtrarLocalizacion() {
            let $vm = this;
            let filtroNombre;
            let tempMonumentos = $vm.tempMonumentos;
            // console.log("Filtrar");

            if ( !$vm.filtroPorNombre ) {
                filtroNombre = $vm.filtroPorNombre.toLowerCase();

                tempMonumentos = $vm.tempMonumentos.filter(monumento => {
                    let tempName1 = monumento.nombres[$vm.idiomaIndex].texto.toLowerCase()
                    // console.log(tempName1.search(filtroNombre));
                    let include = tempName1.search(filtroNombre);
                    return include != -1;
                })
            }
            let filtroTipo = $vm.tipoFilter
            // console.log($vm.tempMonumentos);


            if ( $vm.tipoFilter ) {

                if (filtroTipo == "Recintos Amurallados") {
                    filtroTipo = "Recintos amurallados"
                }
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                    if (filtroTipo == "Paradores") {
                        return monumento.alojamiento == true;
                    }
                    else {
                        return monumento.tipoDeMonumento == filtroTipo;
                    }
                })
                tempMonumentos = tempMonumentos2
            }

            if ( $vm.localizacionesFilter ) {
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                    // console.log(monumento.comunidad);
                    return monumento.comunidad == $vm.localizacionesFilter;
                    // return true
                })
                tempMonumentos = tempMonumentos2;
            }

            $vm.setMarkers(tempMonumentos);

            // console.log(tempMonumentos);
        },
        haversineDistance(coords1, coords2) {
            const R = 6371; // Radio de la Tierra en km
            const lat1 = coords1.lat * Math.PI / 180; 
            const lon1 = coords1.lng * Math.PI / 180;
            const lat2 = coords2.lat * Math.PI / 180;
            const lon2 = coords2.lng * Math.PI / 180;
            const dlat = lat2 - lat1;
            const dlon = lon2 - lon1;

            const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) +
                        Math.cos(lat1) * Math.cos(lat2) *
                        Math.sin(dlon / 2) * Math.sin(dlon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c;
        },
        adjustCoordinates(coords) {
            const adjustment = 0.0003; // Este valor se puede ajustar dependiendo de que tan lejos se quieran ajustar los puntos (hace que el punto vaya hacia el noreste)
            coords.lat += adjustment;
            coords.lng += adjustment;
            return coords;
        },
        filterByService() {
            let $vm = this;
            // console.log($vm.serviciosFilter)
            let tempMonumentos = $vm.tempMonumentos;
            let tempMonumentos2;
            tempMonumentos2 = tempMonumentos.filter(monumento => {
                if ($vm.serviciosFilter == 'restaurante')
                    return monumento.restaurante == true;
                else if ($vm.serviciosFilter == 'espacios')
                    return monumento.espacios == true;
                if ($vm.serviciosFilter == 'alojamiento')
                    return monumento.alojamiento == true;
                if ($vm.serviciosFilter == 'boda')
                    return monumento.bodas == true;

                if ($vm.serviciosFilter == 'museo')
                    return monumento.museo == true;
            }
            )
            $vm.setMarkers(tempMonumentos2);
        },
        limpiarFiltros() {
            let $vm = this;
            // Marcamos el infowindow como cerrado para que no se mantenga un modal con un state antiguo.
            $vm.infoWinOpen = false;
            $vm.rutaFilter = "";
            $vm.localizacionesFilter = "";
            $vm.filtroPorNombre = '';
            $vm.serviciosFilter = "";
            $vm.castillosCheck = false;
            $vm.recintosCheck = false;
            $vm.palaciosCheck = false;
            $vm.palaciosRealesCheck = false;
            $vm.setMarkers($vm.monumentos);
            $vm.mapZoom = 6.3;
            $vm.mapCenter = { lat: 39.952762719398464, lng: -5.057718553453691 }
            $vm.startLocation = null;
            $vm.endLocation = null;
            $vm.rutasOptionsFiltered = $vm.rutasOptions;
            this.tiposDeMonumentos.forEach( t => t.value = false );
            // $vm.waypoints = [];


            // let tempFilter = $vm.castillos.filter()
        },

        getIcon( tipo, servicio ){
            if( tipo === 'Parador'){
                return require('../assets/paradores-small.png');
            }else if( tipo === 'Paquete') {
                return require('../assets/marker-gral-small-blue.png')
            }else if(servicio){
                return require('../assets/marker-gral-small_dorada.png')
            }
            else{
                return require('../assets/marker-gral-small.png');
            }
        },
        // Coloca los marcadores sobre el mapa
        setMarkers(monumentosToFilter) {
            let $vm = this;
            // console.log(monumentosToFilter);
            $vm.markers = []
            monumentosToFilter.forEach((monumento, index) => {
                let rawName = null;
                rawName = monumento.nombre.replaceAll(',', '');
                rawName = rawName.replaceAll('.', '');

                let nombreNormalizado = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
                let nombreNormalizadoLowerCase = nombreNormalizado.toLowerCase().replace(/[\u0020]/g, '-');

                let link = "";
                if( monumento.tipoDeMonumento === "Paquete" ){
                    if (monumento.esPromotor) { 
                        link = `/paquete/${nombreNormalizado}/${monumento._id}`;
                    } else {
                        link = `/paquetes/monumentos/${nombreNormalizado}/${monumento._id}`;
                    }
                    // workaround para obtener el link dentro del monumento jej :>
                    monumento.link = link;
                }else if( monumento.nombreDelMonumento ){
                    let rawName = null;
                    rawName = monumento.nombreDelMonumento.replaceAll(',', '');
                    rawName = rawName.replaceAll('.', '');

                    let nombreNormalizado2 = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
                    let nombreNormalizadoLowerCase2 = nombreNormalizado2.toLowerCase().replace(/[\u0020]/g, '-');

                    if( monumento.servicio ){
                        link = `/castillos/tarifas/${nombreNormalizadoLowerCase2}/${monumento.serviceId}`
                    }else{
                        link = `/castillos/${nombreNormalizadoLowerCase2}`
                    }
                }else{
                    link = `/castillos/${nombreNormalizadoLowerCase}`
                }
                // const link = monumento.tipoDeMonumento === 'Paquete' ? `/paquete/${nombreNormalizado}/${monumento._id}` : `/castillos/${nombreNormalizadoLowerCase}`
                let newMarker = {
                    id: index,
                    _id: monumento._id,
                    icon: this.getIcon(monumento.tipoDeMonumento, (monumento.servicio ?? false ) ),
                    position: {
                        lat: parseFloat(monumento.coordenadas.lat),
                        lng: parseFloat(monumento.coordenadas.lng)
                    },
                    opacity: 1,
                    draggable: false,
                    enabled: true,
                    clicked: 0,
                    esPromotor: monumento.esPromotor ?? false,
                    rightClicked: 0,
                    dragended: 0,
                    ifw: true,
                    link: link,
                    matchingPacks: [],
                    currentLang: this.selectedIdiomaIndex,
                    currentMonument: monumento,
                    parador: monumento.tipoDeMonumento === 'Parador' ? true : false,
                    ifw2text: 'This text is bad please change me :( ',
                }
                // Otro workaround para que no se muestre el boton de ver sitio en los paquetes
                newMarker.currentMonument.showLinkButton = true;
                if (monumento.tipoDeMonumento === 'Paquete' && this.paquetes) {
                    for (let i = 0; i < index; i++) {
                        const otherMarker = monumentosToFilter[i];
                        const distance = this.haversineDistance(
                            { lat: monumento.coordenadas.lat, lng: monumento.coordenadas.lng },
                            { lat: parseFloat(otherMarker.coordenadas.lat), lng: parseFloat(otherMarker.coordenadas.lng) }
                        );

                        if (otherMarker.tipoDeMonumento === 'Paquete' && distance < this.threshold) {
                            const indexToRemove = this.markers.findIndex(m => m._id === otherMarker._id);
                            if (indexToRemove !== -1) {
                                this.markers.splice(indexToRemove, 1);
                            }

                            newMarker.currentMonument.showLinkButton = false;
                            if (!newMarker.matchingPacks.find(p => p._id === otherMarker._id)) {
                                newMarker.matchingPacks.push({
                                    _id: otherMarker._id,
                                    nombre: otherMarker.nombre,
                                    link: otherMarker.link,
                                    esPromotor: otherMarker.esPromotor,
                                });
                            }
                            newMarker.position = this.adjustCoordinates(newMarker.position);
                        }
                    }
                }

                if (monumento.tipoDeMonumento === 'Paquete' && this.paquetes) {
                    newMarker.matchingPacks.push({
                        _id: monumento._id,
                        nombre: monumento.nombre,
                        link: monumento.link,
                        esPromotor: monumento.esPromotor,
                    });
                }
                $vm.markers.push(newMarker);
            })

            let bounds = new this.google.maps.LatLngBounds();

            let numeroDeMarcadores = $vm.markers.length;
            if (numeroDeMarcadores > 1) {

                for (var i = 0; i < numeroDeMarcadores; i++) {
                    bounds.extend($vm.markers[i].position);
                }
                this.$refs.mapRef.fitBounds(bounds)
                this.mapCenter = bounds.getCenter();
                this.mapZoom = 4.4;

                if ( $vm.rutaFilter ) {

                    $vm.startLocation = $vm.markers[0].position;
                    $vm.endLocation = $vm.markers[numeroDeMarcadores - 1].position;
                    let marcadoresIntermedios = $vm.markers;
                    let puntosIntermedios = marcadoresIntermedios?.map((marcador, index) => {
                        let waypoint = { location: marcador.position, stopover: false };
                        return waypoint;
                    })
                    $vm.waypoints = { waypoints: puntosIntermedios };

                }
                else {
                    // console.log("quitar ruta");
                    $vm.startLocation = null;
                    $vm.endLocation = null;
                    $vm.waypoints = []
                }

            }
            else if (numeroDeMarcadores == 1) {
                this.mapCenter = $vm.markers[0].position;
                this.mapZoom = 10;
            }
            else {
                this.mapCenter = { lat: 39.952762719398464, lng: -5.057718553453691 };
                this.mapZoom = 4.4;
            }

            // this.$refs.mapRef.setCenter(bounds.getCenter());
            // this.$refs.mapRef.setZoom(18);

        },
        geoLocation() {
            let $vm = this

            navigator.geolocation.getCurrentPosition(position => {
                this.currentLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
                this.mapCenter = this.currentLocation;


                var marker = new this.google.maps.Marker({
                    animation: this.google.maps.Animation.BOUNCE,
                    position: this.currentLocation,
                    title: 'Current Location',
                    draggable: false,
                    clickable: false,
                    infoText: '<p class="pb-0 mb-0 spainhn-lg-text-red">Current Location</p>',
                    ifw: true,
                    icon: {
                        path: this.google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillOpacity: 1,
                        strokeWeight: 2,
                        fillColor: '#5384ED',
                        strokeColor: '#ffffff',
                        zIndex: 1,
                    }
                });

                this.$refs.mapRef.$mapPromise.then((map) => {
                    //map.setMyLocationEnabled(true)
                    marker.setMap(map)


                })

                //this.mapZoom = 8;


            })
        }


    },
    mounted() {
        //this.geoLocation()
        // let screenWidth = window.innerWidth;
        let screenWidth = window.screen.width;
        // console.log(screenWidth);
        if (screenWidth < 1920) {
            // console.log("Ocultar el buscador")
            this.searchBlockStatus = false;
        }

        const language = this.$store.getters.getIdioma ?? "es";

        ApiService.getTiposDeMonumento( language )
            .then( result => {
                this.tiposDeMonumentos = result.data?.map( t => {
                    return ( { name: t.webName, value: false, id: uuidv4(), type: t.value } )
                })
            })
            .catch( err => {
                console.log(err);
            })
        // if(screenWidth < 768){
        //     this.mapZoom = 5.8;
        // }

        this.$refs.mapRef.$mapPromise.then((map) => {
            map.setOptions({
                styles: [
                    {
                        featureType: 'poi.business',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        elementType: 'labels.icon',
                        stylers: [{ visibility: 'off' }],
                    },
                ]
            })

            axios.get('https://ipapi.co/json/').then(response => {
                if (response.data.country_code == 'ES') {

                    this.geoLocation();
                }
            })
        })
    },
    computed: {
        google: gmapApi,
        waypointsComputed() {


            if( this.rutaFilter ){
                const rutaSeleccionado = this.allRoutes.find( r => r._id === this.rutaFilter );

                const castillosEnLaRuta = rutaSeleccionado.castillos?.map( c => c._id );

                const marcadoresEnLaRuta = this.markers.filter( m => castillosEnLaRuta.includes( m._id ) );

                const numeroDeMarcadores = marcadoresEnLaRuta.length;

                let puntosIntermedios = marcadoresEnLaRuta?.map((marcador, index) => {
                    let waypoint = { location: marcador.position, stopover: false };
                    return waypoint;
                })
                const waypoints = { waypoints: puntosIntermedios };

                if( numeroDeMarcadores > 1 ){
                    const rutaObj = {
                        startLocation: marcadoresEnLaRuta[0].position,
                        endLocation: marcadoresEnLaRuta[numeroDeMarcadores -1 ].position,
                        waypoints: puntosIntermedios
                    }
                    
                    return rutaObj;
                }

                else{
                    return {startLocation: null, endLocation: null, waypoints: []};
                }
            }
            return {startLocation: null, endLocation: null, waypoints: []};
        },
        mapZoomComputed() {
            // return this.mapZoom;
            return 4.75;
        },
        mapCenterComputed() {
            return this.mapCenter
        },
        mapClusterIcon() {

            const color = "rgb(165, 61, 57)"
            const svg = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');
            return `data:image/svg+xml;base64,${svg}`
        },
        selectedIdiomaIndex() {
            let $vm = this;
            let idiomaIndex = 0;
            if ($vm.$i18n.locale == 'es') {
                idiomaIndex = 0;
            }
            else if ($vm.$i18n.locale == 'en') {
                idiomaIndex = 1;
            }
            else if ($vm.$i18n.locale == 'fr') {
                idiomaIndex = 2;
            }
            else if ($vm.$i18n.locale == 'de') {
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma() {
            let $vm = this;

            return $vm.$i18n.locale;
        },
        serviciosOptions() {
            let servicios = [
                // { 'value': 'visita', 'text': this.$t('default.MAIN_MENU_SERVICES_VISITS') },
                { 'value': 'alojamiento', 'text': this.$t('default.MAIN_MENU_SERVICES_ALOJAMIENTO') },
                { 'value': 'restaurante', 'text': this.$t('default.MAIN_MENU_SERVICES_RESTAURANTES') },
                { 'value': 'eventos', 'text': this.$t('default.MAIN_MENU_SERVICES_EVENTOS') },
                { 'value': 'experiencias', 'text': this.$t('default.MAIN_MENU_SERVICES_EXPERIENCIAS') },
                { 'value': 'boda', 'text': this.$t('default.MAIN_MENU_SERVICES_BODA') },
                { 'value': 'espacios', 'text': this.$t('default.MAIN_MENU_SERVICES_ESPACIOS') },
                { 'value': 'museo', 'text': this.$t('default.MAIN_MENU_SERVICES_MUSEOS') }
            ]

            return servicios;
        },
        _availableMonuments(){
            const allMonuments = this.monumentos ?? [];
            const allServices = this.serviciosMonumentos ?? [];
            const allPacks = this.allPacks ?? [];

            const monumentsByName = this.nameFiltering( allMonuments, this.filtroPorNombre );

            const monumentsByType = this.typeFiltering( allMonuments, this.tiposDeMonumentos, this.servicios, this.paquetes );
            const monumentsByCommunity = this.communityFiltering( allMonuments, this.localizacionesFilter );
            const monumentByService = this.serviceFiltering( allMonuments, this.serviciosFilter );

            let _matchedMonuments = allMonuments
                .filter( m => monumentsByName.includes(m._id) && monumentsByType.includes( m._id ) && monumentsByCommunity.includes( m._id) && monumentByService.includes(m._id) )

            const servicesByName = this.nameFiltering( allServices, this.filtroPorNombre );
            const servicesByType = this.typeFiltering( allServices.map( m => ( {...m, tipoDeMonumento: m.subtipoDeServicio } )), this.tiposDeMonumentos );
            const servicesByCommunity = this.communityFiltering( allServices, this.localizacionesFilter );
            let _matchedMonumentsServices = allServices
                .filter( m => 
                servicesByName.includes(m._id) 
                && servicesByType.includes( m._id ) 
                && servicesByCommunity.includes( m._id) )

            if( this.paquetes && !this.servicios ){
                _matchedMonumentsServices = [];
            }
            const servicesToMonuments = _matchedMonumentsServices.map( s => {
                const obj = {
                    nombre: s.nombres[0].texto,
                    nombreDelMonumento: s.castilloNombres[0].texto,
                    tipoDeMonumento: s.subtipoDeServicio,
                    _id: s.monumentoId,
                    coordenadas: {
                        lat: parseFloat(s.location.coordinates[1]),
                        lng: parseFloat(s.location.coordinates[0]),
                    },
                    servicio: true,
                    serviceId: s._id
                };
                return obj;
            })

            _matchedMonuments = _matchedMonuments.concat( servicesToMonuments );

            let _matchedPacks = []
            if( this.paquetes ){
                const packs = this.allPacks;
                _matchedPacks = packs.map( s => {
                    const obj = {
                        nombre: s.nombre,
                        esPromotor: s.promotorId ? true : false,
                        _id: s._id,
                        // nombreDelMonumento: s.castilloNombres[0].texto,
                        tipoDeMonumento: 'Paquete',
                        // _id: s.monumentoId,
                        coordenadas: {
                            lat: parseFloat(s.ubicacion.coordinates[1]),
                            lng: parseFloat(s.ubicacion.coordinates[0]),
                        },
                        servicio: false,
                    };
                    return obj;
                })
                // _matchedMonuments = _matchedPacks;
            }
            _matchedMonuments = _matchedMonuments.concat(_matchedPacks);

            // if( this.servicios ){
                // const matchedIds = _matchedMonuments.map( m => m._id );
                // const allServices = this.serviciosMonumentos ?? [];

                // const processServices = _matchedMonumentsServices.map( s => {
                //     const obj = {
                //         nombre: s.nombres[0].texto,
                //         nombreDelMonumento: s.castilloNombres[0].texto,
                //         tipoDeMonumento: s.subtipoDeServicio,
                //         _id: s.monumentoId,
                //         coordenadas: {
                //             lat: parseFloat(s.location.coordinates[1]),
                //             lng: parseFloat(s.location.coordinates[0]),
                //         },
                //         servicio: true
                //     };
                //     return obj;
                // })

            // }
            return _matchedMonuments;
        },
        _availableCommunities(){
            const _comunidades = this.comunidadesAutonomas.map( ca => ca);
            return _comunidades;
        },
        _opcionesDeRutas(){

            const language = this.$i18n.locale ?? "es";

            const _rutasAutor = [];
            const _rutasColeccionistas = [];
            const _rutasSenderismo = [];
            const _rutasHistoricas = [];
            this.allRoutes.filter( r => {
                if( this.localizacionesFilter){
                    return r.comunidadesAutonomas.find( c => c.value === this.localizacionesFilter);
                }else{
                    return true;
                }
            } ).forEach( r => {
                const name = r.nombres.find( n => n.idioma === language )?.texto;
                const _castillosIds = r.castillos.map( c => c._id );

                if( r.tipo === 'Autor'){
                    _rutasAutor.push( {value: r._id, text: name, comunidades: r.comunidadesAutonomas, castillos: _castillosIds, tipo: r.tipo } )
                }
                if( r.tipo === 'Collector'){
                    _rutasColeccionistas.push( {value: r._id, text: name, comunidades: r.comunidadesAutonomas, castillos: _castillosIds, tipo: r.tipo } )
                }
                if( r.tipo === 'Hicking'){
                    _rutasSenderismo.push( {value: r._id, text: name, comunidades: r.comunidadesAutonomas, castillos: _castillosIds, tipo: r.tipo } )
                }
                if( r.tipo === 'Historical'){
                    _rutasHistoricas.push( {value: r._id, text: name, comunidades: r.comunidadesAutonomas, castillos: _castillosIds, tipo: r.tipo } )
                }
            })

            const _rutasOptions = [
                {label: 'Rutas de Autor', options: _rutasAutor },
                {label: 'Rutas de Coleccionista', options: _rutasColeccionistas },
                {label: 'Rutas de Senderismo', options: _rutasSenderismo },
                {label: 'Rutas Históricas', options: _rutasHistoricas },
            ]
            return _rutasOptions;
        }

    },
    async created() {
        let $vm = this;
        // Buscar comunidades autónomas
        try{
            const _comunidadesResult = await ApiService.getComunidadesAutonomas();
            this.comunidadesAutonomas = _comunidadesResult.data;
        }catch( err ){
            console.log( err );
        }

        // Buscar coordenadas
        try{
            const _coordinatesResult = await ApiService.getAllCordinates("es")
            this.monumentos = _coordinatesResult.data;
            this.tempMonumentos = this.monumentos;
        }catch( err ){
            console.log( err );
        }
        // Buscar rutas
        try{
            const _rutasResult = await ApiService.getAllRutas();
            let data = _rutasResult.data
            this.allRoutes = _rutasResult.data;
        }catch( err ){
            console.log( err );
        }

        try{
            const _getPaquetes = await ApiService.getPaquetes();
            _getPaquetes.data.forEach(pack => {
                const nombre = pack.contenido ? pack.nombre : pack.name;
                const nombreNormalizado = nombre.normalize("NFD")
                    .normalize("NFD")                         
                    .replace(/[\u0300-\u036f]/g, '')          
                    .replace(/[^0-9a-zA-Z]+/g, '-')           
                    .toLowerCase()                           
                    .replace(/^-+|-+$/g, '');

                if (pack.contenido) {
                    for (const item of pack.contenido) {
                        if (item.type === 'visita') {
                            pack.monumentoId = item.monumentoId;
                        }
                    }
                }
                pack.nombre = nombre;
                pack.link = pack.contenido ? `/paquete/${nombreNormalizado}/${pack._id}` : `/paquetes/monumentos/${nombreNormalizado}/${pack._id}`;
                // El paquete viene de monumento_paquetes, por lo que no tiene el campo ubicacion
                if (!pack.ubicacion || !pack.ubicacion?.coordinates?.length) {
                    // if ubicacion doenst exist we need to add the field and set the coordinates
                    const monumento = this.monumentos.find(m => m._id === pack.monumentoId);
                    if (monumento) {
                        pack.ubicacion = { coordinates: [] }; // Initialize ubicacion with an empty coordinates array
                        pack.ubicacion.coordinates[1] = monumento.coordenadas.lat;
                        pack.ubicacion.coordinates[0] = monumento.coordenadas.lng;
                    }
                }
            })
            this.allPacks =  _getPaquetes.data;
        }catch( err ){
            console.log( err );
        }
        try{
            const monumentsService = await ApiService.getMonumentsServices();
            this.serviciosMonumentos = monumentsService.data?.allServices ?? [];
        }catch( err ){
            console.log( err );
        }
    },
    watch: {
        _availableMonuments( availableMonuments ){
            this.setMarkers( availableMonuments );
        }
    }
}
</script>

<style scoped>
.markers {
    background-color: var(--spainhn-rojo-corporativo);
    color: black;
}

@media (min-width: 1440px) {
    .mapa {
        position: relative;
        z-index: 3;
        min-height: 85vh;
        height: auto;
    }

}

@media (max-width: 1440px) {
    .mapa {
        position: absolute;
        z-index: 3;
        min-height: 90vh;
        height: auto;
    }

}

@media (max-width: 768px) {
    .search-title .spainhn-subtitle-text-white {
        font-size: 16px;
    }

    .search-title .spainhn-button {
        font-size: 18px;
    }
}
</style>
