<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="79" height="78" viewBox="0 0 79 78" fill="none">
        <circle cx="39.7704" cy="38.8908" r="26.4454" :class="{ 'svg-pulso': pulse }"  fill="#850B0B" fill-opacity="0.8" />
        <mask id="path-2-inside-1_830_278" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M41.8533 27.2236L44.8228 30.1931C43.6896 31.4658 43.7332 33.4174 44.9537 34.6378C46.1741 35.8582 48.1257 35.9018 49.3983 34.7686L52.0984 37.4687L38.3484 51.2187L35.6484 48.5187C35.6956 48.4767 35.7418 48.433 35.7871 48.3878C37.0527 47.1221 37.0527 45.0701 35.7871 43.8045C34.5214 42.5388 32.4694 42.5388 31.2038 43.8045C31.1585 43.8497 31.1149 43.896 31.0729 43.9432L28.1033 40.9736L41.8533 27.2236Z" />
        </mask>
        <path
            d="M44.8228 30.1931L45.5697 30.8581L46.1972 30.1533L45.5299 29.486L44.8228 30.1931ZM41.8533 27.2236L42.5604 26.5165L41.8533 25.8094L41.1462 26.5165L41.8533 27.2236ZM49.3983 34.7686L50.1054 34.0615L49.4382 33.3942L48.7334 34.0218L49.3983 34.7686ZM52.0984 37.4687L52.8055 38.1758L53.5126 37.4687L52.8055 36.7616L52.0984 37.4687ZM38.3484 51.2187L37.6413 51.9258L38.3484 52.6329L39.0555 51.9258L38.3484 51.2187ZM35.6484 48.5187L34.9834 47.7718L34.192 48.4765L34.9413 49.2258L35.6484 48.5187ZM31.0729 43.9432L30.3657 44.6503L31.1151 45.3996L31.8197 44.6081L31.0729 43.9432ZM28.1033 40.9736L27.3962 40.2665L26.6891 40.9736L27.3962 41.6807L28.1033 40.9736ZM45.5299 29.486L42.5604 26.5165L41.1462 27.9307L44.1157 30.9002L45.5299 29.486ZM45.6608 33.9307C44.8172 33.0871 44.7867 31.7374 45.5697 30.8581L44.0759 29.5281C42.5925 31.1942 42.6493 33.7477 44.2465 35.3449L45.6608 33.9307ZM48.7334 34.0218C47.854 34.8047 46.5044 34.7743 45.6608 33.9307L44.2465 35.3449C45.8438 36.9421 48.3973 36.9989 50.0633 35.5155L48.7334 34.0218ZM52.8055 36.7616L50.1054 34.0615L48.6912 35.4757L51.3913 38.1758L52.8055 36.7616ZM39.0555 51.9258L52.8055 38.1758L51.3913 36.7616L37.6413 50.5116L39.0555 51.9258ZM34.9413 49.2258L37.6413 51.9258L39.0555 50.5116L36.3555 47.8116L34.9413 49.2258ZM35.08 47.6807C35.0484 47.7123 35.0162 47.7426 34.9834 47.7718L36.3134 49.2656C36.375 49.2107 36.4353 49.1538 36.4942 49.0949L35.08 47.6807ZM35.08 44.5116C35.9551 45.3867 35.9551 46.8056 35.08 47.6807L36.4942 49.0949C38.1504 47.4387 38.1504 44.7535 36.4942 43.0973L35.08 44.5116ZM31.9109 44.5116C32.786 43.6364 34.2048 43.6364 35.08 44.5116L36.4942 43.0973C34.838 41.4412 32.1528 41.4412 30.4966 43.0973L31.9109 44.5116ZM31.8197 44.6081C31.8489 44.5754 31.8793 44.5431 31.9109 44.5116L30.4966 43.0973C30.4377 43.1563 30.3808 43.2166 30.326 43.2782L31.8197 44.6081ZM27.3962 41.6807L30.3657 44.6503L31.78 43.236L28.8104 40.2665L27.3962 41.6807ZM41.1462 26.5165L27.3962 40.2665L28.8104 41.6807L42.5604 27.9307L41.1462 26.5165Z"
            fill="white" mask="url(#path-2-inside-1_830_278)" />
        <path d="M31.9922 37.044L41.6981 46.7499" stroke="white" stroke-dasharray="1 1" />
        <path d="M37.9236 31.1126L47.6295 40.8185" stroke="white" stroke-dasharray="1 1" />
    </svg>
</template>

<script>
export default {
    props: {
        pulse: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style>
@keyframes pulso {

    0%,
    100% {
        transform: scale(1);
        opacity: 0.7;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }
}

.svg-pulso {
    animation: pulso 2s infinite;
    transform-origin: center center;
}
</style>