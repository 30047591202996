<template>
    <div class="h-100 pb-4 pt-2 col-12 col-lg-6 col-xxl-4 px-4 container-filter"
        v-bind:class="{ 'd-none': !searchBlockStatus }"
        style="position: fixed;top: 0; left: 0;z-index: 99999; height: 100%; overflow: scroll;">

        <form @submit.prevent="submit" @reset="resetForm">
            <div class="h-100 px-lg-3 pt-0 p-md-3 pb-3 d-flex flex-column mt-auto search-title">
                <div class="px-0 mx-0 border-bottom mt-auto"
                    style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;">
                    <strong class="d-flex py-2 py-md-3">
                        <span style="text-wrap: balance;" class="w-100 text-start spainhn-subtitle-text-white w-75">{{
                            $t('default.HOME_SEARCHBOX_TITLE')
                            }}</span>
                        <font-awesome-icon role="button" class="my-auto ms-auto" @click="hideFilter"
                            icon="fa-circle-chevron-left" size="2x" />
                    </strong>
                </div>

                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.DISPONIBILITY').toUpperCase() }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col class="py-3">
                            <div class="d-flex align-content-center justify-content-evenly">
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" name="DISPONIBILITY"
                                            v-model="formData.disponibilityType" :id="'defaultUnchecked' + idFilter"
                                            value="TODAY" checked>
                                        <label class="custom-control-label" :for="'defaultUnchecked' + idFilter">{{
                                            $t('default.TODAY')
                                            }}</label>
                                    </div>
                                </div>
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" name="DISPONIBILITY"
                                            v-model="formData.disponibilityType" :id="'defaultUnchecked2' + idFilter"
                                            value="TOMORROW">
                                        <label class="custom-control-label" :for="'defaultUnchecked2' + idFilter">{{
                                            $t('default.TOMORROW')
                                            }}</label>
                                    </div>
                                </div>
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio position-relative">
                                        <input type="radio" class="custom-control-input" name="DISPONIBILITY"
                                            v-model="formData.disponibilityType" :id="'defaultUnchecked3' + idFilter"
                                            value="CALENDAR">
                                        <label class="custom-control-label" :for="'defaultUnchecked3' + idFilter">{{
                                            $t('default.CALENDAR')
                                            }}</label>
                                        <DatePickerRange @date="updateDate"
                                            class="position-absolute top-100 data-picker "
                                            :class="{ 'd-none': !(formData.disponibilityType === 'CALENDAR') }" />
                                    </div>
                                </div>
                            </div>
                        </mdb-col>
                    </mdb-row>
                </div>
                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.MAIN_MENU_MONUMENTOS').toUpperCase() }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col class="py-3">
                            <div class="d-flex flex-column align-content-start text-start px-2 justify-content-center">
                                
                                <!-- <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.monumentsType" value="CASTLES" type="radio"
                                            class="custom-control-input" name="MONUMENTOS"
                                            :id="'defaultUnchecked1' + idFilter">
                                        <label class="custom-control-label" :for="'defaultUnchecked1' + idFilter">{{
                                            $t('default.HOME_SEARCHBOX_CASTLE').toUpperCase() }}</label>
                                    </div>
                                </div>
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.monumentsType" value="PALACIOS" type="radio"
                                            class="custom-control-input" name="MONUMENTOS"
                                            :id="'defaultUnchecked22' + idFilter">
                                        <label class="custom-control-label" :for="'defaultUnchecked22' + idFilter">{{
                                            $t('default.HOME_SEARCBOX_PALACIOS').toUpperCase() }}</label>
                                    </div>
                                </div>
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.monumentsType" value="MONASTERIES" type="radio"
                                            class="custom-control-input" name="MONUMENTOS"
                                            :id="'defaultUnchecked33' + idFilter">
                                        <label class="custom-control-label" :for="'defaultUnchecked33' + idFilter">{{
                                            $t('default.HOME_SEARCBOX_MONASTERIES').toUpperCase() }}</label>
                                    </div>
                                </div> -->
                                <div class="disponibility-body" v-for="tipo in tiposDeMonumentos" :key="tipo.id">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.monumentsType" :value="tipo.value" type="radio"
                                            class="custom-control-input" name="MONUMENTOS"
                                            :id="tipo.id" checked>
                                        <label class="custom-control-label" :for="tipo.id">{{
                                            tipo?.label.toUpperCase() }}</label>
                                    </div>
                                </div>

                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.monumentsType" value="ALL" type="radio"
                                            class="custom-control-input" name="MONUMENTOS"
                                            :id="'defaultUnchecked44' + idFilter" checked>
                                        <label class="custom-control-label" :for="'defaultUnchecked44' + idFilter">{{
                                            $t('default.ALL').toUpperCase() }}</label>
                                    </div>
                                </div>
                            </div>
                        </mdb-col>
                    </mdb-row>
                </div>
                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.ACTIVITIES') }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col class="py-3">
                            <div class="d-flex flex-column align-content-start text-start px-2 justify-content-center">
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.activitiesType" value="ENTRANCES" type="radio"
                                            class="custom-control-input" name="ACTIVITIES"
                                            :id="'defaultUnchecked11' + idFilter">
                                        <label class="custom-control-label" :for="'defaultUnchecked11' + idFilter">{{
                                            $t('default.MONUMENTS_ENTRANCES') }}</label>
                                    </div>
                                </div>
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.activitiesType" value="ANOTHER_ENTRANCES" type="radio"
                                            class="custom-control-input" name="ACTIVITIES"
                                            :id="'defaultUnchecked222' + idFilter">
                                        <label class="custom-control-label" :for="'defaultUnchecked222' + idFilter">{{
                                            $t('default.ANOTHER_ACTIVITIES') }}</label>
                                    </div>
                                </div>
                                <div class="disponibility-body">
                                    <div class="custom-control custom-radio">
                                        <input v-model="formData.activitiesType" value="ALL" type="radio"
                                            class="custom-control-input" name="ACTIVITIES"
                                            :id="'defaultUnchecked333' + idFilter" checked>
                                        <label class="custom-control-label" :for="'defaultUnchecked333' + idFilter">{{
                                            $t('default.ALL').toUpperCase() }}</label>
                                    </div>
                                </div>
                            </div>
                        </mdb-col>
                    </mdb-row>
                </div>
                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.MAIN_MENU_RUTAS') }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row> 
                        <mdb-col>
                            <mdb-select search outline v-model="rutasOptions" :label="$t('default.FILTER.RUTAS_LABEL')" />
                        </mdb-col>
                    </mdb-row>
                </div>
                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.LOCATION') }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col>
                            <mdb-select outline v-model="comunidadesAutonomasOptions" :label="$t('default.FILTER.COMUNIDAD')" />
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col>
                            <mdb-select outline v-model="provinciasOptions" :label="$t('default.FILTER.PROVINCIA')" />
                        </mdb-col>
                    </mdb-row>
                </div>
                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.DISTANCE') }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col>
                            <div class="pt-1 pb-1 px-3">
                                <Slider v-model="formData.distance" id="distance" name="distance"
                                    class="slider-red mt-5" merge-h :format="format" :min="0" :max="500"
                                    showTooltip="always" />
                            </div>
                        </mdb-col>
                    </mdb-row>
                </div>
                <div class="container mt-3">
                    <mdb-row>
                        <mdb-col>
                            <div class="header-filter spainhn-gray-background p-2 text-center">
                                <span>{{ $t('default.PRICE') }}</span>
                            </div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col>
                            <div class="pt-1 pb-1 px-3">
                                <Slider v-model="formData.price" search class="slider-red mt-5" merge-h
                                    :format="formatPrice" :min="0" :max="150" showTooltip="always" />
                            </div>
                        </mdb-col>
                    </mdb-row>
                </div>
            </div>
            <div class="d-flex justify-content-evenly">
                <button type="reset" class="btn btn-lg spainhn-gray-background" style="font-weight: bold;">{{
                    $t('default.REMOVE_FILTERS') }}</button>
                <button type="submit" class="btn btn-lg"
                    style="background-color: var(--spainhn-rojo-grisaceo) !important;color: var(--spainhn-blanco-oscuro); font-weight: bold;">{{
                        $t('default.SEE_RESULTS') }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import ApiService from '../services/ApiService';
import DatePickerRange from './DatePickerRange.vue';
// import { uuid } from 'vue-uuid';
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'FilterMobile',
    components: {
        Slider,
        DatePickerRange
    },
    props: {
        searchBlockStatus: {
            type: Boolean,
            default: false
        },
        idFilter: {
            type: String,
            required: true,
            default: 'filter'
        }
    },
    data() {
        return {
            formData: {
                disponibilityType: 'TODAY',
                monumentsType: 'ALL',
                activitiesType: 'ALL',
                distance: [0, 500],
                price: [0, 150],
                comunidadAutonoma: null,
                provincia: null,
                ruta: null,
                location: {
                    lat: null,
                    lng: null
                },
                from: "",
                to: "",
                x: null

            },
            filtroPorNombre: '',
            castillosCheck: false,
            palaciosCheck: false,
            paradoresCheck: false,
            localizacionesFilter: '',
            rutaFilter: '',
            serviciosFilter: '',
            rutasOptions: [],
            serviciosOptions: [],
            priceRange: 0,

            outlineOptions: null,
            format: (v) => {
                if (v === 500) {
                    return this.$t("default.FILTER.NO_LIMIT");
                }
                return `${Math.round(v)} Km`
            },
            formatPrice: (v) => {
                if (v === 0) {
                    return this.$t("default.FILTER.FREE");
                }
                return `${Math.round(v)} €`;
            },
            comunidadesAutonomas: [
                { value: null, text: this.$t('default.SELECT_COMUNIDAD') },
                { value: "Andalucía", text: "Andalucía" },
                { value: "Aragón", text: "Aragón" },
                { value: "Asturias, Principado de", text: "Asturias, Principado de" },
                { value: "Balears, Illes", text: "Balears, Illes" },
                { value: "Canarias", text: "Canarias" },
                { value: "Cantabria", text: "Cantabria" },
                { value: "Castilla - La Mancha", text: "Castilla - La Mancha" },
                { value: "Castilla y León", text: "Castilla y León" },
                { value: "Catalunya", text: "Catalunya" },
                { value: "Ceuta", text: "Ceuta" },
                { value: "Comunitat Valenciana", text: "Comunidad Valenciana" },
                { value: "Extremadura", text: "Extremadura" },
                { value: "Galicia", text: "Galicia" },
                { value: "Madrid, Comunidad de", text: "Madrid" },
                { value: "Ciudad de Melilla", text: "Melilla, Ciudad de" },
                { value: "Murcia, Región de", text: "Murcia, Región de" },
                { value: "Navarra, Comunidad Foral de", text: "Navarra, Comunidad Foral de" },
                { value: "País Vasco", text: "País Vasco" },
                { value: "Rioja, La", text: "Rioja, La" }
            ],
            provincias: [],
            tiposDeMonumentos: [],
        };
    },
    methods: {
        toggleSearchbar() {
            this.searchBlockStatus = !this.searchBlockStatus;
        },
        filtrarNombre() {
        },
        limpiarFiltros() {
            // Implement your logic here
        },
        hideFilter() {
            this.$emit('hideFilter', false);
        },
        async submit() {

            if ((this.formData.distance[1] !== 500 || this.formData.distance[0] !== 0) && this.formData.location === null) {
                const userLocation = await this.getUserLocation();
                if (!userLocation) {
                    return window.alert('Se necesita la ubicación para poder filtrar por distancia')
                }

                /* const { data } = await ApiService.getVisitasFiltered({
                    ...this.formData,
                    location: userLocation
                }); */

            } /* else {
                const { data } = await ApiService.getVisitasFiltered({ ...this.formData });
            } */

            this.$store.commit('setParamsFilter', this.formData)

            this.$router.push({ path: '/resultados', query: { ...this.formData, ...this.$store.state.location, provincia: this.provincias.find(item => item.selected === true)?.value } });
        },
        resetForm() {
            this.comunidadesAutonomas = this.comunidadesAutonomas.map((item) => ({ ...item, selected: false }));
            this.provincias = this.provincias.map((item) => ({ ...item, selected: false }));
            this.rutasOptions = this.rutasOptions.map((item) => ({ ...item, selected: false }));

            this.formData = {
                ...this.formData,
                disponibilityType: 'TODAY',
                monumentsType: 'ALL',
                activitiesType: 'ALL',
                distance: [0, 500],
                price: [0, 150],
            }
        },
        async getUserLocation() {
            return new Promise((resolve, reject) => {
                window.navigator.geolocation.getCurrentPosition(
                    position => {
                        this.$store.commit('setLocation', {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        });

                        this.formData.location = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        });
                    },
                    error => {
                        this.formData.location = null;
                        this.formData.distance = [0, 500];
                        resolve(null);
                    }
                );
            });
        },
        updateDate({ start, end }) {
            this.formData.from = start;
            this.formData.to = end;
        },
        async getProvincias() {
            const provincias = await ApiService.getProvincias();
            this.provincias = provincias.map((item) => {
                return {
                    value: item.nombre,
                    text: item.nombre,
                    comunidad: item.comunidad
                }
            });
        }
    },
    watch: {
        comunidadesAutonomas: function (newValue) {
            this.formData.comunidadAutonoma = newValue.find((item) => item.selected === true)?.value || null;

            this.provincias = this.provincias.map((item) => ({ ...item, selected: false }));
        },
        rutasOptions: function (newValue) {
            this.formData.ruta = newValue.find((item) => item.selected === true)?.value || null;
        }
    },
    computed: {
        comunidadesAutonomasOptions() {
            const selectedRuta = this.rutasOptions.find((item) => item.selected === true);

            if (!selectedRuta || selectedRuta?.value === null) {
                return this.comunidadesAutonomas;
            }

            const comunidadesAutonomasByRutas = selectedRuta.castillos.map((item) => {
                return item._id.comunidad;
            });

            return this.comunidadesAutonomas.filter((item) => comunidadesAutonomasByRutas.includes(item.value) || item.value === null);
        },
        provinciasOptions() {
            const selectedComunidad = this.comunidadesAutonomas.find((item) => item.selected === true);
            if (!selectedComunidad || selectedComunidad?.value === null) {
                return this.provincias;
            }

            return this.provincias.filter((item) => item.comunidad === selectedComunidad.value);
        }
    },
    async created() {
        await this.getProvincias();
        const _tipoDeMonumnetos  = (await ApiService.getTiposDeMonumento()).data;

        this.tiposDeMonumentos = _tipoDeMonumnetos.map( t => {
            const obj = {
                id: uuidv4(),
                value: t.value,
                label: t.webName,
            }
            return obj;
        })

        const idioma = this.$store.getters.getIdioma;
        const { data } = await ApiService.getAllRutas( idioma )
        this.rutasOptions = data?.map((item) => {
            const currentLang = this.$i18n.locale;
            return {
                value: item._id,
                // text: item.nombres?.find((item) => item.idioma == currentLang)?.texto,
                text: item.webName,
                castillos: item.castillos
            }
        })

        this.rutasOptions.unshift({ value: null, text: this.$t('default.SELECT_RUTA') });

        if (this.$store.state.location?.lat && this.$store.state.location?.lng) {
            this.formData = {
                ...this.formData,
                location: this.$store.state.location
            }
        } else {
            await this.getUserLocation();
        }
    }
};
</script>


<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.container-filter {
    background-color: var(--spainhn-blanco-oscuro);
}

.header-filter {
    /* background-color: var(--spainhn-gris-oscuro); */
    font-weight: bold;
    font-size: 16px;
    /* color: var(--spainhn-blanco-oscuro) */
}

.checkbox-rounded [type="checkbox"][class*='filled-in']+label:after {
    border-radius: 50%;
}

.data-picker {
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
</style>