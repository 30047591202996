var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("header-image-rutas"),
      _c("white-loader", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "continaer-fluid d-md-none" },
        [_c("tools-component", { staticStyle: { width: "100%" } })],
        1
      ),
      _c("div", { staticClass: "d-md-none container-fluid" }, [
        _c(
          "span",
          {
            staticClass:
              "spainhn-sections-title my-2 text-start text-uppercase",
          },
          [_vm._v(" " + _vm._s(_vm.$t("default.ROUTES_TITLE")) + " ")]
        ),
        _c(
          "div",
          { staticClass: "row d-md-none px-1" },
          _vm._l(_vm.rutas, function (ruta, index) {
            return _c(
              "div",
              { key: index, staticClass: "col px-0" },
              [
                _c("cards-general", {
                  attrs: {
                    showSubtitle: false,
                    title: ruta.webName,
                    "image-url": `${_vm.imagenesUrl}/rutas/logo/${ruta?.oldId}`,
                  },
                  on: { goToUrl: () => _vm.goToUrl(ruta._id) },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "container-fluid px-5 rutas-main d-md-inline-block d-none",
        },
        [
          _c(
            "p",
            { staticClass: "spainhn-subtitle-2 mb-0 mt-auto text-start" },
            [_vm._v(_vm._s(_vm.$t("default.ROUTES_TITLE")))]
          ),
          _c(
            "div",
            {
              staticClass:
                "d-md-flex justify-content-around pt-0 mt-0 mb-5 d-none",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "ms-0 col-md-4 text-start spainhn-text pe-2" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("default.ROUTES_DESCRIPTION_TEXT")) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "col-md-8" }, [
                  _c("div", { staticClass: "ms-0 h-100 d-flex flex-column" }, [
                    _c(
                      "p",
                      {
                        staticClass: "spainhn-subtitle-3 mb-0 text-start mt-0",
                      },
                      [_vm._v(_vm._s(_vm.$t("default.ROUTES_FILTER_TEXT")))]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-6 col-12 pt-3 align-items-start justify-content-letf",
                        },
                        [
                          _c(
                            "mdb-btn",
                            {
                              staticClass: "spainhn-button d-block w-75",
                              attrs: { color: "indigo" },
                              on: { click: _vm.setHistorical },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.ROUTES_FILTER_HISTORICAL")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-6 col-12 pt-3 align-items-start",
                        },
                        [
                          _c(
                            "mdb-btn",
                            {
                              staticClass: "spainhn-button d-block w-75",
                              attrs: { color: "indigo" },
                              on: { click: _vm.setAutor },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.ROUTES_FILTER_AUTOR"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-6 col-12 pt-3 align-items-start",
                        },
                        [
                          _c(
                            "mdb-btn",
                            {
                              staticClass: "spainhn-button d-block w-75",
                              attrs: { color: "indigo" },
                              on: { click: _vm.setHicking },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.ROUTES_FILTER_HICKING"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-6 col-12 pt-3 align-items-start",
                        },
                        [
                          _c(
                            "mdb-btn",
                            {
                              staticClass: "spainhn-button d-block w-75",
                              attrs: { color: "indigo" },
                              on: { click: _vm.setColeccionista },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.ROUTES_FILTER_COLLECTOR")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c("div"),
          _c("div", { staticClass: "d-md-flex mb-5 mt-0 d-none" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-md-around px-0 mx-auto w-100 flex-wrap",
              },
              _vm._l(_vm.rutas, function (ruta, index) {
                return _c("cards-3", {
                  key: index,
                  class: {
                    "d-none": ruta.tipo != _vm.filter && _vm.filter != null,
                  },
                  attrs: {
                    title: ruta.webName,
                    linkId: ruta._id,
                    imageUrl: `${_vm.imagenesUrl}/rutas/logo/${ruta.oldId}`,
                  },
                  on: {
                    goTo: function ($event) {
                      return _vm.goToRuta($event)
                    },
                  },
                })
              }),
              1
            ),
          ]),
        ]
      ),
      _c("ComponentsCloud"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }