import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",

  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: Object },
    waypoints: { type: Array, default: () => [] },
    destination: { type: Object },
    travelMode: { type: String },
    optimize_waypoint_order: {type: Boolean}
  },

  afterCreate(directionsRenderer) {    
    let directionsService = new window.google.maps.DirectionsService();       
    this.$watch(
      () => [this.origin, this.destination, this.travelMode, this.waypoints, this.optimize_waypoint_order],
      () => {
        let { origin, destination, travelMode, waypoints, optimize_waypoint_order } = this;
        // console.log(waypoints);

        if (!origin || !destination || !travelMode) {            
            
            
            directionsRenderer.set('directions', null);
            

            return
        }

        directionsService.route(
          {
            origin,
            destination,
            travelMode, 
            waypoints,
            optimizeWaypoints:true
          },
          (response, status) => {
              console.log(response);
              console.log(status);
            if (status !== "OK"){
                // console.log(status)
                return;  
            } 
            // directionsRenderer.setSuppressMarkers(true);
            directionsRenderer.setOptions( { suppressMarkers: true } );
            directionsRenderer.setDirections(response);

          }
        );
      }
    );
  }
});