var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 mt-2" }, [
    _c("div", { staticClass: "row", staticStyle: { height: "175px" } }, [
      _c("div", {
        staticClass: "card my-auto mt-2 mt-0",
        style: { backgroundImage: _vm.image },
        attrs: { role: "button" },
        on: { click: _vm.goTo },
      }),
      _c(
        "div",
        {
          staticClass: "col flex-wrap text-start pt-2",
          staticStyle: { height: "135px", width: "123px", "max-width": "90vw" },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-truncate spainhn-text-small pb-1 mb-2 pt-1",
              staticStyle: {
                "font-size": "8pt",
                height: "120px",
                width: "350px",
                "max-width": "100%",
                "white-space": "initial",
                overflow: "hidden",
              },
            },
            [
              _c(
                "strong",
                {
                  staticClass: "spainhn-text flex-wrap",
                  staticStyle: {
                    "font-size": "9pt",
                    color: "var(--spainhn-rojo-corporativo)",
                  },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _c("p", {
                staticClass: "mb-0",
                staticStyle: {
                  "margin-bottom": "0px",
                  height: "30px !important",
                  "text-overflow": "ellipsis !important",
                  "overflow-wrap": "break-word",
                },
                domProps: { innerHTML: _vm._s(_vm.text) },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "text-truncate spainhn-text pb-1",
              staticStyle: { "font-size": "10pt" },
            },
            [
              _c(
                "p",
                {
                  staticClass: "spainhn-text",
                  staticStyle: { color: "var(--spainhn-rojo-corporativo)" },
                },
                [_c("strong", [_vm._v(_vm._s(_vm.fechasEvento))])]
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }