var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100", staticStyle: { "min-width": "20rem" } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 flex-wrap" }, [
          _c("strong", { staticClass: "spainhn-small-text text-wrap" }, [
            _vm._v(_vm._s(_vm.item.itemMonumento?.[0]?.texto)),
          ]),
          _c("p", { staticClass: "spainhn-small-text mb-0 pb-0 text-wrap" }, [
            _vm._v(_vm._s(_vm.item.itemVisita?.[0]?.texto)),
          ]),
          _c("small", { staticClass: "spainhn-small-text" }, [
            _vm._v(" " + _vm._s(_vm.fecha)),
          ]),
        ]),
        _c("div", { staticClass: "col-3 d-flex" }, [
          _c("strong", { staticClass: "spainhn-text my-auto" }, [
            _vm._v("X " + _vm._s(_vm.ticketsPorEntrada)),
          ]),
        ]),
        _c("div", { staticClass: "col-3 d-flex" }, [
          _c("strong", { staticClass: "spainhn-text my-auto" }, [
            _vm._v(
              _vm._s(
                _vm.totalPorEntrada.toLocaleString("es-ES", {
                  maximumFractionDigits: 2,
                })
              ) + " €"
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }