
import http from "../http-common";

class HotelbedsService{

    async findNearlyHotels( params ){
        const result = await http.get('/hotelbeds/nearly-hotels', {
            params: params
        } );
        return result.data;
    }

    availabilityHotelBeds(checkIn, checkOut, rooms, adults, children, hotelId, geoLocation, stay, occupancies){
        const params = {
            checkIn: checkIn,
            checkOut: checkOut,
            rooms: rooms,
            adults: adults,
            children: children,
            hotel: hotelId,
            geoLocation: geoLocation,
            stay: stay,
            occupancies: occupancies
        }
    
        return http.post('/avaliability-by-hotel', params)
    }
    
    async availabilityHotelBedsByDate(geolocation, stay, occupancies){
        const params = {
            geolocation: geolocation,
            stay: stay,
            occupancies: occupancies
        }
    
        const result = await http.post( '/hotelbeds/avaliability-by-date', params )
        return result.data;
    }
    hotelDetailHotelBeds(hotelId, language){
        const params = {
            hotelId: hotelId,
            language: language
        }
    
        return http.post('/hotel-details', params)
    
    }

    /**
     * 
     * @param {Array} hoteles - the code of the hotels
     * @param {String} language -The language
     * @returns 
     */
    async getHotelBasicDetails( hoteles, language ){
        const params = {
            hotels: hoteles,
            language: language
        };

        const result = await http.post( '/hotelbeds/hotel-basic-details', params );

        return result.data;
    }

    checkRateKey(rateKey){
        const params = {
            rateKey: rateKey
        }

        return http.post('/ratekey-details', params)
    }
}

export default new HotelbedsService();