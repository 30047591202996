<template>
    <div>
        <b-modal size="lg" v-model="show" aria-label="" hide-header hide-footer>
            <div class="border px-2 py-4 border-success mb-3" style="background-color:rgba(204, 255, 204, 0.5)">
                <div class="d-flex">
                        <div class="d-flex mx-auto">
                        <font-awesome-icon class="my-auto text-success me-2" icon="fa-circle-check" size="3x"></font-awesome-icon>
                        <p class="my-auto"><strong class="text-spainhn">{{mostrarAlerta}}</strong></p>
                        </div>
                </div>
                <div class="d-flex w-100">
                    <div class="mx-auto">
                        <mdb-btn color="indigo" @click="cerrarModal" class="spainhn-button">ACEPTAR</mdb-btn>
                        
                    </div>
                </div>
            </div>                   
        </b-modal>
    </div>
</template>

<script>
export default {
    props:{
        mensaje: String,
        show: Boolean,
    },
    methods: {
        cerrarModal(){
            console.log("Cerrando modal");
            this.$emit('close')
            // this.$refs['alerta-modal'].hide();
            
        }
    },
    computed:{
        mostrarAlerta(){
            return this.mensaje;
        }
    }
}
</script>