<template>
    <vc-date-picker
            v-model="filtros.range"
            mode="date"
            :masks="masks"
            is-range
            :minDate="new Date()"
            class="data-picker-range"
            
        >
            <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="d-flex justify-start items-center date-picker-container">
                    <input
                        class="w-50 inputs first-input"
                        :placeholder="$t('default.CARDS.START_FROM')"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                    />
                    <div class="separator"/>
                    <input
                        placeholder="Hasta"
                        class="w-50 inputs second-input"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                    />
                </div>
            </template>
        </vc-date-picker>
</template>

<script>
export default {
    name: "DatePickerRange",
    components:{},
    data() {
        return {
            masks: {
                input: 'DD/MM/YYYY',
                display: 'DD/MM/YYYY',
            },
            filtros:{
                range: {
                    start: null,
                    end: null,
                },
            }
        };
    },
    methods:{
        siuu(e){
            console.log(e);
            console.log(this.filtros.range.start);
        }
    },
    watch: {
        'filtros.range': function (val) {
            this.$emit('date', {start: this.filtros.range.start.toISOString(), end: this.filtros.range.end.toISOString()});
        },
    }

}
</script>

<style>

.date-picker-container{
    border: 2px solid var(--spainhn-gris-oscuro);
    border-radius: 5px;
    min-width: 200px;
    overflow: hidden;
    box-sizing: border-box;
}

.separator{
    border: 1px solid var(--spainhn-gris-oscuro);
    min-height: 100%;
}

.first-input{
}


.second-input{
}

.inputs{
    border: none !important;
    text-align: center;
    color: var(--spainhn-rojo-corporativo);
    font-family: sans-serif;
}

@media (width > 768px){
    .data-picker-range > .vc-popover-content-wrapper{
        transform: translate(-20px, 28px) !important;
    }
}
</style>