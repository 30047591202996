<template>
    <default-layout>
        <div class="container-fluid px-0 px-md-5">
            <!-- <div class="d-flex justify-content-around pt-4 mb-3" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" 
                        :iconColor="'rgb(0,0,0)'" >
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black">{{monumentoNombre}}</p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div> -->

            <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
                <div class="d-flex ps-5 my-auto container">
                    <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos">{{monumentoNombre}}>> </p>
                    <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text" role="button"> {{ $t("default.MENSAJE_RUTAS") }} </p>
                </div>
            </div>

            
            <div class="d-flex mb-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards-3 v-for="(ruta, index) in rutas" v-bind:key="index" 
                        @goTo="goToRuta($event)"
                        :title="ruta.nombre"
                        :linkId="ruta._id"
                        :imageUrl="`${imagenesUrl}/rutas/logo/${ruta.rutaId}`" 
                        />
                </div>
            </div>

        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
// import IconBase from '../components/IconBase.vue'
// import IconCasttle from '../components/icons/IconCasttle.vue';
import Cards3 from '../components/Cards3.vue'

export default {
    components:{
        DefaultLayout,
        // IconBase,
        // IconCasttle,
        Cards3
    },
    data(){
        return{
            address: null,
            monumentoNombre: null,
            monumentoId: null,
            rutas: [],
            imagenesUrl: null,
        }
    },
    setup() {
        
    },
    methods:{
        goToRuta(rutaId){
            this.$router.push('/ruta/' + rutaId)
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.monumentoNombre)
        },

    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    created(){
        let $vm = this;
        // $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        $vm.castilloId = $vm.$route.params.monumentoId;
        ApiService.getRoutesByMonumento($vm.$i18n.locale, $vm.castilloId)
            .then(res =>{
                let $vm = this;
                console.log(res)
                $vm.monumentoNombre = res.data[0].castilloNombre;
                this.rutas = res.data;
            })
            .catch(err=>{
                console.log(err);
            })
    }
}
</script>