<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillo">{{monumentWebName}}>> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text" role="button">actividades </p>
                <!-- <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{info.nombres[selectedIdioma].texto}}</p> -->
            </div>
        </div>

        <div class="container-fluid px-5">
            <div class="d-flex justify-content-around pt-4 mb-3" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" 
                        :iconColor="'rgb(0,0,0)'" >
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black">{{monumentWebName}}</p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div>
            
            <div class="d-flex mb-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards-thumbnails v-for="(actividad, index) in actividades" v-bind:key="index"
                        :monument="actividad.webName"
                        :nombreUrl="actividad._id"
                        tipo="actividades"
                        :imageUrl="`${filesUrl}/actividades/` + actividad.oldId + '/'+ actividad.banner"/>
                </div>
            </div>

        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import IconBase from '../components/IconBase.vue'
import IconCasttle from '../components/icons/IconCasttle.vue';
import CardsThumbnails from '../components/CardsThumbnails.vue'

export default {
    components:{
        DefaultLayout,
        IconBase,
        IconCasttle,
        CardsThumbnails
    },
    data(){
        return{
            filesUrl: process.env.VUE_APP_IMAGES_URL,
            address: null,
            monumentoNombre: null,
            monumentoId: null,
            actividades: [],
            monumentWebName: "",
            idiomaIndex: 0
        }
    },
    setup() {
        
    },
    methods:{
        goToEntrada(visitaId){
            // console.log(visitaId)
            this.$router.push('/castillos/tarifas/'+this.monumentoNombre+'/'+visitaId)
        },
        goToCastillo(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
    },
    computed:{
        selectedIdioma(){
            let $vm=this;
            if($vm.$i18n.locale == 'es'){
                $vm.idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                $vm.idiomaIndex = 1;
            }
            return $vm.idiomaIndex;
        }
    },
    created(){
        let $vm = this;
        $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        const _language = this.$store.getters.getIdioma;
        ApiService.getEdificacion( _language, $vm.monumentoNombre )
            .then( result => {
                let data = result.data;
                $vm.monumentWebName = result.data.webName;
                $vm.address = data.direccion;
                $vm.monumentoId = data.id;
                // console.log(data);
                // $vm.edificios = [...result.data];
                ApiService.getActividadesByMonumentoId( _language, $vm.monumentoId)
                    .then( result => {
                        console.log(result.data);
                        $vm.actividades = [...result.data]
                    })
                    .catch( err=> {
                        console.log(err);
                    })
            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script>