<template>
    <mdb-card class="px-0" style="width: 320px;">
        <mdb-view>
            <img :src="`${imagePath}/${mainImagePath}`" :alt="`${name} image cap`"  style="width: 320px; height: 320px; object-fit: cover"/>
        </mdb-view>
        <mdb-card-body>
            <mdb-card-title class="font-weight-bold">
                {{ name }}
            </mdb-card-title>

            <mdb-container>
                <div class="d-flex text-warning">
                    <font-awesome-icon
                    :icon="`${stars < 1 ? 'far' : 'fa'} fa-star`"/>
                    <font-awesome-icon 
                    :icon="`${stars < 2 ? 'far' : 'fa'} fa-star`"/>
                    <font-awesome-icon 
                    :icon="`${stars < 3 ? 'far' : 'fa'} fa-star`"/>
                    <font-awesome-icon 
                    :icon="`${stars < 4 ? 'far' : 'fa'} fa-star`"/>
                    <font-awesome-icon 
                    :icon="`${stars < 5 ? 'far' : 'fa'} fa-star`"/>
                </div>
            </mdb-container>
            
            <mdb-container>
                <div class="d-flex">
                    <h5>{{ distance >= 0.01 ? Math.floor(distance * 100) : '  menos de 1' }} km</h5>
                </div>
            </mdb-container>

            <mdb-container>
                <div class="d-flex">
                    <h4>{{ minRate }}</h4>
                </div>
            </mdb-container>
            <mdb-container class="mt-2">
                <mdb-btn color="danger">VER DETALLES</mdb-btn>
            </mdb-container>
        </mdb-card-body>
    </mdb-card>
</template>

<script>
export default {
    name: 'HotelCard',
    props: {
        mainImagePath: String,
        name: String,
        distance: Number,
        category: String,
        minRate: String
    },
    data(){
        return{
            imagePath: process.env.VUE_APP_HOTELBEDS_IMAGES_PATH
        }
    },
    computed: {
        stars(){
            let cat = 0;

            switch( this.category ){
                case 'GRUPO1': 
                    cat = 1;
                    break;
                case 'GRUPO2':
                    cat = 2;
                    break;
                case 'GRUPO3':
                    cat = 3;
                    break;
                case 'GRUPO4':
                    cat = 4;
                    break;
                case 'GRUPO5':
                    cat = 5;
                    break;
                default:
                    cat = 0;
                    break;
            }
            return cat;
        }
    }
}
</script>

<style>

</style>