var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card my-auto mx-2 mt-5 px-0",
      style: { backgroundImage: _vm.image },
      attrs: { role: "button" },
      on: {
        mouseover: _vm.showText,
        mouseleave: _vm.hideText,
        click: _vm.goToMicrosite,
      },
    },
    [
      _c("div", { staticClass: "container w-100" }, [
        _c(
          "div",
          {
            staticClass: "card-body",
            class: {
              "active-background": _vm.status,
              "disabled-background": !_vm.status,
            },
          },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-column my-auto h-100" },
              [
                _c(
                  "mdb-btn",
                  {
                    staticClass: "my-auto mx-auto",
                    class: _vm.status
                      ? "spainhn-gray-background"
                      : "spainhn-button",
                    staticStyle: {
                      "border-radius": "10px",
                      "padding-top": "5px",
                      "padding-bottom": "5px",
                      border: "none !important",
                    },
                  },
                  [
                    _c("strong", { staticClass: "spainhn-lg-text" }, [
                      _vm._v(_vm._s(_vm.monument)),
                    ]),
                  ]
                ),
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(
                    _vm._s(
                      _vm.provincia ? `${_vm.municipio} - ${_vm.provincia}` : ""
                    )
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "content", class: { "d-none": !_vm.status } },
          [
            _c(
              "div",
              {
                staticClass: "pb-1 pe-2",
                staticStyle: {
                  height: "75%",
                  "overflow-y": "scroll",
                  "scrollbar-color":
                    "var(--spainhn-rojo-corporativo) #ffffff99",
                  "scrollbar-width": "thin",
                },
              },
              [
                _c("div", {
                  staticClass: "spainhn-small-text text-start",
                  domProps: { innerHTML: _vm._s(_vm.text) },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "h-25" },
              [
                _c("font-awesome-icon", {
                  staticStyle: { color: "var(--spainhn-rojo-corporativo)" },
                  attrs: { icon: "fa-plus-circle", size: "2x" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }