<template>
    <div>
        <mdb-row class="">
            <mdb-col class="text-center">
                <mdb-btn :disabled="cantidad==0" color="red" class="red darken-3 text-white mx-auto" @click="quitarPersonas">
                    <font-awesome-icon icon="fa-minus"/>
                </mdb-btn>
            </mdb-col>
            <mdb-col class="my-auto bg-white py-0 text-center h-100 mx-auto">
                <span class="">{{cantidadBoletos}}</span>
            </mdb-col>
            <mdb-col class="text-center">
                <mdb-btn :disabled="noIncrement" color="red" class="red darken-3 text-white mx-auto" @click="agregarPersonas">
                    <font-awesome-icon icon="fa-plus"/>
                </mdb-btn>
            </mdb-col>
        </mdb-row>
    </div>    
</template>

<script>
import EventBus from '../event-bus';
export default {
    props:{
        minPersonas: Number,
        tarifa: Number,
        maximoIncremento: Number,
    },
    data(){
        return{
            cantidad: 0,
            // noIncrement: false
        }
    },
    methods:{
        reset(){
            this.cantidad = 0;
        },
        quitarPersonas(){
            if(this.cantidad - 1 >= this.minPersonas){
                this.cantidad -= 1;
                this.$store.commit('quitarBoleto', 1);
            }
            else{
                this.$store.commit('quitarBoleto', this.minPersonas);
                this.cantidad = 0;
            }

            if(this.cantidad < 0){
                this.cantidad = 0;
            }

            this.$emit('change', { tarifa: this.tarifa, cantidad: this.cantidad} )
        },
        agregarPersonas(){
            if(this.cantidad + 1 <= this.minPersonas){
                this.cantidad = this.minPersonas;
                this.$store.commit('agregarBoleto', this.minPersonas)
            }
            else{
                this.cantidad += 1;
                this.$store.commit('agregarBoleto', 1)
            }
            this.$emit('change', { tarifa: this.tarifa, cantidad: this.cantidad } )
        }
    },
    computed:{
        noIncrement(){
            // console.log(this.maximoIncremento);
            if( this.$store.getters.espacioParaBoletos == null ){
                return false;
            }
            else{
                let proximasPlazas = 0;

                if( this.cantidad == 0 ){
                    proximasPlazas = this.$store.getters.boletosAgregados + this.minPersonas;
                }
                else{
                    proximasPlazas = this.$store.getters.boletosAgregados + 1;
                }

                if( proximasPlazas > this.$store.getters.espacioParaBoletos ){
                    return true
                }
                else{
                    return false;
                }
            }
        },
        cantidadBoletos(){
            return this.cantidad;
        }
    },
    mounted(){
        EventBus.$on("resetBoletos", () => {
            this.reset();
        });
    }
}
</script>