<template>
    <default-layout>
        <!-- <header-image/> -->
        <div class="spainhn-solid-red-background d-flex py-3" style="height:auto; min-height:2rem; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos">{{monumentWebName}}>> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text" role="button" @click="goToRestaurantes">{{tipo}}>> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{ info.nombres[selectedIdiomaIndex]?.texto }}</p>
            </div>
        </div>
        <div class="container-fluid px-md-5 px-2 margen-estandard">
            <div class="d-flex justify-content-around pt-4 mb-3 px-lg-5" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" 
                        :iconColor="'rgb(0,0,0)'" >
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black">{{monumentWebName}}</p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div>

            <div class="container-fluid px-lg-5">
                <div class="row">
                    <div class="text-columns text-start spainhn-text pt-3 pe-3 col-md-8 col-12">
                        <h5 class="mb-0"><strong><small>{{info.webName }}</small></strong></h5>
                        <div class="w-100 border-bottom mt-0 mb-1" style="height:2vh; border-color:rgb(242,90,85) !important;"></div>
                        <div class="spainh-small-text"><span v-html="info.webDescription"></span></div>
                    </div>
                    <div class="d-flex flex-column pt-0 pb-0 px-3 text-wrap col-md-4 col-12"> 
                        <div class="h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column" style="border-radius:10px;">
                            <h5 class="mb-3"><strong><small>{{ $t('default.ACTIVITIES_RESTAURANT_ADDRESS_CONTACT') }}</small></strong></h5>
                            <span class="text-start px-3" v-html="info.webContact||info.webInfo"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row ps-lg-4 pe-0 gx-2">
                    <div class="text-start spainhn-text pt-3 pe-0 mb-5  col-xxl-8 col-12" style="height:100%">
                        <h5 class="mb-3"><strong>{{ $t('default.TICKET_DETAILS_IMAGES') }}</strong></h5>
                            <b-carousel
                            id="monumentosShow"
                            v-model="slide"
                            :interval="4000"
                            controls
                            indicators
                            img-width="940"
                            img-height="528"
                            style="text-shadow: 1px 1px 2px #333;"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                        >
                            <b-carousel-slide v-for="(imagen, index) in imagenes" v-bind:key="index">
                                <template #img>
                                    <div class="mb-3 d-flex" style="height:528px; width:940px; max-width: 100%;">
                                        <img
                                            class="d-block my-auto mx-auto carousel-spainhn"
                                            style="height:100%; width:auto"
                                            :src="`${imagenesUrl}/${tipo}/${folderOldId}/${imagen.name}`"
                                            :alt="imagen.altText"
                                        >
                                    </div>
                                </template>
                            </b-carousel-slide>

                            <a class="carousel-control-prev" aria-controls="monumentosShow___BV_inner_" role="button" data-slide="prev">
                                <!-- INSERT MY OWN PREV ICON USING FONT AWESOME  -->
                                <font-awesome-icon icon="fa-circle" size="3x" class="text-black"></font-awesome-icon>
                                <span class="sr-only">Previous</span>
                            </a>

                            <a class="carousel-control-next" role="button" data-slide="next" aria-controls="monumentosShow___BV_inner_">
                                <!-- INSERT MY OWN PREV ICON USING FONT AWESOME  -->
                                <font-awesome-icon icon="fa-circle" size="3x" class="text-black"></font-awesome-icon>
                                <span class="sr-only">Previous</span>
                            </a>
                        </b-carousel>

                        <div class="overflow-hidden mx-auto" style="height:94.5px; width:940px; max-width:72vw; position:relative;" id="imageSelectorContainer">
                            <div class="d-flex thumbnails-container" style="height:94.5px; width:940px; max-width:100%; position:relative;" ref="imageSelector" id="imageSelector">
                                <div style="height:94.5px; width:140px;" class="thumbnail-slide" v-for="(imagen, index) in imagenes" v-bind:key="index">
                                    <img role="button" @click="changeSlide(index)"
                                        class="px-1"
                                        style="height:100%; width:140px; object-fit: cover;  border-radius: 15px;"
                                        :src="`${imagenesUrl}/${tipo}/${folderOldId}/${imagen.name}`"
                                        :alt="imagen.altText">                        
                                </div>
                            </div>
                        </div>

                        <!-- <div class="d-flex justify-content-center overflow-hidden" style="height:94.5px; width:940px; max-width:82vw;" ref="imageSelector">
                            <img v-for="(imagen, index) in imagenes" v-bind:key="index" role="button" @click="changeSlide(index)"
                                class="mx-auto px-1"
                                style="max-height:100%; width:auto"
                                :src="`${imagenesUrl}/${tipo}/${folderOldId}/${imagen}`"
                                alt="image slot">                        
                        </div> -->
                    </div>

                    <div class="d-flex flex-column pt-3 ps-3 pe-3 col-md-4 col-12 d-none">     

                        <div class="h-100 pt-5 spainhn-bg-gris-cajas ps-3 pe-0 spainhn-text d-flex flex-column" style="border-radius:10px;">
                            <h5 class="mb-3"><strong><small>{{ $t('default.TICKET_DETAILS_DETAILS') }}</small></strong></h5>
                            <span class="text-start px-3" v-html="info.webRecommendations"></span>
                                                                    
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
// import HeaderImage from '../components/HeaderImage.vue'
import IconBase from '../components/IconBase.vue'
import IconCasttle from '../components/icons/IconCasttle.vue';
// import IconPalace from '../components/icons/IconPalace.vue';
// import IconRealPalace from '../components/icons/IconRealPalace.vue'
// import IconArt from '../components/icons/IconArt.vue'
// import IconWalledEnclosure from '../components/icons/IconWalledEnclosure.vue'
// import IconMonastery from '../components/icons/IconMonastery.vue'

import ApiService from '../services/ApiService';

export default {
    components:{
        DefaultLayout, 
        IconBase,
        IconCasttle,
        // IconPalace, 
        // IconRealPalace, 
        // IconArt, 
        // IconWalledEnclosure,
        // IconMonastery, 
        // Cards4,
        // CardsThumbnails// Cards
    },
    data(){
        return{
            // monumentoNombre:"",
            address:null,
            descripcion: null,
            videoId: 'YU5hCOSFki8',
            edificios:[],
            imagenes:[],
            slide:0,
            indicaciones:[],
            horarios: [],
            castilloId: null,
            activeImage: 0,
            nombreUrl: null,
            restaurantes:[],
            actividades:[],
            id: null,
            info: {
                nombres: [],
                descripciones: [],
                contactoTextos: [],
                informacionTextos: []
            },
            tipo: null,
            folderOldId: null,
            idiomaIndex:0,
            imagenesUrl: null,
            monumentoNombres: [],
            monumentoUrl: null,
            monumentWebName: "",
            // Con esta variable determinamos el status del icono        
        }
    },
    methods:{
        showContent(){
            console.log('click en castillo');
        },
        prevImage(){
            let $vm = this;
            if($vm.activeImage == 0){
                $vm.activeImage = $vm.imagenes.length;
            }
        },
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
        goToEntradas(){
            let $vm = this;
            $vm.$router.push("/castillos/tarifas/" + $vm.$route.params.monumentoNombre)
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        changeSlide(index){
            let $vm = this;
            $vm.slide = index;
            let numeroDeImagenes = $vm.imagenes.length;
            let totalWidth = numeroDeImagenes * 150;
            let tempPosition = $vm.thumbnailsPosition;
            let containerWidth = $vm.$refs.imageSelector.clientWidth
            let distanciaMenor = index - 0;
            let distanciaMayor = numeroDeImagenes - index;

            let pixelsToMove = 0;
            if(index % 2){
                pixelsToMove = 120 * index;
            }
            else{
                pixelsToMove = 150* index;
            }
            // let pixelsToMove = 120 * index;
            let steps = Math.abs(index - tempPosition);
            console.log(steps);
            if(steps == 0){
                pixelsToMove = 0;
            }
            let posicionesDisponibles = Math.abs(index - numeroDeImagenes)
            console.log(pixelsToMove)
            // console.log(index)

            if((posicionesDisponibles * 300) > containerWidth){

                if(tempPosition > index){
                    console.log("multiplicación")
                    if(pixelsToMove > 0){
                        pixelsToMove = 0;
                    }
                    else{
                    pixelsToMove *= -1;

                    }
                    // if(index < 2){
                    //     pixelsToMove = 0;
                    // }
                    console.log(pixelsToMove)
                    if(totalWidth > containerWidth){
                    // console.log(totalWidth)
                        $vm.$refs.imageSelector.style.transform = `translate(${pixelsToMove}px)`;

                    }
                }
                else if(tempPosition < index){
                    pixelsToMove *= -1;
                    console.log("aquí")
                    if(pixelsToMove > containerWidth){
                        pixelsToMove = 0
                    }

                    if(totalWidth > containerWidth){
                    // console.log(totalWidth)
                        console.log("Pixeles aquí");
                        $vm.$refs.imageSelector.style.transform = `translate(${pixelsToMove}px)`;

                    }
                }
            }
            $vm.thumbnailsPosition = index;

        },
        goToActividades(){
            let $vm = this;
            $vm.$router.push("/actividades/" + $vm.monumentoUrl)
        },
        goToRestaurantes(){
            let $vm = this;
            if($vm.tipo == 'restaurantes'){
                $vm.$router.push("/Restaurantes/" + $vm.monumentoUrl)
            }
            else if($vm.tipo == 'actividades'){
                $vm.$router.push("/actividades/" + $vm.monumentoUrl)
            }
        },

    },
    setup() {
        
    },
    created(){
        let $vm = this;
        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;
        $vm.id = $vm.$route.params.id;
        $vm.tipo = $vm.$route.params.tipo;
        // console.log($vm.$route.params.tipo)

        const _language = this.$store.getters.getIdioma;
        ApiService.getEdificacion( _language, $vm.monumentoUrl )
            .then( result => {
                // $vm.monumentoNombres = result.data.nombres;
                this.monumentWebName = result.data.webName
            })
            .catch(err => {
                console.log(err)
            })

        if($vm.$route.params.tipo == "restaurantes"){
            ApiService.getRestauranteById( _language, $vm.id)
                .then( result => {
                    $vm.info = result.data;
                    $vm.folderOldId = result.data.oldId;
                    ApiService.getImagenesByRestaurante($vm.info.oldId)
                        .then(res => {

                            let tempImagenes = res.data.images.filter( i => i.visible );

                            tempImagenes.sort( function(a, b) {
                                return a.order - b.order;
                            })

                            this.imagenes = tempImagenes;
                            // console.log(res.data);
                        })
                        .catch(err=>{
                            console.log(err);
                        })
                })
                .catch( err =>{
                    console.log(err);
                })
        }
        else if($vm.$route.params.tipo == "actividades"){
            ApiService.getActividadById(_language, $vm.id)
                .then( result => {
                    // console.log(result);
                    $vm.info = result.data;
                    $vm.folderOldId = result.data.oldId;
                    ApiService.getImagenesByActividad($vm.info.oldId)
                        .then(res=>{
                            let tempImagenes = res.data.images.filter( i => i.visible );

                            tempImagenes.sort( function(a, b) {
                                return a.order - b.order;
                            })

                            this.imagenes = tempImagenes;
                        })
                        .catch(err=> {
                            console.log(err);
                        })
                })
                .catch( err =>{
                    console.log(err);
                })
        }
       
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    computed: {
        player() {
            return this.$refs.youtube.player
        },
        isCastillosSelected(){
            let $vm = this;
            if($vm.selectedType === "Castillos"){
                $vm.castillosColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.castillosDescriptionText');
                return true;
            }
            else{
                $vm.castillosColor = $vm.disabledColor;
                return false;  
            } 
        },
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        // monumentoNombre(){
        //     let $vm = this;
        //     try{
        //         return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
        //     }
        //     catch(err){
        //         return "";
        //     }
        // },
    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    .thumbnails-container{
        transition: all 0.5s;
    }

    .thumbnail-slide{
        /* position:absolute; */
        transform: translate(0px);
        transition: all 0.5s;
    }


    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (min-width: 768px) {
        .carousel-spainhn{
            height:528px; 
            width:auto; 
            max-width: 100vw;
        }
    }

    @media (max-width: 768px){
        .text-columns{
            column-count: 2;
            /* height: 50vh; */
        }
        .carousel-spainhn{
            height:auto; 
            width:100%; 
            max-width: 100vw;
        }

    }

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>