var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-header" },
        [
          _c(
            "mdb-btn",
            {
              attrs: {
                color: "indigo",
                block: "",
                variant: "outline-secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.openModal()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.room.name) + " ")]
          ),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "list-group list-group-flush" },
        _vm._l(_vm.room.rates, function (room, index) {
          return _c("li", { key: index, staticClass: "list-group-item" }, [
            _c("div", { staticClass: "fila" }, [
              _c("span", { staticClass: "float-left" }, [
                _vm._v(_vm._s(room.boardName)),
              ]),
              _c(
                "div",
                _vm._l(
                  room.cancellationPolicies,
                  function (cancelPolicy, index2) {
                    return _c("ul", { key: index2 }, [
                      _vm._v(
                        " " +
                          _vm._s(cancelPolicy.amount) +
                          " € - Costo de cancelación si cancelas antes del: " +
                          _vm._s(
                            new Date(cancelPolicy.from).toLocaleDateString(
                              "es-ES",
                              {
                                timeZone: "UTC",
                                date: "DD-MM-YYY",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )
                          ) +
                          " "
                      ),
                    ])
                  }
                ),
                0
              ),
              _c(
                "div",
                [
                  _c("span", { staticClass: "spainhn-subtitle-3" }, [
                    _vm._v(_vm._s(room.net) + " € "),
                  ]),
                  _c(
                    "mdb-btn",
                    {
                      attrs: { color: "indigo" },
                      on: {
                        click: function ($event) {
                          return _vm.buyRoom(room)
                        },
                      },
                    },
                    [
                      _c(
                        "icon-base",
                        { attrs: { height: 25, width: 25 } },
                        [_c("icon-carrito")],
                        1
                      ),
                      _c("span", [_vm._v("Comprar")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }