var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c("div", { staticClass: "container-fluid px-0 px-md-5" }, [
      _c(
        "div",
        {
          staticClass: "spainhn-solid-red-background d-flex",
          staticStyle: { "min-height": "5vh", width: "100%" },
        },
        [
          _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
            _c(
              "p",
              {
                staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
                attrs: { role: "button" },
                on: { click: _vm.goToCastillos },
              },
              [_vm._v(_vm._s(_vm.monumentoNombre) + ">> ")]
            ),
            _c(
              "p",
              {
                staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text",
                attrs: { role: "button" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.MENSAJE_RUTAS")) + " ")]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "d-flex mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
          },
          _vm._l(_vm.rutas, function (ruta, index) {
            return _c("cards-3", {
              key: index,
              attrs: {
                title: ruta.nombre,
                linkId: ruta._id,
                imageUrl: `${_vm.imagenesUrl}/rutas/logo/${ruta.rutaId}`,
              },
              on: {
                goTo: function ($event) {
                  return _vm.goToRuta($event)
                },
              },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }