var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    {
      staticClass: "grey lighten-3 py-4",
      staticStyle: { "min-height": "200px" },
    },
    [
      _c("h4", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("default.SEATS_UI.PICK_SEATS_INSTRUCTIONS"))),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("h4", [_vm._v(_vm._s(_vm.zonaName))]),
      ]),
      _c("div", { staticClass: "d-flex justify-content-start" }),
      _c(
        "div",
        {
          staticClass: "overflow-auto",
          class: { mirror: _vm.zonaName == "GRADA 3 - CASTILLO" },
        },
        _vm._l(_vm.filasDeAsientos, function (fila, index) {
          return _c(
            "div",
            { key: index, staticClass: "d-flex justify-content-start" },
            [
              _c(
                "small",
                {
                  staticClass: "font-weight-bold font-monospace mr-3",
                  class: {
                    "mirror-normal": _vm.zonaName == "GRADA 3 - CASTILLO",
                  },
                },
                [_c("span", [_vm._v(_vm._s(fila.nombre))])]
              ),
              _vm._l(fila.columnas, function (column, index) {
                return _c(
                  "div",
                  { key: `${fila}+${index}` },
                  [
                    _c("font-awesome-icon", {
                      class: {
                        "deep-orange-text": column.estado == "seleccionado",
                        "grey-text":
                          column.estado == "ocupado" ||
                          column.estado == "reservado",
                        "d-none": column.estado == "escalera",
                      },
                      attrs: {
                        role: "button",
                        icon: `${
                          column.estado == "libre" ? "far" : "fa"
                        } fa-circle`,
                        transform: "shrink-6",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.pickColumn(column, index, fila._id)
                        },
                      },
                    }),
                    _c("div", {
                      staticClass: "d-inline-block",
                      class: { "d-none": column.estado != "escalera" },
                      staticStyle: { "margin-left": "16px" },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        0
      ),
      _c(
        "div",
        { class: { "d-none": _vm.choosenSeats == null } },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("default.SEATS_UI.CHOOSE_LABEL")))]),
          _c("p", [
            _vm._v(_vm._s(_vm.choosenSeats) + " - " + _vm._s(_vm.zonaName)),
          ]),
          _c(
            "mdb-btn",
            {
              staticClass: "red darken-4 text-white font-weight-bold",
              class: { "d-none": _vm.selected == false },
              on: { click: _vm.finalizarCompra },
            },
            [_vm._v(_vm._s(_vm.$t("default.BUTTONS.NEXT")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }