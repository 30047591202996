var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "row gx-0" }, [
        _c(
          "div",
          { staticClass: "mapa position-relative d-flex" },
          [
            _vm.isMobile
              ? _c("FilterMobile", {
                  staticStyle: { position: "absolute" },
                  attrs: {
                    idFilter: _vm.idFilter,
                    searchBlockStatus: _vm.searchBlockStatus,
                  },
                  on: { hideFilter: _vm.toggleSearchbar },
                })
              : _c(
                  "div",
                  {
                    staticClass:
                      "pb-0 col-12 col-xl-12 col-xxl-4 spainhn-gray-background",
                    class: { "d-none": !_vm.searchBlockStatus },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "px-2",
                        staticStyle: {
                          position: "absolute !important",
                          "max-height": "100%",
                          overflow: "scroll",
                          "overflow-x": "hidden",
                          "z-index": "5",
                        },
                      },
                      [
                        _c(
                          "diV",
                          {
                            staticClass:
                              "h-auto d-flex flex-column search-title",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "px-0 mx-0 border-bottom mt-auto",
                                staticStyle: {
                                  "border-color":
                                    "var(--spainhn-rojo-corporativo) !important",
                                  "border-width": "medium !important",
                                },
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "d-flex py-2 py-md-3" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-start spainhn-subtitle-text-white w-75",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "default.HOME_SEARCHBOX_TITLE"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("font-awesome-icon", {
                                      staticClass: "my-auto ms-auto",
                                      attrs: {
                                        role: "button",
                                        icon: "fa-circle-chevron-left",
                                        size: "2x",
                                      },
                                      on: { click: _vm.toggleSearchbar },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-input-group",
                              { staticClass: "py-2 d-flex flex-column" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-75 pe-lg-5 pt-lg-3 py-md-2",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-start spainhn-small-text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "default.HOME_SEARCHBOX_SUBTITLE"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "w-100",
                                      staticStyle: {
                                        "border-radius": "20px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "default.SELECT_BUSQUEDA"
                                        ),
                                        size: "sm",
                                      },
                                      on: {
                                        input: _vm.filtrarNombre,
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.toggleSearchbar.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.filtroPorNombre,
                                        callback: function ($$v) {
                                          _vm.filtroPorNombre = $$v
                                        },
                                        expression: "filtroPorNombre",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ms-3 my-auto d-flex",
                                        staticStyle: {
                                          "border-radius": "25px",
                                          height: "50px",
                                          width: "50px",
                                          "background-color":
                                            "var(--spainhn-rojo-corporativo)",
                                        },
                                        attrs: { role: "button" },
                                        on: { click: _vm.toggleSearchbar },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "my-auto mx-auto",
                                          attrs: {
                                            icon: "fa-magnifying-glass",
                                            size: "1x",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "row py-3 text-start" },
                              [
                                _c(
                                  "mdb-row",
                                  [
                                    _vm._l(
                                      _vm.tiposDeMonumentos,
                                      function (tipo) {
                                        return _c(
                                          "mdb-col",
                                          {
                                            key: tipo.id,
                                            attrs: { col: "12", md: "6" },
                                          },
                                          [
                                            _c("mdb-input", {
                                              attrs: {
                                                type: "checkbox",
                                                id: tipo.id,
                                                name: `${tipo.name}-${tipo.id}`,
                                                label: tipo.name,
                                              },
                                              model: {
                                                value: tipo.value,
                                                callback: function ($$v) {
                                                  _vm.$set(tipo, "value", $$v)
                                                },
                                                expression: "tipo.value",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "mdb-col",
                                      { attrs: { col: "12", md: "6" } },
                                      [
                                        _c("mdb-input", {
                                          attrs: {
                                            type: "checkbox",
                                            id: `tipo-paquete`,
                                            name: `tipo-paquete`,
                                            label: _vm.$t(
                                              "default.FILTER.TOURS_AND_PACKS"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.infoWinOpen = false
                                            },
                                          },
                                          model: {
                                            value: _vm.paquetes,
                                            callback: function ($$v) {
                                              _vm.paquetes = $$v
                                            },
                                            expression: "paquetes",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "mdb-col",
                                      { attrs: { col: "12", md: "6" } },
                                      [
                                        _c("mdb-input", {
                                          attrs: {
                                            type: "checkbox",
                                            id: `tipo-servicio`,
                                            name: `tipo-servicio`,
                                            label: _vm.$t(
                                              "default.FILTER.SERVICES"
                                            ),
                                          },
                                          model: {
                                            value: _vm.servicios,
                                            callback: function ($$v) {
                                              _vm.servicios = $$v
                                            },
                                            expression: "servicios",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "d-flex fw-bold spainhn-text py-0",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "default.HOME_SEARCHBOX_LOCALIZACION_OPTION"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "border-bottom w-25 py-0 my-0",
                                  staticStyle: {
                                    "border-color":
                                      "var(--spainhn-rojo-corporativo) !important",
                                    "border-width": "medium !important",
                                  },
                                }),
                                _c("b-form-select", {
                                  staticClass: "w-100 mt-3",
                                  staticStyle: {
                                    "border-radius": "10px",
                                    height: "2.5rem",
                                  },
                                  attrs: { options: _vm._availableCommunities },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "first",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: "",
                                                disabled: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.MINI_MAP.COMMUNITIES"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.localizacionesFilter,
                                    callback: function ($$v) {
                                      _vm.localizacionesFilter = $$v
                                    },
                                    expression: "localizacionesFilter",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "d-flex fw-bold spainhn-text py-0",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "default.HOME_SEARCHBOX_RUTA_OPTION"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "border-bottom w-25 py-0 my-0",
                                  staticStyle: {
                                    "border-color":
                                      "var(--spainhn-rojo-corporativo) !important",
                                    "border-width": "medium !important",
                                  },
                                }),
                                _c("b-form-select", {
                                  staticClass: "w-100 mt-3",
                                  staticStyle: {
                                    "border-radius": "10px",
                                    height: "2.5rem",
                                  },
                                  attrs: { options: _vm._opcionesDeRutas },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "first",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: "",
                                                disabled: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.MINI_MAP.RUTAS"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rutaFilter,
                                    callback: function ($$v) {
                                      _vm.rutaFilter = $$v
                                    },
                                    expression: "rutaFilter",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "d-flex fw-bold spainhn-text py-0",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "default.HOME_SEARCHBOX_SERVICE_OPTION"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "border-bottom w-25 py-0 my-0",
                                  staticStyle: {
                                    "border-color":
                                      "var(--spainhn-rojo-corporativo) !important",
                                    "border-width": "medium !important",
                                  },
                                }),
                                _c("b-form-select", {
                                  staticClass: "w-100 mt-3",
                                  staticStyle: {
                                    "border-radius": "10px",
                                    height: "2.5rem",
                                  },
                                  attrs: { options: _vm.serviciosOptions },
                                  on: {
                                    change: function ($event) {
                                      _vm.infoWinOpen = false
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "first",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: "",
                                                disabled: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.MINI_MAP.SERVICIOS"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.serviciosFilter,
                                    callback: function ($$v) {
                                      _vm.serviciosFilter = $$v
                                    },
                                    expression: "serviciosFilter",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "d-flex mx-auto mt-4" }, [
                              _c(
                                "div",
                                { staticClass: "py-2 px-2" },
                                [
                                  _c(
                                    "mdb-btn",
                                    {
                                      staticClass: "spainhn-button",
                                      attrs: { color: "indigo", size: "lg" },
                                      on: { click: _vm.limpiarFiltros },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_SEARCHBOX_CLEAR_BUTTON"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 px-2" },
                                [
                                  _c(
                                    "mdb-btn",
                                    {
                                      staticClass: "spainhn-button d-xxl-none",
                                      attrs: { color: "indigo", size: "lg" },
                                      on: { click: _vm.toggleSearchbar },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_SEARCHBOX_SEARCH_BUTTON"
                                          )
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
            _c(
              "div",
              {
                staticClass: "mt-0 mt-md-0 d-flex ms-sm-0 ms-0 pt-0 pt-md-0",
                class: { "d-none": _vm.searchBlockStatus },
                staticStyle: { position: "relative", "z-index": "3" },
              },
              [
                _vm.variant
                  ? _c(
                      "button",
                      {
                        staticClass: "ml-3",
                        staticStyle: {
                          position: "absolute",
                          "z-index": "3",
                          "margin-top": "10px !important",
                          border: "none",
                          height: "44px",
                          width: "44px",
                          "background-color": "#850B0B",
                          color: "white",
                          "border-radius": "50%",
                        },
                        on: { click: _vm.toggleSearchbar },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "fa-filter" },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "mdb-btn",
                      {
                        staticClass: "ml-3 spainhn-button px-5 mt-5",
                        staticStyle: {
                          position: "absolute",
                          "z-index": "3",
                          "margin-top": "5rem !important",
                          border: "none",
                        },
                        attrs: { size: "lg" },
                        on: { click: _vm.toggleSearchbar },
                      },
                      [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("default.HOME_SEARCH_BUTTON"))),
                        ]),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "h-100 flex-grow-1" },
              [
                _c(
                  "GmapMap",
                  {
                    ref: "mapRef",
                    staticStyle: {
                      height: "100%",
                      "margin-left": "auto",
                      "margin-right": "auto",
                    },
                    attrs: {
                      "autobindAllEvents:false": "",
                      center: _vm.mapCenterComputed,
                      zoom: _vm.mapZoomComputed,
                      "map-type-id": "terrain",
                      styles: ["hide"],
                      options: { disableDefaultUI: _vm.variant },
                    },
                  },
                  [
                    _c("DirectionsRenderer", {
                      attrs: {
                        travelMode: "DRIVING",
                        origin: _vm.waypointsComputed.startLocation,
                        waypoints: _vm.waypointsComputed.waypoints,
                        destination: _vm.waypointsComputed.endLocation,
                        optimize_waypoint_order: true,
                      },
                    }),
                    _c(
                      "GmapInfoWindow",
                      {
                        attrs: {
                          position: _vm.infoWindowPos,
                          opened: _vm.infoWinOpen,
                        },
                        on: {
                          closeclick: function ($event) {
                            _vm.infoWinOpen = false
                          },
                        },
                      },
                      [
                        _vm.currentMonument
                          ? _c("InfoMapPopover", {
                              attrs: {
                                monumento: _vm.currentMonument,
                                link: _vm.currentMonumentLink,
                                currentLang: _vm.currentLang,
                                translations: _vm.$t,
                                currentFilter: _vm.serviciosFilter,
                                paqueteFilter: _vm.paquetes,
                                matchingPacks: _vm.currentMonumentPacks,
                                servicioCheck: _vm.servicios,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "GmapCluster",
                      {
                        attrs: {
                          gridSize: 3,
                          zoomOnClick: true,
                          styles: [
                            {
                              textColor: "white",
                              height: 60,
                              width: 60,
                              textSize: 10,
                              backgroundPosition: "0 0",
                              url: _vm.mapClusterIcon,
                            },
                          ],
                          maxZoom: 16,
                        },
                      },
                      _vm._l(_vm.markers, function (m, index) {
                        return _c("GmapMarker", {
                          key: index,
                          attrs: {
                            position: m.position,
                            clickable: true,
                            draggable: false,
                            icon: m.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleInfoWindow(m, index)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }