<template>
    <mdb-container class="grey lighten-3 py-4" style="min-height:200px;">
        <h4 class="font-weight-bold">{{ $t("default.SEATS_UI.PICK_SEATS_INSTRUCTIONS") }}</h4>

        <div class="mt-4">
           <h4>{{ zonaName }}</h4>
        </div>
        <div class="d-flex justify-content-start">
            <!-- <small class="font-weight-bold font-monospace mr-3">{{ " " }}</small>
            <div class="" style="width: 10px; position: relative;"></div>
            <small class="font-weight-bold font-monospace mr-3" v-for="(columna, index) of filasDeAsientos[0].columnas" :key=index>{{ index + 1 }}</small> -->
        </div>
        <div class="overflow-auto" v-bind:class="{'mirror':zonaName == 'GRADA 3 - CASTILLO'}">
            <div v-for="(fila, index) of filasDeAsientos" :key="index" class="d-flex justify-content-start">
                <small class="font-weight-bold font-monospace mr-3" v-bind:class="{'mirror-normal':zonaName == 'GRADA 3 - CASTILLO'}"><span >{{ fila.nombre }}</span></small>
                <div v-for="(column, index) of fila.columnas" :key="`${fila}+${index}`">
                    <font-awesome-icon 
                        role="button" 
                        :icon="`${column.estado == 'libre' ? 'far' : 'fa'} fa-circle`" 
                        transform="shrink-6"
                        v-bind:class="{ 'deep-orange-text': column.estado == 'seleccionado', 'grey-text': (column.estado == 'ocupado' || column.estado == 'reservado'), 'd-none': column.estado == 'escalera' }"
                        @click="pickColumn(column, index, fila._id )"/>
                    <div v-bind:class="{ 'd-none': column.estado != 'escalera'}" class="d-inline-block" style="margin-left: 16px;">

                    </div>
                </div>
            </div>

        </div>

        <div v-bind:class="{'d-none': choosenSeats == null}">
            <p>{{ $t("default.SEATS_UI.CHOOSE_LABEL") }}</p>
            <p>{{ choosenSeats }} - {{ zonaName }}</p>
            <mdb-btn class="red darken-4 text-white font-weight-bold" @click="finalizarCompra" v-bind:class="{'d-none': selected == false}">{{ $t("default.BUTTONS.NEXT") }}</mdb-btn>
        </div>
    </mdb-container>
</template>

<script>
export default{
    name: 'Seats-Picker',
    props:{
        filas: { type: Array },
        Zona: String,
        personas: { type: Number, required: true }
    },
    watch:{
        filas( filas ){
            this.filasDeAsientos = [...filas];
            this.selected = false;
    
            this.choosenSeats = null;
            this.seatsInfo = [];

            this.filasDeAsientos.forEach( f => {
                f.columnas.forEach( c => {
                    if( c.estado == 'seleccionado' ){
                        c.estado = 'libre';
                    }
                })
            })
        }

    },
    data(){
        return{
            filasDeAsientos: [],
            lastRowSelected: null,
            selected: false,
            choosenSeats: null,
            seatsInfo: [],
        }
    },
    methods:{
        /**
         * Borramos la selección de asientos
         * @param {Number} lastRow - La fila en la que estaba la selección anterior, eso evita que se tenga que modificar asiento por asiento
         */
        clearSelection( lastRow ){
            this.filasDeAsientos.forEach( f => {
                if( f._id === lastRow ){
                    f.columnas.forEach( c =>{
                        if( c.estado == 'seleccionado' ){
                            c.estado = 'libre';
                        }
                    })
                }
            })
        },

        autoFill( cell, row ){

            let autoFilled = false;

            let seleccion = "";
            this.filasDeAsientos.forEach( f => {
                if( f._id == row ){
                    for( let x = 0; x < this.nPersonas; x++ ){
                        if( (cell + x) < f.columnas.length ){
                            const estado = f.columnas[cell + x ].estado;
                            if( estado === 'libre' ){
                                autoFilled = true;
                            }
                            else{
                                autoFilled = false;
                            }
                        }
                        else{
                            autoFilled = false;
                        }
                    }

                    if( autoFilled ){
                        this.seatsInfo = [];
                        for( let x = 0; x < this.nPersonas; x++ ){
                            f.columnas[cell + x ].estado = 'seleccionado';
                            // seleccion += `${f.nombre}${f.columnas[cell + x].nombre } `
                            seleccion += `${f.columnas[cell + x].nombre } `

                            const seatInfo = {
                                fila: f._id,
                                columna: f.columnas[cell + x]._id,
                                filaNombre: f.nombre,
                                columnaNombre: f.columnas[cell + x].nombre

                            }
                            this.seatsInfo.push( seatInfo );
                        }
                        this.choosenSeats = seleccion;

                    }
                }
            })
            return autoFilled;
        },
        /**
         * Handle the selection of a cell
         * @param {Object} evt - Celda que se seleccionó
         * @param {Number} index - El indice de la celda
         * @param {String} fila - The _id of the row were the cell is
         */
        pickColumn( evt, index, fila ){
            if( this.lastRowSelected == null){
                this.lastRowSelected = fila;
            }
            // Limpiamos los lugares que se hayan seleccionado con anterioridad
            if( evt.estado == 'libre') {
                // this.clearSelection( this.lastRowSelected );
                // this.autoFill( index, fila );

                if( this.seatsInfo.length < this.nPersonas ){

                    const f = this.filasDeAsientos.filter( f => f._id == fila )[0]
                    const seatInfo = {
                        fila: f._id,
                        columna: f.columnas[index]._id,
                        filaNombre: f.nombre,
                        columnaNombre: f.columnas[index].nombre

                    }
                    this.seatsInfo.push( seatInfo );

                    evt.estado = 'seleccionado'

                    this.choosenSeats = this.seatsInfo.map( si => si.columnaNombre ).reduce( (prev, curr) => prev + " " + curr, " ");

                    this.lastRowSelected = fila;
                }
            }

            else if(evt.estado == 'seleccionado'){
                evt.estado = 'libre'

                const seatsToRemoveFromSelection = this.seatsInfo.map( ( si, index ) => {
                    if( si.columna == evt._id ){
                        return index;
                    }
                }).filter( i => i != undefined)[0];


                this.seatsInfo.splice( seatsToRemoveFromSelection, 1 );
                this.choosenSeats = this.seatsInfo.map( si => si.columnaNombre ).reduce( (prev, curr) => prev + " " + curr, " ");

                // this.clearSelection( this.lastRowSelected );
                // this.autoFill( index, fila );
                // evt.estado = 'seleccionado'
                this.lastRowSelected = fila;

            }

            if( this.seatsInfo.length == this.nPersonas ){
                this.selected = true;
            }
            else{
                this.selected = false;
            }
        },
        finalizarCompra(){
            this.$emit( 'finalizarCompra', this.seatsInfo )
        }
    },
    computed:{
        nPersonas(){
            return this.personas;
        },
        zonaName(){
            // this.choosenSeats = null;
            return this.Zona
        }
    }

}
</script>

<style scoped>
    .mirror{
        /* transform: scaleY(-1) !important; */
        transform:scale( -1, -1) !important;
        /* transform: scaleX(1) !important; */
    }

    .mirror-normal{
        transform: scale(-1, -1) !important;
        /* transform: scaleX(1) !important; */
    }

</style>