var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "breadcrumb-container" }, [
    _c("div", { staticClass: "text-container" }, [
      _c("span", { staticClass: "breadcrumb-text mt-auto text-uppercase" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }