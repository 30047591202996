<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        monumento: {
            type: Object,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        currentLang: {
            type: Number,
            required: true,
        },
        currentFilter: {
            type: String,
            required: true,
        },
         translations: {
            type: Function,
            required: true,
        },
        paqueteFilter: {
            type: Boolean,
            required: true,
        },
        matchingPacks: {
            type: Array,
            required: true,
        },
        servicioCheck: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            showServicios: true,
            showPaquete: true,
            showPaqueteOperador: false,
            showPaqueteMonumento: true,
            visitUrl: null,
        };
    },
    computed: {
        servicesCount() {
            return [
                this.monumento.alojamiento,
                this.monumento.restaurante,
                this.monumento.eventos,
                this.monumento.experiencias,
                this.monumento.boda,
                this.monumento.espacios,
                this.monumento.museo
            ].filter(Boolean).length;
        },
        separatePacks() {
            const packs = this.matchingPacks;
            const packsOperador = [];
            const packsMonumento = [];
            packs.forEach(pack => {
                if (pack.esPromotor) {
                    packsOperador.push(pack);
                }
                else {
                    packsMonumento.push(pack);
                }
            });
            return {packsOperador, packsMonumento};
        },
        getPopoverTitle() {
            if (!this.monumento.showLinkButton) {
                return this.$t("default.MINI_MAP.PACK_INFO_TITLE");
            }
            else if (this.monumento.servicio || this.monumento.tipoDeMonumento === 'Paquete') {
                return this.monumento.nombre;
            }
            else {
                return this.monumento.nombres[this.currentLang].texto
            }
        },
        getVisitUrl() {
            const currentName = this.getPopoverTitle;
            let monumentName = currentName.split("/").pop().toLowerCase();
            monumentName = monumentName.replaceAll(' ', '-');
            return `/castillos/tarifas/${monumentName}`;
        },
},
    methods: {
        toggleServicios() {
            this.showServicios = !this.showServicios;
            this.showPaquete = false;
        },
        togglePaquete() {
            this.showPaquete = !this.showPaquete;
            this.showServicios = false;
        },
        togglePaqueteMonumento() {
            this.showPaqueteMonumento = !this.showPaqueteMonumento;
            this.showPaqueteOperador = false;
        },
        togglePaqueteOperador() {
            this.showPaqueteOperador = !this.showPaqueteOperador;
            this.showPaqueteMonumento = false;
        },
    },
})
</script>

<template>
    <div class="infoCard">
        <div class="d-flex flex-column">
            <p class='pb-0 mb-0 spainhn-lg-text-red text-wrap'>
                {{ getPopoverTitle }}
            </p>
            <br>
            <div v-if="(currentFilter != '' || servicioCheck)" class="text-small text-wrap">
                <button class="btn-accordion" @click="toggleServicios">
                    <font-awesome-icon icon="fa-solid fa-bell-concierge" class="start-icon" />
                    <span class="text-small text-wrap accordion-title">{{ $t('default.MAIN_MENU_MINIMAP_POPOVER_SERVICES_TITLE') }}</span>
                    <font-awesome-icon :icon="showServicios ? 'chevron-up' : 'chevron-down'" class="start-icon" />
                </button>
            </div>
            <ul class="list-group service-list" v-if="showServicios && (currentFilter != '' || servicioCheck)">
                <li><a :href="getVisitUrl" target="_blank" class="list-group-item list-container pack-container"><font-awesome-icon icon="fa-solid fa-ticket" class="start-icon" /><span class=" label-service"> {{ translations('default.MAP_POPOVER_AVAILABLE_VISITS') }}</span></a></li>
                <li v-if="monumento.alojamiento" class="list-group-item list-container "><font-awesome-icon icon="hotel" class="start-icon" /> <span class=" label-service"> {{ translations('default.MAIN_MENU_SERVICES_ALOJAMIENTO') }} </span></li>
                <li v-if="monumento.restaurante" class="list-group-item list-container "><font-awesome-icon icon="fa-solid fa-utensils"  class="start-icon" /><span class=" label-service"> {{ translations('default.MAIN_MENU_SERVICES_RESTAURANTES') }}</span></li>
                <li v-if="monumento.eventos" class="list-group-item list-container "><font-awesome-icon icon="fa-solid fa-calendar" class="start-icon" /><span class=" label-service"> {{ translations('default.MAIN_MENU_SERVICES_EVENTOS') }}</span></li> 
                <li v-if="monumento.experiencias" class="list-group-item list-container"><font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" class="start-icon" /><span class=" label-service"> {{ translations('default.MAIN_MENU_SERVICES_EXPERIENCIAS') }}</span></li>
                <li v-if="monumento.boda" class="list-group-item list-container"><font-awesome-icon icon="fa-solid fa-place-of-worship" class="start-icon" /><span class=" label-service"> {{ translations('default.MAIN_MENU_SERVICES_BODA') }}</span></li>
                <li v-if="monumento.espacios" class="list-group-item list-container"><font-awesome-icon icon="fa-solid fa-mug-saucer" class="start-icon" /><span class=" label-service"> {{ translations('default.MAIN_MENU_SERVICES_ESPACIOS') }}</span></li>
            </ul>
                <div v-if="paqueteFilter && monumento.tipoDeMonumento === 'Paquete' && matchingPacks.length > 0 && !monumento.showLinkButton" class="text-small text-wrap">
                    <button class="btn-accordion" @click="togglePaqueteMonumento" v-if="separatePacks.packsMonumento.length > 0">
                        <font-awesome-icon icon="fa-solid fa-chess-rook" class="start-icon" />
                        <span class="text-small text-wrap accordion-title">{{$t("default.MINI_MAP.TOURISTIC_PACKS")}}</span>
                        <font-awesome-icon :icon="showPaqueteMonumento ? 'chevron-up' : 'chevron-down'" class="start-icon" />
                    </button>
                    <ul class="list-group pack-list" v-if="showPaqueteMonumento">
                        <li v-for="pack in separatePacks.packsMonumento" :key="pack._id" class=""><a :href="pack.link" target="_blank" class="list-group-item list-container pack-container"><font-awesome-icon icon="fa-solid fa-box-open" class="start-icon" /><span class=" label-service"> {{ pack.nombre }}</span></a></li>
                    </ul>
                    <button class="btn-accordion" @click="togglePaqueteOperador" v-if="separatePacks.packsOperador.length > 0">
                        <font-awesome-icon icon="fa-solid fa-box-open" class="start-icon" />
                        <span class="text-small text-wrap accordion-title">{{$t("default.MINI_MAP.TOURS")}}</span>
                        <font-awesome-icon :icon="showPaqueteOperador ? 'chevron-up' : 'chevron-down'" class="start-icon" />
                    </button>
                    <ul class="list-group pack-list" v-if="showPaqueteOperador">
                        <li v-for="pack in separatePacks.packsOperador" :key="pack._id" class="">
                            <a :href="pack.link" target="_blank" class="list-group-item list-container pack-container">
                                <font-awesome-icon icon="fa-solid fa-box-open" class="start-icon" /><span class=" label-service"> {{ pack.nombre }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        <a v-if="monumento.showLinkButton" class='spainhn-button text-white mt-0 spainhn-lg-text p-2' :href="link"><strong>{{ $t("default.MINI_MAP.SHOW_SITE") }}</strong></a>
    </div>
</template>

<style scoped>
.infoCard {
    max-height: 25rem;
    gap: 1rem;
    width: 100%;
    max-width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
}

.label-service {
    text-align: start;
    font-size: 0.75rem; /* 12px */
    grid-column: span 5 / span 5;
}

.list-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    align-items: center;
    padding: 0.5rem;
}

.pack-container:hover {
    /* We should use a light red color as background-color and font-color for hover and use the click cursor */ 
    color: #721C24 ;
    background-color: #f8d7da;
}

.service-list {
    overflow-y: auto;
    list-style-type: none;
}

.pack-list {
    max-height: 15rem;
    overflow-y: auto;
    list-style-type: none;
}

.accordion-title {
    font-size: 0.75rem;
    grid-column: span 4 / span 4;
    text-align: center;
}

.start-icon {
    grid-column: span 1 / span 1;
    text-align: center;
    justify-self: center;
}

.btn-accordion {
   border-radius: 0.125rem;
   border: none;
   display: grid;
   grid-template-columns: repeat(6, minmax(0, 1fr));
   align-items: center;
   padding: 0.5rem;
   color: black;
   font-size: 0.75rem;
   width: 100%;
}


@media (max-width: 768px) {
    .infoCard {
        gap:0.5rem;
        width: 90%;
        max-width: none;
        margin: 0 auto;
    }
}
</style>
