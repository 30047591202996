<template>
    <div class="container-fluid flex flex-column px-0">
        <div class="collapsable-header spainhn-gray-background">
            <div class="header-title-container">
                <span class="text-uppercase" v-bind:class="{'header-active-typhograpy':showContent, 'header-typhograpy': !showContent}">
                    {{ title }}
                </span>
                <span style="width: 30px; height: 30px;" role="button" @click="() => toggleContent()">
                    <svg v-bind:class="{'d-none': showContent}" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <circle cx="15" cy="15" r="15" fill="white"/>
                        <path d="M7 15H23" stroke="#B9B9B9" stroke-width="2" stroke-linecap="round"/>
                        <path d="M15 23L15 7" stroke="#B9B9B9" stroke-width="2" stroke-linecap="round"/>
                    </svg>

                    <svg v-bind:class="{'d-none': !showContent}" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <circle cx="15" cy="15" r="15" fill="white"/>
                        <path d="M7 15H23" stroke="#850B0B" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </span>
            </div>
            <div style="width: 100%; margin-top: 0;" class="animated fadeIn" v-bind:class="{'d-none':!showContent }">
                <hr :class="{'d-none': noDivide }" style="color: black; margin-top: 0"/>
                <div style="text-align: start !important;">
                    <span v-html="htmlContent" style="height: auto; width: 100%">
                    </span>
                </div>
                <slot>
                    
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CollapsableElement',
        props: {
            title: String,
            content: String,
            noDivide: {
                type: Boolean,
                default: false
            }
        },

        data(){
            return{
                showContent: false
            }
        },
        methods: {
            toggleContent(){
                if( this.showContent ){
                    this.showContent = false;
                }
                else if( !this.showContent ){
                    this.showContent = true;
                }

            }
        },
        computed: {
            htmlContent(){
                return this.content;
            }
        }
    }
</script>

<style scoped>
.collapsable-header{
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
}

.header-title-container{
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header-typhograpy{
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.header-active-typhograpy{
    /* color: var(--spainhn-buy-button); */
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

</style>