<template>
    <mdb-container class="mt-4">
        <mdb-card wide class="bg-light">
            <mdb-view>
                <mdb-row class="justify-content-md-center spainhn-solid-red-background px-4 py-2 mb-0">
                    <mdb-col col="12" class="spainhn-subtitle-text-white"
                        style="font-size:24px !important;"><strong>{{ item.information.itemMonumento }}</strong>
                    </mdb-col>
                    <mdb-col col="12">
                        <mdb-row>
                            <mdb-col class="" col="12" md="6">
                                <mdb-row>
                                    <mdb-col col="12">
                                        <div class="text-start spainhn-subtitle-text-white mt-2 mb-2"
                                            style="font-size:20px !important;">{{ item.information?.itemVisita }}
                                        </div>
                                        <p class="text-start">{{ fecha }} - {{ horario }}</p>
                                        <p class="text-start" v-bind:class="{ 'd-none': !item.information.numeroDeAsientos }">
                                            {{ $t("default.SEATS_UI.SEATS", { msg: `${item.information.numeroDeAsientos} - ${item.information.zonaDeBoletos}`}) }}
                                            Asientos: {{ item.information.numeroDeAsientos }} - {{ item.information.zonaDeBoletos }}</p>
                                    </mdb-col>
                                </mdb-row>

                                <mdb-row>
                                    <mdb-col col="2" md="1">
                                        <font-awesome-icon icon="fa-ticket-alt" inverse size="2x"></font-awesome-icon>
                                    </mdb-col>

                                    <mdb-col col="auto">
                                        <p class="spainhn-text-lg fw-bold my-auto ms-3">{{ totalDePersonas }}</p>
                                    </mdb-col>

                                    <mdb-col col="5" md="4">
                                        <p class="spainhn-text-lg fw-bold my-auto ms-2">
                                            {{ totalDeLaVenta }}</p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>

                            <mdb-col col="12" md="6" class="mt-3 mt-md-0">
                                <mdb-row class="my-auto">
                                    <mdb-col md="12" col="6">
                                        <mdb-btn
                                            style="background-color: rgb(67,67,67) !important;color: white !important;"
                                            class=" spainhn-button float-md-right"
                                            @click="quitarReserva">{{ $t("default.BUTTONS.REMOVE") }}</mdb-btn>
                                    </mdb-col>
                                    <mdb-col md="12" col="6" class="my-auto pe-4">
                                        <p class="my-auto float-md-right" role="button"
                                            @click="() => mostrarDetalles = (mostrarDetalles == true ? false : true)">
                                            {{ $t("default.CART.DETAILS") }}<font-awesome-icon class="ms-2" icon="angle-double-right" /></p>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-col>
                        </mdb-row>

                    </mdb-col>
                </mdb-row>
            </mdb-view>

            <mdb-card-body class="fadeIn animated spainhn-bg-gris-cajas" v-bind:class="{ 'd-none': !mostrarDetalles }">

                <h5 class="text-start spainhn-text-lg ms-2 text-black">{{ $t("default.CART.DETAILS") }}</h5>

                <template>
                    <div v-for="details in item.items" :key="details.itemID" class="my-2">

                        <mdb-row class="text-left">
                            <mdb-col col="12">
                                <h6 class="text-color-spainhn font-weight-bold">
                                    {{ details.itemVisita }}
                                </h6>
                            </mdb-col>
                            <mdb-col col="12">
                                {{ fecha }} - {{prettyHour(details.itemHorario)}}
                            </mdb-col>
                            <!-- <mdb-tbl>
                                <mdb-tbl-head class="text-black">
                                    <tr>
                                        <th><small class="text-black fw-bold">{{ $t('default.PRODUCTO') }}</small></th>
                                        <th><small class="text-black fw-bold">{{ $t('default.DAY') }}</small></th>
                                        <th><small class="text-black fw-bold">{{ $t('default.HOUR') }}</small></th>
                                    </tr>
                                </mdb-tbl-head>
                                <mdb-tbl-body>
                                    <tr>
                                        <th><small>{{ details.itemVisita[idiomaIndex]?.texto }}</small></th>
                                        <th><small>{{ fecha }}</small></th>
                                        <th><small>{{ prettyHour(details.itemHorario) }}</small></th>
                                    </tr>
                                </mdb-tbl-body>
                            </mdb-tbl> -->
                        </mdb-row>
        
        
                        <!-- <h5 class="text-start spainhn-text-lg ms-2 text-black mt-2">Tus entradas</h5>         -->
                        <b-table class="spainhn-text text-center text mb-0" :fields="resumenFields" :items="details.itemTickets">
                            <template #cell(precio)="data">
                                <div>
                                    {{ (data.value).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) }}
                                </div>
                            </template>
        
                            <template #cell(total)="data">
                                <div class="text-center">
                                    {{ (data.value).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) }}
                                </div>
                            </template>
                        </b-table>
                    </div>
                </template>

            </mdb-card-body>
        </mdb-card>
    </mdb-container>
</template>

<script>
import moment from 'moment';
import ApiService from "../services/ApiService.js";
export default {
    props: {
        item: Object
    },
    data() {
        return {
            resumenFields: [
                { key: "cantidad", label: this.$t("default.CART.QUANTITY"), thClass: ["text-black", "fw-bold", "spainhn-small-text"], tdClass: "spainhn-small-text" },
                { key: "descripcion", label: this.$t("default.CART.DESCRIPTION"), thClass: ["text-black", "fw-bold", "spainhn-small-text"], tdClass: "spainhn-small-text" },
                { key: "precio", label: this.$t("default.CART.PRICE"), thClass: ["text-black", "fw-bold", "spainhn-small-text"], tdClass: "spainhn-small-text" },
                { key: "total", label: this.$t("default.CART.TOTAL"), thClass: ["text-black", "fw-bold", "spainhn-small-text"], tdClass: "spainhn-small-text" }
            ],
            mostrarDetalles: false,
            // resumen: [],
            asientos: null,
            moment: moment
        }
    },
    methods: {
        async quitarReserva() {
            let $vm = this;
            const cartUuid = this.$store.getters.getCartUuid;
            const items = this.item.items;
            this.$emit('eliminando', true);
            for( let i of items ){
                await ApiService.removeFromCarrito( cartUuid, i.itemUuid );
                this.$store.commit("removeFromCart", i.itemUuid );
            }
            this.$emit( 'eliminando', false );
            this.$toast.error(this.$t("default.NOTIFICATIONS.ITEMS_REMOVED" ));
            // $vm.$store.commit('removeFromCart', $vm.item.itemUuid)

            // const result = await Promise.all( this.item.items.map( async( i ) => {
            //     return await $vm.$store.dispatch('reservaDelete', i.itemUuid)
            // } ) )

            $vm.$emit('itemRemoved', { idVisita: $vm.item.itemIDVisita, monumento: $vm.item.itemMonumento[$vm.idiomaIndex]?.texto })
        },
        prettyHour( hora ){

            const friendlyHours = [];
            if( typeof hora === 'string'){
                const tempHour = hora.split('|');
    
                tempHour.forEach( h => {
                    const tempHorarios = h.split("|")
                    tempHorarios.forEach( tempH => {
                        if( tempH ){
                            friendlyHours.push( moment( tempH ).utc().format("HH:mm") );
                        }else{
                            friendlyHours.push( this.$t("default.FREE_SCHEDULE_LABEL") );
                        }
                    })
                })
            }else{
                if(!hora){
                    friendlyHours.push( this.$t("default.FREE_SCHEDULE_LABEL") );
                }else{
                    friendlyHours.push(moment( hora ).utc().format("HH:mm"))
                }
            }
            return friendlyHours.join(" | ")

            // if( hora ){
            //     return moment(hora).utc().format("HH:mm")
            // }else{
            //     return "Horario libre"
            // }
        }
        // get
    },
    created() {
        // this.resumen = this.item.items;
    },
    computed: {
        idiomaIndex() {
            return this.$store.getters.idiomaIndex;
        },
        fecha() {
            if( typeof this.item.information.itemFecha === 'string' ){
                const _fechas = this.item.information.itemFecha.split('|').map( f => moment(f).utc().tz('Europe/Madrid').format('DD/MM/YYYY') );
                const fecha = _fechas.join(' | ');
                return fecha;
            }else{
                return moment(this.item.information.itemFecha).utc().tz('Europe/Madrid').format('DD/MM/YYYY');
            }
        },
        horario() {

            if( Array.isArray( this.item.information.itemHorario ) ){
                const friendlyHours = [];

                this.item.information.itemHorario.forEach( h => {

                    if( typeof h === 'string'){
                        const tempHorarios = h.split("|")
                        tempHorarios.forEach( tempH => {
                            if( tempH ){
                                friendlyHours.push( moment( tempH ).utc().format("HH:mm") );
                            }else{
                                friendlyHours.push( this.$t("default.FREE_SCHEDULE_LABEL") );
                            }
                        })
                    }else{
                        if( h ){
                            friendlyHours.push( moment( h ).utc().format("HH:mm") );
                        }else{
                            friendlyHours.push( this.$t("default.FREE_SCHEDULE_LABEL") );
                        }
                    }
                })
                return friendlyHours.join(" | ")
            }else{
                if( typeof this.item.information.itemHorario === 'string'){
                    const tempHorarios = this.item.information.itemHorario.split("|").map( h => {
                        if ( !h )  {
                            return this.$t("default.FREE_SCHEDULE_LABEL");
                        }
                        else {
                            let horas = h.split("T")[1];
                            return horas.slice(0, 5);
                        }
                    });
    
                    return tempHorarios.join(' | ');
                }else{
                    if ( !this.item.information.itemHorario ) {
                            return this.$t("default.FREE_SCHEDULE_LABEL");
                        }
                        else {
                            let horas = moment(this.item.information.itemHorario).utc().format().split("T")[1];
                            return horas.slice(0, 5);
                        }
                }
            }
        },
        totalDePersonas() {

            if( this.item.items.length ){
                return this.item.information.itemTickets
                    .map(i => i.cantidad).reduce((acc, curr) => acc + curr, 0) / this.item.items.length;
            }else{
                return 0;
            }
        },
        totalDeLaVenta() {
            return (this.item.information.itemTickets
                .map( i => i.total ).reduce( (acc, curr) => acc + curr, 0 ) ?? 0).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' } );
        }
    }
}
</script>