<template>
    <div class="ms-auto  d-flex flex-column mt-0 pt-0 px-0 mx-0 ml-5">
        <div class="position-relative">
            <span role="button" @click="() => $emit('goTo')">
                <icon-ticket :pulse="pulse" class="buy-icon"/>
            </span>
            <!-- <mdb-btn
                tag="a"
                floating
                @click="() => $emit('goTo')"
                style="background-color: var(--spainhn-rojo-spainhn) !important; width: 55px !important; height: 55px !important;"
                class="mt-0 mb-0 mx-0 p-0">
                <div style="position: relative;" class="d-flex" >
                    <img src="../assets/Ticket.svg"/>
                </div>
            </mdb-btn> -->
            <small class="m-0 position-absolute buy-ticket text-center w-auto" style="top:60%; left: 50%; transform: translateX(-50%)" v-if="showText"><br>
                <mdb-badge color="danger" class="mt-1">
                    {{ $t("default.BUTTONS.BUY") }}
                </mdb-badge>
            </small>
            <!-- <icon-ticket style="width: 100px; height: 100px;"/> -->
        </div>
    </div>
</template>

<script>
import IconTicket from './icons/IconTicket.vue';
export default {
    components: {
        IconTicket
    },
    props: {
        pulse: {
            type: Boolean,
            default: false,
        },
        showText: { type: Boolean, default: true }
    },
}
</script>

<style scoped>
    .buy-ticket{
        color: var(--spainhn-rojo-spainhn);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .icon{
        width: 55px;
        height: 55px;
        flex-shrink: 0;
    }

    .buy-icon{
    position: abs;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }

</style>