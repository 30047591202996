var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vc-date-picker", {
    staticClass: "data-picker-range",
    attrs: {
      mode: "date",
      masks: _vm.masks,
      "is-range": "",
      minDate: new Date(),
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ inputValue, inputEvents, isDragging }) {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-start items-center date-picker-container",
              },
              [
                _c(
                  "input",
                  _vm._g(
                    {
                      staticClass: "w-50 inputs first-input",
                      class: isDragging ? "text-gray-600" : "text-gray-900",
                      attrs: {
                        placeholder: _vm.$t("default.CARDS.START_FROM"),
                      },
                      domProps: { value: inputValue.start },
                    },
                    inputEvents.start
                  )
                ),
                _c("div", { staticClass: "separator" }),
                _c(
                  "input",
                  _vm._g(
                    {
                      staticClass: "w-50 inputs second-input",
                      class: isDragging ? "text-gray-600" : "text-gray-900",
                      attrs: { placeholder: "Hasta" },
                      domProps: { value: inputValue.end },
                    },
                    inputEvents.end
                  )
                ),
              ]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.filtros.range,
      callback: function ($$v) {
        _vm.$set(_vm.filtros, "range", $$v)
      },
      expression: "filtros.range",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }