import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueYoutube from 'vue-youtube';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VCalendar from 'v-calendar';
import Carousel3d from 'vue-carousel-3d';

import InstantSearch from 'vue-instantsearch/vue2/es';
import * as VueGoogleMaps from 'vue2-google-maps';

import VueI18n from 'vue-i18n';

// import i18n from './i18n';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/customStyles.css';

import { library } from '@fortawesome/fontawesome-svg-core'

import { faSearch, faPlusCircle, faCircleChevronDown, faCircleChevronUp, faHandHoldingDollar, faCircleExclamation, faUser, faAngleDoubleRight, 
  faTicket, faCheck, faCircleCheck, faMagnifyingGlass, faCircle, faNewspaper, faCalendarCheck , faPlus, faMinus, faClock, faGlobe, faTicketAlt, 
  faCalendar, faPen, faInfoCircle, faCircleMinus, faCircleChevronLeft, faCircleChevronRight, faCartShopping, faRectangleXmark, faSignsPost, 
  faCircleUser, faTrash, faCalendarXmark, faLocationCrosshairs, faBars, faMousePointer, faStar, faHSquare, faQuestionCircle, faVest, faLandmark, faChessRook,
  faLeaf, faUtensils, faFilter, faBroom, faLocationDot, faPlay, faCompass, faCrown, faGlasses, faHotel, faMugSaucer, faWandMagicSparkles, 
  faBoxOpen,
  faChevronDown,
  faChevronUp,
  faBellConcierge} from '@fortawesome/free-solid-svg-icons'

import { faInstagramSquare, faTwitterSquare, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as es from './i18n/es.json';
import * as en from './i18n/en.json';
import * as fr from './i18n/fr.json';
import * as de from './i18n/de.json';
import * as ja from './i18n/ja.json';
import * as it from "./i18n/it.json";
import * as zh from "./i18n/zh.json";
import * as ru from "./i18n/ru.json";
import * as hi from "./i18n/hi.json";
import * as ar from "./i18n/ar.json";
import * as ca from "./i18n/ca.json";
import * as pt from "./i18n/pt.json";


import GmapCluster from 'vue2-google-maps/dist/components/cluster'

import vueCountryRegionSelect from 'vue-country-region-select'

import DefaultLayout from './layouts/DefaultLayout.vue'
import WhiteLoader from './components/Loaders/WhiteLoader.vue';
import MinimalistBreadcrumb from './components/Breadcrumbs/MinimalistBreadcrumb'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import './gtm.js';
 
Vue.use(VueMoment, {
    moment,
})

library.add(faInstagramSquare, faTwitterSquare, faFacebookSquare, faLinkedin, faSearch, faPlusCircle, faCircleUser,  faPlus, faCalendar, faCheck,
  faCircleExclamation, faTrash, faMagnifyingGlass, faCircleChevronUp, faCircleChevronDown, faCircleChevronRight, faMinus, faAngleDoubleRight, 
  faCircleMinus, faCircleChevronLeft, faCartShopping, faRectangleXmark, faSignsPost, faHandHoldingDollar, faCircleCheck, faCircle, 
  faTicketAlt, faPen, faTicket, faNewspaper, faCalendarCheck, faCalendarXmark, faClock, faGlobe, faLocationCrosshairs, faBars, faUser, faCompass, faCrown, faGlasses, faChessRook,
  faInfoCircle, faMousePointer, faStar, far, faHSquare, faQuestionCircle, faVest, faLandmark, faLeaf, faUtensils, faFilter, faBroom, faLocationDot, faPlay, faWandMagicSparkles, faHotel, faMugSaucer, faBoxOpen, faChevronDown, faChevronUp, faBellConcierge );

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueYoutube);
Vue.use(Carousel3d);
Vue.use(VueI18n);

const messages = {
  'es': { ...es },
  'en': { ...en },
  'fr': { ...fr },
  'de': { ...de },
  'it': { ...it },
  'zh': { ...zh },
  'ja': { ...ja },
  'pt': { ...pt },
  'ru': { ...ru },
  'hi': { ...hi },
  'ar': { ...ar },
  'ca': { ...ca }
}
const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages
})


Vue.component('default-layout', DefaultLayout);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('white-loader', WhiteLoader);
Vue.component('minimalist-breadcrumb', MinimalistBreadcrumb);
Vue.use( Toast, { timeout: 4000, theme: 'colored' } );

Vue.use(vueCountryRegionSelect)
Vue.use(InstantSearch);
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyAZTObDyRwAqUrs-BQTvpjCE7LVny80dM0',
    key: "AIzaSyD9mE44z6RopXyijzgYUTAZLOG5_V_zcH0",
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
 
})

Vue.component('GmapCluster', GmapCluster);
// Vue.component('gmap-cluster', VueGoogleMaps.Cluster)

import * as mdbvue from 'mdbvue'
for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}

import Notify from 'mdbvue/lib/components/Notify'
import { far } from '@fortawesome/free-regular-svg-icons'
Vue.use(Notify)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
