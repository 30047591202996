<template>
    <div class="card-container position-relative my-2">
        <span role="button" v-if="buyBtn" class="position-absolute w-100 start-0 text-white" :class="tipoDeResultado" style="z-index:1 !important;"  @click="() => $emit('goToBuy')">
            + {{ $t('default.SPAINHN_BUTTON_MORE_INFO') }}
        </span>
        <div class="d-flex flex-column" role="button">
            <div class="image-container" @click="() => $emit('goToUrl')">
                <img :src="image" class="d-block" style="">
            </div>
            <buy-component style="position: absolute;" v-if="buyBtn" :showText="true" class="mt-4 opacity-75" @goTo="$emit('goToUrl')" paquete/>
            <div class="info-container position-relative px-2 py-1" :class="{'bg-corp': type === 'monumento'}" @click="() => $emit('goToUrl')">
                <div class="text-container pt-0" style="box-sizing: border-box ;" :class="{'d-flex flex-column justify-content-evenly align-content-center': type === 'monumento'}">
                    <p class="info-title-text text-uppercase text-center p-1 align-content-center" style="border-radius: 10px; min-height: 32px; overflow-y: scroll !important; scrollbar-color: var(--spainhn-rojo-corporativo) #ffffff99; scrollbar-width: thin;"  :class="{'spainhn-gray-background': type === 'monumento'}">{{ title }}</p>
                    <p class="info-subtitle-text text-center" v-if="showSubtitle === true" :class="{'text-white': type === 'monumento'}">{{ subtitle }}</p>
                    <p class="info-none-text text-center mb-0" v-bind:class="{ 'fw-bold': bold, 'text-secondary': muted }">
                        {{ content }}
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import BuyComponent from './BuyComponentSmall.vue';
export default {
    components: {
        BuyComponent
    },
    props: {
        imageUrl: String,
        title: String,
        content: String,
        subtitle: String,
        navigationUrl: String,
        showSubtitle: { type: Boolean, default: true },
        bold: { type: Boolean, default: false },
        muted: { type: Boolean, default: false },
        buyBtn: { type: Boolean, default: false },
        type: { type: String, required: false, default: ''}
    },
    methods: {
        goToBuy() {
            this.$emit('goToBuy');
        }
    },
    computed: {
        image() {
            return encodeURI(this.imageUrl);
            // return `url(${this.imageUrl})`;
        },
        tipoDeResultado(){
            if( this.type === 'paquetes_monumentos'){
                return 'spainhn-label-paquete'
            }else{
                return 'spainhn-label-visit'
            }
        }

    }
}
</script>

<style scoped>
    .card-container{
        width: 179px;
        height: 251px;
        flex-shrink: 0;
        border-radius: 7px;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.11);
        overflow: hidden !important;
    }
    .image-container{
        width: 179px;
        height: 155px;
        flex-shrink:0;
        position: relative;
    }
    .image-container > img{
        width: 100%;
        height: 155px;
        object-fit: cover;
        /* border-radius: 10px 10px 0px 0px; */
    }

    .info-container{
        width: 100%;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .text-container{
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        color: var(--spainhn-buy-button);
        /* color: #fff; */
        flex-direction: column;
        justify-content: center;
    }

    .info-title-text{
        text-align: start;
        font-size: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
    }

    .info-subtitle-text{
        text-align: start;
        font-size: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: rgba( 133, 11, 11, 0.50);
        margin-bottom: 5px;
    }

    .info-none-text{
        text-align: start;
        font-size: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .bg-corp{
        background-color: var(--spainhn-rojo-corporativo);
    }

    .buy-icon{
        width: 45px;
        height: 45px;
    }
</style>