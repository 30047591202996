var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "loader-container" }, [
          _c("img", {
            staticClass: "animated flip infinite",
            staticStyle: { height: "250px", width: "auto" },
            attrs: { src: require("../../assets/SimboloCastillo.svg") },
          }),
          _c("br"),
          _c("span", { staticClass: "text-white" }, [
            _vm._v(" " + _vm._s(_vm.$t("default.LOADER_MSG")) + " "),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }