var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M99.1,196.9c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8\n\t\tC106,199.9,102.9,196.8,99.1,196.9z",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M171.6,203.2c0-3.8-3.1-6.8-6.8-6.8c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8\n\t\tC168.6,210,171.6,206.9,171.6,203.2z",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M187.6,175.2c1,0,1.9-0.9,1.9-1.9l-0.3-51.8c0-0.9-0.7-1.7-1.6-1.9L86.4,103.3l-1.7-15.8\n\t\tc-0.1-1-0.9-1.7-1.9-1.7l-16.7,0.1c-1,0-1.9,0.9-1.9,1.9c0,1.1,0.8,1.9,1.9,1.9l15-0.1l10.7,101.9c0.1,1,0.9,1.7,1.9,1.7l76.7-0.5\n\t\tc1.1,0,1.9-0.8,1.9-1.9c0-1.1-0.8-1.9-1.9-1.9l-75,0.5l-8.7-82.2l98.6,16l0.3,48.3l-77.5,0.5c-0.5,0-1,0.2-1.3,0.6\n\t\tc-0.3,0.3-0.6,0.7-0.6,1.4c0,1,0.9,1.9,1.9,1.9L187.6,175.2z",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M105.6,139.7c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC107.6,138.8,106.7,139.7,105.6,139.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M148.3,143.3c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC146.4,144.1,147.2,143.3,148.3,143.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M144.4,135.5c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-1.9-1.9\n\t\tC142.5,136.4,143.3,135.5,144.4,135.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M140.6,143.3c1.1,0,1.9,0.9,2,1.9c0,1.1-0.8,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC138.6,144.2,139.5,143.3,140.6,143.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M128.9,135.6c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC126.9,136.5,127.8,135.6,128.9,135.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M121.1,135.7c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC119.2,136.6,120,135.7,121.1,135.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M113.4,135.7c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC111.4,136.6,112.3,135.7,113.4,135.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M109.6,147.4c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC111.5,146.5,110.6,147.4,109.6,147.4",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M113.5,155.1c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC115.4,154.2,114.6,155.1,113.5,155.1",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M117.3,143.5c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC115.4,144.4,116.2,143.5,117.3,143.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M117.4,162.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC119.3,162,118.5,162.9,117.4,162.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M121.2,155.1c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC123.2,154.2,122.3,155.1,121.2,155.1",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M125,143.4c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9C123.1,144.3,124,143.4,125,143.4",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M125.2,162.8c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC127.1,161.9,126.2,162.8,125.2,162.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M129,155c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C130.9,154.1,130.1,155,129,155",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M132.8,143.4c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC130.9,144.2,131.7,143.4,132.8,143.4",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M132.9,162.8c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC134.9,161.9,134,162.8,132.9,162.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M136.8,155c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C138.7,154.1,137.8,155,136.8,155",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M136.7,139.5c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC138.6,138.6,137.7,139.5,136.7,139.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M140.7,162.7c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC142.6,161.8,141.8,162.7,140.7,162.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M144.5,154.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC146.4,154,145.6,154.9,144.5,154.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M148.4,162.7c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC150.4,161.8,149.5,162.7,148.4,162.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M152.3,154.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC154.2,154,153.3,154.9,152.3,154.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M152.2,139.4c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC154.1,138.5,153.2,139.4,152.2,139.4",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M156.1,143.2c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC154.1,144.1,155,143.2,156.1,143.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M156.2,162.6c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC158.1,161.7,157.3,162.6,156.2,162.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M160,154.8c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC161.9,153.9,161.1,154.8,160,154.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M159.9,139.3c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC161.9,138.4,161,139.3,159.9,139.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M164,162.6c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C165.9,161.7,165,162.6,164,162.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M163.9,147c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C165.8,146.2,164.9,147,163.9,147",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M167.8,154.8c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC169.7,153.9,168.9,154.8,167.8,154.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M167.7,139.3c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC169.6,138.4,168.8,139.3,167.7,139.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M171.6,147c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C173.5,146.1,172.7,147,171.6,147",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M175.4,139.2c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC177.4,138.3,176.5,139.2,175.4,139.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M105.7,155.2c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC107.7,154.3,106.8,155.2,105.7,155.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M109.7,162.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC111.6,162,110.7,162.9,109.7,162.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M171.7,162.5c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC173.6,161.6,172.8,162.5,171.7,162.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M167.8,154.8c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC169.7,153.9,168.9,154.8,167.8,154.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M175.5,154.7c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC177.5,153.8,176.6,154.7,175.5,154.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M101.7,131.9c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC103.6,131.1,102.8,131.9,101.7,131.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M144.4,135.5c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC142.5,136.4,143.3,135.5,144.4,135.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M140.5,127.8c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-1.9-1.9\n\t\tC138.5,128.7,139.4,127.8,140.5,127.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M136.6,135.6c1.1,0,1.9,0.9,2,1.9c0,1.1-0.8,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC134.7,136.5,135.6,135.6,136.6,135.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M124.9,127.9c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC123,128.8,123.9,127.9,124.9,127.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M117.2,128c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9C115.3,128.8,116.1,128,117.2,128",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M109.4,128c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9C107.5,128.9,108.4,128,109.4,128",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M105.6,139.7c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC107.6,138.8,106.7,139.7,105.6,139.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M113.4,135.7c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC111.4,136.6,112.3,135.7,113.4,135.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M121.1,135.7c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC119.2,136.6,120,135.7,121.1,135.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M128.9,135.6c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC126.9,136.5,127.8,135.6,128.9,135.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M132.7,131.7c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC134.7,130.9,133.8,131.7,132.7,131.7",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M148.2,131.6c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC150.2,130.7,149.3,131.6,148.2,131.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M152.1,135.5c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC150.2,136.4,151.1,135.5,152.1,135.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M156,131.6c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C157.9,130.7,157.1,131.6,156,131.6",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M159.9,139.3c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC161.9,138.4,161,139.3,159.9,139.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M163.8,131.5c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC165.7,130.6,164.8,131.5,163.8,131.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M97.8,124.2c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C99.7,123.3,98.8,124.2,97.8,124.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M140.5,127.8c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC138.5,128.7,139.4,127.8,140.5,127.8",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M136.5,120.1c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-1.9-1.9\n\t\tC134.6,121,135.5,120.1,136.5,120.1",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M132.7,127.9c1.1,0,1.9,0.9,2,1.9c0,1.1-0.8,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC130.8,128.7,131.6,127.9,132.7,127.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M121,120.2c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9C119.1,121.1,119.9,120.2,121,120.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M113.3,120.2c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC111.3,121.1,112.2,120.2,113.3,120.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M105.5,120.3c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC103.6,121.2,104.4,120.3,105.5,120.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M101.7,131.9c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC103.6,131.1,102.8,131.9,101.7,131.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M109.4,128c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9C107.5,128.9,108.4,128,109.4,128",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M117.2,128c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9C115.3,128.8,116.1,128,117.2,128",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M124.9,127.9c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC123,128.8,123.9,127.9,124.9,127.9",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M128.8,124c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C130.7,123.1,129.9,124,128.8,124",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M101.8,147.5c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9\n\t\tC103.7,146.6,102.9,147.4,101.8,147.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M109.5,143.5c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC107.6,144.4,108.5,143.5,109.5,143.5",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M97.8,124.2c-1.1,0-1.9-0.9-2-1.9c0-1.1,0.9-1.9,1.9-2c1.1,0,1.9,0.9,2,1.9C99.7,123.3,98.8,124.2,97.8,124.2",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M105.5,120.3c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC103.6,121.2,104.4,120.3,105.5,120.3",
      },
    }),
    _c("path", {
      staticClass: "st0",
      attrs: {
        d: "M113.3,120.2c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2c-1.1,0-1.9-0.9-2-1.9\n\t\tC111.3,121.1,112.2,120.2,113.3,120.2",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }