// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/Garcinuno.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.image-header[data-v-33e013f6]{\n    position: absolute;\n    height: 55vh;\n    width: 100%;\n    background-position: center;\n    /* background-image: url('../assets//MONUMENTOS_.jpg'); */\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(133, 11, 11, 0.85) 100% );\n}\n.image-header-container[data-v-33e013f6]{\n    position: relative;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), lightgray 50% / cover no-repeat;\n    width: auto;\n    height: 55vh;\n}\n.header-text[data-v-33e013f6]{\n    font-size: 14px;\n    color: #FFF;\n    font-weight: 400;\n    font-style: normal;\n    font-family: Montserrat;\n    max-height: 80%;\n    max-width: 85%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.text-container[data-v-33e013f6]{\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    -webkit-line-clamp: 4;\n}\n.header-title[data-v-33e013f6]{\n    color: #FFF;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}\n\n", ""]);
// Exports
module.exports = exports;
