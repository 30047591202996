var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M236,101.7H64c-6.5,0-11.8,5.3-11.8,11.8v72.9c0,6.5,5.3,11.8,11.8,11.9h172c6.5,0,11.8-5.3,11.9-11.9v-72.9\n        C247.8,107,242.5,101.7,236,101.7z M244.2,186.5c0,4.5-3.7,8.2-8.2,8.2H64c-4.5,0-8.2-3.7-8.2-8.2v-72.9c0-4.5,3.7-8.2,8.2-8.2h172\n        c4.5,0,8.2,3.7,8.2,8.2V186.5z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M234.1,139.9c-4.6,0-8.4,3.8-8.3,8.4c0,4.6,3.8,8.4,8.4,8.3c4.6,0,8.3-3.8,8.3-8.4\n        C242.5,143.7,238.7,139.9,234.1,139.9z M234.1,153c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7\n        C238.8,150.9,236.7,153,234.1,153L234.1,153z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M213.2,110.3H71.2c-5.5,0-10,4.5-10,10v59.2c0,5.5,4.5,10,10,10h141.9c5.5,0,10-4.5,10-10v-59.2\n        C223.2,114.9,218.8,110.4,213.2,110.3C213.2,110.4,213.2,110.3,213.2,110.3z M219.6,179.6c0,3.5-2.8,6.4-6.4,6.4H71.2\n        c-3.5,0-6.4-2.8-6.4-6.4l0,0v-59.2c0-3.5,2.8-6.4,6.3-6.4c0,0,0,0,0,0h141.9c3.5,0,6.4,2.8,6.4,6.4V179.6z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M103.4,175l-0.9-0.1c-1-0.2-2,0.4-2.2,1.4c-0.2,1,0.4,2,1.4,2.2c0.1,0,0.1,0,0.2,0c0.3,0.1,0.7,0.1,1,0.2h0.2\n        c1,0,1.8-0.9,1.8-1.9C104.9,175.9,104.3,175.1,103.4,175L103.4,175z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M99.8,148.5c-0.9-0.5-2-0.2-2.5,0.7c0,0,0,0,0,0l-0.5,0.8c-0.5,0.9-0.3,2,0.6,2.5c0.3,0.2,0.6,0.3,1,0.3\n        c0.6,0,1.2-0.3,1.6-0.9l0.5-0.9C101,150.1,100.7,149,99.8,148.5C99.8,148.5,99.8,148.5,99.8,148.5z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M98.5,173.6l-0.8-0.4c-0.9-0.5-2-0.1-2.5,0.8c-0.4,0.8-0.1,1.9,0.6,2.4c0.3,0.2,0.6,0.4,1,0.5\n        c0.9,0.4,2,0.1,2.5-0.8C99.8,175.1,99.4,174,98.5,173.6C98.5,173.6,98.5,173.6,98.5,173.6z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M94.1,169.9c-0.5-0.9-1.6-1.2-2.5-0.7c-0.9,0.5-1.2,1.6-0.7,2.5c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9\n        c0.3,0.4,0.9,0.7,1.4,0.7c0.4,0,0.8-0.1,1.2-0.4c0.8-0.6,0.9-1.8,0.3-2.6C94.4,170.3,94.3,170.1,94.1,169.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M108.6,174.8c-0.3,0.1-0.6,0.1-0.8,0.1c-1,0.1-1.7,1.1-1.5,2.1c0.1,0.9,0.9,1.6,1.8,1.6h0.3l1-0.2\n        c1-0.2,1.6-1.2,1.4-2.2C110.5,175.3,109.6,174.6,108.6,174.8z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M121.4,163.1c-0.9-0.4-2,0-2.4,0.9c0,0,0,0,0,0l-0.4,0.9c-0.4,0.9,0,2,0.9,2.4c0.2,0.1,0.5,0.2,0.8,0.2\n        c0.7,0,1.4-0.4,1.7-1.1l0.4-0.9C122.7,164.6,122.3,163.5,121.4,163.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M124,157.9c-0.9-0.5-2-0.1-2.5,0.8c0,0,0,0,0,0l-0.5,0.9c-0.5,0.9-0.1,2,0.8,2.5c0.3,0.1,0.5,0.2,0.8,0.2\n        c0.7,0,1.3-0.4,1.6-1l0.4-0.8C125.2,159.5,124.9,158.4,124,157.9C124,157.9,124,157.9,124,157.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M91.1,168.2c1-0.1,1.8-0.9,1.7-1.9c0,0,0,0,0-0.1c0-0.2,0-0.3,0-0.5v-0.3c0-1-0.9-1.8-1.9-1.8\n        c-0.9,0-1.7,0.7-1.8,1.7v0.4c0,0.2,0,0.5,0,0.7c0,1,0.9,1.8,1.8,1.8L91.1,168.2z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M113.2,172.8c-0.2,0.2-0.5,0.3-0.7,0.5c-0.9,0.5-1.1,1.7-0.6,2.5c0,0,0,0,0,0c0.3,0.5,0.9,0.9,1.6,0.9\n        c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.6-0.4,0.9-0.6c0.8-0.6,1-1.7,0.4-2.6C115.2,172.4,114,172.2,113.2,172.8L113.2,172.8z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M119,168.4c-0.9-0.5-2-0.2-2.5,0.7c0,0,0,0,0,0c-0.2,0.3-0.3,0.5-0.5,0.7c-0.6,0.9-0.3,2,0.5,2.5c0,0,0,0,0,0\n        c0.9,0.5,2,0.3,2.5-0.5c0.2-0.3,0.4-0.6,0.6-0.9C120.2,170,119.9,168.9,119,168.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M90.9,135.1h0.9h0.2c1,0,1.8-0.9,1.8-1.9c0-0.9-0.7-1.7-1.7-1.8l-1.1,0c-1,0-1.8,0.8-1.9,1.8\n        C89.1,134.2,89.9,135.1,90.9,135.1C90.9,135.1,90.9,135.1,90.9,135.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M95.5,136.3c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.3,0.7,0.4,1.1,0.4c1,0,1.8-0.8,1.8-1.8c0-0.6-0.3-1.1-0.7-1.4\n        c-0.3-0.2-0.7-0.5-1-0.7c-0.8-0.6-2-0.4-2.6,0.5c-0.6,0.8-0.4,2,0.5,2.6C95.4,136.3,95.4,136.3,95.5,136.3L95.5,136.3z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M99.2,144.4c0,0.3-0.1,0.6-0.2,0.8c-0.2,1,0.4,2,1.4,2.2c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0.1\n        c0.9,0,1.6-0.6,1.8-1.4c0.1-0.4,0.2-0.7,0.2-1.1c0.1-1-0.6-1.9-1.6-2.1C100.2,142.9,99.4,143.5,99.2,144.4L99.2,144.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M94.2,157.3c0.3,0.2,0.6,0.3,1,0.3c0.6,0,1.2-0.3,1.5-0.8l0.5-0.8c0.7-0.8,0.6-1.9-0.1-2.6\n        c-0.8-0.7-1.9-0.6-2.6,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-0.6,0.8C93.1,155.6,93.4,156.7,94.2,157.3z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M98.5,139.9l0.3,0.8c0.3,0.7,1,1.2,1.7,1.2c0.2,0,0.4,0,0.6-0.1c1-0.4,1.4-1.4,1.1-2.4l-0.4-1\n        c-0.4-0.9-1.5-1.4-2.4-1S98.1,138.8,98.5,139.9C98.5,139.8,98.5,139.8,98.5,139.9L98.5,139.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M80,133.3l-0.9,0.3c-1,0.3-1.5,1.4-1.2,2.3c0.2,0.8,0.9,1.3,1.7,1.3c0.2,0,0.4,0,0.6-0.1l0.9-0.3\n        c1-0.2,1.6-1.2,1.4-2.2s-1.2-1.6-2.2-1.4C80.1,133.2,80.1,133.2,80,133.3z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M85.8,131.8c-0.3,0-0.7,0.1-1,0.2c-1,0.2-1.6,1.2-1.4,2.2c0.2,0.8,0.9,1.5,1.8,1.5c0.1,0,0.2,0,0.4,0l0.9-0.2\n        c1-0.2,1.7-1.1,1.5-2.1S86.8,131.7,85.8,131.8L85.8,131.8z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M74.4,135.1l-0.9,0.3c-1,0.3-1.5,1.4-1.2,2.3c0.2,0.8,0.9,1.3,1.7,1.3c0.2,0,0.4,0,0.6-0.1l0.9-0.3\n        c1-0.4,1.4-1.4,1.1-2.4C76.3,135.3,75.3,134.8,74.4,135.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M93.5,158.3c-0.9-0.5-2-0.1-2.5,0.7c0,0,0,0,0,0c-0.2,0.3-0.3,0.6-0.5,0.9c-0.4,0.9-0.1,2,0.8,2.4c0,0,0,0,0,0\n        c0.2,0.1,0.5,0.2,0.8,0.2c0.7,0,1.3-0.4,1.7-1c0.1-0.3,0.3-0.5,0.4-0.8C94.8,159.9,94.5,158.8,93.5,158.3\n        C93.6,158.3,93.6,158.3,93.5,158.3z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M184.5,169.6c-0.3,0-0.5,0-0.8,0.1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8l0,0c0.4,0,0.7,0,1.1-0.1\n        c1-0.1,1.7-1,1.6-2c0,0,0,0,0,0C186.3,170.3,185.5,169.5,184.5,169.6z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M189,167.9c-0.2,0.1-0.5,0.3-0.7,0.4c-0.9,0.5-1.2,1.6-0.7,2.5c0.3,0.6,0.9,0.9,1.6,0.9c0.3,0,0.6-0.1,0.9-0.2\n        c0.3-0.2,0.6-0.4,0.8-0.4c0.2-0.1,0.3-0.2,0.5-0.4c0.7-0.7,0.7-1.9,0-2.6C190.7,167.5,189.7,167.4,189,167.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M179.6,168.9l-0.8-0.3c-0.9-0.5-2-0.1-2.5,0.8c-0.5,0.9-0.1,2,0.8,2.5c0.1,0,0.1,0.1,0.2,0.1\n        c0.3,0.1,0.6,0.3,1,0.4c0.2,0.1,0.4,0.1,0.6,0.1c1,0,1.8-0.8,1.8-1.9C180.8,169.9,180.3,169.2,179.6,168.9L179.6,168.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M193.4,147.9c-0.5-0.9-1.7-1.1-2.5-0.6s-1.1,1.7-0.6,2.5l0.4,0.8c0.5,0.9,1.6,1.2,2.5,0.7s1.2-1.6,0.7-2.5\n        C193.8,148.5,193.6,148.2,193.4,147.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M174.2,165.9c-0.8-0.6-2-0.4-2.6,0.4c-0.6,0.8-0.4,2,0.4,2.6c0,0,0,0,0,0l0.8,0.6c0.3,0.2,0.6,0.3,1,0.3\n        c1,0,1.8-0.9,1.8-1.9c0-0.6-0.3-1.1-0.8-1.5C174.6,166.3,174.4,166.1,174.2,165.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M124.7,153.5l-0.6,0.8c-0.6,0.8-0.4,2,0.4,2.6c0.3,0.2,0.7,0.3,1.1,0.3c0.6,0,1.2-0.3,1.5-0.8l0.5-0.8\n        c0.6-0.8,0.4-2-0.4-2.6C126.5,152.6,125.3,152.7,124.7,153.5L124.7,153.5L124.7,153.5z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M169.8,162.4c-0.8-0.7-1.9-0.6-2.6,0.2c-0.7,0.8-0.6,1.9,0.1,2.6l0.8,0.7c0.8,0.7,1.9,0.6,2.6-0.2\n        c0.7-0.8,0.6-1.9-0.2-2.6l0,0L169.8,162.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M195.5,158L195.5,158c-1,0-1.8,0.9-1.8,1.9c0,0.2,0,0.5,0,0.8c-0.1,1,0.7,1.9,1.7,1.9c0,0,0,0,0,0h0.1\n        c1,0,1.8-0.8,1.8-1.7c0-0.3,0-0.6,0-1.1C197.3,158.8,196.5,158,195.5,158C195.5,158,195.5,158,195.5,158z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M195,163.7c-0.9-0.5-2-0.2-2.5,0.7c0,0,0,0,0,0c-0.1,0.2-0.3,0.4-0.4,0.7c-0.6,0.8-0.4,2,0.4,2.6\n        c0.3,0.2,0.6,0.3,1,0.3c0.6,0,1.2-0.3,1.5-0.8c0.2-0.3,0.4-0.7,0.6-1C196.1,165.3,195.8,164.2,195,163.7z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M192.6,154.7c0.1,0.3,0.2,0.6,0.3,0.8c0.2,0.8,1,1.3,1.8,1.3c0.2,0,0.4,0,0.5-0.1c1-0.3,1.5-1.3,1.2-2.3\n        c0,0,0,0,0,0c-0.1-0.3-0.2-0.7-0.3-1c-0.3-1-1.4-1.5-2.4-1.1C192.8,152.7,192.3,153.8,192.6,154.7L192.6,154.7z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M147.1,144.9c-0.4-0.1-0.7-0.2-1.1-0.2c-1-0.2-2,0.5-2.1,1.5s0.5,2,1.5,2.1l0,0c0.3,0,0.5,0.1,0.8,0.2\n        c0.2,0,0.3,0.1,0.5,0.1c1,0,1.8-0.8,1.9-1.8C148.5,145.8,147.9,145.1,147.1,144.9z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M134.6,146l-1,0.4c-0.9,0.4-1.4,1.5-1,2.4c0.4,0.9,1.5,1.4,2.4,1c0.3-0.1,0.5-0.2,0.8-0.3\n        c1-0.3,1.5-1.3,1.2-2.3c-0.3-1-1.3-1.5-2.3-1.2C134.7,146,134.7,146,134.6,146L134.6,146z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M140.7,144.6c-0.4,0-0.7,0.1-1,0.1c-1,0.1-1.7,1.1-1.6,2.1c0.1,0.9,0.9,1.6,1.8,1.6h0.2l0.9-0.1\n        c1-0.1,1.7-1,1.7-2c0,0,0,0,0,0C142.7,145.3,141.7,144.5,140.7,144.6z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M166.3,159.1l-0.7-0.7c-0.8-0.7-1.9-0.6-2.6,0.1c-0.6,0.7-0.6,1.8,0.1,2.5l0.7,0.7c0.3,0.3,0.8,0.5,1.3,0.5\n        c1,0,1.8-0.8,1.8-1.8C166.9,160,166.7,159.5,166.3,159.1L166.3,159.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M129.1,149.1c-0.3,0.2-0.6,0.4-0.8,0.7c-0.8,0.7-0.9,1.8-0.2,2.6c0.3,0.4,0.9,0.6,1.4,0.6\n        c0.4,0,0.9-0.2,1.2-0.4c0.2-0.2,0.4-0.4,0.7-0.6c0.8-0.6,1-1.8,0.3-2.6C131.1,148.6,129.9,148.4,129.1,149.1\n        C129.1,149,129.1,149.1,129.1,149.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M162.1,155.1l-0.7-0.7c-0.7-0.7-1.9-0.8-2.6-0.1c-0.7,0.7-0.8,1.9-0.1,2.6c0.1,0.1,0.1,0.1,0.2,0.2l0.7,0.7\n        c0.8,0.7,1.9,0.6,2.6-0.1C162.8,156.9,162.8,155.8,162.1,155.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M157.7,151.1l-0.8-0.6c-0.7-0.7-1.9-0.7-2.6,0.1c-0.7,0.7-0.7,1.9,0.1,2.6c0.1,0.1,0.1,0.1,0.2,0.2l0.7,0.6\n        c0.8,0.7,1.9,0.6,2.6-0.2c0,0,0,0,0,0C158.6,153,158.5,151.8,157.7,151.1C157.7,151.1,157.7,151.1,157.7,151.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M152.9,147.5L152,147c-0.8-0.6-2-0.4-2.6,0.5c-0.6,0.8-0.4,2,0.5,2.6c0.1,0.1,0.2,0.1,0.2,0.1l0.8,0.5\n        c0.9,0.6,2,0.3,2.6-0.6C154,149.2,153.8,148.1,152.9,147.5L152.9,147.5z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M198.6,140.9l-7.1-3c0-0.2-0.1-0.4-0.2-0.6l4.1-6.8c0.5-0.9,0.1-2-0.8-2.5c-0.8-0.4-1.8-0.2-2.4,0.6l-3.8,6.3\n        c-0.3-0.1-0.7-0.1-1-0.1c-0.7,0-1.4,0.2-2,0.5l-6.4-2.7c-0.9-0.5-2-0.1-2.5,0.8c-0.5,0.9-0.1,2,0.8,2.5c0.1,0,0.2,0.1,0.2,0.1\n        l5.7,2.4c0,0.2-0.1,0.4-0.1,0.5c0,1,0.4,2.1,1.1,2.8l-3.4,5.6c-0.5,0.9-0.2,2,0.6,2.5c0.3,0.2,0.6,0.3,0.9,0.3\n        c0.6,0,1.2-0.3,1.6-0.9l3.7-6.3c1.1-0.1,2.1-0.7,2.8-1.5l6.5,2.7c0.2,0.1,0.5,0.1,0.7,0.1c1,0,1.8-0.8,1.8-1.8\n        C199.8,141.8,199.4,141.1,198.6,140.9L198.6,140.9z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }