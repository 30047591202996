<template class="px-2">
    <div class="card my-auto mt-2 mx-1 mt-0" v-bind:style="{backgroundImage: image}" role="button" @click="goToRestaurantMicrosite">
        <div class="container">
          <div class="card-body w-100" v-bind:class="{'active-background':status, 'disabled-background':!status}">
            <strong class="d-inline-block spainhn-text">{{monument}}</strong>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardsThumbnail',
  props:{
    imageUrl: String,
    // title: String,
    monument: String,
    text: String,
    nombreUrl: String,
    tipo: String
    // date: String
  },
  data(){
    return{
      // image: `url(${require('@/assets/img_avatar.png')})`
      // image: `url(${this.imageUrl})`,
      status: false
      // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
    }
  },
  methods:{
    showText(){
      if(this.status == true)
        this.status = false;
      else
        this.status = true;
    },
    goToRestaurantMicrosite(){
      // console.log(this.monument);
      let $vm = this;
      if($vm.tipo != "Entrada"){
        $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre + '/restaurantes-y-actividades/' + $vm.tipo +'/' + $vm.nombreUrl);        
      }
      else{
        // console.log(this.nombreUrl)
        this.$emit('goTo', this.nombreUrl);
      }
    }
  },
  setup() {

  },
  computed:{
    image(){
      let urlEncoded = encodeURI(this.imageUrl);

      return `url(${urlEncoded})`
    }
  }
}
</script>

<style scoped>

@media (min-width: 1440px) {
    .card{
        height: 231px;
        width: 300px;
        /* height: 50vh; */
    }
}

@media (max-width: 1440px) {
    .card{
        height: 10rem;
        width: 15rem;
        /* height: 50vh; */
    }
}

@media (max-width: 768px){
    .spainhn-subtitle-black{
      font-size: 18pt;
    }
    .card{
        height: 10rem;
        width: 15rem;
        margin: 5px auto !important;
        /* height: 50vh; */
    }
}

@media (max-width: 424px){
    .card{
        height: 15rem;
        width: 20rem;
        /* height: 50vh; */
    }

}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
}

img {
    border-radius: 10px 1px 0 0;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.content{
  z-index: 1;
  background-color: var(--spainhn-gris-caja);
  position: absolute;
  height: 100%;
  width: 100%;
  top:0%;
  /* padding-top:5%; */
  padding: 5% 8% 5% 8%;
  padding-bottom: 20%;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  transition: 0.3s;
}

.card-body{
  height: 30%;
  padding: 5% 5%;
  padding-bottom: 10%;
  /* background-color: rgba(0,0,0,0.7); */
  color:white;
  border-radius: 0px 0px 10px 10px;
  z-index: 2;
  overflow: hidden;
}

.active-background{
  background-color: var(--spainhn-rojo-claro);
}

.disabled-background{
  background-color: rgba(0,0,0,0.7);
}
</style>