var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-auto d-flex flex-column mt-0 pt-0 px-0 mx-0 ml-5" },
    [
      _c("div", { staticClass: "position-relative" }, [
        _c(
          "span",
          { attrs: { role: "button" }, on: { click: () => _vm.$emit("goTo") } },
          [
            _c("icon-ticket", {
              staticClass: "buy-icon",
              attrs: { pulse: _vm.pulse },
            }),
          ],
          1
        ),
        _vm.showText
          ? _c(
              "p",
              {
                staticClass:
                  "m-0 position-absolute buy-ticket text-center w-auto",
                staticStyle: {
                  top: "80%",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              },
              [_c("br"), _vm._v(_vm._s(_vm.label) + " ")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }