var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "20",
        viewBox: "0 0 40 20",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M37.5779 0L2.42207 0C1.78003 0.00106337 1.1646 0.252451 0.710603 0.699087C0.25661 1.14572 0.00108088 1.75119 0 2.38282L0 17.0457C0.00108088 17.6774 0.25661 18.2828 0.710603 18.7295C1.1646 19.1761 1.78003 19.4275 2.42207 19.4286L37.5779 19.4286C38.22 19.4275 38.8354 19.1761 39.2894 18.7295C39.7434 18.2828 39.9989 17.6774 40 17.0457V2.38282C39.9989 1.75119 39.7434 1.14572 39.2894 0.699087C38.8354 0.252451 38.22 0.00106337 37.5779 0ZM39.2478 17.0457C39.2478 17.4815 39.0719 17.8993 38.7587 18.2074C38.4456 18.5155 38.0208 18.6886 37.5779 18.6886L2.42207 18.6886C1.97919 18.6886 1.55444 18.5155 1.24127 18.2074C0.928107 17.8993 0.752172 17.4815 0.752172 17.0457L0.752172 2.38282C0.752172 1.94712 0.928107 1.52925 1.24127 1.22116C1.55444 0.913067 1.97919 0.739983 2.42207 0.739983L37.5779 0.739983C38.0208 0.739983 38.4456 0.913067 38.7587 1.22116C39.0719 1.52925 39.2478 1.94712 39.2478 2.38282V17.0457Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.1351 7.42856C36.7964 7.43018 36.4657 7.53209 36.1849 7.72144C35.9041 7.91079 35.6856 8.17909 35.5571 8.49247C35.4286 8.80586 35.3958 9.15028 35.4629 9.48228C35.53 9.81428 35.6939 10.119 35.9339 10.3579C36.174 10.5968 36.4795 10.7593 36.8118 10.8248C37.1441 10.8903 37.4884 10.8558 37.8011 10.7259C38.1139 10.5959 38.3812 10.3761 38.5692 10.0944C38.7572 9.81267 38.8575 9.48156 38.8575 9.14285C38.8559 8.68728 38.6736 8.25094 38.3507 7.92957C38.0278 7.60819 37.5906 7.42801 37.1351 7.42856ZM37.1351 10.1043C36.9447 10.1031 36.7589 10.0455 36.6012 9.93883C36.4435 9.83213 36.321 9.68111 36.2491 9.50482C36.1772 9.32854 36.1591 9.1349 36.1971 8.94834C36.2351 8.76179 36.3276 8.59069 36.4628 8.45663C36.598 8.32258 36.7699 8.23158 36.9567 8.19513C37.1436 8.15868 37.3371 8.1784 37.5127 8.25182C37.6884 8.32524 37.8384 8.44905 37.9437 8.60763C38.0491 8.76622 38.1051 8.95246 38.1047 9.14285C38.102 9.39806 37.9986 9.64186 37.817 9.82118C37.6354 10.0005 37.3903 10.1008 37.1351 10.1002V10.1043Z",
          fill: "#732B28",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.8102 1.71414L3.7606 1.71414C3.21781 1.71414 2.69724 1.92693 2.31343 2.3057C1.92961 2.68446 1.71399 3.19818 1.71399 3.73383L1.71399 15.6944C1.71668 16.2293 1.93317 16.7415 2.3164 17.1196C2.69963 17.4978 3.21864 17.7115 3.7606 17.7141L32.8102 17.7141C33.3522 17.7115 33.8712 17.4978 34.2544 17.1196C34.6377 16.7415 34.8541 16.2293 34.8568 15.6944V3.74191C34.8579 3.476 34.8058 3.2125 34.7034 2.96653C34.601 2.72055 34.4504 2.49695 34.2603 2.30855C34.0701 2.12015 33.8442 1.97065 33.5953 1.86865C33.3465 1.76665 33.0797 1.71414 32.8102 1.71414ZM34.1119 15.7025C34.1113 16.043 33.974 16.3694 33.73 16.6102C33.486 16.851 33.1553 16.9865 32.8102 16.987L3.7606 16.987C3.58967 16.987 3.42041 16.9538 3.26248 16.8893C3.10456 16.8247 2.96107 16.7301 2.8402 16.6108C2.71933 16.4915 2.62345 16.3499 2.55804 16.1941C2.49262 16.0382 2.45896 15.8712 2.45896 15.7025L2.45896 3.74191C2.45815 3.57272 2.49122 3.40503 2.55627 3.24849C2.62132 3.09194 2.71707 2.94962 2.83802 2.8297C2.95897 2.70978 3.10274 2.61462 3.26106 2.54968C3.41939 2.48475 3.58915 2.45133 3.7606 2.45133L32.8102 2.45133C33.1553 2.45186 33.486 2.58737 33.73 2.82815C33.974 3.06893 34.1113 3.39534 34.1119 3.73585V15.7025Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.4828 14.8846L10.2662 14.8637C10.2065 14.8555 10.1447 14.8548 10.0845 14.8616C10.0243 14.8684 9.96701 14.8825 9.91606 14.9031C9.86511 14.9237 9.82157 14.9503 9.78807 14.9814C9.75457 15.0125 9.7318 15.0474 9.72115 15.084C9.71049 15.1206 9.71217 15.1582 9.72607 15.1944C9.73998 15.2305 9.76582 15.2646 9.80205 15.2946C9.83827 15.3246 9.88413 15.3497 9.93684 15.3686C9.98955 15.3875 10.048 15.3997 10.1087 15.4045C10.1949 15.4135 10.2785 15.4224 10.3548 15.4284H10.4114C10.5251 15.4273 10.634 15.4003 10.7164 15.3527C10.7988 15.3051 10.8487 15.2404 10.8561 15.1715C10.8636 15.1026 10.828 15.0346 10.7566 14.9808C10.6851 14.9271 10.583 14.8917 10.4705 14.8816L10.4828 14.8846Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.5869 9.20374C9.55823 9.17349 9.52662 9.15402 9.49388 9.14644C9.46114 9.13886 9.42791 9.14332 9.3961 9.15957C9.36429 9.17581 9.33453 9.20352 9.30852 9.2411C9.28252 9.27869 9.26078 9.3254 9.24456 9.37857L9.1802 9.57875C9.15673 9.64922 9.14388 9.73004 9.14299 9.81281C9.14211 9.89557 9.15321 9.97727 9.17515 10.0494C9.19709 10.1216 9.22906 10.1815 9.26776 10.2231C9.30645 10.2646 9.35045 10.2862 9.39519 10.2857C9.43839 10.2862 9.48093 10.2659 9.51862 10.2268C9.55631 10.1877 9.58786 10.1312 9.61018 10.0627L9.68138 9.83974C9.69776 9.7865 9.70831 9.72782 9.71242 9.66704C9.71653 9.60626 9.71413 9.54457 9.70536 9.48551C9.69659 9.42644 9.68161 9.37116 9.66128 9.32281C9.64095 9.27446 9.61568 9.234 9.5869 9.20374Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.44498 14.3697L9.25339 14.3136C9.14626 14.284 9.02251 14.2779 8.9075 14.2967C8.7925 14.3155 8.69495 14.3577 8.63485 14.4147C8.57475 14.4717 8.55666 14.5392 8.58427 14.6032C8.61189 14.6673 8.68312 14.7232 8.78337 14.7594C8.86511 14.7846 8.9443 14.8084 9.03882 14.8309C9.15031 14.861 9.27902 14.8657 9.39679 14.8439C9.51455 14.8221 9.61178 14.7756 9.66721 14.7145C9.69441 14.6839 9.71023 14.6507 9.71376 14.6167C9.71728 14.5827 9.70845 14.5486 9.68776 14.5166C9.66708 14.4845 9.63496 14.4549 9.59327 14.4297C9.55159 14.4045 9.50118 14.3841 9.44498 14.3697Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.45718 13.8325C8.44084 13.805 8.41871 13.7809 8.39215 13.7618C8.36558 13.7426 8.33516 13.7288 8.30275 13.7213C8.27034 13.7137 8.23664 13.7125 8.20373 13.7178C8.17082 13.723 8.1394 13.7346 8.1114 13.7519C8.08341 13.7691 8.05944 13.7915 8.04098 13.8179C8.02252 13.8442 8.00996 13.8738 8.00407 13.9049C7.99818 13.936 7.9991 13.968 8.00675 13.9987C8.01441 14.0295 8.02865 14.0584 8.04859 14.0838C8.07596 14.1241 8.10609 14.1627 8.1388 14.1993C8.16151 14.2263 8.19036 14.2482 8.22321 14.2632C8.25605 14.2782 8.29204 14.286 8.3285 14.286C8.38403 14.2856 8.43778 14.2674 8.48106 14.2345C8.53122 14.1961 8.56329 14.1404 8.57025 14.0797C8.57721 14.019 8.5585 13.9581 8.5182 13.9104C8.49634 13.8856 8.47596 13.8596 8.45718 13.8325Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.4598 14.8616C11.39 14.8705 11.3177 14.878 11.248 14.8839C11.1335 14.8941 11.0297 14.9297 10.9574 14.9837C10.8851 15.0376 10.8497 15.1059 10.8582 15.1748C10.8667 15.2437 10.9185 15.3082 11.0033 15.3552C11.0881 15.4023 11.1995 15.4284 11.3153 15.4284H11.385L11.6342 15.4002C11.7532 15.3851 11.8575 15.3426 11.9243 15.2818C11.991 15.2211 12.0148 15.147 11.9905 15.0758C11.967 15.0056 11.8981 14.9438 11.7987 14.9037C11.6994 14.8636 11.5776 14.8485 11.4598 14.8616Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.1315 12.286C14.0871 12.2656 14.039 12.2542 13.9901 12.2525C13.9412 12.2507 13.8924 12.2587 13.8466 12.2758C13.8008 12.293 13.7588 12.319 13.7231 12.3525C13.6874 12.3859 13.6586 12.4261 13.6385 12.4707L13.5573 12.6472C13.5159 12.7371 13.5119 12.8397 13.5461 12.9326C13.5804 13.0254 13.6501 13.1008 13.7399 13.1423C13.789 13.1646 13.8422 13.1763 13.8962 13.1768C13.9674 13.1763 14.037 13.1556 14.0969 13.1172C14.1569 13.0788 14.2048 13.0242 14.235 12.9597L14.3162 12.7791C14.3568 12.6892 14.3602 12.5869 14.3256 12.4945C14.291 12.4021 14.2212 12.3271 14.1315 12.286Z",
          fill: "url(#paint0_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.6653 11.2328C14.622 11.2098 14.5747 11.1957 14.5259 11.1911C14.4771 11.1865 14.428 11.1917 14.3812 11.2062C14.3344 11.2207 14.291 11.2443 14.2534 11.2757C14.2158 11.3071 14.1847 11.3456 14.1621 11.389L14.0687 11.5696C14.0242 11.6579 14.0165 11.7603 14.0473 11.8542C14.0781 11.9482 14.1449 12.0261 14.2331 12.0708C14.2855 12.0969 14.343 12.1108 14.4015 12.1114C14.4703 12.1118 14.5379 12.093 14.5967 12.0571C14.6554 12.0213 14.7031 11.9698 14.7343 11.9085L14.8216 11.738C14.8448 11.6947 14.8593 11.6471 14.864 11.5982C14.8687 11.5492 14.8637 11.4997 14.8491 11.4527C14.8346 11.4057 14.8109 11.3621 14.7793 11.3243C14.7477 11.2865 14.709 11.2554 14.6653 11.2328Z",
          fill: "url(#paint1_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.99508 13.3147C8.04463 13.3114 8.09302 13.2982 8.13737 13.2759C8.18173 13.2535 8.22116 13.2225 8.25332 13.1847C8.28548 13.1469 8.30973 13.103 8.32462 13.0556C8.33952 13.0082 8.34476 12.9583 8.34004 12.9089C8.34004 12.8764 8.34004 12.844 8.34004 12.8115V12.7547C8.33808 12.6596 8.29995 12.5689 8.23342 12.501C8.16689 12.4331 8.07697 12.3932 7.982 12.3893C7.88702 12.3854 7.79414 12.4179 7.72229 12.4801C7.65044 12.5424 7.60504 12.6297 7.59534 12.7242V12.8115C7.59534 12.8622 7.59534 12.9129 7.59534 12.9616C7.60003 13.0575 7.64143 13.1478 7.71094 13.2139C7.78046 13.28 7.87276 13.3169 7.9687 13.3167L7.99508 13.3147Z",
          fill: "url(#paint2_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.4675 14.2544C12.4208 14.2889 12.3741 14.3194 12.3275 14.3478C12.2856 14.3733 12.2492 14.4069 12.2204 14.4466C12.1915 14.4863 12.1709 14.5313 12.1596 14.5791C12.1482 14.6268 12.1465 14.6763 12.1544 14.7247C12.1623 14.7731 12.1798 14.8195 12.2057 14.8611C12.2385 14.9158 12.2849 14.961 12.3404 14.9923C12.3959 15.0236 12.4585 15.04 12.5223 15.0397C12.594 15.0414 12.6647 15.0216 12.7252 14.9829C12.7881 14.9443 12.851 14.9038 12.9119 14.8591C12.9926 14.8013 13.047 14.7137 13.0632 14.6157C13.0794 14.5177 13.056 14.4173 12.9981 14.3366C12.9402 14.2559 12.8527 14.2014 12.7547 14.1853C12.6567 14.1691 12.5563 14.1925 12.4756 14.2504L12.4675 14.2544Z",
          fill: "url(#paint3_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.6507 13.3552C13.6084 13.3305 13.5616 13.3144 13.513 13.3079C13.4645 13.3013 13.4151 13.3045 13.3677 13.3171C13.3204 13.3298 13.276 13.3516 13.2372 13.3815C13.1983 13.4114 13.1658 13.4487 13.1414 13.4912C13.1109 13.5439 13.0805 13.5926 13.048 13.6413C13.0212 13.6825 13.0027 13.7285 12.9937 13.7768C12.9846 13.8251 12.9852 13.8747 12.9954 13.9228C13.0056 13.9709 13.0252 14.0164 13.053 14.0569C13.0808 14.0974 13.1164 14.132 13.1576 14.1587C13.2407 14.2118 13.3413 14.2301 13.4378 14.2096C13.5342 14.1891 13.6187 14.1315 13.673 14.0492C13.7116 13.9903 13.7481 13.9295 13.7867 13.8645C13.836 13.7789 13.8494 13.6772 13.8239 13.5818C13.7984 13.4863 13.7361 13.4048 13.6507 13.3552Z",
          fill: "url(#paint4_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.94448 6.60043H8.12101H8.15551C8.25068 6.59891 8.34167 6.5611 8.4099 6.49474C8.47812 6.42837 8.51842 6.33846 8.52257 6.24337C8.52672 6.14828 8.49439 6.0552 8.4322 5.98315C8.37001 5.9111 8.28265 5.86552 8.18798 5.85573L7.96883 5.8537C7.87198 5.85743 7.77967 5.89569 7.70858 5.96156C7.6375 6.02744 7.59233 6.11658 7.58126 6.21286C7.57963 6.26216 7.58774 6.3113 7.60512 6.35747C7.6225 6.40364 7.64881 6.44593 7.68254 6.48192C7.71627 6.51792 7.75677 6.54691 7.80172 6.56724C7.84666 6.58758 7.89517 6.59885 7.94448 6.60043Z",
          fill: "url(#paint5_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.8878 6.84953C8.93042 6.87591 8.97303 6.90432 9.01361 6.93476C9.07841 6.98577 9.15841 7.01363 9.24088 7.01389C9.31856 7.01378 9.39428 6.98943 9.45748 6.94425C9.52068 6.89906 9.5682 6.83529 9.59344 6.76181C9.61867 6.68834 9.62036 6.60882 9.59827 6.53434C9.57617 6.45986 9.53139 6.39412 9.47017 6.3463C9.40521 6.29773 9.33746 6.25301 9.26726 6.21237C9.22539 6.18391 9.17818 6.16426 9.12849 6.15461C9.07879 6.14496 9.02765 6.14552 8.97818 6.15625C8.92871 6.16698 8.88194 6.18766 8.84071 6.21703C8.79947 6.2464 8.76465 6.28385 8.73834 6.3271C8.71203 6.37035 8.69479 6.41849 8.68767 6.46861C8.68055 6.51873 8.6837 6.56978 8.69692 6.61864C8.71014 6.66751 8.73316 6.71317 8.76458 6.75287C8.796 6.79256 8.83516 6.82545 8.87969 6.84953H8.8878Z",
          fill: "url(#paint6_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.62704 8.49732C9.62704 8.55413 9.60675 8.60892 9.59457 8.66371C9.58314 8.71142 9.58128 8.76092 9.58909 8.80935C9.5969 8.85779 9.61423 8.90419 9.64007 8.94589C9.66592 8.98759 9.69978 9.02375 9.73968 9.05229C9.77959 9.08082 9.82475 9.10117 9.87257 9.11215C9.90054 9.11854 9.92911 9.12194 9.95779 9.1223C10.0419 9.12223 10.1236 9.09374 10.1895 9.04144C10.2555 8.98914 10.3018 8.91609 10.321 8.83416C10.3372 8.76314 10.3514 8.69009 10.3636 8.61704C10.3757 8.52109 10.3501 8.4242 10.2922 8.34676C10.2342 8.26932 10.1485 8.21737 10.0531 8.20186C9.95763 8.18634 9.85988 8.20847 9.78042 8.26358C9.70095 8.31869 9.64597 8.40248 9.62704 8.49732Z",
          fill: "url(#paint7_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.61847 11.109C8.67847 11.1497 8.74891 11.1723 8.82138 11.174C8.88286 11.1741 8.94341 11.159 8.9976 11.1299C9.05179 11.1009 9.09792 11.0589 9.13185 11.0076L9.24142 10.8493C9.27688 10.8099 9.30355 10.7634 9.31965 10.7129C9.33575 10.6624 9.3409 10.609 9.33478 10.5564C9.32865 10.5037 9.31138 10.453 9.28411 10.4075C9.25685 10.362 9.22021 10.3229 9.17665 10.2927C9.13308 10.2625 9.08358 10.2419 9.03145 10.2323C8.97931 10.2227 8.92573 10.2244 8.87427 10.2371C8.8228 10.2498 8.77464 10.2734 8.73299 10.3061C8.69133 10.3389 8.65713 10.3802 8.63267 10.4272L8.51904 10.5916C8.46382 10.6735 8.44328 10.7739 8.46191 10.8709C8.48054 10.9678 8.53684 11.0535 8.61847 11.109Z",
          fill: "url(#paint8_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.49265 7.56599L9.55758 7.72223C9.58427 7.79337 9.63193 7.85474 9.69425 7.89822C9.75657 7.9417 9.83061 7.96524 9.90659 7.96573C9.95102 7.96572 9.99505 7.95746 10.0365 7.94138C10.1292 7.90713 10.2046 7.83746 10.2461 7.74768C10.2876 7.6579 10.2917 7.55534 10.2576 7.4625L10.1724 7.25959C10.153 7.21365 10.1246 7.17206 10.0889 7.13728C10.0532 7.10249 10.0108 7.07522 9.96439 7.05706C9.91795 7.03891 9.86835 7.03024 9.8185 7.03157C9.76865 7.03289 9.71958 7.04419 9.67417 7.06479C9.62876 7.0854 9.58794 7.11489 9.55412 7.15152C9.52029 7.18816 9.49415 7.2312 9.47723 7.2781C9.46031 7.32501 9.45296 7.37483 9.4556 7.42462C9.45825 7.47441 9.47085 7.52317 9.49265 7.56802V7.56599Z",
          fill: "url(#paint9_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.73683 6.23094L5.54812 6.28978C5.46386 6.31714 5.39207 6.37356 5.34558 6.44898C5.29909 6.52439 5.28093 6.61387 5.29435 6.70144C5.30776 6.78901 5.35188 6.86896 5.41881 6.927C5.48575 6.98503 5.57114 7.01738 5.65972 7.01825C5.69755 7.01882 5.73523 7.01334 5.77133 7.00202L5.95598 6.94317C6.00564 6.93132 6.05234 6.90942 6.09319 6.87881C6.13405 6.8482 6.1682 6.80954 6.19352 6.76521C6.21885 6.72088 6.23481 6.67183 6.24043 6.62109C6.24605 6.57035 6.2412 6.51899 6.22619 6.4702C6.21117 6.4214 6.18631 6.37621 6.15314 6.3374C6.11997 6.2986 6.07919 6.267 6.03333 6.24458C5.98747 6.22216 5.93749 6.20938 5.88649 6.20703C5.8355 6.20468 5.78456 6.21282 5.73683 6.23094Z",
          fill: "url(#paint10_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.91783 5.93866C6.85087 5.93866 6.78188 5.96301 6.71492 5.97721C6.62483 5.9963 6.54498 6.04801 6.4907 6.1224C6.43642 6.19679 6.41155 6.28861 6.42086 6.38022C6.43017 6.47184 6.47301 6.55678 6.54115 6.61873C6.60929 6.68068 6.69791 6.71526 6.79 6.71583C6.81498 6.71782 6.84009 6.71782 6.86507 6.71583L7.0477 6.6793C7.14484 6.66208 7.23116 6.60698 7.28767 6.52611C7.34418 6.44525 7.36625 6.34524 7.34903 6.2481C7.33181 6.15097 7.2767 6.06465 7.19584 6.00814C7.11497 5.95163 7.01497 5.92955 6.91783 5.94677V5.93866Z",
          fill: "url(#paint11_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.60423 6.59607L4.42161 6.65695C4.33719 6.68436 4.2653 6.74096 4.21883 6.81658C4.17237 6.8922 4.15436 6.9819 4.16804 7.0696C4.18172 7.1573 4.22619 7.23725 4.29348 7.29513C4.36078 7.353 4.44649 7.38501 4.53524 7.38542C4.57383 7.38514 4.61215 7.37898 4.64888 7.36715L4.83962 7.30425C4.9303 7.27053 5.00436 7.20292 5.04617 7.11567C5.08799 7.02842 5.09431 6.92835 5.06379 6.83653C5.03327 6.74472 4.96831 6.66834 4.88258 6.62348C4.79685 6.57863 4.69706 6.5688 4.60423 6.59607Z",
          fill: "url(#paint12_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.48487 11.3041C8.44167 11.2809 8.39427 11.2665 8.34544 11.2618C8.29661 11.2571 8.24733 11.2621 8.20049 11.2767C8.15364 11.2912 8.11016 11.315 8.07258 11.3465C8.03501 11.378 8.00409 11.4167 7.98164 11.4603C7.94917 11.5232 7.91671 11.5841 7.88627 11.647C7.86492 11.6911 7.8525 11.739 7.84972 11.7879C7.84694 11.8367 7.85386 11.8857 7.87008 11.9319C7.8863 11.9781 7.9115 12.0207 7.94423 12.0571C7.97695 12.0935 8.01656 12.1231 8.06078 12.1442C8.11111 12.1696 8.16672 12.1828 8.22311 12.1827C8.29223 12.1832 8.36013 12.1645 8.41924 12.1287C8.47836 12.0928 8.52637 12.0413 8.55792 11.9798C8.5843 11.925 8.61271 11.8702 8.64112 11.8154C8.66536 11.7718 8.68056 11.7238 8.6858 11.6741C8.69105 11.6245 8.68624 11.5743 8.67165 11.5266C8.65707 11.4788 8.63301 11.4345 8.60092 11.3963C8.56883 11.3581 8.52936 11.3267 8.48487 11.3041Z",
          fill: "url(#paint13_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.9354 13.6027C26.8809 13.6091 26.826 13.6125 26.7711 13.6128C26.672 13.6128 26.5771 13.6522 26.5071 13.7222C26.437 13.7922 26.3977 13.8872 26.3977 13.9862C26.3977 14.0852 26.437 14.1802 26.5071 14.2502C26.5771 14.3202 26.672 14.3596 26.7711 14.3596C26.8471 14.3582 26.9229 14.3528 26.9983 14.3433C27.0467 14.3385 27.0937 14.3242 27.1366 14.3012C27.1794 14.2781 27.2173 14.2469 27.248 14.2092C27.2788 14.1714 27.3018 14.128 27.3157 14.0814C27.3296 14.0348 27.3341 13.9859 27.3291 13.9375C27.3188 13.8419 27.2723 13.7539 27.199 13.6916C27.1258 13.6293 27.0314 13.5975 26.9354 13.6027Z",
          fill: "url(#paint14_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27.8546 13.2561C27.8059 13.2866 27.7572 13.317 27.7085 13.3434C27.637 13.384 27.5809 13.4471 27.5489 13.5229C27.5169 13.5987 27.5109 13.683 27.5317 13.7626C27.5525 13.8421 27.599 13.9126 27.664 13.9631C27.729 14.0135 27.8089 14.041 27.8912 14.0414C27.9544 14.0418 28.0166 14.0258 28.0717 13.9947C28.1347 13.9602 28.1955 13.9237 28.2239 13.9034C28.2599 13.884 28.2933 13.8601 28.3234 13.8324C28.3888 13.7671 28.4276 13.6798 28.4323 13.5875C28.4371 13.4952 28.4074 13.4044 28.3491 13.3327C28.2908 13.261 28.2079 13.2135 28.1166 13.1993C28.0253 13.1852 27.9319 13.2054 27.8546 13.2561Z",
          fill: "url(#paint15_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.9511 13.4672L25.7867 13.4023C25.7411 13.3797 25.6915 13.3667 25.6407 13.364C25.5899 13.3613 25.5392 13.369 25.4915 13.3866C25.4438 13.4042 25.4002 13.4314 25.3634 13.4665C25.3266 13.5015 25.2974 13.5437 25.2774 13.5905C25.2575 13.6373 25.2473 13.6876 25.2476 13.7384C25.2478 13.7893 25.2584 13.8395 25.2787 13.8861C25.299 13.9327 25.3286 13.9746 25.3657 14.0094C25.4029 14.0441 25.4467 14.0709 25.4945 14.0881C25.5594 14.1165 25.6264 14.1429 25.6974 14.1673C25.7385 14.1817 25.7817 14.1893 25.8252 14.1896C25.9123 14.189 25.9965 14.1579 26.0631 14.1018C26.1297 14.0457 26.1746 13.968 26.19 13.8823C26.2054 13.7966 26.1904 13.7082 26.1475 13.6324C26.1046 13.5566 26.0365 13.4981 25.9551 13.4672H25.9511Z",
          fill: "url(#paint16_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.7575 9.20186C28.7061 9.11764 28.6233 9.05728 28.5274 9.03406C28.4315 9.01085 28.3303 9.02669 28.2461 9.07808C28.1619 9.12947 28.1015 9.21222 28.0783 9.30812C28.0551 9.40402 28.0709 9.50521 28.1223 9.58943L28.2136 9.7477C28.2375 9.79034 28.2695 9.82786 28.3079 9.85812C28.3462 9.88838 28.3901 9.9108 28.4372 9.92408C28.4842 9.93737 28.5333 9.94126 28.5818 9.93554C28.6304 9.92982 28.6773 9.9146 28.7199 9.89076C28.7626 9.86691 28.8001 9.83489 28.8303 9.79654C28.8606 9.75819 28.883 9.71426 28.8963 9.66725C28.9096 9.62023 28.9135 9.57107 28.9078 9.52255C28.902 9.47403 28.8868 9.42712 28.863 9.38448C28.8285 9.31955 28.794 9.25868 28.7575 9.20186Z",
          fill: "url(#paint17_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.8439 12.8524C24.7633 12.795 24.6633 12.7718 24.5656 12.7877C24.4679 12.8037 24.3805 12.8576 24.3224 12.9376C24.2937 12.9772 24.273 13.022 24.2617 13.0695C24.2503 13.1171 24.2484 13.1664 24.2561 13.2146C24.2639 13.2629 24.281 13.3092 24.3067 13.3508C24.3323 13.3924 24.3659 13.4285 24.4056 13.4571L24.576 13.5768C24.636 13.6175 24.7065 13.64 24.779 13.6417C24.858 13.6395 24.9343 13.6123 24.997 13.5641C25.0596 13.5159 25.1055 13.4491 25.128 13.3733C25.1505 13.2975 25.1485 13.2165 25.1222 13.1419C25.096 13.0673 25.0469 13.0029 24.9819 12.9579C24.9433 12.9255 24.8926 12.8889 24.8439 12.8524Z",
          fill: "url(#paint18_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.8097 10.3438L14.6879 10.5082C14.6311 10.589 14.6085 10.689 14.6253 10.7864C14.642 10.8837 14.6966 10.9705 14.7772 11.0277C14.8399 11.0727 14.9152 11.0968 14.9923 11.0967C15.0518 11.0966 15.1104 11.0824 15.1633 11.0552C15.2163 11.0281 15.262 10.9887 15.2967 10.9404L15.4063 10.7882C15.4354 10.7491 15.4566 10.7045 15.4686 10.6572C15.4806 10.6098 15.4831 10.5606 15.476 10.5122C15.4689 10.4639 15.4524 10.4174 15.4274 10.3755C15.4024 10.3335 15.3693 10.2969 15.3302 10.2678C15.291 10.2386 15.2465 10.2174 15.1991 10.2054C15.1518 10.1935 15.1025 10.1909 15.0542 10.198C15.0058 10.2051 14.9594 10.2216 14.9174 10.2466C14.8755 10.2716 14.8389 10.3047 14.8097 10.3438Z",
          fill: "url(#paint19_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.9567 12.1356C23.9202 12.1031 23.8777 12.0781 23.8315 12.062C23.7853 12.0459 23.7364 12.0391 23.6876 12.0419C23.6388 12.0448 23.5911 12.0572 23.5471 12.0785C23.5031 12.0998 23.4637 12.1295 23.4312 12.166C23.3987 12.2025 23.3737 12.2451 23.3576 12.2912C23.3415 12.3374 23.3347 12.3863 23.3375 12.4351C23.3404 12.4839 23.3528 12.5317 23.3741 12.5757C23.3954 12.6197 23.4251 12.659 23.4616 12.6916L23.6138 12.8255C23.651 12.8575 23.6941 12.8818 23.7406 12.8971C23.7872 12.9124 23.8364 12.9184 23.8853 12.9148C23.9342 12.9111 23.9819 12.8978 24.0256 12.8757C24.0694 12.8536 24.1084 12.8231 24.1404 12.7859C24.1723 12.7487 24.1967 12.7056 24.212 12.6591C24.2273 12.6125 24.2333 12.5633 24.2296 12.5144C24.226 12.4655 24.2127 12.4178 24.1906 12.3741C24.1685 12.3303 24.138 12.2913 24.1008 12.2593L23.9567 12.1356Z",
          fill: "url(#paint20_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.1654 11.2491C29.0659 11.2512 28.9713 11.2921 28.9015 11.3629C28.8318 11.4338 28.7925 11.5291 28.792 11.6286C28.792 11.6793 28.792 11.732 28.792 11.7848C28.7887 11.8336 28.795 11.8825 28.8107 11.9289C28.8264 11.9752 28.851 12.018 28.8833 12.0547C28.9155 12.0915 28.9547 12.1215 28.9986 12.1431C29.0425 12.1647 29.0902 12.1774 29.139 12.1805H29.1654C29.2598 12.1797 29.3505 12.1433 29.4193 12.0786C29.4881 12.014 29.5301 11.9257 29.5367 11.8315C29.5367 11.7645 29.5367 11.6996 29.5367 11.6103C29.5362 11.5622 29.5261 11.5146 29.5071 11.4704C29.4882 11.4261 29.4606 11.386 29.4261 11.3525C29.3916 11.3189 29.3508 11.2925 29.3061 11.2748C29.2613 11.257 29.2135 11.2483 29.1654 11.2491Z",
          fill: "url(#paint21_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.0725 12.4118C29.03 12.3876 28.9831 12.372 28.9346 12.3659C28.8861 12.3598 28.8369 12.3634 28.7897 12.3764C28.7426 12.3894 28.6985 12.4116 28.66 12.4417C28.6214 12.4718 28.5892 12.5092 28.5652 12.5518C28.5388 12.5985 28.5084 12.6431 28.4779 12.6878C28.4213 12.7688 28.399 12.869 28.4162 12.9664C28.4333 13.0638 28.4883 13.1504 28.5692 13.2072C28.629 13.2486 28.6995 13.2719 28.7721 13.2742C28.8322 13.2745 28.8915 13.2603 28.9448 13.2328C28.9982 13.2052 29.0441 13.1651 29.0786 13.1159C29.1241 13.051 29.1654 12.9832 29.2023 12.913C29.2502 12.829 29.2634 12.7296 29.2392 12.636C29.2149 12.5424 29.1551 12.462 29.0725 12.4118Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.5936 10.5835C28.6139 10.6403 28.6321 10.6972 28.6504 10.754C28.6739 10.8304 28.7212 10.8972 28.7855 10.9447C28.8498 10.9922 28.9276 11.0178 29.0075 11.0178C29.0448 11.0176 29.0818 11.0115 29.1171 10.9995C29.1639 10.9854 29.2074 10.9621 29.2452 10.931C29.2829 10.9 29.3141 10.8617 29.337 10.8185C29.3598 10.7752 29.3738 10.7279 29.3782 10.6792C29.3826 10.6305 29.3773 10.5815 29.3626 10.5348C29.3423 10.4658 29.32 10.3989 29.2977 10.3319C29.2643 10.2385 29.1952 10.1623 29.1056 10.1198C29.016 10.0774 28.9132 10.0723 28.8198 10.1057C28.7265 10.139 28.6502 10.2081 28.6077 10.2977C28.5653 10.3874 28.5602 10.4902 28.5936 10.5835Z",
          fill: "url(#paint22_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.3523 8.59075C19.2792 8.57045 19.2042 8.55625 19.1311 8.54205C19.0334 8.52536 18.9331 8.54817 18.8523 8.60544C18.7714 8.66271 18.7166 8.74976 18.6999 8.84743C18.6832 8.94511 18.706 9.04542 18.7633 9.12628C18.8206 9.20715 18.9076 9.26195 19.0053 9.27863C19.0601 9.27863 19.1128 9.29892 19.1656 9.3111C19.1968 9.31914 19.2288 9.32323 19.261 9.32327C19.3524 9.32457 19.4411 9.29208 19.5101 9.23205C19.579 9.17201 19.6234 9.08864 19.6347 8.99791C19.646 8.90717 19.6235 8.81546 19.5714 8.74033C19.5192 8.6652 19.4412 8.61193 19.3523 8.59075Z",
          fill: "url(#paint23_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.8222 8.81428L16.6193 8.89342C16.5281 8.93217 16.456 9.00556 16.4189 9.09746C16.3818 9.18936 16.3827 9.29224 16.4215 9.38346C16.4602 9.47468 16.5336 9.54677 16.6255 9.58387C16.7174 9.62097 16.8203 9.62005 16.9115 9.5813C16.9643 9.55695 17.0191 9.53463 17.0759 9.51434C17.1239 9.49905 17.1684 9.47418 17.2066 9.44123C17.2448 9.40828 17.2759 9.36794 17.298 9.32264C17.3202 9.27733 17.333 9.22801 17.3355 9.17764C17.3381 9.12727 17.3305 9.0769 17.313 9.02957C17.2956 8.98224 17.2688 8.93893 17.2342 8.90226C17.1995 8.86558 17.1579 8.8363 17.1116 8.81618C17.0654 8.79606 17.0155 8.78552 16.9651 8.78519C16.9146 8.78487 16.8647 8.79476 16.8182 8.81428H16.8222Z",
          fill: "url(#paint24_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.0583 8.53792C17.9872 8.53792 17.9162 8.55213 17.8554 8.56227C17.7624 8.57605 17.6781 8.62433 17.6193 8.6975C17.5604 8.77066 17.5312 8.86334 17.5376 8.95705C17.544 9.05075 17.5855 9.1386 17.6538 9.20308C17.7221 9.26756 17.8122 9.30393 17.9061 9.30494H17.9568L18.1374 9.28262C18.1861 9.27805 18.2334 9.26391 18.2765 9.24102C18.3197 9.21813 18.358 9.18693 18.3891 9.14923C18.4202 9.11153 18.4435 9.06805 18.4578 9.0213C18.4721 8.97455 18.477 8.92544 18.4722 8.87679C18.462 8.77709 18.4127 8.68549 18.3351 8.622C18.2576 8.55851 18.158 8.52828 18.0583 8.53792Z",
          fill: "#732B28",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.2462 11.4782L23.1042 11.3422C23.0319 11.2777 22.9375 11.2438 22.8407 11.2477C22.7439 11.2515 22.6524 11.2928 22.5855 11.3629C22.5186 11.4329 22.4816 11.5262 22.4822 11.6231C22.4828 11.72 22.521 11.8128 22.5888 11.882L22.7308 12.0159C22.7647 12.0496 22.805 12.0762 22.8492 12.0943C22.8934 12.1124 22.9408 12.1216 22.9885 12.1214C23.0631 12.1214 23.1359 12.099 23.1976 12.0572C23.2593 12.0154 23.3071 11.9561 23.3348 11.8869C23.3625 11.8177 23.3689 11.7418 23.3531 11.669C23.3373 11.5961 23.3001 11.5297 23.2462 11.4782Z",
          fill: "url(#paint25_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.6983 9.43521C15.6435 9.47782 15.5867 9.52246 15.5339 9.56913C15.4759 9.61864 15.4344 9.68475 15.415 9.75856C15.3957 9.83236 15.3994 9.91032 15.4257 9.98194C15.452 10.0536 15.4996 10.1154 15.5622 10.1591C15.6247 10.2029 15.6991 10.2264 15.7754 10.2266C15.8638 10.2259 15.9492 10.1943 16.0169 10.1373C16.0615 10.0987 16.1082 10.0622 16.1548 10.0257C16.2331 9.96513 16.2841 9.87596 16.2967 9.77779C16.3092 9.67963 16.2823 9.58049 16.2218 9.50217C16.1921 9.46297 16.155 9.43008 16.1124 9.40543C16.0699 9.38078 16.0229 9.36487 15.9741 9.35864C15.9254 9.3524 15.8758 9.35596 15.8285 9.36911C15.7811 9.38226 15.7368 9.40473 15.6983 9.43521Z",
          fill: "url(#paint26_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.3948 10.6629L22.2508 10.5269C22.2159 10.4896 22.1736 10.4598 22.1268 10.4394C22.0799 10.4191 22.0293 10.4085 21.9782 10.4085C21.9271 10.4085 21.8765 10.419 21.8296 10.4393C21.7827 10.4596 21.7404 10.4893 21.7055 10.5266C21.6705 10.564 21.6436 10.6081 21.6264 10.6562C21.6092 10.7043 21.6021 10.7555 21.6054 10.8065C21.6088 10.8575 21.6226 10.9073 21.646 10.9527C21.6694 10.9982 21.7019 11.0384 21.7414 11.0708L21.8815 11.2047C21.9539 11.2693 22.0487 11.3031 22.1456 11.299C22.2426 11.2949 22.3341 11.2532 22.4009 11.1828C22.4677 11.1123 22.5043 11.0186 22.5032 10.9216C22.5021 10.8246 22.4632 10.7318 22.3948 10.6629Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.5057 9.85677L21.3535 9.72893C21.3163 9.69387 21.2723 9.66681 21.2243 9.64943C21.1763 9.63204 21.1252 9.62469 21.0742 9.62785C21.0232 9.631 20.9734 9.64459 20.9279 9.66776C20.8823 9.69094 20.8421 9.72321 20.8095 9.76258C20.7769 9.80195 20.7528 9.84759 20.7386 9.89666C20.7244 9.94573 20.7204 9.9972 20.7269 10.0479C20.7334 10.0985 20.7502 10.1474 20.7763 10.1913C20.8024 10.2352 20.8372 10.2733 20.8786 10.3032L21.0268 10.427C21.0641 10.4584 21.1072 10.4822 21.1537 10.497C21.2002 10.5118 21.2492 10.5173 21.2978 10.5131C21.3464 10.509 21.3937 10.4953 21.4371 10.4729C21.4804 10.4504 21.5189 10.4196 21.5503 10.3823C21.5821 10.345 21.6061 10.3017 21.6211 10.255C21.6361 10.2083 21.6416 10.1591 21.6375 10.1102C21.6333 10.0614 21.6196 10.0138 21.5969 9.97032C21.5743 9.92681 21.5433 9.88822 21.5057 9.85677Z",
          fill: "#850B0B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.5252 9.12021L20.3466 9.01672C20.3043 8.98772 20.2564 8.96776 20.2061 8.95807C20.1557 8.94838 20.1038 8.94918 20.0538 8.96043C20.0037 8.97167 19.9565 8.99311 19.9151 9.02341C19.8737 9.05371 19.839 9.09221 19.8131 9.13653C19.7872 9.18084 19.7708 9.23001 19.7648 9.28097C19.7588 9.33192 19.7633 9.38357 19.7782 9.43269C19.793 9.4818 19.8178 9.52733 19.851 9.56644C19.8842 9.60554 19.9252 9.63738 19.9712 9.65997L20.1275 9.75534C20.2112 9.80915 20.3128 9.82752 20.41 9.8064C20.5072 9.78528 20.5921 9.72641 20.6459 9.64272C20.6997 9.55904 20.7181 9.4574 20.697 9.36017C20.6759 9.26294 20.617 9.17809 20.5333 9.12427L20.5252 9.12021Z",
          fill: "url(#paint27_linear_1103_614)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.03 7.81446L28.4649 7.2017C28.4547 7.16362 28.4422 7.12611 28.4274 7.08936L29.3256 5.69228C29.3734 5.60734 29.3844 5.50867 29.3564 5.41646C29.3284 5.32424 29.2634 5.24543 29.1749 5.19614C29.0863 5.14684 28.9809 5.13079 28.88 5.15125C28.7791 5.17172 28.6905 5.22716 28.6322 5.30624L27.8022 6.59711C27.7303 6.57967 27.6564 6.57007 27.5821 6.56852C27.4277 6.57025 27.2762 6.60819 27.1418 6.67881L25.7329 6.12529C25.6834 6.10171 25.6291 6.08787 25.5735 6.08463C25.5179 6.08139 25.4622 6.08883 25.4098 6.10647C25.3575 6.12412 25.3096 6.15159 25.2692 6.18717C25.2288 6.22276 25.1968 6.26568 25.1751 6.31328C25.1534 6.36088 25.1425 6.41212 25.1431 6.46379C25.1437 6.51547 25.1558 6.56648 25.1786 6.61363C25.2014 6.66077 25.2344 6.70305 25.2757 6.73781C25.3169 6.77258 25.3654 6.79908 25.4181 6.81567L26.6751 7.30996C26.6673 7.34631 26.6629 7.3832 26.6619 7.42025C26.6632 7.63456 26.7521 7.84043 26.9107 7.99624L26.171 9.14618C26.116 9.23155 26.0997 9.33365 26.1257 9.43012C26.1517 9.5266 26.2179 9.60958 26.3097 9.6609C26.3721 9.69583 26.4437 9.7142 26.5166 9.714C26.5866 9.71415 26.6554 9.69749 26.7163 9.66563C26.7773 9.63377 26.8283 9.58781 26.8644 9.53222L27.6856 8.2536C27.8058 8.24197 27.9224 8.20837 28.0285 8.15473C28.1347 8.1011 28.2284 8.02849 28.3042 7.94109L29.7262 8.4987C29.7756 8.5186 29.8288 8.52902 29.8825 8.52934C29.9756 8.52898 30.0657 8.49889 30.1377 8.44412C30.2097 8.38935 30.2591 8.31326 30.2778 8.22863C30.2964 8.144 30.283 8.05601 30.2399 7.97947C30.1968 7.90292 30.1265 7.84249 30.041 7.80833L30.03 7.81446Z",
          fill: "#850B0B",
        },
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_1103_614",
                x1: "13.5228",
                y1: "12.7142",
                x2: "14.3507",
                y2: "12.7142",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint1_linear_1103_614",
                x1: "14.0221",
                y1: "11.6508",
                x2: "14.8581",
                y2: "11.6508",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint2_linear_1103_614",
                x1: "7.59128",
                y1: "12.846",
                x2: "8.34207",
                y2: "12.846",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint3_linear_1103_614",
                x1: "12.1489",
                y1: "14.6116",
                x2: "13.058",
                y2: "14.6116",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint4_linear_1103_614",
                x1: "12.977",
                y1: "13.761",
                x2: "13.8272",
                y2: "13.761",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint5_linear_1103_614",
                x1: "7.58532",
                y1: "6.23315",
                x2: "8.52685",
                y2: "6.23315",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint6_linear_1103_614",
                x1: "8.70721",
                y1: "6.58574",
                x2: "9.61424",
                y2: "6.58574",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint7_linear_1103_614",
                x1: "9.58443",
                y1: "8.65356",
                x2: "10.3677",
                y2: "8.65356",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint8_linear_1103_614",
                x1: "8.45411",
                y1: "10.7194",
                x2: "9.31041",
                y2: "10.7194",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint9_linear_1103_614",
                x1: "9.46018",
                y1: "7.50106",
                x2: "10.28",
                y2: "7.50106",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint10_linear_1103_614",
                x1: "5.28027",
                y1: "6.61648",
                x2: "6.21368",
                y2: "6.61648",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint11_linear_1103_614",
                x1: "6.41663",
                y1: "6.3242",
                x2: "7.35613",
                y2: "6.3242",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint12_linear_1103_614",
                x1: "4.16188",
                y1: "6.98161",
                x2: "5.09529",
                y2: "6.98161",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint13_linear_1103_614",
                x1: "7.84975",
                y1: "11.7221",
                x2: "8.68373",
                y2: "11.7221",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint14_linear_1103_614",
                x1: "26.4058",
                y1: "13.9801",
                x2: "27.3473",
                y2: "13.9801",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint15_linear_1103_614",
                x1: "27.5259",
                y1: "13.6173",
                x2: "28.439",
                y2: "13.6173",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint16_linear_1103_614",
                x1: "25.2672",
                y1: "13.7817",
                x2: "26.1945",
                y2: "13.7817",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint17_linear_1103_614",
                x1: "28.0675",
                y1: "9.48188",
                x2: "28.9117",
                y2: "9.48188",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint18_linear_1103_614",
                x1: "24.2514",
                y1: "13.2116",
                x2: "25.1584",
                y2: "13.2116",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint19_linear_1103_614",
                x1: "14.619",
                y1: "10.6442",
                x2: "15.4813",
                y2: "10.6442",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint20_linear_1103_614",
                x1: "23.3378",
                y1: "12.4785",
                x2: "24.2307",
                y2: "12.4785",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint21_linear_1103_614",
                x1: "28.7859",
                y1: "11.7118",
                x2: "29.5367",
                y2: "11.7118",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint22_linear_1103_614",
                x1: "28.5733",
                y1: "10.5511",
                x2: "29.3809",
                y2: "10.5511",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint23_linear_1103_614",
                x1: "18.6948",
                y1: "8.93164",
                x2: "19.6323",
                y2: "8.93164",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint24_linear_1103_614",
                x1: "16.3961",
                y1: "9.20185",
                x2: "17.3234",
                y2: "9.20185",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint25_linear_1103_614",
                x1: "22.4731",
                y1: "11.6811",
                x2: "23.3619",
                y2: "11.6811",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint26_linear_1103_614",
                x1: "15.402",
                y1: "9.79031",
                x2: "16.3009",
                y2: "9.79031",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint27_linear_1103_614",
                x1: "19.7845",
                y1: "9.384",
                x2: "20.6977",
                y2: "9.384",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#732B28" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#F25A55" } }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }