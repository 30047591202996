var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "carousel slide",
        attrs: { id: _vm.carouselId, "data-ride": "carousel" },
      },
      [
        _c(
          "div",
          { staticClass: "carousel-inner mx-auto" },
          [
            _c(
              "a",
              {
                staticClass: "left carousel-control my-auto prev",
                attrs: { "data-slide": "prev" },
                on: { click: _vm.prev },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-white",
                  attrs: { icon: "fa-circle-chevron-left", size: "3x" },
                }),
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              _vm._l([_vm.currentIndex], function (i) {
                return _c(
                  "div",
                  { key: i, staticClass: "d-flex flex-row my-auto mx-auto" },
                  _vm._l(_vm.currentImg, function (entrada, index) {
                    return _c("Cards", {
                      key: index,
                      staticClass: "fade-leave-active",
                      attrs: {
                        date: entrada.fechas,
                        proxDate: entrada.fechaInicioEvento,
                        entradaId: entrada._id,
                        monumentoNombreURL: entrada.monumentUrl,
                        imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`,
                        title: entrada.webName,
                        monument: entrada.monumentName,
                        tipo: _vm.tipo,
                      },
                      on: {
                        goToEntrada: function ($event) {
                          return _vm.goToEntrada($event)
                        },
                      },
                    })
                  }),
                  1
                )
              }),
              0
            ),
            _c(
              "a",
              {
                staticClass: "right carousel-control next",
                attrs: { "data-slide": "next" },
                on: { click: _vm.next },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-white",
                  attrs: { icon: "fa-circle-chevron-right", size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }