var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "GmapMap",
        {
          staticClass: "mx-auto",
          staticStyle: {
            width: "100%",
            height: "100%",
            "border-radius": "10px",
          },
          attrs: {
            center: { lat: _vm.latitud, lng: _vm.longitud },
            zoom: _vm.zoom,
            "map-type-id": "terrain",
          },
        },
        _vm._l(_vm.markers, function (m, index) {
          return _c("GmapMarker", {
            key: index,
            attrs: {
              position: m?.position,
              clickable: true,
              draggable: false,
              icon: require("@/assets/marker-gral-small.png"),
            },
            on: {
              click: function ($event) {
                _vm.center = m?.position
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }