<template>
    <div class="card mt-1 mb-1" v-bind:style="{backgroundImage: image}">
        <div class="container">
          <div class="card-body">
            <strong class="d-block spainhn-small-text">{{$t('default.ROUTES_KNOW_ROUTES')}}</strong>
            <div class="w-100 border-bottom my-auto" style="height:2vh; border-color:rgb(255,255,255) !important;"></div>
            <strong class="spainhn-text d-block" role="button" @click="goToRuta">{{monument}}</strong>
            <strong class="d-block spainhn-text">{{date}}</strong>
            <mdb-btn color="indigo" class="spainhn-button mt-2" size="sm" @click="emitEvent"><strong class="spainhn-small-text">{{$t('default.SPAINHN_BUTTON_MORE_ROUTES')}}</strong></mdb-btn>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Cards4',
  props:{
    imageUrl: String,
    title: String,
    monument: String,
    text: String,
    date: String,
    rutaId: String
  },
  data(){
    return{
      // image: `url(${require('@/assets/img_avatar.png')})`
      // image: `url(${this.imageUrl})`
      // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
    }
  },
  setup() {

  },
  created(){
  },
  methods:{
    emitEvent(){
      let $vm = this;
      $vm.$emit('goTo')
    },
    goToRuta(){
      let $vm = this;
      $vm.$router.push('/ruta/' + $vm.rutaId);
    }
  },
    computed:{
    image(){
      let urlEncoded = encodeURI(this.imageUrl);

      return `url(${urlEncoded})`
    }
  }
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  height: 235.5px;  
  max-width: 468px;
  width: auto;
  
}

img {
    border-radius: 10px 1px 0 0;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  border-radius: 10px;
}

.card-body{
  height: 100%;
  padding: 5% 0%;
  padding-bottom: 10%;
  /* background-color: rgba(165,61,57,0.7); */
  background-color: rgba(0,0,0,0.5);
  color:white;
  border-radius: 10px 10px 10px 10px;
  padding-top: auto;
  align-items: flex-end;
}

@media(max-width:768px){
  .card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  height: 235.5px;
  width: auto;
  }
}

</style>