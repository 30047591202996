<template>
    <div>
        <div class="d-flex mb-0 mt-0 pt-0" style="position:static; width: 100%; background-color:rgba(0,0,0,0); height: auto; z-index:1;" v-bind:class="{'d-none': mostrarBuscador}">
            <!-- <img src="../assets/HOME-PANORAMICA.jpg" style="z-index: 0; position: static; width:100%; max-height: 720px;"/> -->
            <!-- <img src="../assets/MapaBanner2.jpeg" style="z-index: 0; position: static; width:100%; max-height: 720px;"/> -->
            <img src="../assets/IMAGENDEFONDO3.jpg" style="z-index: 0; position: static; width:100%; max-height: 720px;"/>

            <!-- <img src="../assets/HOME-PANORAMICA.jpg" style="z-index: 0; position: static; width: 100%;"/> -->

            <div class="d-flex w-100" style="position:absolute; z-index: 1;">            
                <div class="d-flex w-100" style="position:static;">
                    <div class="ms-3 mt-md-5 mt-2">
                        <div class="d-flex flex-column align-items-center">
                                <mdb-btn 
                                    color="indigo" size="lg" 
                                    class="spainhn-button py-md-3 px-md-4 py-2 px-1 mb-md-3 mb-lg-5 mb-sm-5" 
                                    @click="() => mostrarBuscador = true"><strong>{{$t('default.HOME_BUTTON_SEARCHABLE')}}</strong></mdb-btn>

                                <div class="mt-5 mb-5 d-md-inline-block d-none"></div>
                                <div class="mt-5 mb-5 d-sm-inline-block d-none"></div>
                                <div class="mt-5 mb-5 d-lg-inline-block d-none"></div>
                                <div class="mt-5 mb-5 d-xl-inline-block d-none"></div>

                                <mdb-btn tag="a" 
                                    href="https://www.youtube.com/watch?v=wR1iYYlnliw&ab_channel=ReddeCastillosyPalaciosdeEspa%C3%B1a"
                                    target="_blank" 
                                    color="indigo" size="lg" 
                                    class="spainhn-button py-md-3 px-md-4 py-2 px-1 mt-4"><strong>{{$t('default.HOME_BUTTON_VIDEO')}}</strong></mdb-btn>
                        </div>
                    </div>
                    <!-- <a class="ms-auto mt-5" role="button" href="https://www.youtube.com/watch?v=wR1iYYlnliw&ab_channel=ReddeCastillosyPalaciosdeEspa%C3%B1a" 
                        target="_blank" rel="noreferrer noopener">
                        <img src="../assets/banner-video.jpg" class="ms-auto" style="max-width: 50vw; object-fit: contain; height: auto;"/>
                    </a> -->
                </div>
            </div>

        </div>

        <mapa v-if="mostrarBuscador == true"></mapa>
        
    </div>
</template>

<script>
import mapa from './Mapa.vue'

export default{
    name:'BuscadorDeCastillos',
    components:{
        mapa
    },
    data(){
        return{
            mostrarBuscador: false
        }
    },
    methods:{


    },
    mounted(){
        
    },
    computed:{
    },
    created(){

    }
}
</script>

<style scoped>
    .markers{
        background-color: var(--spainhn-rojo-corporativo);
        color: black;
    }

    @media (min-width: 1440px){
        .mapa{
            position:relative; 
            z-index:3; 
            min-height:90vh; 
            height: auto;
        }

    }
    @media (max-width: 1440px){
        .mapa{
            position:absolute; 
            z-index:3; 
            min-height:90vh; 
            height: auto;
        }

    }
    @media (max-width: 768px){
        .search-title .spainhn-subtitle-text-white{
            font-size: 16px;
        }
        .search-title .spainhn-button{
            font-size: 18px;
        }
    }
</style>
