var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("SpainhnNavigationBreadcrumb", {
        attrs: { "navigation-links": _vm.links },
      }),
      _c("div", {
        staticClass: "image-header-container",
        staticStyle: {
          "background-position": "top",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        style: {
          backgroundImage: `url('${_vm.imagenesUrl}/rutas/logo/${_vm.microsite.rutaId}`,
        },
      }),
      _c("div", { staticClass: "container mt-2" }, [
        _c("div", { staticClass: "text-start" }, [
          _c(
            "div",
            { staticClass: "spainhn-header-info mt-auto mb-3 d-flex" },
            [
              _c(
                "div",
                { staticStyle: { "margin-right": "10px" } },
                [_c("icon-route-red")],
                1
              ),
              _c("div", [_vm._v(" " + _vm._s(_vm.microsite.webName) + " ")]),
            ]
          ),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                `<div class='text-content'>${_vm.microsite.webDescription}</div>`
              ),
            },
          }),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "container-fluid px-0 px-md-3 px-lg-5 margen-estandard mt-3",
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "pt-3 h-100 pe-3 col-xxl-8 col-12 order-md-first",
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "spainhn-sections-title text-uppercase mb-2",
                      },
                      [_vm._v("Mapa")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-5 d-flex align-items-center justify-content-center d-flex mx-auto",
                      },
                      [
                        _c("img", {
                          staticClass: "align-items-center mx-auto",
                          staticStyle: { width: "100%", "max-width": "90vw" },
                          attrs: {
                            src: `${_vm.imagenesUrl}/rutas/mapa/${_vm.microsite.rutaId}`,
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-md-flex d-none flex-column pt-3 h-100 col-xxl-4 col-12 order-md-last",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow spainhn-bg-gris-cajas ps-4 pe-4 pt-3 d-flex flex-column text-start spainhn-text pb-5 pt-5",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-3 text-center" },
                        [
                          _c(
                            "icon-base",
                            {
                              staticClass: "my-auto me-2",
                              attrs: {
                                width: 80,
                                height: 80,
                                iconColor: "rgb(0,0,0)",
                              },
                            },
                            [_c("icon-casttle")],
                            1
                          ),
                          _c(
                            "h3",
                            {
                              staticClass: "d-flex fw-bold spainhn-text mt-4",
                              staticStyle: {
                                "font-size": "20pt",
                                "font-weight": "700",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.ROUTES_DETAILS_WHERE_TITLE")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "spinhn-text text-start w-100" },
                        [
                          _c("p", {
                            staticClass: "spainhn-text",
                            staticStyle: {
                              "list-style-type": "decimal !important",
                              "font-size": "15pt",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.microsite.webLocations),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex d-md-none" },
              [
                _c("collapsable-element", {
                  attrs: {
                    title: _vm.$t("default.ROUTES_DETAILS_WHERE_TITLE"),
                    content: _vm.microsite.webLocations,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.microsite.rutaEnlazada
            ? _c("div", { staticClass: "container-fluid mb-2" }, [
                _c("div", { staticClass: "row text-start" }, [
                  _c("div", { staticClass: "col-12 mx-auto" }, [
                    _c(
                      "div",
                      { staticClass: "text-center pt-0 w-100 mx-auto" },
                      [
                        _c(
                          "strong",
                          {
                            staticClass: "spainhn-subtitle-black",
                            staticStyle: { "font-size": "20pt" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("default.MENSAJE_RUTA_ENLAZA"))
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass: "w-100 border-bottom mb-0",
                      staticStyle: {
                        height: "2vh",
                        "border-color":
                          "var(--spainhn-rojo-corporativo) !important",
                        "border-width": "medium !important",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                      },
                      [
                        _c("cards-3", {
                          attrs: {
                            title: _vm.microsite.rutaEnlazada.webName,
                            linkId: _vm.microsite.rutaEnlazada.oldId,
                            imageUrl: `${_vm.imagenesUrl}/rutas/logo/${_vm.rutaOldIdImage}`,
                          },
                          on: {
                            goTo: function ($event) {
                              return _vm.goToRuta($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "container d-md-none",
              staticStyle: { "margin-top": "10px" },
            },
            [
              _c("div", { staticClass: "spainhn-sections-title" }, [
                _vm._v(" MONUMENTOS DE LA RUTA "),
              ]),
              _c(
                "div",
                { staticClass: "d-flex flex-wrap justify-content-center" },
                _vm._l(_vm.edificios, function (edificio, index) {
                  return _c("recommendations-card", {
                    key: index,
                    staticClass: "my-2",
                    attrs: {
                      title: edificio.webName,
                      nombreUrl: edificio._id,
                      "image-url": `${_vm.imagenesUrl}/monumentos/${edificio.oldId}/${edificio.banner}`,
                      showDefault: false,
                    },
                    on: {
                      goTo: (evt) =>
                        _vm.goToCastillo(evt, edificio.nombreNormalizado),
                    },
                  })
                }),
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "container-fluid mb-5 mt-4 d-md-flex flex-wrap d-none",
            },
            [
              _c("div", { staticClass: "row w-100" }, [
                _c("div", { staticClass: "col-12 ps-4 ps-md-0 mx-auto" }, [
                  _c("div", { staticClass: "text-center pt-0 mx-auto" }, [
                    _c(
                      "strong",
                      {
                        staticClass: "spainhn-subtitle-black",
                        staticStyle: { "font-size": "20pt" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("default.ROUTES_DETAILS_MONUMENTS"))
                        ),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticClass: "w-100 border-bottom mb-3",
                    staticStyle: {
                      height: "2vh",
                      "border-color":
                        "var(--spainhn-rojo-corporativo) !important",
                      "border-width": "medium !important",
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                    },
                    _vm._l(_vm.edificios, function (edificio, index) {
                      return _c("cards-2", {
                        key: index,
                        attrs: {
                          monument: edificio.webName,
                          imageUrl: `${_vm.imagenesUrl}/monumentos/${edificio.oldId}/${edificio.banner}`,
                          text: edificio.webDescription,
                          id: edificio._id,
                          provincia: edificio.provincia,
                          municipio: edificio.municipio,
                          nombreUrl: edificio.nombreNormalizado,
                          role: "button",
                        },
                      })
                    }),
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }