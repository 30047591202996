var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    [
      _c(
        "mdb-modal",
        _vm._b(
          {
            staticClass: "shadow",
            attrs: { show: _vm.popupStart, centered: "", scrollable: "" },
            on: { close: () => _vm.$emit("close") },
          },
          "mdb-modal",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "mdb-modal-header",
            [
              _c("mdb-modal-title", [
                _c("h3", { staticClass: "font-weight-bold text-uppercase" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
            ],
            1
          ),
          _c("mdb-modal-body", {}, [
            _c("div", [
              _vm.html
                ? _c("p", { domProps: { innerHTML: _vm._s(_vm.description) } })
                : _c(
                    "p",
                    [
                      _vm._t("default"),
                      _vm._v(" " + _vm._s(_vm.description) + " "),
                    ],
                    2
                  ),
            ]),
          ]),
          _c(
            "mdb-modal-footer",
            { staticClass: "justify-content-center" },
            [
              _c(
                "mdb-btn",
                {
                  attrs: { color: "danger", size: "md" },
                  on: { click: () => _vm.$emit("close") },
                },
                [_vm._v(_vm._s(_vm.$t("default.BUTTONS.ACCEPT")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }