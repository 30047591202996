<template>
    <mdb-container class="justify-content-center">
        <span class="fw-bold dark text-uppercase">{{ $t("default.CATEGORY_CLOUD_TITLE") }}</span>
        <ul class="mx-auto breadcrumb">
            <li>
                <a href="/castillos">{{ $t('default.CATEGORY_CLOUD_MONUMENTS') }}</a>
            </li>
            <li class="mx-0">
                <font-awesome-icon icon="circle" style="vertical-align: middle;" transform="shrink-10"/>
            </li>
            <li class="mx-0">
                <a href="/castillos/servicios/eventos-singulares"> {{ $t('default.CATEGORY_CLOUD_EVENTS') }} </a>
            </li>
            <li class="mx-0">
                <font-awesome-icon icon="circle" style="vertical-align: middle;" transform="shrink-10"/>
            </li>
            <li class="mx-0">
                <a href="/rutas-recomendadas">{{  $t('default.CATEGORY_CLOUD_ROUTES') }}</a>
            </li>
            <!-- <li class="mx-0">
                <font-awesome-icon icon="circle" style="vertical-align: middle;" transform="shrink-10"/>
            </li>
            <li class="mx-0">
                <a>actividades</a>
            </li> -->
            <li class="mx-0">
                <font-awesome-icon icon="circle" style="vertical-align: middle;" transform="shrink-10"/>
            </li>
            <li class="mx-0">
                <a href="/castillos/servicios/experiencias-exclusivas">{{ $t('default.CATEGORY_CLOUD_EXPERIENCES') }}</a>
            </li>

        </ul>
    </mdb-container>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
/* Style the list */
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  border-radius: 5px;
  margin: 0;
  color: rgba(67, 67, 67,0.4);
  /* background-color: #FFFFFF; */
  background-color: transparent;
  display: flex;
  justify-content: center;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  line-height: 1.5;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 2px;
  color: #D9D9D9;
  content: "\00a0";
  vertical-align: middle;
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: rgba(67, 67, 67, 0.7);
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;
  transition: all 0.3s ease-out;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #ae2929;
  text-decoration: underline;
}

.breadcrumb li.separator {
  color: #D9D9D9;
  margin: 0 1px; /* Adjust spacing between separator and items */
}

/* Media query for medium-sized screens (adjust the breakpoint as needed) */
@media only screen and (min-width: 768px) {
  ul.breadcrumb li a{
    font-size: 20px; /* Adjust the font size for medium screens */
  }
}
</style>