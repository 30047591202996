var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("home-layout2-vue", [
    _c(
      "div",
      { staticClass: "d-flex-flex-column home-carousel" },
      [
        _c("mapa-banner"),
        _c(
          "div",
          {
            staticClass: "pt-5 py-xxl-5 spainhm-main",
            style: {
              padding: "0 0",
              backgroundImage: `url(${require("@/assets/home-bg.jpg")})`,
              backgroundSize: "cover",
              "background-repeat": "no-repeat",
              "background-position": "center",
              "min-height": "35rem",
            },
          },
          [
            _c("div", { staticClass: "py-xxl-5" }, [
              _c("div", { staticClass: "row m-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-lg-3 col-xl-3 col-md-5 col-sm-6 ms-auto pb-3",
                    style: {
                      backgroundImage: `url(${require("@/assets/Logo_fondo.png")})`,
                      backgroundSize: "contain",
                      "background-repeat": "no-repeat",
                      backgroundPosition: "top center",
                    },
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass: "spainhn-title-text ms-auto text-end mb-0",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("default.patrimonioTitle")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "text-end pt-0 ms-auto border-top border-danger",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("default.patrimonioSubtitle")) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-lg-4 col-xl-3 col-md-6 col-sm-6 me-auto",
                  },
                  [
                    _c("p", { staticClass: "spainhn-text text-start mt-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("default.welcome1")) + " "),
                    ]),
                    _c("p", { staticClass: "spainhn-text mt-1 text-start" }, [
                      _vm._v(" " + _vm._s(_vm.$t("default.welcome2")) + " "),
                    ]),
                    _c("p", { staticClass: "spainhn-text mt-1 text-start" }, [
                      _vm._v(" " + _vm._s(_vm.$t("default.welcome3")) + " "),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "row px-5 justify-content-center spainhn-cards m-0",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "px-3 border-bottom border-danger col-lg-4 col-sm-6 mt-3",
                    staticStyle: {
                      "background-image": "url(/src/assets/logo.png)",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "spainhn-solid-red-background mt-2 mx-auto",
                        staticStyle: {
                          "border-radius": "50%",
                          height: "140px",
                          width: "140px",
                        },
                      },
                      [
                        _c(
                          "icon-base",
                          {
                            attrs: {
                              iconColor: "rgb(255,255,255)",
                              height: 110,
                              width: 110,
                            },
                          },
                          [_c("icon-casttle")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "text-center spainhn-text mt-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("default.HOME_CASTLES_DESCRIPTION")) + " "
                      ),
                      _c("strong", { staticClass: "fw-bolder" }, [
                        _vm._v(_vm._s(_vm.$t("default.HOME_CASTLES_EMPHASIS"))),
                      ]),
                      _c("span", [
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("default.HOME_CASTLES_TEXT"))),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "px-3 border-bottom border-danger col-lg-4 col-sm-6 mt-3",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "spainhn-solid-red-background mt-2 mx-auto",
                        staticStyle: {
                          "border-radius": "50%",
                          height: "140px",
                          width: "140px",
                        },
                      },
                      [
                        _c(
                          "icon-base",
                          {
                            attrs: {
                              iconColor: "rgb(255,255,255)",
                              height: 110,
                              width: 110,
                            },
                          },
                          [_c("icon-palace")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "text-center spainhn-text mt-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("default.HOME_PALACES_DESCRIPTION")) + " "
                      ),
                      _c("strong", { staticClass: "fw-bolder" }, [
                        _vm._v(
                          _vm._s(_vm.$t("default.HOME_PALACES_EMPHASIS")) + " "
                        ),
                      ]),
                      _c("span", [
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("default.HOME_PALACES_TEXT"))),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "px-3 border-bottom border-danger col-lg-4 col-sm-6 mt-3",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "spainhn-solid-red-background mt-2 mx-auto",
                        staticStyle: {
                          "border-radius": "50%",
                          height: "140px",
                          width: "140px",
                        },
                      },
                      [
                        _c(
                          "icon-base",
                          {
                            staticClass: "pt-4",
                            attrs: {
                              iconColor: "rgb(255,255,255)",
                              height: 110,
                              width: 110,
                            },
                          },
                          [_c("icon-routes")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "text-center spainhn-text mt-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("default.HOME_ROUTES_DESCRIPTION")) + " "
                      ),
                      _c("strong", { staticClass: "fw-bolder" }, [
                        _vm._v(
                          _vm._s(_vm.$t("default.HOME_ROUTES_EMPHASIS")) + " "
                        ),
                      ]),
                      _c("span", [
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("default.HOME_ROUTES_TEXT"))),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "spainhn-solid-red-background py-md-3 py-3 px-3 mess-box",
          },
          [
            _c("h4", { staticClass: "fw-bold" }, [
              _vm._v(_vm._s(_vm.$t("default.HOME_SEARCH_TITLE")) + " "),
            ]),
            _c("div", { staticClass: "w-25 mx-auto border-bottom my-3" }),
            _c("h6", [_vm._v(_vm._s(_vm.$t("default.HOME_SEARCH_SUBTITLE")))]),
          ]
        ),
        _c("mapa"),
      ],
      1
    ),
    _c("div", { staticClass: "container-fluid p-0 slider-cards" }, [
      _c(
        "div",
        { staticClass: "row m-0", staticStyle: { "min-height": "70vh" } },
        [
          _c(
            "div",
            {
              staticClass:
                "spainhn-bg-gris-cajas col-xxl-9 col-12 justify-content-center px-3 order-last order-xxl-first py-4 visitas-container",
              staticStyle: { "min-height": "70vh" },
            },
            [
              _c(
                "div",
                { staticClass: "pt-1" },
                [
                  _c("slider", {
                    staticClass: "my-3",
                    attrs: {
                      tipo: "Evento",
                      carouselId: "eventos",
                      images: _vm.eventosClusters,
                    },
                  }),
                  _c(
                    "h3",
                    {
                      staticClass: "spainhn-title my-auto",
                      class: { "d-none": _vm.eventos.length },
                    },
                    [_vm._v("Aún no hay próximas visitas")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "spainhn-solid-red-background col-xxl-3 px-4 px-md-5 order-first order-xxl-last pt-5 pb-3 col-12",
            },
            [
              _c(
                "h1",
                { staticClass: "spainhn-title-text-2 text-start pt-md-5" },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("default.HOME_NEXT_EVENTS_TITLE"))),
                  ]),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "spainhn-text text-start pe-md-5",
                  staticStyle: { color: "white" },
                },
                [_vm._v(_vm._s(_vm.$t("default.HOME_EVENTS_DESCRIPTION")))]
              ),
              _c(
                "mdb-btn",
                {
                  staticClass: "spainhn-button-gray ms-4 mt-2 text-white",
                  attrs: { color: "indigo", size: "lg" },
                  on: { click: _vm.goToEventos },
                },
                [
                  _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("default.HOME_ALL_EVENTS_BUTTON")) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "container-fluid p-0 slider-cards" }, [
      _c(
        "div",
        { staticClass: "row m-0", staticStyle: { "min-height": "70vh" } },
        [
          _c(
            "div",
            {
              staticClass:
                "spainhn-solid-red-background col-12 col-xxl-3 pt-5 pb-3 px-md-5 px-4",
            },
            [
              _c(
                "h1",
                { staticClass: "spainhn-title-text-2 text-end pt-md-5" },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("default.HOME_NEXT_EXPERIENCES_TITLE"))
                    ),
                  ]),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "spainhn-text text-end ps-md-5",
                  staticStyle: { color: "white" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("default.HOME_EXPERIENCES_DESCRIPTION_1"))
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "spainhn-text text-end ps-md-5",
                  staticStyle: { color: "white" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("default.HOME_EXPERIENCES_DESCRIPTION_2"))
                  ),
                ]
              ),
              _c(
                "mdb-btn",
                {
                  staticClass: "spainhn-button-gray ms-4 mt-2 text-white",
                  attrs: { color: "indigo", size: "lg" },
                  on: { click: _vm.goToExperiences },
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("default.HOME_ALL_EXPERIENCES_BUTTON"))
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "spainhn-bg-gris-cajas col-xxl-9 col-12 justify-content-center px-3 py-4 visitas-container",
              staticStyle: { "min-height": "70vh" },
            },
            [
              _c(
                "div",
                { staticClass: "pt-1 mt-2" },
                [
                  _c("ul", { staticClass: "carousel-indicators bg-danger" }, [
                    _c("li", {
                      staticClass: "active",
                      attrs: {
                        "data-target": "#experiencias",
                        "data-slide-to": "0",
                      },
                    }),
                    _c("li", {
                      attrs: {
                        "data-target": "#experiencias",
                        "data-slide-to": "1",
                      },
                    }),
                    _c("li", {
                      attrs: {
                        "data-target": "#experiencias",
                        "data-slide-to": "2",
                      },
                    }),
                  ]),
                  _c("slider", {
                    staticClass: "my-3",
                    attrs: {
                      tipo: "Experiencia",
                      carouselId: "experiencias",
                      images: _vm.experienciasClusters,
                    },
                  }),
                  _c(
                    "h3",
                    {
                      staticClass: "spainhn-title my-auto",
                      class: { "d-none": _vm.experiencias.length },
                    },
                    [_vm._v("Aún no hay próximas experiencias")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }