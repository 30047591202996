<template>
    <div class="d-flex spainhn-gray-background justify-content-center align-items-center tool-box" 
        v-bind:class="{'active': active}" 
        @click="() => $emit('toggleFilter')"
        role="button">
        <span style="margin-right: 9px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M2.89329 1H22.7582C23.4439 1 23.9998 1.55577 23.9998 2.24139L24 4.21027C24.0002 4.53963 23.8693 4.85549 23.6364 5.08837L15.6724 13.0519C15.4397 13.2847 15.3088 13.6005 15.3088 13.9298V21.7578C15.3088 22.5655 14.5497 23.1582 13.7662 22.9623L11.2831 22.3415C10.7303 22.2034 10.3426 21.7067 10.3426 21.137V13.9298C10.3426 13.6005 10.2118 13.2847 9.97896 13.0519L2.01537 5.0883C1.78253 4.85547 1.65173 4.53967 1.65173 4.21039V2.24155C1.65173 1.55587 2.20759 1 2.89329 1Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        <span class="text-uppercase">{{ $t("default.FILTER.FILTRO") }}</span>
    </div>
</template>

<script>
    export default {
        props:{
            active: {type: Boolean, default: false}
        }
    }
</script>

<style scoped>
 .tool-box{
    /* background: #d9d9d9;
    color: #FFF; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }

 .active{
    background: #BF5B5B;
 }
</style>