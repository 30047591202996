import { BIconArrowDownUp } from "bootstrap-vue";
import mountableMixin from "vue2-google-maps/dist/utils/mountableMixin";
import http from "../http-common";
class ApiDataService{
    getAllCordinates(idioma){
        return http.get("/coordenadas", { params:{'idioma':idioma} });
    }
    getAllEdificaciones(tipo, idioma){
        return http.get("/edificaciones", { params:{'tipo':tipo, 'idioma':idioma} });
    }

    getMonumentosById(idioma, ids){
        return http.get("/edificacionesById", { params:{'monumentos':ids, 'idioma':idioma} });
    }

    getEdificacion(idioma, nombre){
        return http.get("/edificacion", { params:{'nombre':nombre, 'idioma':idioma} });
    }
    getEdificacionByServicio(servicio, idioma){
        return http.get("/monumento-servicio", { params:{'servicio':servicio, 'idioma':idioma} });
    }
    getVisitasByEdificacion(idioma, id, tipo){
        return http.get("/entradasByEdificacion", { params:{'monumentoId':id, 'idioma':idioma, 'tipo':tipo} });
    }
    // getAllVisitasByEdificacion(idioma, id){
    //     console.log(id);
    //     return http.get("/entradasByEdificacion", { params:{'monumentoId':id, 'idioma':idioma} });
    // }
    getVisitaById(idioma, id){
        // console.log("Pidiendo información de la entrada");
        return http.get("/entradaById", { params:{'entradaId':id, 'idioma':idioma} });        
    }

    getEntradaNombres(id, idioma='es'){
        return http.get("/entradaNombresById", { params:{'entradaId':id, idioma: idioma } });        
    }

    getFechaByVisitaId(idioma, id, selectedDate){
        
        // convertedDate = new Date(year.toString() + '-' + month.toString() + '-' + day.toString())
        return http.get("/fechaByVisitaId", { params:{'visitaId':id, 'idioma':idioma, 'fecha':selectedDate} });
    }

    getFechaByVisitaId2(idioma, id, selectedDate){
        
        // convertedDate = new Date(year.toString() + '-' + month.toString() + '-' + day.toString())
        return http.get(`/fechas-visita`, { params:{'visitaId':id, 'idioma':idioma, 'fecha':selectedDate} });
    }

    consultarFechaDeServicios( idioma, id, fechaInicial, tipo ){
        return http.get('/pack-experiencias/calendario', { params: { id, idioma, fechaInicial, tipo } } );
    }

    async consultarAcuerdoComercial( id, tipo ){
        const result = await http.get('/condiciones-comerciales', {
            params:{
                id,
                tipo
            }
        })

        return result;
    }

    async consultarHorarios( idioma, productoId, fechaInicial, fechaFinal, tipo){
        const result = await http.get(`/calendario/horarios`, {
            params: {
                fechaInicial,
                fechaFinal,
                tipo,
                productoId,
                idioma
            }
        })

        return result.data;

    }

    getTarifa( idioma, tarifa ){
        return http.get('/visita/tarifa', { params:{ 'tarifaId': tarifa}, 'idioma':idioma } )
    }

    getNumberEntradas(monumentoId){
        return http.get("/entradasCount", { params:{'monumentoId':monumentoId} });
    }

    async getPlazasForDate(horarioId, tipoDeServicio='monumentos'){
        return await http.get("/getPlazas", {params: {'horarioId':horarioId, tipoDeServicio } });
    }

    getModificadoresDePrecios( idPrecio ){
        return http.post(`/modificadores-de-precios`, idPrecio )
    }
    getAllEventos(){
        return http.get("/allEventos");
    }

    getFiltrosParaEventos(){
        return http.get("/filtros-eventos")
    }

    getFiltrosParaExperiencias(){
        return http.get("/filtros-experiencias")
    }

    getAllExperiences(){
        return http.get("/allExperiencias");
    }

    getEventosProximos(idioma){
        return http.get("/eventosProximos", { params:{ 'idioma':idioma } });        
    }

    getEventosDisponibles( monumento ){
        return http.get("/eventos-disponibles", {
            params:{
                'monumento':monumento
            }
        })
    }

    getExperienciasProximas(idioma){
        return http.get("/experienciasProximas", { params:{ 'idioma':idioma } });        
    }

    getEventosByMonumento(idioma, monumentoId){
        return http.get("/eventosByMonumento", { params:{ 'idioma':idioma, 'monumentoId':monumentoId } });        
    }

    getServicioByMonumento(idioma, monumentoId, tipo){
        return http.get("/servicioByMonumento", { params:{ 'idioma':idioma, 'monumentoNombre':monumentoId, 'tipo':tipo } });        
    }

    // Obtenemos los restaurantes relacionados con este monumento
    getRestaurantesByMonumentoId(idioma, id){
        return http.get("/restaurantes", {params:{'monumentoId':id, 'idioma':idioma}} );
    }

    getRestauranteById(idioma, id){
        return http.get("/restaurante", {params:{'id':id, 'idioma':idioma}});
    }

    getActividadesByMonumentoId(idioma, id){
        return http.get("/actividades", {params:{'monumentoId':id, 'idioma':idioma}} );
    }

    getActividadById(idioma, id){
        return http.get("/actividad", {params:{'id':id, 'idioma':idioma}});
    }

    getImagenesByMonumento(folder){
        return http.get("/monumento-imagenes", {params:{'folder':folder}})
    }

    getImagenesByRestaurante(folder){
        return http.get("/restaurante-imagenes", {params:{'folder':folder}})
    }

    getImagenesByActividad(folder){
        return http.get("/actividad-imagenes", {params:{'folder':folder}})
    }

    getImagenesByVisita(folder){
        return http.get("/visita-imagenes", {params:{'folder':folder}})
    }

    getAllRutas(idioma){
        return http.get("/rutas", {params:{'idioma':idioma}})
    }

    getRuta(idioma, rutaId){
        return http.get("/ruta", {params:{'idioma':idioma, 'rutaId':rutaId}})

    }

    getUniqueRoute(idioma, monumentoId){
        return http.get('/uniqueRoute', {params:{'idioma':idioma, 'monumentoId':monumentoId}})
    }

    getRoutesByMonumento(idioma, monumentoId){
        return http.get('/rutasPorMonumento', {params:{'idioma':idioma, 'monumentoId':monumentoId}})
    }

    getTiposDeMonumento( language ){
        return http.get("/tiposDeMonumento", {
            params: {
                language
            }
        });
    }

    initRedsys(payload){
        return http.post('/redsysConnection', payload)
    }

    registerFreeVisits(payload){
        return http.post('/redsys', payload)
    }

    redsysClientConfirmation(Ds_Signature, Ds_MerchantParameters, Ds_Signature_Version){
        return http.get('/redsys-confirmacion-cliente', {
            params:{
                Ds_Signature:Ds_Signature,
                Ds_Signature_Version: Ds_Signature_Version,
                Ds_MerchantParameters: Ds_MerchantParameters
            }
        })
    }

    getRutasCastillos(){
        return http.get('/rutas-castillos')
    }
    
    getComision(monumentoId, visitaId){
        return http.get('/tipoComision', {params:{'monumentoId':monumentoId, 'visitaId':visitaId}} )
    }

    getComunidadesAutonomas(){
        return http.get("/comunidadesAutonomas");
    }
    /**
     * Pedimos las entradas de los blogs disponibles en el servidor
     * @param {number} blogId - el id del blog que se quiere consultar, en caso de no pasar un valor, se pediran todas las entradas
     * @returns {Promise} Respuesta del servidor con la/las entradas de blog solicitadas
     */
    getBlogs(blogId){
        return http.get('/blogs', {
            params: {'blogId':blogId}
        })
    }

    /**
     * Solicita al servidor iniciar sesión para el usuario
     * @param {String} username - nombre de usuario
     * @param {String} password - contraseña de acceso
     * @returns {Promise} Si las credenciales son correctas, se responde con los datos del usuario
     * en caso contrario, se responde con un error
     */
    userLogin(username, password){
        // Creamos el cuerpo de la solicitud
        let body = {username: username, password: password};
        return http.post('/user-login', body)
    }
    validateClientToken(accessToken){
        let body = {};
        return http.post('/validate-token', body, {
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }
    /**
     * Preguntamos los datos del cliente, utilizando el token para obtener la información
     * @param {Token de acceso que contiene los datos del cliente} accessToken 
     * @returns 
     */
    getClientInformation(accessToken){
        let body = {};
        return http.post('/get-client-by-token', body, {
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }
        })

    }
    
    /**
     * Envía una solicitud al servidor para actualizar los datos del cliente
     * @param {String} accessToken 
     * @param {Object} body 
     */
    updateClientInformation(accessToken, body){
        return http.put('/update-cliente', body, {
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }

    /**
     * Solicitamos las reservas que haya realizado este cliente
     * @param {*} accessToken 
     */
    getClientReservations(accessToken){
        return http.get('/cliente-reservas', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }

    requestReset(body){
        return http.post('/cliente-reset', body)
    }

    resetPasswordById(body){
        return http.put('/cliente-reset-by-token', body)
    }

    /**
     * Se le pide al servidor que envíe un mail a la cuenta oficial de spainheritagenetwork
     * para que se contancten con el usuario interesado en algún evento
     * @param {object} body - contiene la información que se enviará al servidor para completar el email
     */
    sendInfoEmail(body){
        return http.post('/cliente-contacto', body)
    }

    /**
     * Se le pide al servidor que envíe un mail a la cuenta oficial de spainheritagenetwork
     * para que se contancten con el usuario interesado en algún evento
     * @param {object} body - contiene la información que se enviará al servidor para completar el email
     */
    sendGeneralInfoEmail(body){
        return http.post('/cliente-contacto-general', body)
    }

    createCarrito(carrito){
        return http.post('/carrito', carrito)
    }

    updateCarrito( uuid, reserva ){
        return http.put(`/carrito/${uuid}`, reserva )
    }

    removeFromCarrito( uuid, reservaId ){
        return http.put(`/carrito/delete-reserva/${reservaId}`)
    }

    getCarrito( uuid ){
        return http.get(`/carrito?carrito=${uuid}`)
    }

    calcelEvent(body){

        const params = {
            email: body.email,
            tipoDevolucion: body.tipoDevolucion,
            localizador: body.localizador,
            motivo: body.motivo,
            numeroDePedido: body.numeroDePedido,
            mount: body.mount

        }
        return http.post('/redsysCancel', body)
    }

    registerCart( uuid ){
        return http.post('/createCart', uuid )
    }    

    sendResellerRequest( form ){
        return http.post('/registro-mayorista', form);
    }

    sendMonumentRequest( form ){
        return http.post('/registro-monumento', form )
    }

    sendPrestadorRequest( form ){
        return http.post('/registro-prestador', form )
    }

    getMunicpio( codigoPostal ){
        return http.get(`/municipios?zipCode=${codigoPostal}`)
    }

    getZonasConfig( configuracionId ){
        return http.get(`/fecha/asientos?configuracion=${configuracionId}`)
    }

    verifySeats( seatsId, zona, configuracionId, filas ){
        return http.get('/free-seats', {
            params: {
                seats: seatsId,
                zona: zona,
                configuracionId: configuracionId,
                filas: filas
            }
        })
    }

    getCartInfo( cartUuid ){
        return http.get('/get-cart-info', {
            params: {
                cartUuid: cartUuid
            }
        })
    }

    validateCoupon(body ){
        return http.post('/validate-coupon', body)

    }

    getPaquetes(monumentoId, language="es"){

        if( monumentoId ){
            return http.get(`/paquetes/${monumentoId}`, {
                params:{
                    language
                }
            })
        }else{
            return http.get("/paquetes");
        }
    }

    getMonumentsServices(monumentoId){
        return http.get(`/servicios-monumentos`);
    }

    getPaquetesMonumentos( language ="es" ){
        return http.get("/paquetes-monumentos", {
            params:{
                language
            }
        });
    }

    obtenerPaquete(id, tipoDePaquete, lang = 'es' ){
        return http.get(`/paquete/${id}`, {
            params: {
                tipoDePaquete,
                idioma: lang,
                language: lang
            }
        })
    }

    getVisitasFiltered(body, lang){
        return http.post('/visitas-filtered', body, {
            params:{
                idioma: lang
            }
        });
    }

    async getNearestMonuments( geolocation, language="es" ){
        const body = {
            lng: geolocation.lng,
            lat: geolocation.lat,
            language
        }
        const result = await http.post( '/sources/nearest', body )

        return result;
    }

    async getResourcesByBuscador(input, fechaRange, language="es"){
        const result = await http.post( '/sources/buscador', {input, fechaRange, language} )
        return result;
    }

    async getProvincias(){
        const { data } = await http.get('/provincias');
        return data;
    }

    async obtenerTarifasDelPaquete( paqueteId, tarifaId, tipo ){
        const result = await http.get(`/paquete/${paqueteId}/tarifa`, {
            params: {tarifaId, tipo }
        })
        return result.data;
    }

    async getAvailableLanguages( ){
        const result = await http.get("/idiomas-disponibles");
        return result.data;
    }
}

export default new ApiDataService();