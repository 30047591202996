var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    [
      _c(
        "mdb-modal",
        {
          staticClass: "shadow",
          attrs: { show: _vm.popupStart, centered: "" },
          on: { close: () => (_vm.popupStart = false) },
        },
        [
          _c("mdb-modal-body", {}, [
            _c(
              "div",
              { staticClass: "py-5 px-3" },
              [
                _c("h3", { staticClass: "font-weight-bold text-uppercase" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("hr", { staticClass: "red unique-color-dark" }),
                _c("h5", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.subtitle)),
                ]),
                _vm.imageUrl
                  ? _c("mdb-view", [
                      _c("img", {
                        staticClass: "mx-auto",
                        staticStyle: {
                          width: "auto",
                          "max-height": "300px",
                          "object-fit": "cover",
                        },
                        attrs: {
                          src: _vm.imageUri,
                          alt: `${_vm.imageUrl} image cap`,
                        },
                      }),
                    ])
                  : _vm._e(),
                _c(
                  "mdb-container",
                  { class: { "d-none": !_vm.imageUrl } },
                  [
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "mt-5",
                        attrs: { color: _vm.color, size: "lg" },
                        on: { click: () => _vm.$emit("close") },
                      },
                      [_vm._v("CERRAR")]
                    ),
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "mt-5",
                        attrs: { color: _vm.color, size: "lg" },
                        on: { click: () => _vm.$emit("goTo") },
                      },
                      [_vm._v("VER DETALLES")]
                    ),
                  ],
                  1
                ),
                _c(
                  "mdb-container",
                  { class: { "d-none": _vm.imageUrl } },
                  [
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "mt-5",
                        attrs: { color: _vm.color, size: "lg" },
                        on: { click: () => _vm.$emit("close") },
                      },
                      [_vm._v("ACEPTAR")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }