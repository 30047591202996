var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { staticClass: "justify-content-center" }, [
    _c("span", { staticClass: "fw-bold dark text-uppercase" }, [
      _vm._v(_vm._s(_vm.$t("default.CATEGORY_CLOUD_TITLE"))),
    ]),
    _c("ul", { staticClass: "mx-auto breadcrumb" }, [
      _c("li", [
        _c("a", { attrs: { href: "/castillos" } }, [
          _vm._v(_vm._s(_vm.$t("default.CATEGORY_CLOUD_MONUMENTS"))),
        ]),
      ]),
      _c(
        "li",
        { staticClass: "mx-0" },
        [
          _c("font-awesome-icon", {
            staticStyle: { "vertical-align": "middle" },
            attrs: { icon: "circle", transform: "shrink-10" },
          }),
        ],
        1
      ),
      _c("li", { staticClass: "mx-0" }, [
        _c(
          "a",
          { attrs: { href: "/castillos/servicios/eventos-singulares" } },
          [_vm._v(" " + _vm._s(_vm.$t("default.CATEGORY_CLOUD_EVENTS")) + " ")]
        ),
      ]),
      _c(
        "li",
        { staticClass: "mx-0" },
        [
          _c("font-awesome-icon", {
            staticStyle: { "vertical-align": "middle" },
            attrs: { icon: "circle", transform: "shrink-10" },
          }),
        ],
        1
      ),
      _c("li", { staticClass: "mx-0" }, [
        _c("a", { attrs: { href: "/rutas-recomendadas" } }, [
          _vm._v(_vm._s(_vm.$t("default.CATEGORY_CLOUD_ROUTES"))),
        ]),
      ]),
      _c(
        "li",
        { staticClass: "mx-0" },
        [
          _c("font-awesome-icon", {
            staticStyle: { "vertical-align": "middle" },
            attrs: { icon: "circle", transform: "shrink-10" },
          }),
        ],
        1
      ),
      _c("li", { staticClass: "mx-0" }, [
        _c(
          "a",
          { attrs: { href: "/castillos/servicios/experiencias-exclusivas" } },
          [_vm._v(_vm._s(_vm.$t("default.CATEGORY_CLOUD_EXPERIENCES")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }