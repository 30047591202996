var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "aria-label": "",
            "hide-header": "",
            "hide-footer": "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "border px-2 py-4 border-success mb-3",
              staticStyle: { "background-color": "rgba(204, 255, 204, 0.5)" },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mx-auto" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "my-auto text-success me-2",
                      attrs: { icon: "fa-circle-check", size: "3x" },
                    }),
                    _c("p", { staticClass: "my-auto" }, [
                      _c("strong", { staticClass: "text-spainhn" }, [
                        _vm._v(_vm._s(_vm.mostrarAlerta)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "d-flex w-100" }, [
                _c(
                  "div",
                  { staticClass: "mx-auto" },
                  [
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "spainhn-button",
                        attrs: { color: "indigo" },
                        on: { click: _vm.cerrarModal },
                      },
                      [_vm._v("ACEPTAR")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }