var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-0 image-header-container" }, [
    _c("div", { staticClass: "image-header" }, [
      _c("div", { staticClass: "text-container py-3" }, [
        _c("div", { staticClass: "mb-0 mt-auto" }, [
          _c("div", { staticClass: "d-flex mt-auto" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "52",
                  height: "25",
                  viewBox: "0 0 52 25",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M48.354 0L3.11664 0C2.29048 0.0013683 1.49856 0.324845 0.914378 0.899561C0.330196 1.47428 0.00139084 2.25337 0 3.06614L0 21.9339C0.00139084 22.7466 0.330196 23.5257 0.914378 24.1004C1.49856 24.6752 2.29048 24.9986 3.11664 25L48.354 25C49.1801 24.9986 49.972 24.6752 50.5562 24.1004C51.1404 23.5257 51.4692 22.7466 51.4706 21.9339V3.06614C51.4692 2.25337 51.1404 1.47428 50.5562 0.899561C49.972 0.324845 49.1801 0.0013683 48.354 0ZM50.5027 21.9339C50.5027 22.4945 50.2763 23.0322 49.8734 23.4287C49.4704 23.8251 48.9238 24.0478 48.354 24.0478L3.11664 24.0478C2.54675 24.0478 2.0002 23.8251 1.59723 23.4287C1.19426 23.0322 0.967868 22.4945 0.967868 21.9339L0.967868 3.06614C0.967868 2.50548 1.19426 1.96779 1.59723 1.57135C2.0002 1.1749 2.54675 0.952184 3.11664 0.952184L48.354 0.952184C48.9238 0.952184 49.4704 1.1749 49.8734 1.57135C50.2763 1.96779 50.5027 2.50548 50.5027 3.06614V21.9339Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M47.7841 9.55882C47.3483 9.5609 46.9228 9.69204 46.5615 9.93569C46.2001 10.1793 45.919 10.5246 45.7537 10.9278C45.5883 11.3311 45.5461 11.7743 45.6324 12.2015C45.7187 12.6287 45.9296 13.0208 46.2386 13.3282C46.5475 13.6356 46.9405 13.8447 47.3681 13.929C47.7957 14.0132 48.2387 13.9689 48.6412 13.8017C49.0437 13.6344 49.3876 13.3517 49.6295 12.9891C49.8714 12.6266 50.0005 12.2006 50.0005 11.7647C49.9984 11.1785 49.7639 10.617 49.3484 10.2035C48.9329 9.78997 48.3703 9.55812 47.7841 9.55882ZM47.7841 13.0019C47.5391 13.0004 47.3001 12.9262 47.0972 12.7889C46.8943 12.6517 46.7366 12.4573 46.6441 12.2305C46.5515 12.0037 46.5282 11.7545 46.5772 11.5144C46.6261 11.2744 46.7451 11.0542 46.919 10.8817C47.093 10.7092 47.3142 10.5921 47.5546 10.5452C47.7951 10.4983 48.044 10.5237 48.2701 10.6182C48.4961 10.7126 48.6891 10.872 48.8247 11.076C48.9603 11.2801 49.0323 11.5197 49.0318 11.7647C49.0283 12.0931 48.8953 12.4068 48.6616 12.6376C48.4279 12.8683 48.1125 12.9974 47.7841 12.9966V13.0019Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M42.219 2.2057L4.83901 2.2057C4.14056 2.2057 3.47072 2.47951 2.97684 2.96689C2.48296 3.45428 2.20551 4.11531 2.20551 4.80457L2.20551 20.1951C2.20897 20.8833 2.48754 21.5423 2.98067 22.029C3.4738 22.5156 4.14163 22.7905 4.83901 22.7939L42.219 22.7939C42.9164 22.7905 43.5843 22.5156 44.0774 22.029C44.5705 21.5423 44.8491 20.8833 44.8525 20.1951V4.81497C44.8539 4.4728 44.7868 4.13374 44.6551 3.81723C44.5234 3.50072 44.3296 3.213 44.0849 2.97057C43.8403 2.72814 43.5495 2.53578 43.2293 2.40452C42.9091 2.27327 42.5658 2.2057 42.219 2.2057ZM43.894 20.2055C43.8933 20.6436 43.7166 21.0636 43.4026 21.3735C43.0887 21.6833 42.663 21.8576 42.219 21.8583L4.83901 21.8583C4.61906 21.8583 4.40126 21.8156 4.19805 21.7325C3.99484 21.6495 3.8102 21.5277 3.65467 21.3742C3.49914 21.2207 3.37577 21.0385 3.2916 20.838C3.20742 20.6374 3.1641 20.4225 3.1641 20.2055L3.1641 4.81497C3.16306 4.59725 3.20562 4.38148 3.28932 4.18004C3.37303 3.97861 3.49624 3.79547 3.65187 3.64116C3.80751 3.48685 3.9925 3.3644 4.19622 3.28085C4.39995 3.1973 4.6184 3.15429 4.83901 3.15429L42.219 3.15429C42.663 3.15498 43.0887 3.32934 43.4026 3.63917C43.7166 3.94899 43.8933 4.36901 43.894 4.80717V20.2055Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M40.6311 7.7182L38.6809 8.65346C38.6361 8.62958 38.5899 8.60842 38.5423 8.59009L38.0157 6.51881C37.9777 6.39931 37.8945 6.30237 37.7832 6.24781C37.6718 6.19324 37.5407 6.18517 37.4166 6.22525C37.2925 6.26532 37.1848 6.35051 37.1155 6.4634C37.0462 6.5763 37.0206 6.70837 37.0439 6.83263L37.5304 8.74648C37.4509 8.79887 37.377 8.85997 37.3103 8.92856C37.1764 9.07542 37.0792 9.25125 37.0278 9.4397L35.2705 10.2798C35.2048 10.3058 35.1441 10.3447 35.0923 10.3942C35.0405 10.4437 34.9986 10.5026 34.9692 10.5674C34.9399 10.6321 34.9237 10.7013 34.9218 10.7705C34.9198 10.8398 34.9321 10.9076 34.9578 10.9698C34.9836 11.032 35.0222 11.0872 35.0714 11.132C35.1206 11.1768 35.1792 11.2102 35.2436 11.2301C35.308 11.25 35.3767 11.256 35.4456 11.2477C35.5145 11.2394 35.582 11.217 35.6439 11.1819L37.2122 10.4328C37.2396 10.472 37.2704 10.5085 37.3044 10.542C37.5072 10.7289 37.7789 10.8259 38.0647 10.8133L38.4978 12.5186C38.5299 12.6452 38.6117 12.7501 38.7253 12.8103C38.839 12.8705 38.9751 12.8811 39.104 12.8397C39.1916 12.8116 39.2717 12.7604 39.3355 12.6916C39.3971 12.6259 39.4418 12.5465 39.4653 12.4612C39.4888 12.3759 39.4903 12.2875 39.4697 12.2048L38.987 10.3099C39.0815 10.1866 39.1522 10.0474 39.1949 9.90042C39.2376 9.75343 39.2514 9.60156 39.2357 9.45355L41.0084 8.60455C41.0705 8.57558 41.1269 8.5347 41.1744 8.48435C41.2558 8.39643 41.3065 8.28522 41.3182 8.16942C41.3298 8.05362 41.3016 7.9403 41.2382 7.84853C41.1749 7.75676 41.0804 7.69214 40.9705 7.66553C40.8606 7.63893 40.7422 7.65197 40.635 7.70246L40.6311 7.7182Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M5.14703 12.2685C6.56316 10.5635 9.82024 7.66509 11.5196 9.71105C13.6438 12.2685 11.5196 16.1047 14.7059 17.3834C17.8921 18.6621 20.0163 10.9898 23.7336 12.2685C27.451 13.5472 26.3889 19.3015 29.5751 19.3015C32.7614 19.3015 37.0098 17.3834 35.9477 13.5472C35.098 10.4783 38.6029 9.71105 38.6029 9.71105",
                    stroke: "white",
                    "stroke-width": "0.75",
                    "stroke-linecap": "round",
                    "stroke-dasharray": "2 2",
                  },
                }),
              ]
            ),
            _c(
              "span",
              { staticClass: "text-uppercase header-title mt-auto ms-1" },
              [_vm._v(" " + _vm._s(_vm.$t("default.MAIN_MENU_RUTAS")) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "text-center my-auto header-text py-2" }, [
          _vm._v(" " + _vm._s(_vm.$t("default.ROUTES_DESCRIPTION_TEXT")) + " "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }