<template>
    <div class="d-flex spainhn-gray-background justify-content-center align-items-center tool-box" 
        v-bind:class="{ 'active': active }" 
        @click="() => $emit('toggleFilter')"
        role="button">
        <span style="margin-right: 9px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <path d="M9.33333 23.2222L2.09686 25.6344C1.55725 25.8143 1 25.4126 1 24.8437V4.37842C1 4.01972 1.22953 3.70126 1.56981 3.58785L9.33333 1M9.33333 23.2222L17.6667 26M9.33333 23.2222V1M9.33333 1L17.6667 3.77778M17.6667 26L25.4301 23.4122C25.7704 23.2988 26 22.9803 26 22.6215V2.15619C26 1.58739 25.4428 1.18575 24.9032 1.36563L17.6667 3.77778M17.6667 26V3.77778" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>        
        </span>
        <span class="text-uppercase">{{ $t("default.HOME_RESULTS_MAP_LABEL") }}</span>
    </div>
</template>

<script>
    export default {
        props:{
            active: {type: Boolean, default: false }
        }
    }
</script>

<style scoped>
 .tool-box{
    /* background: #d9d9d9;
    color: #FFF; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 .active{
    background: #BF5B5B;
 }


</style>