<template>
    <default-layout>
        <!-- <header-image/> -->
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos">
                    {{monumentoNombre}}>> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text" role="button">{{
                $t('default.NAVEGADOR_ALOJAMIENTOS_CERCANOS') }}</p>
                <!-- <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{info.nombres[0].texto}}</p> -->
            </div>
        </div>
        <div class="container-fluid px-md-5 px-1">
            <div class="d-flex justify-content-around pt-1 mb-1" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" :iconColor="'rgb(0,0,0)'">
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black">{{monumentoNombre}}</p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div>

            <filtro-hoteles 
                :monumento-nombre="monumentoNombre" 
                :latitude="coordinates.lat" 
                :longitude="coordinates.lng" 
                @hotelesEncontrados="( evt ) => refreshHotels(evt)"
                @loading="(evt) => {loading = evt;}"/>

            <mdb-container v-if="loading">
                <img src="@/assets/SimboloCastillo.svg" style="width: 300px; height: 300px; color: brown !important;" class="d-block mx-auto"/>
                <mdb-spinner color="red" big crazy/>
            </mdb-container>
            <!-- <div class="container-fluid mb-1">
                <mdb-row>
                    <div class="d-flex flex-column pt-3 ps-3 pe-3 col-12">
                        <h5 class="mb-0"><strong><small>{{ $t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_DONDE') }}
                                    {{monumentoNombre}}?</small></strong></h5>
                        <p class="mb-3"><strong><small>{{ $t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_HEADER') }}
                                    {{monumentoNombre}}</small></strong></p>

                        <div class="h-100 pt-5 pb-5 spainhn-bg-gris-cajas ps-3 pe-0 spainhn-text d-flex flex-column"
                            style="border-radius:10px;">
                            <span class="text-start px-3 text-wrap mx-auto spainhn-text"
                                style="font-size:11pt">{{$t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_SUB_1')}}
                                <strong>{{monumentoNombre}}</strong>
                                {{$t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_SUB_2')}}
                            </span>
                            <div class="d-flex flex-column pt-3 ps-3 pe-3 col-12" style="border-radius:10px;">
                                <mdb-row>
            
                                    <mdb-col>
                                        <label class="form-label" for="checkIn">Entrada</label>
                                        <input v-model="checkIn" class="form-control" type="date" name="checkIn" id="checkIn">
                                    </mdb-col>

                                    <mdb-col>
            
                                        <label class="form-label" for="checkOut">Salida</label>
                                        <input v-model="checkOut" class="form-control" type="date" name="checkIn" id="checkOut">
                                    </mdb-col>

                                    <mdb-col>
                                        <label class="form-label" for="habitaciones">Habitaciones</label>
                                        <select v-model="rooms" class="form-control" name="occupancies" id="occupancies">
                                            <option value="1" >1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </mdb-col>

                                    <mdb-col>
                                        <label class="form-label" for="adults">Adultos</label>
                                        <select v-model="adults" class="form-control" type="range" min="1" max="10" name="adults" id="adults">
                                            <option value="1">1</option>
                                            <option value="2" >2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                        </select>
                                    </mdb-col>
            
                                    <mdb-col>
            
                                        <label class="form-label" for="children">Niños</label>
                                        <select v-model="children" class="form-control" type="range" min="0" max="4" name="children" id="children">
                                            <option value="0" >0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </mdb-col>
            
                                    <mdb-col col="12" class="mt-3">
                                        <mdb-btn color="indigo" class="text-white font-weight-bold" @click="searchAvailabilityHotelBeds">Buscar</mdb-btn>
                                    </mdb-col>
                                </mdb-row>
                            </div>
                        </div>
                    </div>
                </mdb-row>
            </div> -->

            <mdb-container class="flex">
                <mdb-row>
                    <mdb-col v-for="(hotel, index) of hotelesCercanos" :key="index" lg="4" md="3" col="12" class="mt-3">
                        <hoteles-card 
                            :main-image-path="hotel.banner?.path" 
                            :name="hotel.name" :distance="hotel.distance"
                            :category="hotel.categoryType"
                            :minRate="hotel.minRate ? parseFloat(hotel.minRate).toLocaleString('es-ES', { style: 'currency', currency: hotel.currency }) : ''"
                            />
                    </mdb-col>
                </mdb-row>
            </mdb-container>

            <div  v-if="showPanelQuery" class="container-fluid mb-5">
                <div class="row">
                    <div class="h-100 pt-5 pb-5 spainhn-bg-gris-cajas" style="border-radius:10px;">
                        <card-hotelbeds v-for="(room, index) in hotelBedsQuery" v-bind:key="index"
                            :room="room"
                            @buyRoom="abrirFormularioComprar($event)"
                            @openModal="abrirModal($event)">

                        </card-hotelbeds>
                    </div>
                </div>
            </div>
            
            <!-- <modal-hotel-beds
                :roomDetails="roomSelected"
                :show="showModal">

            </modal-hotel-beds> -->

            <b-container v-if="showFormHolder">
                <div class="container-fluid mb-5"> 
                    <div class="h-100 pt-5 pb-5 spainhn-bg-gris-cajas">
                        <h5>Información de Habitación</h5>

                        <p>Tipo de Habitiación: {{roomSelected.boardName}}</p>
                        <p>Adultos: {{roomSelected.adults}}</p>
                        <p>No. de cuartos: {{roomSelected.rooms}}</p>

                        <p>Politicias de cancelacion:</p><ul v-for="(cancelPolicy, index2) in roomSelected.cancellationPolicies" v-bind:key="index2">                                
                                    {{cancelPolicy.amount}} € - Costo de cancelación si cancelas antes del: {{new Date(cancelPolicy.from).toLocaleDateString("es-ES", { timeZone: "UTC",date:'DD-MM-YYY',  hour: "2-digit", minute: "2-digit",
                            })}}
                        </ul>

                        <b-row>
                            <b-col sm="3">
                                <label for="name">Nombre(s)</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-input></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label for="surname">Apellido(s)</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-input></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label for="remarks">Observaciones o Peticiones especiales</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-textarea></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="2">

                                <mdb-btn>
                                    <icon-base :height="25" :width="25">
                                        <icon-carrito/>
                                    </icon-base>
                                    <span>Comprar</span>
                                </mdb-btn>
                            </b-col>
                        </b-row>
                    </div>                   
                </div>
            </b-container>


            <!-- <div class="container-fluid">
                <p class="spainhn-small-text">{{$t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_CONTACTO_1')}}</p>
                <mdb-btn color="indigo" class="spainhn-button mb-3">{{$t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_CONTACTO_2')}}
                </mdb-btn>
            </div> -->
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
// import HeaderImage from '../components/HeaderImage.vue'
import ApiService from '../services/ApiService';
import HotelbedsService from '../services/HotelbedsService.js';
import CardHotelbeds  from '../components/CardHotelbeds';
// import ModalHotelBeds from '../components/ModalHotelBeds'
import IconBase from '../components/IconBase.vue';
import IconCasttle from '../components/icons/IconCasttle.vue';
import HotelesCardVue from '../components/HotelesCard.vue';
import HotelesCard from '../components/HotelesCard.vue';
import FiltroHoteles from '../components/FiltroHoteles.vue';

const GENERAL_FACILITIES_CODE = 60;

export default {
    components: {
        DefaultLayout,
        IconBase,
        IconCasttle,
        CardHotelbeds,
        HotelesCard,
        FiltroHoteles
        // ModalHotelBeds
    },
    data() {
        return {
            // monumentoNombre:"",
            monumentoUrl: null,
            monumentoNombres: [],
            address: null,
            descripcion: null,
            videoId: 'YU5hCOSFki8',
            edificios: [],
            imagenes: [],
            slide: 0,
            indicaciones: null,
            horarios: null,
            castilloId: null,
            activeImage: 0,
            nombreUrl: null,
            restaurantes: [],
            actividades: [],
            id: null,
            info: null,
            tipo: null,
            tipoHeader: null,
            folderOldId: null,
            tipoSubheader: null,
            hotelBedsInfo: null,
            roomSelected:null,
            showModal:false,
            showFormHolder:false,
            showPanelQuery: false,
            coordinates: {
                lat: "",
                lng: ""
            },
            hotelesCercanos: [],
            hotelesConDisponibilidad: [],
            loading: false
            // address: null
            // Con esta variable determinamos el status del icono        
        }
    },
    methods: {
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },

        calculateDistance( startLatitude, startLongitude, endLatitude, endLongitude ){
            let x = startLatitude - endLatitude;
            let y = startLongitude - endLongitude;

            return Math.sqrt( (x * x) + ( y * y) );
        },

        async refreshHotels( evt ){

            this.loading = true;

            this.hotelesConDisponibilidad = evt.hotels;

            if( evt.total > 0 ){
                const codigoDeHoteles = evt.hotels.map( h => h.code );
    
                const result = await HotelbedsService.getHotelBasicDetails( codigoDeHoteles )
    
                const detallesDeHoteles = this.groupBy( result, 'code' );
                
                console.log( detallesDeHoteles );
                
                this.hotelesConDisponibilidad.forEach( h => {
    
                    try{
                        const sortedImages = detallesDeHoteles[ h.code ][0].images.sort( (a, b) => {
                            return a.visualOrder - b.visualOrder
                        } )
                        h.banner = sortedImages[0];
                        h.categoryType = detallesDeHoteles[ h.code ][0].categoryGroupCode;
                    }
                    catch( err ){
                        console.log( err );
                    }
                    h.distance = this.calculateDistance( parseFloat(h.latitude), parseFloat(h.longitude), parseFloat(this.coordinates.lat), parseFloat(this.coordinates.lng) )
                })
    
                this.hotelesCercanos = [...this.hotelesConDisponibilidad];
            }

            else{
                this.hotelesCercanos = [];
            }

            this.loading = false;
        },
        showContent() {
            console.log('click en castillo');
        },
        prevImage() {
            let $vm = this;
            if ($vm.activeImage == 0) {
                $vm.activeImage = $vm.imagenes.length;
            }
        },
        goToCastillosYPalacios() {
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos() {
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
        goToEntradas() {
            let $vm = this;
            $vm.$router.push("/castillos/tarifas/" + $vm.monumentoNombre)
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        async searchAvailabilityHotelBeds(){
            let $vm = this;

            const checkIn = $vm.checkIn;
            const checkOut = $vm.checkOut;
            const rooms = $vm.rooms;
            const adults = $vm.adults;
            const children = $vm.children
            const hotel = 117618

            HotelbedsService.availabilityHotelBeds( checkIn, checkOut, rooms, adults, children, hotel).then(res=>{
                $vm.hotelBedsQuery = res['data']['hotels']['hotels'][0]['rooms']
                console.log($vm.hotelBedsQuery)
                $vm.hotelBedsQuery.forEach(room =>{
                    room.images = []
                    room.facilities = []
                    // Object.assign(room,{images: this.getImagesFromRoom(room.code)})
                    room.images =  this.getImagesFromRoom(room.code)
                    room.facilities =  this.getRoomFacilities(room.code)
                    room.facilities = [...room.facilities, ...this.getGeneralFacilities()]
                    $vm.showPanelQuery = true;
                })
                console.log($vm.hotelBedsQuery)
            })

        },
        detallerateKey(rateKey){
            HotelbedsService.checkRateKey(rateKey).then(response =>{
                console.log(response)
                return response
            })
        },
        verDetalleHabitacion(event){

            let detalleRateKey = this.detallerateKey(event.rateKey)
            console.log(detalleRateKey)


        },
        getImagesFromRoom(roomCode){
            let images = []
            const allImages = this.hotelBedsInfo['images']
            let imagesFilter = allImages.filter(
                item => 'roomCode' in item && item.roomCode == roomCode 
            )
            images = imagesFilter? imagesFilter: []
            return images;
        },

        getRoomFacilities(roomCode){
            let facilities = []
            let rooms = this.hotelBedsInfo['rooms']
            let roomsFilter = rooms.filter(item =>{
                if(item['roomCode'] == roomCode){
                //   console.log(true)  
                  return true
                }else{
                    // console.log(false)
                    return false
                }
            }) 
            if(roomsFilter[0]){

                facilities = 'roomFacilities' in roomsFilter[0]? roomsFilter[0].roomFacilities: []
            }
            
            return facilities
        },
        getGeneralFacilities(){
            const allFacilities = this.hotelBedsInfo['facilities']
            let facilities = allFacilities.filter(item =>{
                return item.facilityGroupCode == GENERAL_FACILITIES_CODE
            })           
            return facilities
        },
        abrirModal(event){
            console.log('entra a abrir modal')
            this.roomSelected = event.room;
            this.showModal = true;
        },
        abrirFormularioComprar(event){
            console.log(event.room)
            console.log('abrir formulario')
            this.showModal = false;
            this.showFormHolder = true;
            this.showPanelQuery = false;
            this.roomSelected = event.room;

        }
    },
    setup() {

    },
    async created() {
        let $vm = this;
        // $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        $vm.id = $vm.$route.params.monumentoId;
        $vm.tipo = $vm.$route.params.tipo;

        // console.log($vm.tipo)
        if ($vm.tipo == "bodas") {
            $vm.tipoHeader = "Bodas en"
            $vm.tipoSubheader = "Celebra tu boda en"
        }

        else if ($vm.tipo == "restaurante") {
            $vm.tipoHeader = "Restaurante de"
            $vm.tipoSubheader = "Disfruta la gastronomía en"
        }

        else if ($vm.tipo == "espacios") {
            $vm.tipoHeader = "Alquiler de espacios en"
            $vm.tipoSubheader = "Reserva el "
        }

        else if ($vm.tipo == "museo") {
            $vm.tipoHeader = "Museo de "
            $vm.tipoSubheader = "Conoce el museo y exposiciones de "
        }

        else if ($vm.tipo == "alojamiento") {
            $vm.tipoHeader = "Habitaciones de "
            $vm.tipoSubheader = "Alojate en "
        }

        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;

        try{
            const result = await ApiService.getEdificacion($vm.$i18n.locale, $vm.monumentoUrl)
            this.address = result.data.address;
            this.monumentoNombres = result.data.nombres;

            this.coordinates = result.data?.coordenadas;

            console.log( this.coordinates );

            const hotelesCercanos = await HotelbedsService.findNearlyHotels( this.coordinates );

            hotelesCercanos.sort( (a, b) => {
                return a.distance - b.distance;
            })

            this.hotelesCercanos = hotelesCercanos;
            console.log( hotelesCercanos );
        }
        catch( err ){
            console.log( err );
        }
            // .then(res => {
            //     // let $vm = this;
            //     // console.log(res);
            //     // $vm.address = res.data.direccion;
            //     // $vm.monumentoNombres = res.data.nombres;

            //     //AQUI VALIDAMOS SI ES UN TIPO ALOJAMIENTO Y SI EL API ES CON HOTELBEDS
            //     /* const apiExterna = res.data.apiExterna
            //     if(apiExterna == 'hotelbeds'){

            //     } */
            //     // HotelbedsService.hotelDetailHotelBeds(348159, $vm.$i18n.locale).then(res=>{
            //     //     $vm.hotelBedsInfo = res['data']['hotel'];
            //     //     this.getImagesFromRoom()
            //     //     console.log($vm.hotelBedsInfo)
            //     // })

            // })
            // .catch(err => {
            //     console.log(err);
            // })
        // ApiService.getServicioByMonumento('es', $vm.id, $vm.tipo)
        //     .then( result => {
        //         // console.log(result);
        //         $vm.info = result.data;
        //         console.log($vm.info);
        //         $vm.address = result.data.direccion;
        //         $vm.descripcion = result.data.descripcion;
        //         // $vm.folderOldId = result.data.oldId;
        //         // ApiService.getImagenesByRestaurante($vm.info.oldId)
        //         //     .then(res => {
        //         //         $vm.imagenes = res.data;
        //         //         console.log(res);
        //         //     })
        //         //     .catch(err=>{
        //         //         console.log(err);
        //         //     })
        //     })
        //     .catch( err =>{
        //         console.log(err);
        //     })
        // $vm.tipo = $vm.$route.params.tipo;
        // console.log($vm.$route.params.tipo)
        // if($vm.$route.params.tipo == "restaurantes"){
        //     ApiService.getRestauranteById('es', $vm.id)
        //         .then( result => {
        //             // console.log(result);
        //             $vm.info = result.data;
        //             $vm.folderOldId = result.data.oldId;
        //             ApiService.getImagenesByRestaurante($vm.info.oldId)
        //                 .then(res => {
        //                     $vm.imagenes = res.data;
        //                     console.log(res);
        //                 })
        //                 .catch(err=>{
        //                     console.log(err);
        //                 })
        //         })
        //         .catch( err =>{
        //             console.log(err);
        //         })
        // }
        // else if($vm.$route.params.tipo == "actividades"){
        //     ApiService.getActividadById('es', $vm.id)
        //         .then( result => {
        //             // console.log(result);
        //             $vm.info = result.data;
        //             $vm.folderOldId = result.data.oldId;
        //             ApiService.getImagenesByActividad($vm.info.oldId)
        //                 .then(res=>{
        //                     $vm.imagenes = res.data;
        //                     console.log(res);
        //                 })
        //                 .catch(err=> {
        //                     console.log(err);
        //                 })
        //         })
        //         .catch( err =>{
        //             console.log(err);
        //         })
        // }


    },
    computed: {
        player() {
            return this.$refs.youtube.player
        },
        isCastillosSelected() {
            let $vm = this;
            if ($vm.selectedType === "Castillos") {
                $vm.castillosColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.castillosDescriptionText');
                return true;
            }
            else {
                $vm.castillosColor = $vm.disabledColor;
                return false;
            }
        },
        selectedIdiomaIndex() {
            let $vm = this;
            let idiomaIndex = 0;
            if ($vm.$i18n.locale == 'es') {
                idiomaIndex = 0;
            }
            else if ($vm.$i18n.locale == 'en') {
                idiomaIndex = 1;
            }
            else if ($vm.$i18n.locale == 'fr') {
                idiomaIndex = 2;
            }
            else if ($vm.$i18n.locale == 'de') {
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma() {
            let $vm = this;

            return $vm.$i18n.locale;
        },

        monumentoNombre() {
            return this.monumentoNombres[ this.selectedIdiomaIndex ]?.texto ?? "";
            // try {
            //     return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
            // }
            // catch (err) {
            //     return "";
            // }
        }
    }
}
</script>


<style scoped>
.text-columns {
    column-count: 3;
    /* height: 50vh; */
}

.disable-selector {
    color: rgba(225, 225, 225);
}

.slide-show-container {
    position: relative;
    width: 100%;
    margin: auto;
}

.spainhn-slides {
    display: none;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 10s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media (min-width: 768px) {
    .text-columns {
        column-count: 3;
        /* height: 50vh; */
    }
}

@media (max-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }
}

@media (max-width: 424px) {
    .text-columns {
        column-count: 1;
        /* height: 50vh; */
    }
}
</style>