<template>
    <div class="card my-auto mx-2 mt-5 px-0" @mouseover="showText" @mouseleave="hideText"
      @click="goToMicrosite" v-bind:style="{backgroundImage: image}" 
      role="button">
        <div class="container w-100">
          <div class="card-body" v-bind:class="{'active-background':status, 'disabled-background':!status}">
            <div class="d-flex flex-column my-auto h-100">
              <mdb-btn style="border-radius: 10px; padding-top: 5px; padding-bottom: 5px; border: none !important;" :class="status ? 'spainhn-gray-background' : 'spainhn-button' " class=" my-auto mx-auto"><strong class="spainhn-lg-text">{{monument}}</strong></mdb-btn>
              <p class="m-0" >{{ provincia? `${municipio} - ${provincia}` : '' }}</p>
            </div>
          </div>
          <div class="content" v-bind:class="{'d-none':!status}">
            <div class="pb-1 pe-2" style="height:75%; overflow-y: scroll; scrollbar-color: var(--spainhn-rojo-corporativo) #ffffff99; scrollbar-width: thin; ">
              <div class="spainhn-small-text text-start" v-html="text"></div>
            </div>
            <div class="h-25">
              <font-awesome-icon icon="fa-plus-circle" size="2x" style="color:var(--spainhn-rojo-corporativo)"></font-awesome-icon>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Cards2',
  props:{
    imageUrl: String,
    // title: String,
    monument: String,
    text: String,
    nombreUrl: String,
    provincia: String,
    municipio: String,
    extendUrl: {type: String, default: ''}
    // date: String
  },
  data(){
    return{
      // image: `url(${require('@/assets/img_avatar.png')})`
      status: false
      // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
    }
  },
  methods:{
    showText(){
      this.status = true;
      // if(this.status == true)
      //   this.status = false;
      // else
      //   this.status = true;
    },
    hideText(){
      this.status = false;
    },
    goToMicrosite(){
      // console.log(this.monument);
      let $vm = this;
      $vm.$router.push('/castillos/' + $vm.encodedUrl + $vm.extendUrl);
    }
  },
  setup() {

  },
  created(){
  },
  computed: {
    image(){
      // console.log(this.imageUrl);
      let urlEnconded = encodeURI(this.imageUrl)
      return `url(${urlEnconded})`;
    },
    encodedUrl(){
      let $vm = this;

      let rawName =  $vm.nombreUrl.replaceAll(',', '');
      rawName =  rawName.replaceAll('.', '');

      let nombreNormalizado = rawName.normalize("NFD").replace( /[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g,'')
      let nombreNormalizadoLowerCase = nombreNormalizado.toLowerCase().replace(/[\u0020]/g, '-');
      
      // console.log(nombreNormalizadoLowerCase);
      return nombreNormalizadoLowerCase;
    }
  }
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  height: 25rem;
  width: 22rem;
  min-width: 300px;
}

img {
    border-radius: 10px 1px 0 0;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.content{
  z-index: 1;
  background-color: var(--spainhn-gris-caja);
  position: absolute;
  height: 100%;
  width: 101%;
  top:0%;
  /* padding-top:5%; */
  padding: 5% 8% 5% 8%;
  padding-bottom: 20%;
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
  transition: 0.3s;
}

.card-body{
  height: 35%;
  padding: 5% 5%;
  padding-bottom: 10%;
  /* background-color: rgba(0,0,0,0.7); */
  color:white;
  border-radius: 0px 0px 10px 10px;
  z-index: 2;
  overflow: hidden;

}

.active-background{
  background-color: var(--spainhn-rojo-corporativo);
}

.disabled-background{
  background-color: rgba(0,0,0,0.7);
}
</style>