var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("SpainhnNavigationBreadcrumb", {
        attrs: { "navigation-links": _vm.links },
      }),
      _c(
        "div",
        {
          staticClass: "loader-component",
          class: { "d-none": !_vm.movingToCart },
          attrs: { id: "loader" },
        },
        [
          _c("img", {
            staticClass: "animated flip infinite",
            staticStyle: { height: "220px", width: "auto" },
            attrs: {
              src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "spainhn-bg-gris-cajas w-100 margen-estandard d-none d-md-inline-block",
          staticStyle: { "min-height": "10vh" },
        },
        [
          _c("div", { staticClass: "pt-4 row ps-lg-5 ps-0" }, [
            _c("div", { staticClass: "col-md-7 col-12 ps-md-3 ps-4 pe-4" }, [
              _c(
                "p",
                { staticClass: "spainhn-text ms-auto mt-3 me-0 text-start" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("default.TICKET_DETAILS_WELCOME_1")) + " "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.micrositeData.webName))]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("default.TICKET_DETAILS_WELCOME_2")) +
                      " "
                  ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.monumentName) },
                  }),
                  _vm._v(
                    ". " + _vm._s(_vm.$t("default.TICKET_DETAILS_WELCOME_3"))
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-md-5 align-content-center col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-content-center",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "spainhn-subtitle ms-auto my-auto",
                      staticStyle: { "font-size": "15pt" },
                    },
                    [
                      _c("span", { class: { "d-none": false } }, [
                        _vm._v(_vm._s(_vm.$t("default.SPAINHN_DESDE"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.precioOrientativo) + " €"),
                    ]
                  ),
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button me-lg-0 me-auto ms-3",
                      attrs: { color: "indigo", size: "sm" },
                      on: { click: _vm.goToCarrito },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "my-auto" },
                            [
                              _c(
                                "icon-base",
                                { attrs: { height: 40, width: 40 } },
                                [_c("icon-carrito")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "strong",
                            {
                              staticClass: "spainhn-text",
                              class: { "d-none": _vm.experiencia },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("default.SPAINHN_BUTTON_BUY_TICKET")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "strong",
                            {
                              staticClass: "spainhn-text",
                              class: { "d-none": !_vm.experiencia },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("default.SPAINHN_BUTTON_EXPERIENCES")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "container-fluid px-3 px-md-5 mb-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "pt-3 col-xxl-8 col-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-start spainhn-text pt-0 pe-0 mb-1 mb-md-5 col-12 px-0",
                    class: { "d-none": _vm.clorian },
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c(
                      "b-carousel",
                      {
                        staticStyle: { "text-shadow": "1px 1px 2px #333" },
                        attrs: {
                          id: "monumentosShow",
                          interval: 4000,
                          controls: "",
                          indicators: "",
                          "img-width": "940",
                          "img-height": "528",
                        },
                        on: {
                          "sliding-start": _vm.onSlideStart,
                          "sliding-end": _vm.onSlideEnd,
                        },
                        model: {
                          value: _vm.slide,
                          callback: function ($$v) {
                            _vm.slide = $$v
                          },
                          expression: "slide",
                        },
                      },
                      [
                        _vm._l(_vm.imagenes, function (imagen, index) {
                          return _c("b-carousel-slide", {
                            key: index,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "img",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mb-3 d-flex mx-auto carousel-homeslide px-0 mx-0",
                                          staticStyle: {
                                            height: "344px",
                                            width: "940px",
                                            "max-width": "100%",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "my-md-auto mx-auto carousel-spainhn",
                                            staticStyle: {
                                              height: "auto",
                                              width: "auto",
                                              "max-width": "940px",
                                              "max-height": "344px",
                                              display: "block",
                                              "object-fit": "cover",
                                            },
                                            attrs: {
                                              src: `${_vm.imagenesUrl}/visitas_castillos/${_vm.visitaOldId}/${imagen.name}`,
                                              alt: imagen.altText,
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "carousel-control-prev",
                            attrs: {
                              "aria-controls": "monumentosShow___BV_inner_",
                              role: "button",
                              "data-slide": "prev",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "text-black",
                              attrs: { icon: "fa-circle", size: "3x" },
                            }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Previous"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "carousel-control-next",
                            attrs: {
                              role: "button",
                              "data-slide": "next",
                              "aria-controls": "monumentosShow___BV_inner_",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "text-black",
                              attrs: { icon: "fa-circle", size: "3x" },
                            }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Previous"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "overflow-hidden mx-auto d-md-block d-none",
                        staticStyle: {
                          height: "94.5px",
                          width: "940px",
                          "max-width": "100%",
                          position: "relative",
                        },
                        attrs: { id: "imageSelectorContainer" },
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "imageSelector",
                            staticClass: "d-flex thumbnails-container",
                            staticStyle: {
                              height: "94.5px",
                              width: "940px",
                              "max-width": "72vw",
                              position: "relative",
                            },
                            attrs: { id: "imageSelector" },
                          },
                          _vm._l(_vm.imagenes, function (imagen, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "thumbnail-slide",
                                staticStyle: {
                                  height: "94.5px",
                                  width: "140px",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "px-1",
                                  staticStyle: {
                                    height: "100%",
                                    width: "140px",
                                    "object-fit": "cover",
                                    "border-radius": "15px",
                                  },
                                  attrs: {
                                    role: "button",
                                    src: `${_vm.imagenesUrl}/visitas_castillos/${_vm.visitaOldId}/${imagen.name}`,
                                    alt: imagen.altText,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSlide(index)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { class: { "d-none": !_vm.clorian } },
                  _vm._l(_vm.imagenes, function (imagen, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "mb-3 d-flex mx-auto",
                        staticStyle: {
                          height: "528px",
                          width: "940px",
                          "max-width": "100%",
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "d-block mx-auto my-auto carousel-spainhn",
                          staticStyle: { height: "528", width: "auto" },
                          attrs: { src: imagen, alt: "image slot" },
                        }),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-around pt-0 pt-md-2 mb-0 px-lg-2 px-md-0 mx-auto",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex px-lg-4 align-middle pb-2",
                          staticStyle: {
                            width: "100%",
                            position: "relative",
                            "min-height": "54px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ms-0 d-flex flex-column text-start me-md-auto me-lg-0 mt-1 me-1",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0 pb-0 spainhn-header-info",
                                },
                                [_vm._v(_vm._s(_vm.micrositeData.webName))]
                              ),
                              _c(
                                "small",
                                {
                                  staticClass:
                                    "mb-0 mt-0 spainhn-subheader-info text-dark pt-0",
                                },
                                [_vm._v(_vm._s(_vm.monumentName))]
                              ),
                            ]
                          ),
                          _c("buy-component", {
                            attrs: { tipo: _vm.tipoDeEntrada },
                            on: { goTo: () => _vm.goToBuy() },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", {
                    staticClass: "w-100 border-bottom mt-0 mb-1",
                    staticStyle: {
                      height: "2vh",
                      "border-color":
                        "var(--spainhn-rojo-corporativo) !important",
                    },
                  }),
                  _c("div", { staticClass: "text-start spainhn-text pt-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "html-spacing mb-1",
                        class: { "html-container": !_vm.showFullText },
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.micrositeData.webDescription),
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "d-flex mx-auto my-2" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "read-more-btn spainhn-gray-background mx-auto mb-1",
                          on: { click: () => _vm.toggleFullText() },
                        },
                        [
                          _c(
                            "span",
                            { class: { "d-none": _vm.showFullText } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.BUTTONS.READ_MORE"))
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { class: { "d-none": !_vm.showFullText } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.BUTTONS.READ_LESS"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 d-md-inline-block d-none" }, [
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "spainhn-sections-title text-start fw-bold mb-0 pb-0 pt-2",
                      },
                      [_vm._v(_vm._s(_vm.$t("default.MICROSITES_SCHEDULE")))]
                    ),
                    _c("div", {
                      staticClass: "w-100 border-bottom mt-0 pt-0 mb-1",
                      staticStyle: {
                        height: "2vh",
                        "border-color": "rgb(242,90,85) !important",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "spinhn-text text-start text-columns" },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.micrositeData.webSchedule),
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "mdb-container",
                  { staticClass: "d-md-none" },
                  [
                    _vm.micrositeData.webSchedule
                      ? _c("collapsable-element", {
                          staticClass: "my-2",
                          attrs: {
                            title: _vm.$t("default.MICROSITES_SCHEDULE"),
                            content: `<div style='font-size:12px;'>${_vm.micrositeData.webSchedule}</div>`,
                          },
                        })
                      : _vm._e(),
                    _vm.micrositeData.webDetails
                      ? _c(
                          "collapsable-element",
                          {
                            staticClass: "my-2",
                            attrs: {
                              title: _vm.$t("default.TICKET_DETAILS_DETAILS"),
                              content: `<div style='font-size:12px;'>${_vm.micrositeData.webDetails}</div>`,
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "w-100 border-bottom mt-auto mb-3",
                              staticStyle: {
                                height: "2vh",
                                "border-color": "rgb(0,0,0) !important",
                              },
                            }),
                            _c(
                              "h4",
                              { staticClass: "spainhn-sections-title" },
                              [_vm._v(_vm._s(_vm.micrositeData.webName))]
                            ),
                            _c(
                              "h4",
                              { staticClass: "spainhn-sections-title" },
                              [
                                _c("span", { class: { "d-none": !true } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("default.SPAINHN_DESDE"))
                                  ),
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.precioOrientativo) + " €"
                                ),
                              ]
                            ),
                            _c(
                              "mdb-btn",
                              {
                                staticClass: "spainhn-button mt-1 mb-2 mx-auto",
                                attrs: { color: "indigo", size: "sm" },
                                on: { click: _vm.goToCarrito },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "my-auto" },
                                      [
                                        _c(
                                          "icon-base",
                                          { attrs: { height: 50, width: 50 } },
                                          [_c("icon-carrito")],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "strong",
                                      {
                                        staticClass: "spainhn-sections-title",
                                        class: { "d-none": _vm.experiencia },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "default.SPAINHN_BUTTON_BUY_TICKET"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "strong",
                                      {
                                        staticClass: "spainhn-sections-title",
                                        class: { "d-none": !_vm.experiencia },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "default.SPAINHN_BUTTON_EXPERIENCES"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.micrositeData.webDiscounts
                      ? _c("collapsable-element", {
                          staticClass: "my-2",
                          attrs: {
                            title: _vm.$t("default.TICKET_DETAILS_DISCOUNTS"),
                            content: `<div style='font-size:12px;'>${_vm.micrositeData.webDiscounts}</div>`,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-md-flex flex-column pt-3 px-3 col-xxl-4 d-none" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg-6 col-xxl-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column text-start",
                      staticStyle: { "border-radius": "10px" },
                    },
                    [
                      _c("h4", { staticClass: "mb-3" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("default.TICKET_DETAILS_DETAILS"))
                          ),
                        ]),
                      ]),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.micrositeData.webDetails),
                        },
                      }),
                      _c("div", {
                        staticClass: "w-100 border-bottom mt-auto mb-3",
                        staticStyle: {
                          height: "2vh",
                          "border-color": "rgb(0,0,0) !important",
                        },
                      }),
                      _c("h4", { staticClass: "spainhn-subtitle" }, [
                        _vm._v(_vm._s(_vm.micrositeData.webName)),
                      ]),
                      _c("h4", { staticClass: "spainhn-subtitle" }, [
                        _c("span", { class: { "d-none": !true } }, [
                          _vm._v(_vm._s(_vm.$t("default.SPAINHN_DESDE"))),
                        ]),
                        _vm._v(" " + _vm._s(_vm.precioOrientativo) + " €"),
                      ]),
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "spainhn-button mt-1 mb-2 mx-auto",
                          attrs: { color: "indigo", size: "sm" },
                          on: { click: _vm.goToCarrito },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "my-auto" },
                                [
                                  _c(
                                    "icon-base",
                                    { attrs: { height: 50, width: 50 } },
                                    [_c("icon-carrito")],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "strong",
                                {
                                  staticClass: "spainhn-text",
                                  class: { "d-none": _vm.experiencia },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "default.SPAINHN_BUTTON_BUY_TICKET"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "strong",
                                {
                                  staticClass: "spainhn-text",
                                  class: { "d-none": !_vm.experiencia },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "default.SPAINHN_BUTTON_EXPERIENCES"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-12 pt-3 col-lg-6 col-xxl-12",
                    class: { "d-none": _vm.clorian },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column text-start",
                        staticStyle: { "border-radius": "10px" },
                      },
                      [
                        _c("h4", { staticClass: "mb-3" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("default.TICKET_DETAILS_DISCOUNTS"))
                            ),
                          ]),
                        ]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.micrositeData.webDiscounts),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm.eventosRecomendados?.length > 0
        ? _c("div", { staticClass: "d-flex flex-column pt-3 col-12" }, [
            _c(
              "span",
              { staticClass: "spainhn-sections-title text-uppercase" },
              [_vm._v(_vm._s(_vm.$t("default.TICKET_DETAILS_MORE")))]
            ),
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap justify-content-center px-0",
                staticStyle: { "column-gap": "15px" },
              },
              _vm._l(_vm.eventosRecomendados, function (evento, index) {
                return _c("recommendations-card", {
                  key: index,
                  staticClass: "my-2",
                  class: { "d-none": index > 2 },
                  attrs: {
                    title: evento.webName,
                    nombreUrl: evento.visitaId,
                    fecha: evento.fechas,
                    tipo: "Entrada",
                    imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`,
                  },
                  on: { goTo: _vm.goToEntrada },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "spainhn-bg-gris-cajas w-100",
          staticStyle: { "min-height": "10rem" },
        },
        [
          _c("div", { staticClass: "pt-4 row ps-md-5 ps-0" }, [
            _c("div", { staticClass: "col-md-7 col-12 ps-md-5 ps-4 pe-4" }, [
              _c(
                "p",
                { staticClass: "spainhn-text ms-auto mt-3 me-0 text-start" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("default.TICKET_DETAILS_WELCOME_1")) + " "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.micrositeData.webName))]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("default.TICKET_DETAILS_WELCOME_2")) +
                      " "
                  ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.monumentoNombre) },
                  }),
                  _vm._v(
                    ". " + _vm._s(_vm.$t("default.TICKET_DETAILS_WELCOME_3"))
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-md-5 col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-content-center pb-2",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "spainhn-subtitle my-auto",
                      staticStyle: { "font-size": "15pt" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.SPAINHN_DESDE")) +
                          " " +
                          _vm._s(_vm.precioOrientativo) +
                          " €"
                      ),
                    ]
                  ),
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button mt-1 mb-2 ms-3",
                      attrs: { color: "indigo", size: "sm" },
                      on: { click: _vm.goToCarrito },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "my-auto" },
                            [
                              _c(
                                "icon-base",
                                { attrs: { height: 50, width: 50 } },
                                [_c("icon-carrito")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "strong",
                            {
                              staticClass: "spainhn-text",
                              class: { "d-none": _vm.experiencia },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("default.SPAINHN_BUTTON_BUY_TICKET")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "strong",
                            {
                              staticClass: "spainhn-text",
                              class: { "d-none": !_vm.experiencia },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("default.SPAINHN_BUTTON_EXPERIENCES")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("welcome-modal", {
        attrs: {
          "popup-start": _vm.showWelcome,
          title: _vm.welcomeTitle,
          subtitle: _vm.welcomeSubtitle,
          "image-url": null,
          color: "danger",
        },
        on: {
          close: function ($event) {
            _vm.showWelcome = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }