<template>
    <div>
        <div class="card">
            <div class="card-header">
                <mdb-btn color="indigo" block variant="outline-secondary" @click="openModal()">
                    {{room.name}}
                </mdb-btn>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(room, index) in room.rates" v-bind:key="index"> 
                    <div class="fila">
                        <span class="float-left">{{room.boardName}}</span>
                        <div>
                            <ul v-for="(cancelPolicy, index2) in room.cancellationPolicies" v-bind:key="index2">                                
                                {{cancelPolicy.amount}} € - Costo de cancelación si cancelas antes del: {{new Date(cancelPolicy.from).toLocaleDateString("es-ES", { timeZone: "UTC",date:'DD-MM-YYY',  hour: "2-digit", minute: "2-digit",
                  })}}
                            </ul>
                        </div>
                        <div>
                            <span class="spainhn-subtitle-3">{{room.net}} €  </span>
                            <mdb-btn color="indigo" @click="buyRoom(room)">
                                <icon-base :height="25" :width="25">
                                    <icon-carrito/>
                                </icon-base>
                                <span>Comprar</span>
                            </mdb-btn>
                        </div>
                    </div>                   
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import IconCarrito from '../components/icons/IconCarrito.vue'
    import IconBase from '../components/IconBase.vue'
    export default{
        components:{
            IconCarrito,
            IconBase
        },
        props:{
            room: Object
        },
        methods:{
            buyRoom(room){
                console.log(room)
                this.$emit('buyRoom', {room: room})
            },
            openModal(){
                console.log('entra a abrir modal')
                this.$emit('openModal', {room: this.room})
            },

        }
    }
</script>

<style scoped>
   .fila{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

   }

   .fila not:first-child{
    justify-content: end;
   }
</style>
