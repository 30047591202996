var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mb-2 d-flex flex-column",
      class: { "d-none": _vm.videoId == "" },
      staticStyle: { padding: "0px" },
    },
    [
      _c(
        "span",
        { staticClass: "spainhn-sections-title mb-2 text-uppercase" },
        [_vm._v(" " + _vm._s(_vm.$t("default.MICROSITES_MULTIMEDIA")) + " ")]
      ),
      _c(
        "div",
        { staticClass: "multimedia-container mx-auto" },
        [
          _c("youtube", {
            ref: "youtube",
            staticClass: "me-auto",
            staticStyle: { "border-radius": "10px !important" },
            attrs: { "video-id": _vm.videoId, width: "100%", height: "100%" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }