<template>
    <div>
        <div class="d-flex pe-0 w-100">
                <div class="text-start pt-3 pe-3" style="width:100%">
                    <!-- <h4 class="my-auto pb-0 mb-0 spainhn-title" role="button"><strong> {{monumentoNombres[idiomaIndex].texto}}</strong> </h4>
                    <h5 class="mb-0"><strong class="spainhn-subtitle">{{nombres[idiomaIndex].texto}}</strong></h5> -->
                </div>
            </div>

            <b-card class="d-flex pe-0 w-100 container">
                
                <div class="d-flex my-auto pb-3">
                    <div class="text-start h-100">
                        <font-awesome-icon icon="fa-mouse-pointer" class="fa-2x ms-2 my-auto"/>
                    </div>
                    <div class="d-flex my-auto" v-bind:class="{'d-none':selectedSeats.zonaName != null}">
                        <p class="my-auto ms-3 fw-bold">{{ $t('default.RESERVATION_STEP_4') }}</p>
                    </div>

                    <div class="d-flex ms-auto" v-bind:class="{'d-none': selectedSeats.zonaName == null}">
                        <div class="d-flex my-auto">
                            <div class="ms-auto d-flex">
                                <!-- <p class="text-start" v-bind:class="{'d-none':horariosLimitados}"><small class="spainhn-small-text">{{ $t('default.CART_STEP1_NOTES_1') }}<br>({{ $t('default.CART_STEP1_NOTES_2') }})</small></p> -->
                                <p class="text-start my-auto">
                                    <strong>{{selectedSeats.zonaName}} - {{ selectedSeats.asientosResumen }}</strong>
                                </p>
                            </div>
                            <div class="ms-3">
                                <font-awesome-icon role="button" v-b-toggle.seats class="circle-icon shadow" icon="fa-pen"/>
                            </div>
                        </div>
                    </div>
                </div>

                <b-collapse 
                    id="seats" 
                    class="border-top mt-2" 
                    style="border-color: rgb(165, 61, 57) !important; border-width: 2px !important;" 
                    v-model="expand">
                    <mdb-container>
                        <mdb-row>
                            <mdb-col md="4" col="12" class="order-md-1 order-2 my-auto">
                                <h4 class="text-start font-weight-bold">{{ $t('default.RESERVATION_STEP_4_1') }}</h4>
                                <mdb-btn v-for="(zona, index) of zonasDisponibles" :key="index" 
                                    class="d-block text-white" 
                                    :style="`width: 200px; background-color: ${zona.color}  !important;`"
                                    @click="getZoneInfo( zona )"
                                    >
                                    {{ zona.name }} <small class="text-italic" v-bind:class="{'d-none':true}">({{ zona.asientos }})</small>
                                </mdb-btn>                        
                            </mdb-col>
                            <mdb-col col="10" md="6" class="order-md-2 order-1">
                                <div>
                                    <!-- <img src="../assets/gradas.png" class="float-rigth img-fluid" style="transform: rotate(90deg); max-height: 500px;"> -->
                                    <img :src="imagenUrl" class="float-rigth img-fluid" style="max-height: 500px;">
                                </div>
                            </mdb-col>
                        </mdb-row>
                    </mdb-container>

                    <seats-picker :Zona="zonaInfo ? zonaInfo.name : ''" :filas="filasConfig" :personas="numeroDePersonas" @finalizarCompra="finalizarCompra">

                    </seats-picker>
                </b-collapse>
            </b-card>
    </div>
</template>

<script>
import SeatsPicker from './SeatsPicker.vue';
export default {
    components: { SeatsPicker },
    name: 'SeatsConfigurator',
    props:{
        expanded: { type: Boolean, default: false },
        zonas: { type: Array },
        personas: { type: Number, required: true},
        originLayout: String,
        imagenIlustrativa: String
    },
    data(){
        return{
            selectedSeats: {
                zonaName: null,
                zonaId: null,
                asientosResumen: null,
                asientosInfo: []
            },
            zonaInfo: null,
            filasConfig: [],
            imagen: null,
            origin: null,
        }
    },
    methods: {
      getZoneInfo( evt ){
        this.zonaInfo ={
            name: evt.name,
            _id: evt._id,
        } 

        this.filasConfig = evt.filas;
      },
      finalizarCompra( payload ){
        this.selectedSeats = {
            zonaName: this.zonaInfo.name,
            zonaId: this.zonaInfo._id,
            asientosResumen: payload.map( p => ( p.columnaNombre ) ).reduce( (prev, curr) => prev + " " + curr, "" ),
            asientosInfo: payload,
        };

        this.$emit( 'getAsientos', this.selectedSeats )
      }
    },
    watch:{
        originLayout( originLayout ){
            console.log(originLayout);
            this.origin = this.originLayout;
            return originLayout;
        },
        imagenIlustrativa( imagenIlustrativa ){
            this.imagen = this.imagenIlustrativa;
        },
        expanded( expanded ){
            if( expanded == false){
                this.filasConfig = [];
            }
        }   
    },
    computed: {
        expand(){

            const showConfigurator  = this.expanded;
            if( this.showConfigurator == false){
                this
            }
            return showConfigurator;
        },
        zonasDisponibles(){
            return this.zonas;
        },
        numeroDePersonas(){
            return this.personas;
        },
        imagenUrl(){

            console.log(this.origin);
            return `${process.env.VUE_APP_IMAGES_URL_VPS}/recintos/${this.origin}/${this.imagen}`
        }
    }
}
</script>