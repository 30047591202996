<template>
    <div class="card my-auto mx-2 mt-5" style="backgroundRepeat:no-repeat; " v-bind:style="{backgroundImage: image}">
        <div class="container">
          <div class="card-body">
            <div class="d-flex flex-column mt-auto h-100 justify-content-end">
            <strong class="spainhn-text mt-5">{{title}}</strong>
            <small class="spainhn-small-text">{{monument}}</small>
            <strong class="spainhn-text">{{date}}</strong>
            <mdb-btn color="indigo" class="spainhn-button mt-2" @click="goTo" size="sm" ><strong>{{$t('default.SPAINHN_BUTTON_ROUTE')}}</strong></mdb-btn>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Cards2',
  props:{
    imageUrl: String,
    title: String,
    monument: String,
    text: String,
    date: String,
    linkId: String
  },
  data(){
    return{
      // image: `url(${require('@/assets/img_avatar.png')})`
      // image: `url(${this.imageUrl})`
      // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
    }
  },
  methods:{
      goTo(){
        this.$emit('goTo', this.linkId)
      }

  },
  setup() {

  },
  created(){
    // console.log(this.title);
  },
  computed:{
    image(){
      let urlEncoded = encodeURI(this.imageUrl);

      return `url(${urlEncoded})`
    }
  }
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  height:24rem;
  width:20rem;
  min-width: 250px;
  max-width: 85vw;
}

img {
    border-radius: 10px 1px 0 0;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  border-radius: 10px;
}

.card-body{
  height: 100%;
  padding: 5% 5%;
  padding-bottom: 10%;
  background-color: rgba(0,0,0,0.4);
  color:white;
  border-radius: 10px 10px 10px 10px;
  padding-top: 0.3rem;
  align-items: flex-end;
  margin-top: auto;
  overflow: hidden;

}
</style>