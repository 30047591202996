<template>
    <div class="center" v-if="loading">
        <div class="loader-container">
            <img src="../../assets/SimboloCastillo.svg" style="height:250px; width:auto;" class="animated flip infinite"/>
            <br>
            <span class="text-white">
                {{ $t("default.LOADER_MSG") }}
            </span>
        </div>  
    </div>        
</template>

<script>
    export default {
        props: {
            loading: { type: Boolean, default: false }
        }
    }
</script>

<style scoped>
    /* .vertical-center{
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    } */

    .loader-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50%);
    }

    .center {
        position: fixed;
        /* top: 50%; */
        bottom: 0;
        /* left: 50%; */
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        /* margin: auto; */
        /* transform: translate(-50%, -50%); */
        z-index: 10;
    }

</style>