<template>
    <div style="">
        <div class="d-flex flex-column mb-3" style="position:static; background-color:rgba(0,0,0,0); z-index:1; min-height:90vh; height: 100%;">
            <div class="row gx-0" style="position:static; z-index:1; height: 100%;">

                <FilterMobile v-if="isMobile" @hideFilter="toggleSearchbar" :searchBlockStatus="searchBlockStatus" style="position: relative;"/>

                <div v-else class="h-100 pb-0 px-4 col-12 col-xl-12 col-xxl-4 px-2 spainhn-gray-background" v-bind:class="{'d-none':!searchBlockStatus,}"
                    style="position: static !important; min-height:90vh; z-index: 5; height: 100%;">

                    <diV class="h-100 px-lg-3 pt-0 p-md-3 pb-3 d-flex flex-column mt-auto search-title">
                        <div class="px-0 mx-0 border-bottom mt-auto" style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;"> 
                            <strong class="d-flex py-2 py-md-3">
                                <span class="text-start spainhn-subtitle-text-white w-75">{{ $t('default.HOME_SEARCHBOX_TITLE')}}</span> 
                                <font-awesome-icon role="button" class="my-auto ms-auto" @click="toggleSearchbar" icon="fa-circle-chevron-left" size="2x"/>
                            </strong>
                        </div>

                        <b-input-group class="py-2 d-flex flex-column">
                            <div class="w-75 pe-lg-5 pt-lg-3 py-md-2">
                                <p class="text-start spainhn-small-text"> {{ $t('default.HOME_SEARCHBOX_SUBTITLE')}}</p>
                            </div>
                            <div class="d-flex">
                                <b-form-input @input="filtrarNombre"  v-model="filtroPorNombre" v-on:keyup.enter="toggleSearchbar" :placeholder="$t('default.SELECT_BUSQUEDA')" class="w-100" style="border-radius: 20px; height:50px" size="sm"/>
                                <div class="ms-3 my-auto d-flex" style="border-radius: 25px; height:50px; width: 50px; background-color: var(--spainhn-rojo-corporativo);" role="button" @click="toggleSearchbar">
                                    <font-awesome-icon class="my-auto mx-auto" icon="fa-magnifying-glass" size="1x"></font-awesome-icon>
                                </div>
                            </div>
                            
                        </b-input-group>
                    
                        <div class="row py-3 text-start">
                            <mdb-row>
                                <mdb-col col="12" md="6" v-for="tipo in tiposDeMounmentos" :key="tipo.id">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" :id="tipo.id" :name="`${tipo.name}-${tipo.uuid}`">
                                        <label class="custom-control-label" >{{ tipo.name }}</label>
                                    </div>                                
                                </mdb-col>
                            </mdb-row>>
                        </div>
                        <div class="mt-3">
                            <p class="d-flex fw-bold spainhn-text py-0">{{ $t('default.HOME_SEARCHBOX_LOCALIZACION_OPTION')}}</p>
                            <div class="border-bottom w-25 py-0 my-0" style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;"></div>
                            <b-form-select v-model="localizacionesFilter" :options="comunidadesAutonomas" @change="filtrarNombre"
                                class="w-100 mt-3" style="border-radius: 10px; height:2.5rem;"/>
                        </div>

                        <div class="mt-3">
                            <p class="d-flex fw-bold spainhn-text py-0">{{ $t('default.HOME_SEARCHBOX_RUTA_OPTION')}}</p>
                            <div class="border-bottom w-25 py-0 my-0" style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;"></div>
                            <b-form-select v-model="rutaFilter" :options="rutasOptionsFiltered" @change="filtrarNombre" class="w-100 mt-3" style="border-radius: 10px; height:2.5rem;"/>
                        </div>

                        <div class="mt-3">
                            <p class="d-flex fw-bold spainhn-text py-0">{{ $t('default.HOME_SEARCHBOX_SERVICE_OPTION')}}</p>
                            <div class="border-bottom w-25 py-0 my-0" style="border-color:var(--spainhn-rojo-corporativo) !important; border-width:medium !important;"></div>
                            <b-form-select v-model="serviciosFilter" :options="serviciosOptions" @change="filtrarNombre" class="w-100 mt-3" style="border-radius: 10px; height:2.5rem;"/>
                        </div>

                        <div class="d-flex mx-auto mt-4">
                            <div class="py-2 px-2">
                                <mdb-btn color="indigo" size="lg" class="spainhn-button" @click="limpiarFiltros">{{ $t('default.HOME_SEARCHBOX_CLEAR_BUTTON')}}</mdb-btn>
                            </div>

                            <div class="py-2 px-2">
                                <mdb-btn color="indigo" @click="toggleSearchbar" class="spainhn-button d-xxl-none" size = "lg">{{ $t('default.HOME_SEARCHBOX_SEARCH_BUTTON') }} </mdb-btn>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col mapa">
                    <div class="mt-0 mt-md-0 d-flex ms-sm-0 ms-0 pt-0 pt-md-0" v-bind:class="{'d-none':searchBlockStatus}"
                        style="position:relative; z-index:3;">
                        <mdb-btn color="indigo" class="spainhn-button px-5 mt-5" size="lg" @click="toggleSearchbar" style="position:absolute; z-index:3; margin-top:5rem !important;">
                            <strong>{{ $t('default.HOME_SEARCH_BUTTON')}}</strong>
                        </mdb-btn>
                    </div>                    
                    <div style="position:absolute; z-index:1; width: 100%; min-height:90vh; height: 100%;">
                        <GmapMap
                            ref="mapRef"
                            autobindAllEvents:false
                            :center="mapCenterComputed"
                            :zoom="mapZoomComputed"
                            map-type-id="terrain"
                            :styles="['hide']"
                            style="width: 100%; min-height:90vh; height: 100%;">
                            <DirectionsRenderer
                                travelMode="DRIVING"
                                :origin="startLocation"
                                :waypoints="waypointsComputed"
                                :destination="endLocation"
                                :optimize_waypoint_order="true"
                            />
                            <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" 
                                :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                            </GmapInfoWindow>
                            
                            <GmapCluster :gridSize="3" :zoomOnClick="true" 
                                :styles="[{'textColor':'white', 'height':60, 'width':60, 'textSize':10, 
                                    'backgroundPosition':'0 0', 'url':mapClusterIcon}]" :maxZoom="16">
                                <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.position"
                                    :clickable="true"
                                    :draggable="false"
                                    @click="toggleInfoWindow(m,index)"
                                    :icon="m.parador ? require('../assets/paradores-small.png'): require('../assets/marker-gral-small.png') "
                                />
                            </GmapCluster>
                            
                        </GmapMap>
                    </div>

                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../services/ApiService';
import {gmapApi} from 'vue2-google-maps'
import DirectionsRenderer from "@/components/DirectionsRenderer";
import FilterMobile from "@/components/FilterMobile";
import axios from 'axios'
// import { uuid as uuid } from "vue-uuid";
import { v4 as uuidv4 } from 'uuid';
export default{
    name:'BuscadorDeCastillos',
    components:{
        DirectionsRenderer,
        FilterMobile,
    },
    data(){
        return{
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            iconShowHide:'fa-circle-chevron-up',
            currentLocation :{lat: 0, lng: 0},
            searchBlockStatus: true,
            tipoFilter:null,
            markers:[],
            monumentos:[],
            rutaFilter: null,
            serviciosFilter:null,
            mapZoom: 6.3,
            localizacionesFilter: null,
            comunidadesAutonomas:[
                { value: null, text:this.$t('default.SELECT_COMUNIDAD') },
                {value:"Andalucía" , text:"Andalucía" },
                {value:"Aragón" , text:"Aragón" },
                {value:"Asturias, Principado de" , text: "Asturias, Principado de" },
                {value:"Balears, Illes" , text:"Balears, Illes" },
                {value:"Canarias" , text:"Canarias" },
                {value:"Cantabria" , text:"Cantabria" },
                {value:"Castilla - La Mancha" , text: "Castilla - La Mancha" },
                {value:"Castilla y León" , text:"Castilla y León" },
                {value: "Catalunya", text:"Catalunya" },
                {value: "Ceuta", text:"Ceuta" },
                {value: "Comunitat Valenciana", text:"Comunidad Valenciana" },
                {value: "Extremadura", text:"Extremadura" },
                {value: "Galicia", text:"Galicia" },
                {value: "Madrid, Comunidad de", text:"Madrid" },
                {value: "Ciudad de Melilla", text:"Melilla, Ciudad de" },
                {value: "Murcia, Región de" , text:"Murcia, Región de" },
                {value: "Navarra, Comunidad Foral de", text:"Navarra, Comunidad Foral de" },
                {value: "País Vasco", text:"País Vasco" },
                {value: "Rioja, La", text:"Rioja, La" }
            ],
            rutasOptions:[
                {value:null, text: this.$t('default.SELECT_RUTA')}
            ],
            filtroPorNombre:null,
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,

            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                width: 0,
                height: -35
                }
            },
            castillosCheck: false,
            palaciosCheck: false,
            palaciosRealesCheck: false,
            recintosCheck: false,
            paradoresCheck: false,
            rutasOptionsFiltered: [],
            mapCenter:  {lat:39.952762719398464, lng:-5.057718553453691},
            startLocation: null,
            endLocation: null,
            waypoints: [],

            rutasAutor: [],
            rutasColeccionista: [],
            rutasSenderismo: [],
            rutasHistoricas:[],
            tiposDeMounmentos: []
        }
    },
    methods:{

        toggleSearchbar(){
            let $vm = this;
            if($vm.searchBlockStatus){
                $vm.searchBlockStatus = false;
                $vm.iconShowHide = 'fa-circle-chevron-down';
            }
            else{
                $vm.searchBlockStatus = true;
                $vm.iconShowHide = 'fa-circle-chevron-up';
            }
        },

        goToMonument(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },

        toggleInfoWindow: function(marker, idx) {
            console.log(this.markers);
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.infoText;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;

            }
        },
        filtrarTipo(){
            let $vm = this;
            let filtro = $vm.tipoFilter;
            if(filtro == "Recintos"){
                filtro = "Recintos amurallados"
            }
            let tempMonumentos = $vm.tempMonumentos.filter( monumento => {

                if( filtro == "Paradores"){
                    return monumento.alojamiento == true;
                }
                else{
                    return monumento.tipoDeMonumento == filtro;
                }
            })

            if( !$vm.filtroPorNombre ){
                let filtroNombre = $vm.filtroPorNombre.toLowerCase();
                let tempMonumentos2 = tempMonumentos.filter( monumento => {
                let tempName1 = monumento.nombres[0].texto.toLowerCase()
                let include = tempName1.search(filtroNombre);
                return include != -1;
                })

                tempMonumentos = tempMonumentos2;
            }

            if( !$vm.localizacionesFilter){
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                return monumento.comunidad == $vm.localizacionesFilter;
                // return true
                })
                tempMonumentos = tempMonumentos2;
            }

            let mapTop = $vm.$refs['mapRef'].getBoundingClientRect().top - 200;
            window.scrollTo( {top: mapTop, left:0} )
            $vm.setMarkers(tempMonumentos);

        },
        filtrarNombre(){
            let $vm = this;
            let tempMonumentos = $vm.tempMonumentos;

            // Look up for monuments by name
            if( !$vm.filtroPorNombre){
                // first we have to convert to lowecase and remove the white spaces at the end and the start
                let filtroNombre = $vm.filtroPorNombre.toLowerCase();
                filtroNombre = filtroNombre.trim()

                // We remove all accents and diatrics
                filtroNombre = filtroNombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                tempMonumentos = $vm.tempMonumentos.filter( monumento => {
                    // Convert to lower case string
                    let tempName1 = monumento.nombres[$vm.selectedIdiomaIndex].texto.toLowerCase();
                    // Remove accents and diatrics to compare with the input
                    tempName1 = tempName1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    
                    // Check if the name includes the input field
                    let include = tempName1.search(filtroNombre);

                    return include != -1;
                })
            }
            // console.log($vm.tempMonumentos);
            
            let tiposAceptados = [];
            if($vm.castillosCheck || $vm.recintosCheck || $vm.palaciosCheck || $vm.palaciosRealesCheck || $vm.paradoresCheck){
                // let filtroTipo = $vm.tipoFilter
                if($vm.castillosCheck){
                    tiposAceptados.push("Castillos");
                }
                // if($vm.recintosCheck){
                //     tiposAceptados.push("Recintos amurallados")
                // }
                if($vm.palaciosCheck){
                    tiposAceptados.push("Palacios");
                }
                if($vm.palaciosRealesCheck){
                    tiposAceptados.push("Palacios Reales")
                }
                if($vm.paradoresCheck){
                    tiposAceptados.push("Parador")
                }

                let tempMonumentos2 = tempMonumentos.filter( monumento => {

                    if( this.paradoresCheck ){
                        return ( tiposAceptados.includes(monumento.tipoDeMonumento) );
                    }
                    else{
                        return ( tiposAceptados.includes(monumento.tipoDeMonumento) );
                    }
                })
                tempMonumentos = tempMonumentos2

            }

            if( !$vm.localizacionesFilter ){
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                    // console.log(monumento.comunidad);
                    return monumento.comunidad == $vm.localizacionesFilter;
                    // return true
                })

                // console.log(tempMonumentos2);

                let monumentosIdsArray = tempMonumentos2.map( tempMonumento => {
                    return tempMonumento._id;
                })
                // console.log($vm.rutasOptions);
                
                // let tempRutasAutorOptions = { label: 'Rutas de Autor', options:[] };

                // tempRutasAutorOptions.options = $vm.rutasAutor.filter( rutaValue => {
                //     if(rutaValue.)
                // })

                let tempRutas = $vm.rutasOptions.filter( rutaValue => {
                    if(rutaValue.value == null){
                        return true;
                    }
                    else{
                        var castillosInRuta = rutaValue.value.map( rutaValue2 => {
                            return rutaValue2._id;
                        })

                        // console.log(castillosInRuta)
                        let rutaValida = false;
                        monumentosIdsArray.forEach( monumentoId => {
                            // console.log(monumentoId)
                            if(castillosInRuta.includes(monumentoId)){
                                rutaValida = true;
                                return true;
                            }
                        })

                        return rutaValida;
                    }
                } )
                // let tempRutas = $vm.rutasOptions.filter( rutaValue => rutaValue.value != null).map( rutaValue => {
                    
                //     let castillosInRuta = rutaValue.value.map( rutaValue2 => {
                //         return rutaValue2._id;
                //     })

                //     monumentosIdsArray.forEach( )
                //     console.log(castillosInRuta);
                //     return rutaValue;
                // });

                // console.log(tempRutas);
                $vm.rutasOptionsFiltered = tempRutas;
                // console.log(monumentosIdsArray)
                tempMonumentos = tempMonumentos2;
            }
            else{
                $vm.rutasOptionsFiltered = $vm.rutasOptions;
            }

            if( !$vm.serviciosFilter ){
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                // console.log(monumento.comunidad);
                    if($vm.serviciosFilter == 'restaurante')
                        return monumento.restaurante == true;
                    else if($vm.serviciosFilter == 'espacios')
                        return monumento.espacios == true;
                    if($vm.serviciosFilter == 'alojamiento')
                        return monumento.alojamiento == true;
                    if($vm.serviciosFilter == 'boda')
                        return monumento.bodas == true;

                    if($vm.serviciosFilter == 'museo')
                        return monumento.museo == true;

                    if($vm.serviciosFilter == "visita"){
                        return monumento.visitas == true
                    }

                    if($vm.serviciosFilter == "experiencias"){
                        return monumento.experiencias == true
                    }

                    if($vm.serviciosFilter == "eventos"){
                        return monumento.eventos == true
                    }


                // return true
                })
                tempMonumentos = tempMonumentos2;
            }

            if( !$vm.rutaFilter ){
                // console.log($vm.rutaFilter);
                let castillosEnRuta = $vm.rutaFilter.map( castillo => {
                    return castillo.nombres[$vm.selectedIdiomaIndex].texto;
                })
                // console.log(castillosEnRuta);

                let filtroNombre = castillosEnRuta;
                let tempMonumentos2 = tempMonumentos.filter( monumento => {
                    let tempName1 = monumento.nombres[$vm.selectedIdiomaIndex].texto;
                    return filtroNombre.includes(tempName1);
                })

                tempMonumentos = tempMonumentos2;
            }

            // window.scroll({ top:1500, left:0});
            $vm.setMarkers(tempMonumentos);
        },
        
        filtrarLocalizacion(){ 
            let $vm = this;
            let filtroNombre;
            let tempMonumentos = $vm.tempMonumentos;
            // console.log("Filtrar");

            if( !$vm.filtroPorNombre ){
                filtroNombre = $vm.filtroPorNombre.toLowerCase();
                
                tempMonumentos = $vm.tempMonumentos.filter( monumento => {
                let tempName1 = monumento.nombres[$vm.idiomaIndex].texto.toLowerCase()
                // console.log(tempName1.search(filtroNombre));
                let include = tempName1.search(filtroNombre);
                return include != -1;
                })
            }
            let filtroTipo = $vm.tipoFilter
            // console.log($vm.tempMonumentos);
            

            if( !$vm.tipoFilter ){
                
                if(filtroTipo == "Recintos Amurallados"){
                    filtroTipo = "Recintos amurallados"
                }
                let tempMonumentos2 = tempMonumentos.filter( monumento => {
                    if( filtroTipo == "Paradores"){
                        return monumento.alojamiento == true;
                    }
                    else{
                        return monumento.tipoDeMonumento == filtroTipo;
                    }
                })
                tempMonumentos = tempMonumentos2
            }

            if(!$vm.localizacionesFilter ){
                let tempMonumentos2 = tempMonumentos.filter(monumento => {
                // console.log(monumento.comunidad);
                return monumento.comunidad == $vm.localizacionesFilter;
                // return true
                })
                tempMonumentos = tempMonumentos2;
            }

            $vm.setMarkers(tempMonumentos);

            // console.log(tempMonumentos);
        },
        filterByService(){
            let $vm = this;
            // console.log($vm.serviciosFilter)
            let tempMonumentos = $vm.tempMonumentos;
            let tempMonumentos2;
            tempMonumentos2 = tempMonumentos.filter( monumento=>{
                    if($vm.serviciosFilter == 'restaurante')
                        return monumento.restaurante == true;
                    else if($vm.serviciosFilter == 'espacios')
                        return monumento.espacios == true;
                    if($vm.serviciosFilter == 'alojamiento')
                        return monumento.alojamiento == true;
                    if($vm.serviciosFilter == 'boda')
                        return monumento.bodas == true;

                    if($vm.serviciosFilter == 'museo')
                        return monumento.museo == true;
                }
            )
            $vm.setMarkers(tempMonumentos2);
        },
        limpiarFiltros(){
            let $vm = this;
            $vm.rutaFilter = null;
            $vm.localizacionesFilter = null;
            $vm.filtroPorNombre = null;
            $vm.serviciosFilter = null;
            $vm.castillosCheck = false;
            $vm.recintosCheck = false;
            $vm.palaciosCheck = false;
            $vm.palaciosRealesCheck = false;
            $vm.setMarkers($vm.monumentos);
            $vm.mapZoom = 6.3;
            $vm.mapCenter = {lat:39.952762719398464, lng:-5.057718553453691}
            $vm.startLocation = null;
            $vm.endLocation = null;
            $vm.rutasOptionsFiltered = $vm.rutasOptions;
            // $vm.waypoints = [];

             
            // let tempFilter = $vm.castillos.filter()
        },
        // Coloca los marcadores sobre el mapa
        setMarkers(monumentosToFilter){
            let $vm = this;
            // console.log(monumentosToFilter);
            $vm.markers = []
            monumentosToFilter.forEach( (monumento, index)=> {
                    let rawName = null;
                    rawName = monumento.nombre.replaceAll(',', '');
                    rawName =  rawName.replaceAll('.', '');

                    let nombreNormalizado = rawName.normalize("NFD").replace( /[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g,'')
                    let nombreNormalizadoLowerCase = nombreNormalizado.toLowerCase().replace(/[\u0020]/g, '-');

                    let newMarker = {
                    id: index,
                    position: {
                    lat: parseFloat(monumento.coordenadas.lat),
                    lng: parseFloat(monumento.coordenadas.lng)
                    },
                    opacity: 1,
                    draggable: false,
                    enabled: true,
                    clicked: 0,
                    rightClicked: 0,
                    dragended: 0,
                    ifw: true,
                    parador: monumento.tipoDeMonumento === 'Parador' ? true: false,
                    ifw2text: 'This text is bad please change me :( ',
                    infoText: '<p class="pb-0 mb-0 spainhn-lg-text-red">' + monumento.nombres[this.selectedIdiomaIndex].texto + '</p>' +
                    '<br><a class="spainhn-button text-white mt-0 spainhn-lg-text" href="/castillos/' + nombreNormalizadoLowerCase + '" ><strong>Ver sitio</strong></a>',

                }
                $vm.markers.push(newMarker)
            })

            let bounds = new this.google.maps.LatLngBounds();
            
            let numeroDeMarcadores = $vm.markers.length;
            if(numeroDeMarcadores > 1){

                for(var i = 0; i < numeroDeMarcadores; i++){
                    bounds.extend($vm.markers[i].position);
                }
                this.$refs.mapRef.fitBounds(bounds)
                this.mapCenter = bounds.getCenter();
                this.mapZoom = 6.3;

                if( $vm.rutaFilter ){

                    console.log( this.rutaFilter );
                    // console.log("pintar ruta")

                    // console.log($vm.markers);
                    // console.log($vm.rutaFilter)
                    $vm.startLocation = $vm.markers[0].position;
                    $vm.endLocation = $vm.markers[numeroDeMarcadores - 1].position;

                    // Guardamos todos los marcadores en esta variable para después remover el primero y el último
                    // Los elementos que queden serán los puntos intermedios
                    
                    let marcadoresIntermedios = $vm.markers;
                    
                    // marcadoresIntermedios.pop();
                    // marcadoresIntermedios.shift();

                    let puntosIntermedios = marcadoresIntermedios.map( (marcador, index) => {
                        let waypoint = {location: marcador.position, stopover: false};
                        return waypoint;
                    })
                    $vm.waypoints = {waypoints: puntosIntermedios};

                }
                else{
                    // console.log("quitar ruta");
                    $vm.startLocation = null;
                    $vm.endLocation = null;
                    $vm.waypoints = []
                }

            }
            else if(numeroDeMarcadores === 1){
                this.mapCenter = $vm.markers[0].position;
                this.mapZoom = 10;
            }
            else{
                this.mapCenter = {lat:39.952762719398464, lng:-5.057718553453691};
                this.mapZoom = 6.3;
            }
            
            // this.$refs.mapRef.setCenter(bounds.getCenter());
            // this.$refs.mapRef.setZoom(18);

        },
        geoLocation(){
            let $vm = this

            navigator.geolocation.getCurrentPosition(position =>{
                this.currentLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
                this.mapCenter = this.currentLocation; 

                
                var marker = new this.google.maps.Marker({
                    animation: this.google.maps.Animation.BOUNCE,
                    position: this.currentLocation,
                    title: 'Current Location',
                    draggable: false,
                    clickable: false,
                    infoText: '<p class="pb-0 mb-0 spainhn-lg-text-red">Current Location</p>',
                    ifw: true,
                    icon: {
                        path: this.google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillOpacity: 1,
                        strokeWeight: 2,
                        fillColor: '#5384ED',
                        strokeColor: '#ffffff',
                        zIndex: 1,
                    }
                });
                
                this.$refs.mapRef.$mapPromise.then((map) => {   
                    //map.setMyLocationEnabled(true)
                    marker.setMap(map)
                    

                })

                //this.mapZoom = 8;


            })
        }

    },
    mounted(){
        //this.geoLocation()
        // let screenWidth = window.innerWidth;
        let screenWidth = window.screen.width;
        // console.log(screenWidth);
        if(screenWidth < 1920){
            // console.log("Ocultar el buscador")
            this.searchBlockStatus = false;
        }
        if(screenWidth < 768){
            this.mapZoom = 5.8;
        }

        this.$refs.mapRef.$mapPromise.then((map) => {
            map.setOptions({
            styles: [
            {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            },
            ]
        })

        axios.get('https://ipapi.co/json/').then(response =>{
            if(response.data.country_code == 'ES'){

                this.geoLocation();
                }
        })
    })
    },
    computed:{
        google: gmapApi,
        waypointsComputed(){
            
            // let waypoints = this.waypoints ? this.waypoints: [];
            return this.waypoints.waypoints;

            // console.log(this.waypoints.waypoint);
            // try{
            //     return this.waypoints.waypoint;
            // }
            // catch(err){
            //     return [];
            // }

        },
        mapZoomComputed(){
            return this.mapZoom;
        },
        mapCenterComputed(){
            return this.mapCenter
        },
        mapClusterIcon(){

            const color = "rgb(165, 61, 57)"
            const svg = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');
            return `data:image/svg+xml;base64,${svg}`
        },
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        serviciosOptions(){
            let servicios = [
                {'value':null, 'text': this.$t('default.SELECT_SERVICIO') },
                {'value':'visita', 'text': this.$t('default.MAIN_MENU_SERVICES_VISITS') },
                {'value':'alojamiento', 'text': this.$t('default.MAIN_MENU_SERVICES_ALOJAMIENTO') },
                {'value':'restaurante', 'text': this.$t('default.MAIN_MENU_SERVICES_RESTAURANTES') },
                {'value':'eventos', 'text':this.$t('default.MAIN_MENU_SERVICES_EVENTOS') },
                {'value':'experiencias', 'text':this.$t('default.MAIN_MENU_SERVICES_EXPERIENCIAS') },
                {'value':'boda', 'text': this.$t('default.MAIN_MENU_SERVICES_BODA') },
                {'value':'espacios', 'text': this.$t('default.MAIN_MENU_SERVICES_ESPACIOS')},
                {'value':'museo', 'text': this.$t('default.MAIN_MENU_SERVICES_MUSEOS')}
            ]

            return servicios;
        }

    },
    created(){
        let $vm = this;
        ApiService.getAllCordinates("es")
            .then(result => {
                this.monumentos = result.data;
                this.tempMonumentos = this.monumentos;
                $vm.setMarkers(this.monumentos);
            })
            .catch(err => {
                console.log(err);
            })
        ApiService.getAllRutas()
        .then( res => {
            let data = res.data
        
            let rutasAutor = data.filter( ruta => ruta.tipo == "Autor");
            let rutasSenderismo = data.filter( ruta => ruta.tipo == "Hicking");
            let rutasColeccionista = data.filter(ruta => ruta.tipo == "Collector")
            let rutasHistoricas = data.filter( ruta => ruta.tipo == "Historical")

            $vm.rutasColeccionista = rutasColeccionista;
            $vm.rutasSenderismo = rutasSenderismo;
            $vm.rutasHistoricas = rutasHistoricas;
            $vm.rutasAutor = rutasAutor;
            
            // console.log(rutasAutor);
            // console.log(rutasSenderismo);
            // console.log(rutasColeccionista);
            // console.log(rutasHistoricas);
            data.forEach(ruta => {

                let nombreRuta;
                // console.log(ruta.tipo)
                if(this.$i18n.locale == 'es'){
                    nombreRuta = ruta.nombres[0].texto;
                }
                if(this.$i18n.locale == 'en'){
                    nombreRuta = ruta.nombres[1].texto == "" || ruta.nombres[1].texto == null ? ruta.nombres[0].texto : ruta.nombres[1].texto;
                }
                if(this.$i18n.locale == 'fr'){
                    nombreRuta = ruta.nombres[2].texto == "" || ruta.nombres[2].texto == null ? ruta.nombres[0].texto : ruta.nombres[2].texto;
                }
                if(this.$i18n.locale == 'de'){
                    nombreRuta = ruta.nombres[3].texto == "" || ruta.nombres[3].texto == null ? ruta.nombres[0].texto : ruta.nombres[3].texto;
                }
                this.rutasOptions.push({value:ruta.castillos, text:nombreRuta} )
            })

            let rutasAutorOptions = { label: 'Rutas de Autor', options:[] }
            rutasAutorOptions.options = rutasAutor.map( ruta => {
                let nombreRuta;
                if(this.$i18n.locale == 'es'){
                    nombreRuta = ruta.nombres[0].texto;
                }
                if(this.$i18n.locale == 'en'){
                    nombreRuta = ruta.nombres[1].texto;
                }
                if(this.$i18n.locale == 'fr'){
                    nombreRuta = ruta.nombres[2].texto;
                }
                if(this.$i18n.locale == 'de'){
                    nombreRuta = ruta.nombres[3].texto;
                }
                
                return { value:ruta.castillos, text:nombreRuta}
            })

            let rutasSenderismoOptions = { label: 'Rutas de Senderismo', options:[] }
            rutasSenderismoOptions.options = rutasSenderismo.map( ruta => {
                let nombreRuta;
                if(this.$i18n.locale == 'es'){
                    nombreRuta = ruta.nombres[0].texto;
                }
                if(this.$i18n.locale == 'en'){
                    nombreRuta = ruta.nombres[1].texto;
                }
                if(this.$i18n.locale == 'fr'){
                    nombreRuta = ruta.nombres[2].texto;
                }
                if(this.$i18n.locale == 'de'){
                    nombreRuta = ruta.nombres[3].texto;
                }
                
                return { value:ruta.castillos, text:nombreRuta}
            })

            let rutasHistoricasOptions = { label: 'Rutas Históricas', options:[] }
            rutasHistoricasOptions.options = rutasHistoricas.map( ruta => {
                let nombreRuta;
                if(this.$i18n.locale == 'es'){
                    nombreRuta = ruta.nombres[0].texto;
                }
                if(this.$i18n.locale == 'en'){
                    nombreRuta = ruta.nombres[1].texto;
                }
                if(this.$i18n.locale == 'fr'){
                    nombreRuta = ruta.nombres[2].texto;
                }
                if(this.$i18n.locale == 'de'){
                    nombreRuta = ruta.nombres[3].texto;
                }
                
                return { value:ruta.castillos, text:nombreRuta}
            })

            let rutasColeccionistaOptions = { label: 'Rutas de Coleccionista', options:[] }
            rutasColeccionistaOptions.options = rutasColeccionista.map( ruta => {
                let nombreRuta;
                if(this.$i18n.locale == 'es'){
                    nombreRuta = ruta.nombres[0].texto;
                }
                if(this.$i18n.locale == 'en'){
                    nombreRuta = ruta.nombres[1].texto;
                }
                if(this.$i18n.locale == 'fr'){
                    nombreRuta = ruta.nombres[2].texto;
                }
                if(this.$i18n.locale == 'de'){
                    nombreRuta = ruta.nombres[3].texto;
                }
                
                return { value:ruta.castillos, text:nombreRuta}
            })

            // this.rutasOptions = [
            //     {value:null, text:"Selecciona una ruta"},

            //     rutasAutorOptions, rutasSenderismoOptions, rutasHistoricasOptions, rutasColeccionistaOptions
            // ];
            this.rutasOptionsFiltered = this.rutasOptions;
            // console.log(this.rutasOptions);

        })
        .catch(err => {
            console.log(err)
        })
        ApiService.getRutasCastillos()
            .then( result => {
                // console.log(result.data);
                let rutasCastillos = result.data;
                // rutasCastillos.map( ruta => {
                //     console.log(ruta.castillos)
                // })
                // if(result.config)
            })
            .catch( err=> {
                console.log(err);
            })
        ApiService.getTiposDeMonumento()
            .then( result => {
                console.log(result.data);
                this.tiposDeMounmentos = result.data.map( t => {
                    return ( { name: t.webName, value: false, id: uuidv4(), type: t.value } )
                })
            })
            .catch( err => {
                console.log(err);
            })

    }
}
</script>

<style scoped>
    .markers{
        background-color: var(--spainhn-rojo-corporativo);
        color: black;
    }

    @media (min-width: 1440px){
        .mapa{
            position:relative; 
            z-index:3; 
            min-height:90vh; 
            height: auto;
        }

    }
    @media (max-width: 1440px){
        .mapa{
            position:absolute; 
            z-index:3; 
            min-height:90vh; 
            height: auto;
        }

    }
    @media (max-width: 768px){
        .search-title .spainhn-subtitle-text-white{
            font-size: 16px;
        }
        .search-title .spainhn-button{
            font-size: 18px;
        }
    }
</style>
