<template>
    <div class="container-fluid mb-1">
        <mdb-row>
            <div class="d-flex flex-column pt-3 ps-3 pe-3 col-12">
                <h5 class="mb-0"><strong><small>{{ $t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_DONDE') }}
                            {{monumentoNombre}}?</small></strong></h5>
                <p class="mb-3"><strong><small>{{ $t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_HEADER') }}
                            {{monumentoNombre}}</small></strong></p>

                <div class="h-100 pt-5 pb-5 spainhn-bg-gris-cajas ps-3 pe-0 spainhn-text d-flex flex-column"
                    style="border-radius:10px;">
                    <span class="text-start px-3 text-wrap mx-auto spainhn-text"
                        style="font-size:11pt">{{$t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_SUB_1')}}
                        <strong>{{monumentoNombre}}</strong>
                        {{$t('default.MENSAJE_ALOJAMIENTOS_CERCANOS_SUB_2')}}
                    </span>
                    <div class="d-flex flex-column pt-3 ps-3 pe-3 col-12" style="border-radius:10px;">
                        
                        <form @submit.prevent="searchAvailabilityHotelBeds">
                            <mdb-row>
                                <mdb-col>
                                    <label class="form-label" for="checkIn">Entrada</label>
                                    <input v-model="checkIn" class="form-control" type="date" name="checkIn" id="checkIn" required>
                                </mdb-col>
    
                                <mdb-col>
    
                                    <label class="form-label" for="checkOut">Salida</label>
                                    <input v-model="checkOut" class="form-control" type="date" name="checkIn" id="checkOut" required>
                                </mdb-col>
    
                                <mdb-col>
                                    <label class="form-label" for="habitaciones">Habitaciones</label>
                                    <select v-model="rooms" class="form-control" name="occupancies" id="occupancies" @change="(evt) => updateNumberOfRooms(evt)">
                                        <option value="1" >1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </mdb-col>
    
                                
                                <room-data v-for="(r, index) of roomsArray" 
                                    :room="index" :key="index" 
                                    @updateOccupancie="( evt ) => updateOccupancie( evt )"/>
    
                                <mdb-col col="12" class="mt-3">
                                    <mdb-btn color="indigo" class="text-white font-weight-bold" type="submit">Buscar</mdb-btn>
                                </mdb-col>
                            </mdb-row>
                        </form>
                    </div>
                </div>
            </div>
        </mdb-row>
    </div>
</template>

<script>
import HotelbedsService from '../services/HotelbedsService';
import RoomData from './RoomData.vue';
export default {
  components: { RoomData },
    props: {
        monumentoNombre: String,
        latitude: String,
        longitude: String,
        hotelesDisponibles: { type: Array, default: () => [] }
    },
    data(){
        return{
            checkIn:null,
            checkOut:null,
            rooms:1,
            adults:2, 
            children:0,
            roomSelected:null,
            hotelBedsQuery: null,     
            showPanelQuery: false,
            geolocation: {
                radius: "50",
                unit: "km",
                latitude: null,
                longitude: null,
            },
            stay: {
                checkIn: null,
                checkOut: null
            },
            langauge: 'es',
            occupancies: [],
            roomsArray: []
        }
    },
    methods: {
        updateNumberOfRooms(){
            let $vm = this;
            this.roomsArray = [];
            for( let x=0; x < $vm.rooms; x++){
                $vm.roomsArray.push( { number: x + 1 } )
            }

            $vm.occupancies.length = $vm.rooms;
        },
        updateOccupancie( evt ){
            let $vm = this;
            console.log( evt );
            const total = evt.children + evt.adults;

            const paxes = [];

            for( let x = 0; x < total; x++){
                paxes.push( { type: 'AD' } )
            }

            $vm.occupancies[ evt.room ] = {
                rooms: 1,
                adults: evt.adults,
                children: evt.children ?? 0,
                // paxes: [...paxes]
            }
        },
        async searchAvailabilityHotelBeds(){
            let $vm = this;

            $vm.$emit( 'loading', true );

            const checkIn = $vm.checkIn;
            const checkOut = $vm.checkOut;
            const rooms = $vm.rooms;
            const adults = $vm.adults;
            const children = $vm.children
            const hotel = 117618

            $vm.geolocation.latitude = $vm.latitude;
            $vm.geolocation.longitude = $vm.longitude;

            $vm.stay.checkIn = $vm.checkIn;
            $vm.stay.checkOut = $vm.checkOut;


            // const result = await HotelbedsService.availabilityHotelBeds( checkIn, checkOut, rooms, adults, children, hotel, $vm.geolocation, $vm.stay, $vm.occupancies );
            const result = await HotelbedsService.availabilityHotelBedsByDate( $vm.geolocation, $vm.stay, $vm.occupancies );

            console.log( result );

            $vm.$emit('hotelesEncontrados', result.hotels );
                // .then(res=>{
                //     $vm.hotelBedsQuery = res['data']['hotels']['hotels'][0]['rooms']
                //     console.log($vm.hotelBedsQuery)
                //     $vm.hotelBedsQuery.forEach(room =>{
                //         room.images = []
                //         room.facilities = []
                //         // Object.assign(room,{images: this.getImagesFromRoom(room.code)})
                //         room.images =  this.getImagesFromRoom(room.code)
                //         room.facilities =  this.getRoomFacilities(room.code)
                //         room.facilities = [...room.facilities, ...this.getGeneralFacilities()]
                //         $vm.showPanelQuery = true;
                //     })
                //     console.log($vm.hotelBedsQuery)
                // })

        },
    },
    created(){
        for( let x=0; x < this.rooms; x++){
            this.roomsArray.push( { number: x + 1 } )
        }
    }
}
</script>

<style>

</style>