var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card mt-1 mb-1", style: { backgroundImage: _vm.image } },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("strong", { staticClass: "d-block spainhn-small-text" }, [
              _vm._v(_vm._s(_vm.$t("default.ROUTES_KNOW_ROUTES"))),
            ]),
            _c("div", {
              staticClass: "w-100 border-bottom my-auto",
              staticStyle: {
                height: "2vh",
                "border-color": "rgb(255,255,255) !important",
              },
            }),
            _c(
              "strong",
              {
                staticClass: "spainhn-text d-block",
                attrs: { role: "button" },
                on: { click: _vm.goToRuta },
              },
              [_vm._v(_vm._s(_vm.monument))]
            ),
            _c("strong", { staticClass: "d-block spainhn-text" }, [
              _vm._v(_vm._s(_vm.date)),
            ]),
            _c(
              "mdb-btn",
              {
                staticClass: "spainhn-button mt-2",
                attrs: { color: "indigo", size: "sm" },
                on: { click: _vm.emitEvent },
              },
              [
                _c("strong", { staticClass: "spainhn-small-text" }, [
                  _vm._v(_vm._s(_vm.$t("default.SPAINHN_BUTTON_MORE_ROUTES"))),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }