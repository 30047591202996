<template>
  <home-layout2-vue>
    <div class="d-flex-flex-column home-carousel">
      <mapa-banner></mapa-banner>
      <!-- <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="300"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-1.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
           <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-9.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
           <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-3.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-4.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
           <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-5.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
           <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-6.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-7.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>
           <b-carousel-slide img-blank>
            <div class="d-flex mb-0 mt-0 pt-0" style="position:relative; width: 100%; background-color:rgba(0,0,0,0); z-index:1; top: -0;">
              <img src="../assets/banner-8.jpg" style="z-index: -1; position: relative; width:100%; height:; max-height: 720px;"/>
            </div>
          </b-carousel-slide>

      </b-carousel> -->

      <div class="pt-5 py-xxl-5 spainhm-main" :style="{padding:'0 0', backgroundImage:`url(${require('@/assets/home-bg.jpg')})`, backgroundSize:'cover', 'background-repeat': 'no-repeat','background-position': 'center', 'min-height':'35rem'}">
        <div class="py-xxl-5">
          <div class="row m-0">
            <div class="col-lg-3 col-xl-3 col-md-5 col-sm-6 ms-auto pb-3" :style="{ backgroundImage:`url(${require('@/assets/Logo_fondo.png')})`, backgroundSize:'contain', 'background-repeat': 'no-repeat','backgroundPosition': 'top center'}">
              <h1 class="spainhn-title-text ms-auto text-end mb-0">
                {{ $t('default.patrimonioTitle')}}
              </h1>
              <h2 class="text-end pt-0 ms-auto border-top border-danger">
                {{ $t('default.patrimonioSubtitle')}}
              </h2>
            </div>

            <div class="col-lg-4 col-xl-3 col-md-6 col-sm-6 me-auto" >
              <p class="spainhn-text text-start mt-0">
                {{ $t('default.welcome1') }}
              </p>
              <p class="spainhn-text mt-1 text-start">
                {{ $t('default.welcome2') }}
              </p>
              <p class="spainhn-text mt-1 text-start">
                {{ $t('default.welcome3') }}
              </p>
            </div>
          </div>
        </div>

        <div class="row px-5 justify-content-center spainhn-cards m-0">
          <div class="px-3 border-bottom border-danger col-lg-4 col-sm-6 mt-3" style="background-image: url(/src/assets/logo.png)">
            <div class="spainhn-solid-red-background mt-2 mx-auto" style="border-radius:50%; height:140px; width:140px">
              <icon-base iconColor="rgb(255,255,255)" :height="110" :width="110">
                <icon-casttle/>
              </icon-base>
            </div>
            <p class="text-center spainhn-text mt-3">{{ $t('default.HOME_CASTLES_DESCRIPTION') }}
              <strong class="fw-bolder">{{ $t('default.HOME_CASTLES_EMPHASIS')}}</strong>
              <span><br>{{ $t('default.HOME_CASTLES_TEXT') }}</span>
            </p>
          </div>
          <div class="px-3 border-bottom border-danger col-lg-4 col-sm-6 mt-3">
            <div class="spainhn-solid-red-background mt-2 mx-auto" style="border-radius:50%; height:140px; width:140px">
              <icon-base iconColor="rgb(255,255,255)" :height="110" :width="110"><icon-palace/>
              </icon-base>
            </div>
            <p class="text-center spainhn-text mt-3">{{ $t('default.HOME_PALACES_DESCRIPTION') }}
              <strong class="fw-bolder">{{ $t('default.HOME_PALACES_EMPHASIS')}} </strong>
              <span><br>{{ $t('default.HOME_PALACES_TEXT')}}</span>
            </p>
          </div>
          <div class="px-3 border-bottom border-danger col-lg-4 col-sm-6 mt-3">
            <div class="spainhn-solid-red-background mt-2 mx-auto" style="border-radius:50%; height:140px; width:140px">
              <icon-base class="pt-4" iconColor="rgb(255,255,255)" :height="110" :width="110">
                <icon-routes/>
              </icon-base>
            </div>
            <p class="text-center spainhn-text mt-3">{{ $t('default.HOME_ROUTES_DESCRIPTION') }}
              <strong class="fw-bolder">{{ $t('default.HOME_ROUTES_EMPHASIS')}} </strong>
              <span><br>{{ $t('default.HOME_ROUTES_TEXT')}}</span>
            </p>
          </div>
        </div>

      </div>

      <div class="spainhn-solid-red-background py-md-3 py-3 px-3 mess-box">
        <h4 class="fw-bold">{{ $t('default.HOME_SEARCH_TITLE')}}
        </h4>
        <div class="w-25 mx-auto border-bottom my-3"></div>
        <h6>{{ $t('default.HOME_SEARCH_SUBTITLE')}}</h6>
      </div>
      

      <mapa></mapa>

    </div>

    <div class="container-fluid p-0 slider-cards">
      <div class="row m-0" style="min-height:70vh">
        <div class="spainhn-bg-gris-cajas col-xxl-9 col-12 justify-content-center px-3 order-last order-xxl-first py-4 visitas-container" style="min-height:70vh">
          <div class="pt-1">
            <slider class="my-3"  :tipo="'Evento'" :carouselId="'eventos'" :images="eventosClusters"></slider>
            <h3 class="spainhn-title my-auto" v-bind:class="{'d-none':eventos.length}">Aún no hay próximas visitas</h3>
          </div>
        </div>

        <div class="spainhn-solid-red-background col-xxl-3 px-4 px-md-5 order-first order-xxl-last pt-5 pb-3 col-12">
          <h1 class="spainhn-title-text-2 text-start pt-md-5"><strong>{{ $t('default.HOME_NEXT_EVENTS_TITLE')}}</strong></h1>
          <p class="spainhn-text text-start pe-md-5" style="color:white">{{ $t('default.HOME_EVENTS_DESCRIPTION')}}</p>
          <mdb-btn color="indigo" class="spainhn-button-gray ms-4 mt-2 text-white" size="lg" @click="goToEventos"><strong> {{ $t('default.HOME_ALL_EVENTS_BUTTON')}} </strong></mdb-btn>
        </div>
      </div>
    </div>

    <div class="container-fluid p-0 slider-cards">
      <div class="row m-0" style="min-height:70vh">
        <div class="spainhn-solid-red-background col-12 col-xxl-3 pt-5 pb-3 px-md-5 px-4">
          <h1 class="spainhn-title-text-2 text-end pt-md-5"><strong>{{ $t('default.HOME_NEXT_EXPERIENCES_TITLE')}}</strong></h1>
          <p class="spainhn-text text-end ps-md-5" style="color:white">{{ $t('default.HOME_EXPERIENCES_DESCRIPTION_1')}}</p>
          <p class="spainhn-text text-end ps-md-5" style="color:white">{{ $t('default.HOME_EXPERIENCES_DESCRIPTION_2')}}</p>
          <mdb-btn color="indigo" class="spainhn-button-gray ms-4 mt-2 text-white" size="lg" @click="goToExperiences"><strong>{{ $t('default.HOME_ALL_EXPERIENCES_BUTTON')}}</strong> </mdb-btn>

        </div>
        <div class="spainhn-bg-gris-cajas col-xxl-9 col-12 justify-content-center px-3 py-4 visitas-container" style="min-height:70vh;">
          <div class="pt-1 mt-2">
            <ul class="carousel-indicators bg-danger">
              <!-- <li  v-for="(i,index) in experienciasClusters" :key="index" data-target="#experiencias" :data-slide-to="index"/> -->
              <li data-target="#experiencias" data-slide-to="0" class="active"></li>
              <li data-target="#experiencias" data-slide-to="1"></li>
              <li data-target="#experiencias" data-slide-to="2"></li>
            </ul>
            <slider :tipo="'Experiencia'" class="my-3" :carouselId="'experiencias'" :images="experienciasClusters"></slider>
            <h3 class="spainhn-title my-auto" v-bind:class="{'d-none':experiencias.length}">Aún no hay próximas experiencias</h3>
          </div>
        </div>
      </div>
    </div>
  </home-layout2-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HomeLayout2Vue from '../layouts/DefaultLayout.vue'
import DefaultLayout from '../layouts/HomeLayout.vue'
import IconBase from '../components/IconBase.vue'
import IconCasttle from '../components/icons/IconCasttle.vue'
import IconPalace from '../components/icons/IconPalace.vue'
// import IconRealPalace from '../components/icons/IconRealPalace.vue'
// import IconWalledEnclosure from '../components/icons/IconWalledEnclosure.vue'
// import IconMonastery from '../components/icons/IconMonastery.vue'
import IconRoutes from '../components/icons/IconRoutes.vue';
// import IconArt from '../components/icons/IconArt.vue'
// import IconBaseStrech from '../components/IconBaseStrech.vue'
import Mapa from '../components/Mapa.vue'
import MapaBanner from '../components/MapaBanner.vue'
import ApiService from '../services/ApiService';
import Slider from '../components/Slider.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
    DefaultLayout,
    IconBase,
    IconCasttle,
    IconPalace,
    // IconRealPalace,
    // IconWalledEnclosure,
    // IconMonastery,
    IconRoutes,
    // IconArt,
    // IconBaseStrech,
    // Card,
    Mapa,
    Slider,
    HomeLayout2Vue,
    MapaBanner
  },
  data(){
    return{
      slide:0,
      sliding:null,
      idiomaIndex: 0,
      width: window.innerWidth,
      // Código para el video que se muestra en el inicio
      videoId: "wR1iYYlnliw",
      playerVars: {
        autoplay:1, 
        mute:1,
        controls:0,
        showInfo:0,
        modestBranding:true,
        rel:0,
        loop:1
      },
      experiencias:[],
      experienciasClusters:[],
      eventos:[],
      eventosClusters:[],
      // De este valor depende cuantas cards se mostrarán
      clusterSize:4
    }
  },
  methods:{
    goToExperiences(){
      let $vm = this;
      $vm.$router.push('/experiencias-exclusivas');
    },
    goToEventos(){
      let $vm = this;
      $vm.$router.push('/eventos-singulares');
    },
    goToEntrada(event){
      // console.log(event);
      let $vm = this;
      // let castilloNombre = null;
      // $vm.eventos.map( evento => {
      //   if(evento.visitaId == event){
      //     castilloNombre = evento.castilloNombre;
      //   }
      // })
      console.log(event);
      $vm.$router.push('/castillos/tarifas/'+event.monumentoUrl+'/'+event.entradaId);
    },
    // onSlideStart(slide) {
    //   // slide = slide;
    //     this.sliding = true
    //   },
    //   onSlideEnd() {
    //     this.sliding = false
    //     // slide=slide;
    //   }
  },
  created(){
    let $vm = this;

    // console.log($vm.width);
        if($vm.width > 1500){
          $vm.clusterSize = 4;
        }

        else if($vm.width > 1200){
          $vm.clusterSize = 3;
        }

        else if($vm.width >= 768){
          $vm.clusterSize = 2
        }

        else{
          $vm.clusterSize = 1;
        }

    ApiService.getEventosProximos("es")
      .then( result => {
        $vm.eventos = [...result.data];

        $vm.eventos.sort( function(a,b){
          if(b.fechas.length > 0 && a.fechas.length > 0){
            return new Date(a.fechas[0]) - new Date(b.fechas[0])
          }
          else if(b.fechas.length > 0 && a.fechas.length == 0){
            return 1;
          }
          else if(b.fechas.length == 0 && a.fechas.length > 0){
            return -1;
          }
          else if(b.fechas.length == 0 && a.fechas.length == 0){
            if(a.fechaInicioEvento === undefined && b.fechaInicioEvento ){
                return 1;
            }
            else if(a.fechaInicioEvento && b.fechaInicioEvento){
                return  new Date( a.fechaInicioEvento ) - new Date( b.fechaInicioEvento );

            }
            else{
                return -1;
            }
          }
          else{
            return 0;
          }
        })

        let eventosLength = $vm.eventos.length;

        let numberOfClusters = (eventosLength/$vm.clusterSize)

        for(let index = 0; index < numberOfClusters; index++){
          let eventoCluster = [];
          for(let i=0; i< this.clusterSize; i++){
            let tempEvent = $vm.eventos[i + (index * this.clusterSize)]
            if(tempEvent != undefined)
              eventoCluster.push(tempEvent);
          }

          if( eventoCluster.length < this.clusterSize ){
            let eventosRestantes = this.clusterSize - eventoCluster.length;
            // console.log(eventosRestantes);
            for(let i=0; i< eventosRestantes; i++){
              let tempEvent = $vm.eventos[i]
              if(tempEvent != undefined)
                eventoCluster.push(tempEvent);
            }
          }
          $vm.eventosClusters.push(eventoCluster);
        }

        // console.log($vm.eventosClusters);
        // console.log($vm.eventos);
      })
      .catch(err => {
        console.log(err);
      })

    ApiService.getExperienciasProximas("es")
      .then( result => {
        $vm.experiencias = [...result.data];

        let experienciasLength = $vm.experiencias.length;
        
        let numberOfClusters = (experienciasLength/$vm.clusterSize)

        for(let index = 0; index < numberOfClusters; index++){
          let experienciaCluster = [];
          for(let i=0; i< this.clusterSize; i++){
            let tempExp = $vm.experiencias[i + (index * this.clusterSize)]
            if(tempExp != undefined)
              experienciaCluster.push(tempExp);
          }
          // Revisamos que todos los grupos de experiencias tengan el mismo tamaño
          if( experienciaCluster.length < this.clusterSize ){
            let experienciasRestantes = this.clusterSize - experienciaCluster.length;
            // console.log(experienciasRestantes);
            // Agregamos el número de experiencias que hagan falta
            for(let i=0; i< experienciasRestantes; i++){
              let tempExp = $vm.experiencias[i]
              if(tempExp != undefined)
                experienciaCluster.push(tempExp);
            }
          }

          $vm.experienciasClusters.push(experienciaCluster);
        }
      })
      .catch(err => {
        console.log(err);
      })

  }
}
</script>

<style scoped>
  @media(max-width:1440px){
    .spainhm-main .spainhn-text{
        font-size: 16px;
    }
  }
  @media(max-width:1400px){
   .py-xxl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
   @media(max-width:991px){
    .spainhm-main .border-bottom{
      border-bottom: 0 !important;
    }
   }
  @media(max-width:767px){
    .spainhm-main .py-xxl-5{
      padding-left: 20px;
      padding-right: 20px;
    }
    .py-xxl-5 {
      padding-top: 1rem !important;
      padding-bottom:1rem !important;
    }
    .spainhn-cards{
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
    .mess-box h4{
      font-size: 17px;
    }   
    .slider-cards .spainhn-button-gray{
      margin-left: 0 !important;
      font-size: 18px;
    }
  }
</style>
