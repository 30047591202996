var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card mx-2 py-2", style: { backgroundImage: _vm.image } },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("strong", { staticClass: "d-block spainhn-text" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("small", { staticClass: "spainhn-small-text d-block" }, [
              _vm._v(_vm._s(_vm.monument)),
            ]),
            _c(
              "strong",
              {
                staticClass: "d-block spainhn-text",
                class: { "d-none": !_vm.evento },
              },
              [_vm._v(_vm._s(_vm.fechaEvento))]
            ),
            _c(
              "strong",
              {
                staticClass: "d-block spainhn-text",
                class: {
                  "d-none": !_vm.evento || (_vm.fechaEvento && !_vm.proxDate),
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.proxDate
                      ? new Date(_vm.proxDate).toLocaleDateString("es-ES", {
                          timeZone: "UTC",
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : null
                  )
                ),
              ]
            ),
            _c(
              "mdb-btn",
              {
                staticClass: "spainhn-button mt-2",
                class: { "d-none": _vm.experiencia },
                attrs: { color: "indigo", size: "sm" },
                on: { click: _vm.goToEntrada },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                    class: { "d-none": !_vm.ventaDisponible },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-auto" },
                      [
                        _c(
                          "icon-base",
                          { attrs: { height: 35, width: 35 } },
                          [_c("icon-carrito")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("default.SPAINHN_BUTTON_ENTRADAS"))),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                    class: { "d-none": _vm.ventaDisponible },
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("default.PROXIMAMENTE_TEXT"))),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "mdb-btn",
              {
                staticClass: "spainhn-button mt-2",
                class: { "d-none": !_vm.experiencia },
                attrs: { color: "indigo", size: "sm" },
                on: { click: _vm.goToEntrada },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-auto me-2" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "fa-calendar-check", size: "2x" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "my-auto" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("default.SPAINHN_BUTTON_EXPERIENCES"))
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }