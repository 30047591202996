var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      { staticClass: "container-fluid px-3 px-md-5 singular-main" },
      [
        _c(
          "div",
          {
            staticClass: "spainhn-solid-red-background d-flex py-3",
            staticStyle: {
              height: "auto",
              "min-height": "75px",
              width: "100%",
            },
          },
          [
            _c(
              "p",
              { staticClass: "my-auto ps-4 spainhn-subtitle-2 text-white" },
              [_vm._v(_vm._s(_vm.$t("default.HOME_NEXT_EVENTS_TITLE")))]
            ),
          ]
        ),
        _c("div", { staticClass: "d-flex justify-content-around pt-4 mb-3" }, [
          _c("div", { staticClass: "d-flex", staticStyle: { width: "100%" } }, [
            _c(
              "div",
              { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
              [
                _c("small", { staticClass: "mb-0 spainhn-subtitle-4 mt-4" }, [
                  _vm._v(_vm._s(_vm.$t("default.ROUTES_FILTER_TEXT"))),
                ]),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "d-lg-flex mb-5" },
          [
            _c("b-select", {
              staticClass: "mt-2 spainhn-custom-input me-lg-3 w-100",
              attrs: { options: _vm.comunidadesAutonomas },
              on: { change: _vm.filterMonuments },
              model: {
                value: _vm.localizacionFilter,
                callback: function ($$v) {
                  _vm.localizacionFilter = $$v
                },
                expression: "localizacionFilter",
              },
            }),
            _c("b-select", {
              staticClass:
                "mt-2 spainhn-custom-input mx-lg-1 w-100 overflow-hidden",
              attrs: { options: _vm.rutasOptionsFiltered },
              on: { change: _vm.filterMonuments },
              model: {
                value: _vm.rutaFilter,
                callback: function ($$v) {
                  _vm.rutaFilter = $$v
                },
                expression: "rutaFilter",
              },
            }),
            _c("b-select", {
              staticClass: "mt-2 spainhn-custom-input ms-lg-3 w-100",
              attrs: { options: _vm.sortMonumentos },
              on: { change: _vm.filterMonuments },
              model: {
                value: _vm.castilloFilter,
                callback: function ($$v) {
                  _vm.castilloFilter = $$v
                },
                expression: "castilloFilter",
              },
            }),
          ],
          1
        ),
        _c("b-spinner", {
          staticClass: "d-flex mx-auto",
          class: { "d-none": !_vm.loading },
          attrs: { variant: "danger" },
        }),
        _c("div", { staticClass: "d-flex mb-5" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
            },
            _vm._l(_vm.eventosFiltered, function (evento, index) {
              return _c("cards", {
                key: index,
                attrs: {
                  tipo: _vm.tipo,
                  title: evento.nombres[_vm.selectedIdiomaIndex]?.texto,
                  entradaId: evento?._id,
                  monument:
                    evento.castilloNombres[_vm.selectedIdiomaIndex]?.texto,
                  monumentoNombreURL: evento.castilloNombres[0]?.texto,
                  imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.banner}`,
                  date: evento.fechas,
                  proxDate: evento.fechaInicioEvento,
                  role: "button",
                },
                on: {
                  goToEntrada: function ($event) {
                    return _vm.goToEntrada($event)
                  },
                },
              })
            }),
            1
          ),
        ]),
        _c("ComponentsCloud"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }