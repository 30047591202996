<template>
    <div class="card mx-2 py-2" v-bind:style="{backgroundImage: image}">
        <div class="container">
          <div class="card-body">
            <strong class="d-block spainhn-text">{{title}}</strong>
            <small class="spainhn-small-text d-block">{{monument}}</small>
            <strong class="d-block spainhn-text" v-bind:class="{ 'd-none':!evento }">{{fechaEvento}}</strong>
            <strong class="d-block spainhn-text" v-bind:class="{ 'd-none': ( !evento || (fechaEvento && !proxDate )) }">{{proxDate ? ( new Date( proxDate ).toLocaleDateString('es-ES', {timeZone: 'UTC', month:"2-digit", day:"2-digit", year:"numeric"}) ):null }}</strong>
            <mdb-btn color="indigo" class="spainhn-button mt-2" size="sm" @click="goToEntrada" v-bind:class="{'d-none':experiencia}">
              <div class="d-flex justify-content-center align-items-center" v-bind:class="{'d-none':!ventaDisponible}">
                <div class="my-auto">
                  <icon-base :height="35" :width="35">
                      <icon-carrito/>
                  </icon-base>
                </div>
                <strong>{{$t('default.SPAINHN_BUTTON_ENTRADAS')}}</strong>
              </div>
              <div class="d-flex justify-content-center align-items-center" v-bind:class="{'d-none':ventaDisponible}">
                <strong>{{$t('default.PROXIMAMENTE_TEXT')}}</strong>
              </div>
            </mdb-btn>

            <mdb-btn color="indigo" class="spainhn-button mt-2" size="sm" @click="goToEntrada" v-bind:class="{'d-none':!experiencia}">
              <div class="d-flex justify-content-center align-items-center">
                <div class="my-auto me-2">
                  <font-awesome-icon icon='fa-calendar-check' size="2x"></font-awesome-icon>
                </div>
                <div class="my-auto">
                  <strong>{{$t('default.SPAINHN_BUTTON_EXPERIENCES')}}</strong>
                </div>
              </div>
            </mdb-btn>
          </div>
        </div>
    </div>
</template>

<script>

import IconCarrito from '../components/icons/IconCarrito.vue';
import IconBase from '../components/IconBase.vue';
export default {
  components:{
    IconCarrito,
    IconBase
  },
  props:{
    imageUrl: String,
    title: String,
    monument: String,
    date: Array,
    entradaId: String,
    monumentoNombreURL: String,
    tipo:String,
    proxDate: String
  },
  data(){
    return{
      // image: `url(${require('@/assets/img_avatar.png')})`
      
      // image: `url(${encodeURI(this.imageUrl)})`,
      // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
    }
  },
  setup() {
      
  },
  methods:{
    goToEntrada(){
      this.$emit('goToEntrada', {entradaId:this.entradaId, monumentoUrl: this.monumentoNombreURL})
    }
  },
  mounted(){
    // let $vm = this;
    // console.log(this.date)
    // let $vm = this;
    // console.log(this.tipo);
  },
  computed: {
    image(){
      let urlEnconded = encodeURI(this.imageUrl)
      return `url(${urlEnconded})`;

    },
    ventaDisponible(){
      if(this.tipo == 'Evento'){
        if(this.date){
          if(this.date.length > 0){
            return true;
          }
          else{
            return false;
          }
        }
        else{
          return false;
        }
      }
      else{
        return true;
      }
    },  
    evento(){
      if(this.tipo == 'Evento'){
        // console.log("Es evento")
        return true;
      }
      else{
        return false;
      }
    },
    experiencia(){
      if( this.tipo == "Experiencia"){
        return true;
      }
      else{
        return false;
      }
    },
    fechaEvento(){
      if(this.date != null && this.date != undefined){
        if(this.date.length == 0){
          return "";
        }
        else{
          let fechasString = "";

          let tempFechas = this.date;

          tempFechas.sort( function(a, b){
              return new Date(a) - new Date(b)
          })
          
          if(this.date.length > 1){
            fechasString = new Date(tempFechas[0]).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'}) + " - ";
            let lastFecha = new Date(tempFechas[ tempFechas.length - 1] ).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'});
            fechasString += lastFecha;
            return fechasString;
          }

          else{
            tempFechas.forEach( date => {
              fechasString += new Date(date).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'})
            })

            return fechasString;
          }
          // fechasString = this.fecha.reduce( (acc, prev) => acc + ',' + new Date(prev).toLocaleDateString() )
        }
      }
      else{
        return ""
      }
    }
  }
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  /* height:55vh;  */
  /* width:45%; */
  height:30rem;
  width:20rem;
  min-width: 250px;

  max-width: 85vw;
  position:relative;
}

img {
    border-radius: 10px 1px 0 0;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  border-radius: 10px;
}

.card-body{
  height: 40%;
  padding: 0% 10%;
  padding-bottom: 2%;
  padding-top: 5%;
  background-color: rgba(0,0,0,0.7);
  color:white;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
  /* -ms-flex-item-align:end; */
}
</style>