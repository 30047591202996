var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card my-auto mx-2 mt-2",
      style: { backgroundImage: _vm.image },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "card-body" }, [
          _c(
            "small",
            {
              staticClass: "m-0 position-absolute text-center w-auto",
              staticStyle: {
                top: "5%",
                left: "20%",
                transform: "translateX(-50%)",
              },
            },
            [
              _c("mdb-badge", { staticClass: "buy-ticket" }, [
                _vm._v(_vm._s(_vm.precioReferencia)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "overflow-hidden d-flex pt-auto",
              staticStyle: { height: "150px" },
            },
            [
              _c("strong", { staticClass: "mt-auto mx-auto spainhn-text" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]
          ),
          _c(
            "small",
            {
              staticClass:
                "spainhn-small-text d-block text-decoration-underline",
            },
            [_vm._v(_vm._s(_vm.monument))]
          ),
          _c(
            "strong",
            {
              staticClass: "d-block spainhn-text",
              class: { "d-none": !_vm.evento },
            },
            [_vm._v(_vm._s(_vm.fechaEvento))]
          ),
          _c(
            "strong",
            {
              staticClass: "d-block spainhn-text",
              class: { "d-none": !_vm.evento || _vm.fechaEvento != "" },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.proxDate
                    ? new Date(_vm.proxDate).toLocaleDateString("es-ES", {
                        timeZone: "UTC",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    : ""
                )
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "mdb-btn",
                {
                  staticClass:
                    "spainhn-button spainheritage-background mt-1 mb-2 mx-auto align-items-center d-flex px-lg-2",
                  attrs: { color: "indigo", size: "sm" },
                  on: { click: _vm.goToEntrada },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center my-auto",
                      class: { "d-none": !_vm.ventaDisponible },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "me-1 my-auto" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "fa-plus" },
                          }),
                        ],
                        1
                      ),
                      _c("strong", { staticClass: "spainhn-text my-auto" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("default.SPAINHN_BUTTON_MORE_INFO")) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                      class: { "d-none": _vm.ventaDisponible },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("default.PROXIMAMENTE_TEXT"))),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "mdb-btn",
                {
                  staticClass:
                    "spainhn-button mt-1 mb-2 mx-auto align-items-center d-flex",
                  class: { "d-none": _vm.experiencia },
                  attrs: { color: "indigo", size: "sm" },
                  on: { click: _vm.goToBuy },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center my-auto",
                      class: { "d-none": !_vm.ventaDisponible },
                    },
                    [
                      _c(
                        "div",
                        {},
                        [
                          _c(
                            "icon-base",
                            { attrs: { height: 25, width: 25 } },
                            [_c("icon-carrito")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("strong", { staticClass: "spainhn-text my-auto" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("default.SPAINHN_BUTTON_BUY_TICKET")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                      class: { "d-none": _vm.ventaDisponible },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("default.PROXIMAMENTE_TEXT"))),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "mdb-btn",
                {
                  staticClass: "spainhn-button mt-1 mb-2 mx-auto",
                  class: { "d-none": !_vm.experiencia },
                  attrs: { color: "indigo", size: "sm" },
                  on: { click: _vm.goToBuy },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "my-auto me-1" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "fa-calendar-check" },
                          }),
                        ],
                        1
                      ),
                      _c("strong", { staticClass: "spainhn-text my-auto" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("default.SPAINHN_BUTTON_EXPERIENCES")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }