<template>
    <default-layout>
        <!-- <div class="spainhn-solid-red-background d-flex" style="min-height:5vh; width:100%">
                <div class="menu-breadcrumbs">
                    <p class="my-auto pb-0 ms-2 spainhn-small-text" role="button" @click="goToCastillos" v-html="`${monumentoNombre} >>`"></p>
                    <p class="my-auto pb-0 ms-2 spainhn-small-text" role="button" @click="goToEntradas">{{tipoDeEntrada}}</p><span> >></span>
                    <p class="my-auto pb-0 ms-2 spainhn-small-text" >{{nombre[selectedIdiomaIndex]?.texto }}</p>
                </div>
        </div> -->

        <SpainhnNavigationBreadcrumb :navigation-links="links"/>

        <div id="loader" :class="{'d-none': !movingToCart}" class="loader-component">
            <img src="../assets/SimboloCastilloRojoCorporativa.svg" style="height:220px; width:auto;" class="animated flip infinite"/>
            <!-- <h5>Cargando...</h5> -->
        </div>

        <!-- Welcome header, don't use it on mobile -->
        <div class="spainhn-bg-gris-cajas w-100 margen-estandard d-none d-md-inline-block" style="min-height:10vh;">
            <div class="pt-4 row ps-lg-5 ps-0">
                <div class="col-md-7 col-12 ps-md-3 ps-4 pe-4">
                    <p class="spainhn-text ms-auto mt-3 me-0 text-start">{{ $t('default.TICKET_DETAILS_WELCOME_1')}} 
                        <b>{{micrositeData.webName}}</b> 
                        {{ $t('default.TICKET_DETAILS_WELCOME_2')}} <span v-html="monumentName"></span>. 
                        {{ $t('default.TICKET_DETAILS_WELCOME_3')}}</p>
                </div>
                <div class="col-md-5 align-content-center col-12">
                <div class="d-flex justify-content-center align-content-center">
                    <p class="spainhn-subtitle ms-auto my-auto" style="font-size:15pt"> <span v-bind:class="{'d-none':false}">{{$t('default.SPAINHN_DESDE')}}</span> {{precioOrientativo}} €</p>

                    <!-- <buy-component /> -->
                    <mdb-btn color="indigo" class="spainhn-button me-lg-0 me-auto ms-3" size="sm" @click="goToCarrito">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="my-auto">
                                <icon-base :height="40" :width="40">
                                    <icon-carrito/>
                                </icon-base>
                            </div>
                            <strong class="spainhn-text" v-bind:class="{'d-none':experiencia}">
                                {{ $t('default.SPAINHN_BUTTON_BUY_TICKET')}}
                            </strong>
                            <strong class="spainhn-text" v-bind:class="{'d-none':!experiencia}">
                                {{ $t('default.SPAINHN_BUTTON_EXPERIENCES')}}
                            </strong>
                        </div>
                    </mdb-btn>
                </div>
                </div>
            </div>
        </div>


        <div class="container-fluid px-3 px-md-5 mb-4">

            <div class="row">
                <div class="pt-3 col-xxl-8 col-12">
                    <div class="row">
                        <div class="text-start spainhn-text pt-0 pe-0 mb-1 mb-md-5 col-12 px-0" 
                            style="height:100%" v-bind:class="{'d-none':clorian}">
                            <!-- <h5 class="mb-3"><strong>{{ $t('default.TICKET_DETAILS_IMAGES')}}</strong></h5> -->
                            <b-carousel
                                id="monumentosShow"
                                v-model="slide"
                                :interval="4000"
                                controls
                                indicators
                                img-width="940"
                                img-height="528"
                                style="text-shadow: 1px 1px 2px #333;"
                                @sliding-start="onSlideStart"
                                @sliding-end="onSlideEnd"
                            >
                                <b-carousel-slide v-for="(imagen, index) in imagenes" v-bind:key="index">
                                    <template #img>
                                        <div class="mb-3 d-flex mx-auto carousel-homeslide px-0 mx-0" style="height:344px; width:940px; max-width: 100%;">
                                            <img
                                                class="my-md-auto mx-auto carousel-spainhn"
                                                style="height:auto; width:auto; max-width:940px; max-height:344px; display: block; object-fit: cover"
                                                :src="`${imagenesUrl}/visitas_castillos/${visitaOldId}/${imagen.name}`"
                                                :alt="imagen.altText"
                                            >
                                        </div>
                                    </template>
                                </b-carousel-slide>
                                <a class="carousel-control-prev" aria-controls="monumentosShow___BV_inner_" role="button" data-slide="prev">
                                    <!-- INSERT MY OWN PREV ICON USING FONT AWESOME  -->
                                    <font-awesome-icon icon="fa-circle" size="3x" class="text-black"></font-awesome-icon>
                                    <span class="sr-only">Previous</span>
                                </a>

                                <a class="carousel-control-next" role="button" data-slide="next" aria-controls="monumentosShow___BV_inner_">
                                    <!-- INSERT MY OWN PREV ICON USING FONT AWESOME  -->
                                    <font-awesome-icon icon="fa-circle" size="3x" class="text-black"></font-awesome-icon>
                                    <span class="sr-only">Previous</span>
                                </a>

                            </b-carousel>

                            <div class="overflow-hidden mx-auto d-md-block d-none" style="height:94.5px; width:940px; max-width:100%; position:relative;" id="imageSelectorContainer">
                                <div class="d-flex thumbnails-container" style="height:94.5px; width:940px; max-width:72vw; position:relative;" ref="imageSelector" id="imageSelector">
                                    <div style="height:94.5px; width:140px;" class="thumbnail-slide" v-for="(imagen, index) in imagenes" v-bind:key="index">
                                        <img role="button" @click="changeSlide(index)"
                                            class="px-1"
                                            style="height:100%; width:140px; object-fit: cover;  border-radius: 15px;"
                                            :src="`${imagenesUrl}/visitas_castillos/${visitaOldId}/${imagen.name}`"
                                            :alt="imagen.altText">                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div v-bind:class="{'d-none':!clorian}">
                            <div class="mb-3 d-flex mx-auto" style="height:528px; width:940px; max-width: 100%;" v-for="(imagen, index) in imagenes" v-bind:key="index">
                                <img
                                    class="d-block mx-auto my-auto carousel-spainhn"
                                    style="height:528; width:auto"
                                    :src="imagen"
                                    alt="image slot"
                                >
                            </div>

                        </div>

                        <div class="col-12">
                            <div class="d-flex justify-content-around pt-0 pt-md-2 mb-0 px-lg-2 px-md-0 mx-auto">
                                <div class="d-flex px-lg-4 align-middle pb-2" style="width:100%; position: relative; min-height: 54px; position: relative;">

                                    <div class="ms-0 d-flex flex-column text-start me-md-auto me-lg-0 mt-1 me-1">
                                        <p class="mb-0 pb-0 spainhn-header-info">{{ micrositeData.webName }}</p>
                                        <small class="mb-0 mt-0 spainhn-subheader-info text-dark pt-0">{{monumentName}}</small>
                                    </div>

                                    <buy-component @goTo="() => goToBuy()" :tipo="tipoDeEntrada"/>
                                </div>
                            </div>

                            <div class="w-100 border-bottom mt-0 mb-1" style="height:2vh; border-color:var(--spainhn-rojo-corporativo) !important;"></div>

                            <div class="text-start spainhn-text pt-0">
                                <div class="html-spacing mb-1" v-bind:class="{'html-container': !showFullText }">
                                    <div v-html="micrositeData.webDescription" class="">
                                    </div>
                                </div>

                                <div class="d-flex mx-auto my-2">
                                    <button class="read-more-btn spainhn-gray-background mx-auto mb-1" @click="() => toggleFullText()">
                                        <span v-bind:class="{'d-none': showFullText }">{{ $t("default.BUTTONS.READ_MORE") }}</span>
                                        <span v-bind:class="{'d-none': !showFullText }">{{ $t("default.BUTTONS.READ_LESS") }}</span>
                                    </button>
                                </div>

                            </div>

                            <!-- <div class="text-start spainhn-text ">
                                <div class="spainh-text"><span v-html="descripcion[selectedIdiomaIndex]?.texto"></span></div>
                            </div> -->
                            <!-- <h2 class="mb-0 spainhn-subtitle-black"><strong>{{nombre[selectedIdiomaIndex]?.texto}}</strong></h2> -->
                        </div>

                        <div class="col-12 d-md-inline-block d-none">
                            <div class="mt-3">
                                <h2 class="spainhn-sections-title text-start fw-bold mb-0 pb-0 pt-2">{{ $t('default.MICROSITES_SCHEDULE') }}</h2>
                                <div class="w-100 border-bottom mt-0 pt-0 mb-1" style="height:2vh; border-color:rgb(242,90,85) !important;"></div>

                                <div class="spinhn-text text-start text-columns">

                                    <p v-html="micrositeData.webSchedule"></p>
                                </div>
                            </div>
                        </div>

                        <mdb-container class="d-md-none">
                            <collapsable-element 
                                v-if="micrositeData.webSchedule" 
                                :title="$t('default.MICROSITES_SCHEDULE')" class="my-2" 
                                :content="`<div style='font-size:12px;'>${micrositeData.webSchedule}</div>`"/>

                            <collapsable-element 
                                v-if="micrositeData.webDetails" 
                                :title="$t('default.TICKET_DETAILS_DETAILS')" class="my-2" 
                                :content="`<div style='font-size:12px;'>${micrositeData.webDetails}</div>`">

                                <div class="w-100 border-bottom mt-auto mb-3" style="height:2vh; border-color:rgb(0,0,0) !important;"></div>
                                <h4 class="spainhn-sections-title">{{micrositeData.webName}}</h4>
                                <h4 class="spainhn-sections-title"><span v-bind:class="{'d-none':!true}">{{$t('default.SPAINHN_DESDE')}}</span> {{precioOrientativo}} €</h4>
                                <mdb-btn color="indigo" class="spainhn-button mt-1 mb-2 mx-auto" size="sm" @click="goToCarrito">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="my-auto">
                                            <icon-base :height="50" :width="50">
                                                <icon-carrito/>
                                            </icon-base>
                                        </div>
                                        <strong class="spainhn-sections-title" v-bind:class="{'d-none':experiencia}">
                                            {{ $t('default.SPAINHN_BUTTON_BUY_TICKET')}}
                                        </strong>
                                        <strong class="spainhn-sections-title" v-bind:class="{'d-none':!experiencia}">
                                            {{ $t('default.SPAINHN_BUTTON_EXPERIENCES')}}
                                        </strong>
                                    </div>
                                </mdb-btn>                                              

                            </collapsable-element>

                            <collapsable-element 
                                v-if="micrositeData.webDiscounts" 
                                :title="$t('default.TICKET_DETAILS_DISCOUNTS')" class="my-2" 
                                :content="`<div style='font-size:12px;'>${micrositeData.webDiscounts}</div>`"/>
                        </mdb-container>
                    </div>
                    
                </div>
                
                <div class="d-md-flex flex-column pt-3 px-3 col-xxl-4 d-none">
                    <div class="row">

                        <div class="col-12 col-lg-6 col-xxl-12">

                            <div class="h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column text-start" style="border-radius:10px;">
                                <h4 class="mb-3"><strong>{{$t('default.TICKET_DETAILS_DETAILS')}}</strong></h4>
                                <span v-html="micrositeData.webDetails"></span>
                                <div class="w-100 border-bottom mt-auto mb-3" style="height:2vh; border-color:rgb(0,0,0) !important;"></div>
                                <h4 class="spainhn-subtitle">{{micrositeData.webName}}</h4>
                                <h4 class="spainhn-subtitle"><span v-bind:class="{'d-none':!true}">{{$t('default.SPAINHN_DESDE')}}</span> {{precioOrientativo}} €</h4>
                                <mdb-btn color="indigo" class="spainhn-button mt-1 mb-2 mx-auto" size="sm" @click="goToCarrito">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="my-auto">
                                            <icon-base :height="50" :width="50">
                                                <icon-carrito/>
                                            </icon-base>
                                        </div>
                                        <strong class="spainhn-text" v-bind:class="{'d-none':experiencia}">
                                            {{ $t('default.SPAINHN_BUTTON_BUY_TICKET')}}
                                        </strong>
                                        <strong class="spainhn-text" v-bind:class="{'d-none':!experiencia}">
                                            {{ $t('default.SPAINHN_BUTTON_EXPERIENCES')}}
                                        </strong>
                                    </div>
                                </mdb-btn>                                              
                            </div>
                        </div>

                        <div class="col-12 pt-3 col-lg-6 col-xxl-12" v-bind:class="{'d-none':clorian}">
                            <div class="h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column text-start" style="border-radius:10px;">
                                <h4 class="mb-3"><strong>{{ $t('default.TICKET_DETAILS_DISCOUNTS')}}</strong></h4>
                                <span v-html="micrositeData.webDiscounts"></span>                                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column pt-3 col-12" v-if="eventosRecomendados?.length > 0"> 
            <span class="spainhn-sections-title text-uppercase">{{ $t('default.TICKET_DETAILS_MORE') }}</span>
            <div class="d-flex flex-wrap justify-content-center px-0" style="column-gap: 15px;">
                <!-- Poner aquí las visitas -->
                <recommendations-card v-for="(evento, index) in eventosRecomendados" v-bind:key="index"
                    class="my-2"
                    :title="evento.webName"
                    :nombreUrl="evento.visitaId"
                    :fecha="evento.fechas"
                    @goTo="goToEntrada"
                    :tipo="'Entrada'"
                    :imageUrl="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`"
                    v-bind:class="{'d-none':index > 2}"
                />
            </div>
        </div>

        <div class="spainhn-bg-gris-cajas w-100" style="min-height:10rem;">
            <div class="pt-4 row ps-md-5 ps-0">
                <div class="col-md-7 col-12 ps-md-5 ps-4 pe-4">
                    <p class="spainhn-text ms-auto mt-3 me-0 text-start">{{ $t('default.TICKET_DETAILS_WELCOME_1')}} <b>{{micrositeData.webName}}</b> 
                        {{ $t('default.TICKET_DETAILS_WELCOME_2')}} <span v-html="monumentoNombre"></span>. {{ $t('default.TICKET_DETAILS_WELCOME_3')}}</p>
                </div>
                <div class="col-md-5 col-12">
                <div class="d-flex justify-content-center align-content-center pb-2">
                    <p class="spainhn-subtitle my-auto" style="font-size:15pt;">{{$t('default.SPAINHN_DESDE')}} {{precioOrientativo}} €</p>
                    <mdb-btn color="indigo" class="spainhn-button mt-1 mb-2 ms-3" size="sm" @click="goToCarrito">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="my-auto">
                                <icon-base :height="50" :width="50">
                                    <icon-carrito/>
                                </icon-base>
                            </div>
                            <strong class="spainhn-text" v-bind:class="{'d-none':experiencia}">
                                {{ $t('default.SPAINHN_BUTTON_BUY_TICKET')}}
                            </strong>
                            <strong class="spainhn-text" v-bind:class="{'d-none':!experiencia}">
                                {{ $t('default.SPAINHN_BUTTON_EXPERIENCES')}}
                            </strong>

                        </div>
                    </mdb-btn>                                             
                </div>
                </div>
            </div>
        </div>

        <welcome-modal 
            :popup-start="showWelcome" 
            :title="welcomeTitle" 
            :subtitle="welcomeSubtitle"
            :image-url="null"
            @close="showWelcome = false"
            :color="'danger'"/>

    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import IconBase from '../components/IconBase.vue'
import IconCarrito from '../components/icons/IconCarrito.vue';
import Cards from '../components/Cards.vue';
import ClorianService from '../services/ClorianService';
import WelcomeModal from '../components/WelcomeModal.vue';
// import IconCasttle from '../components/icons/IconCasttle.vue';
import SpainhnNavigationBreadcrumb from '../components/SpainhnNavigationBreadcrumb'
import BuyComponent from '../components/BuyComponent';
import RecommendationsCard from '../components/RecommendationsCard'

import CollapsableElement from '../components/CollapsableElement'
export default {
    components:{
        DefaultLayout,
        IconBase,
        IconCarrito,
        // Cards,
        WelcomeModal,
        SpainhnNavigationBreadcrumb,
        CollapsableElement,
        RecommendationsCard,
        BuyComponent
        // IconCasttle,
        // Cards
    },
    data(){
        return{
            address: null,
            // monumentoNombre: null,
            monumentoNombres: [],
            monumentoUrl: null,
            monumentoId: null,
            entradaDetalle: [],
            visitaId: null,
            tipoDeEntrada: null,
            nombre: [],
            descripcion: [],
            detalles: [],
            precioOrientativo:null,
            descuentos: [],
            horarios: [],
            imagenes:[],
            slide:0,
            sliding:null,
            activeImage: 0,
            visitaOldId: null,
            idiomaIndex:0,
            entradasRecomendadas:[],
            imagenesUrl: null,
            desdeOn: false,
            clorian: false,
            clorianPos: null,
            eventosRecomendados: [],
            entradaId: null,
            welcomeData: {
                active: false,
            },
            welcomeMessages: [],
            welcomeMessage: {
                title: null,
                subtitle: null
            },
            showWelcome: false,
            links: [
                { text: null },
                { text: null}
            ],
            ubicacion:{
                provincia: null,
                comunidadAutonoma: null
            },
            showFullText: false,
            apiDeVentasActive: false,
            apiDeVentasUrl: "",
            movingToCart: false,
            monumentName: "",
            micrositeData: { 
                webName: "",
                webDescription: "",
                details: "",
                discount: "",
                schedules: "",
                webDiscounts: "",
                webDetails: "",
                webSchedule: "",

            }
        }
    },
    methods:{

        goToContactForm(){
            this.$router.push({ name: 'contactReserva', query: {
                monumento: this.monumentoNombre,
                tipo: this.tipoDeEntrada,
                visita: this.nombre[(this.selectedIdiomaIndex) ?? 0]?.texto
            }})
        },
        goToBuy(){

            if( this.apiDeVentasUrl !== 'clorian' && this.apiDeVentasActive ){
                this.$toast.success( this.$t("default.NOTIFICATIONS.MONUMENT_REDIRECT") );
                this.movingToCart = true;
                setTimeout( () => {
                    window.open(`${this.apiDeVentasUrl}?idProducto=${this.visitaOldId}`, '_blank');
                    this.movingToCart = false;
                }, 2000 )
            }else if( this.tipoDeEntrada === 'Experiencia' ){
                this.goToContactForm();
            }else{
                this.$router.push(`/castillos/reservar/idvisita:${this.entradaId}`)
            }
        },
        toggleFullText(){
            if( this.showFullText )
                this.showFullText = false;
            else
                this.showFullText = true;
        },
        goToCarrito(){
            let $vm = this;

            if($vm.clorian){
                $vm.$router.push(`/castillos/reservar/idvisita:${$vm.entradaId}?monumento=${$vm.monumentoNombres[0].texto}&pos=${$vm.clorianPos}`);
            }
            else{
                if( this.apiDeVentasUrl !== 'clorian' && this.apiDeVentasActive ){
                    this.$toast.success( this.$t("default.NOTIFICATIONS.MONUMENT_REDIRECT") );
                    this.movingToCart = true;
                    setTimeout( () => {
                        window.open(`${this.apiDeVentasUrl}?idProducto=${this.visitaOldId}`, '_blank');
                        this.movingToCart = false;
                    }, 2000 )
                }
                else if( this.tipoDeEntrada === 'Experiencia'){
                    this.goToContactForm();
                }else{
                    $vm.$router.push('/castillos/reservar/idvisita:'+$vm.entradaId);            
                }
            }
        },
        prevImage(){
            let $vm = this;
            if($vm.activeImage == 0){
                $vm.activeImage = $vm.imagenes.length;
            }
        },
         goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        changeSlide(index){
            let $vm = this;
            $vm.slide = index;
            let numeroDeImagenes = $vm.imagenes.length;
            let totalWidth = numeroDeImagenes * 150;
            let tempPosition = $vm.thumbnailsPosition;
            let containerWidth = $vm.$refs.imageSelector.clientWidth
            let distanciaMenor = index - 0;
            let distanciaMayor = numeroDeImagenes - index;

            let pixelsToMove = 0;
            if(index % 2){
                pixelsToMove = 120 * index;
            }
            else{
                pixelsToMove = 150* index;
            }
            // let pixelsToMove = 120 * index;
            let steps = Math.abs(index - tempPosition);
            console.log(steps);
            if(steps == 0){
                pixelsToMove = 0;
            }
            let posicionesDisponibles = Math.abs(index - numeroDeImagenes)
            console.log(pixelsToMove)
            // console.log(index)

            if((posicionesDisponibles * 300) > containerWidth){

                if(tempPosition > index){
                    console.log("multiplicación")
                    if(pixelsToMove > 0){
                        pixelsToMove = 0;
                    }
                    else{
                    pixelsToMove *= -1;

                    }
                    // if(index < 2){
                    //     pixelsToMove = 0;
                    // }
                    console.log(pixelsToMove)
                    if(totalWidth > containerWidth){
                    // console.log(totalWidth)
                        $vm.$refs.imageSelector.style.transform = `translate(${pixelsToMove}px)`;

                    }
                }
                else if(tempPosition < index){
                    pixelsToMove *= -1;
                    console.log("aquí")
                    if(pixelsToMove > containerWidth){
                        pixelsToMove = 0
                    }

                    if(totalWidth > containerWidth){
                    // console.log(totalWidth)
                        console.log("Pixeles aquí");
                        $vm.$refs.imageSelector.style.transform = `translate(${pixelsToMove}px)`;

                    }
                }


            }

            $vm.thumbnailsPosition = index;

        },
        goToEntrada(evento){
            let $vm = this;
            console.log(evento)
            $vm.$router.push("/castillos/tarifas/" + $vm.$route.params.monumentoNombre + '/' + evento)
        },
        goToEntradas(evento){
            let $vm = this;
            // console.log(evento)
            if($vm.tipoDeEntrada == "Experiencia"){
                $vm.$router.push("/experiencias-exclusivas/" + $vm.$route.params.monumentoNombre)
            }
            else if($vm.tipoDeEntrada == "Evento"){
                $vm.$router.push("/eventos-singulares/" + $vm.$route.params.monumentoNombre)
            }
            else{
                $vm.$router.push("/castillos/tarifas/" + $vm.$route.params.monumentoNombre)
            }
        },
        updateEntrada(){
            let $vm = this;
             $vm.entradaId = $vm.$route.params.entradaId;

            const _language = this.$store.getters.getIdioma;
            ApiService.getEdificacion(_language, $vm.monumentoUrl)
            .then( result => {
                console.log(result.data);
                let data = result.data;
                // $vm.address = data.direccion;
                $vm.monumentoId = data.id;
                // $vm.edificios = [...result.data];
                    ApiService.getVisitaById(_language, $vm.entradaId)
                        .then( result => {
                            console.log(result.data);
                            let entrada = result.data;

                            console.log(entrada)
                            // $vm.entradaDetalle = [...result.data];
                            $vm.tipoDeEntrada = entrada.tipo

                            $vm.nombre = entrada.nombres;
                            $vm.descripcion = entrada.descripciones;
                            $vm.detalles = entrada.detalles;
                            $vm.precioOrientativo = entrada.precioOrientativo
                            $vm.descuentos = entrada.descuentos
                            $vm.horarios = entrada.horarios

                            $vm.imagenes = entrada.imagenes;
                            $vm.visitaOldId = entrada.oldId;
                        })
                        .catch( err=> {
                            console.log(err);
                        })
                    ApiService.getVisitasByEdificacion('es', _language, $vm.monumentoId, "Evento")
                        .then( result => {
                            // console.log(result)
                            $vm.entradasRecomendadas = [...result.data];

                            $vm.entradasRecomendadas.sort( function(a,b){                            
                                if(b.fechas.length > 0 && a.fechas.length > 0){
                                    return new Date(a.fechas[0]) - new Date(b.fechas[0])
                                }
                                else if(b.fechas.length > 0 && a.fechas.length == 0){
                                    return 1;
                                }
                                else if(b.fechas.length == 0 && a.fechas.length > 0){
                                    return -1;
                                }
                                else if(b.fechas.length == 0 && a.fechas.length == 0){
                                    return -1;
                                }
                                else{
                                    return 0;
                                }
                            } )

                        })
                        .catch(err => {
                            console.log(err);
                        })

            })
            .catch(err => {
                console.log(err);
            })
        }
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    computed: {
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            else if($vm.$i18n.locale == 'ja'){
                idiomaIndex = 4;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        selectedEntrada(){
            let $vm = this;
            // console.log($vm.$route.params.visitaId);
            $vm.updateEntrada();
            return $vm.$route.params.visitaId;
        },
        experiencia(){
            let $vm = this;
            // console.log($vm.tipo)
            if($vm.tipoDeEntrada == "Experiencia"){
                return true;
            }
            else{
                return false;
            }
        },
        monumentoNombre(){
            let $vm = this;
            try{
                return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
            }
            catch(err){
                return "";
            }
        },
        welcomeTitle(){
            const language = this.$i18n.locale ?? 'es';
            let welcomeTitle = "";
            welcomeTitle = this.welcomeMessages.filter( wm => wm.idioma === language ).map( wm => wm.title )[0];
            return welcomeTitle;
        },
        welcomeSubtitle(){
            const language = this.$i18n.locale ?? 'es';
            let welcomeSubtitle = "";
            welcomeSubtitle = this.welcomeMessages.filter( wm => wm.idioma === language ).map( wm => wm.subtitle  )[0];
            return welcomeSubtitle;
        },

    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
    setup() {
        
    },
    created(){
        let $vm = this;
        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;
        $vm.entradaId = $vm.$route.params.entradaId;
        const _language = this.$store.getters.getIdioma;
        ApiService.getEdificacion(_language, $vm.monumentoUrl)
            .then( result => {
                let data = result.data;
                this.monumentName = data.webName;
                this.provincia = result.data.provincia;
                this.comunidadAutonoma = result.data.comunidad;

                this.links = [
                    { text: this.provincia },
                    { text: this.comunidadAutonoma }
                ]

                // $vm.address = data.direccion;
                $vm.monumentoId = data.id;
                $vm.monumentoNombres = data.nombres;
                $vm.clorianPos = data.clorianPos;
                // $vm.edificios = [...result.data];

                this.apiDeVentasActive = data.apiDeVentasActive;
                this.apiDeVentasUrl = data.apiDeVentasUrl;
                if( data.apiDeVentasActive == true && data.apiDeVentasUrl == "clorian" ){
                    ClorianService.consultarDetallesProducto( $vm.entradaId, data.nombre, $vm.clorianPos )
                        .then( res=>{
                            $vm.clorian = true;
                            let entrada = res.data;

                            $vm.tipoDeEntrada = "Tarifa";
                            $vm.nombre = [{texto: entrada.productDetails.product.name}];
                            $vm.detalles = [{texto:entrada.productDetails.product.shortDescription}];
                            $vm.descripcion = [{texto: entrada.productDetails.product.description}];
                            $vm.precioOrientativo = entrada.productDetails.product.basePrice;
                            $vm.descuentos = [{texto:null}];
                            $vm.horarios = [{texto:null}];

                            $vm.imagenes = [entrada.product.image];
                            this.entradasRecomendadas = [];
                        } )
                        .catch(err => {
                            console.log(err);
                        })
                }
                else{
                    ApiService.getVisitaById(_language, $vm.entradaId)
                        .then( result => {
                            let entrada = result.data;
                            this.micrositeData = result.data;
                            // $vm.entradaDetalle = [...result.data];
                            $vm.tipoDeEntrada = entrada.tipo

                            if($vm.tipoDeEntrada == "Experiencia"){
                                $vm.desdeOn = true;
                            }
                            // console.log(entrada);
                            $vm.nombre = entrada.nombres;
                            $vm.descripcion = entrada.descripciones;
                            $vm.detalles = entrada.detalles;
                            $vm.precioOrientativo = entrada.precioOrientativo
                            $vm.descuentos = entrada.descuentos
                            $vm.horarios = entrada.horarios

                            // $vm.imagenes = entrada.imagenes;
                            $vm.visitaOldId = entrada.oldId;

                            this.welcomeData.active = entrada.welcomeActive ?? false;
                            this.welcomeMessages = entrada.welcomeMessages ?? [];

                            if( this.welcomeData.active ){
                                this.showWelcome = true;
                            }

                            let tempImg = entrada.imagenes.images.filter( i => i.visible );

                            tempImg.sort( function(a,b){
                                return a.order - b.order
                            })
                            this.imagenes = tempImg;

                            // let imagenPrincipalIndex = $vm.imagenes.indexOf(entrada.banner);
                            // let imagenPrincipal = $vm.imagenes.splice(imagenPrincipalIndex, 1);

                            // $vm.imagenes.unshift(imagenPrincipal);
                        })
                        .catch( err=> {
                            console.log(err);
                        })
                    ApiService.getEventosByMonumento(_language, $vm.monumentoId)
                        .then( result => {
                            // console.log(result)
                        this.eventosRecomendados = [...result.data];

                        console.log(this.eventosRecomendados)
                        // Ponemos al frente los eventos que tienen fechas asignadas
                        this.eventosRecomendados.sort( function(a,b){                            
                                if(b.fechas?.length > 0 && a.fechas?.length > 0){
                                    return new Date(a.fechas[0]) - new Date(b.fechas[0])
                                }
                                else if(b.fechas?.length > 0 && a.fechas?.length == 0){
                                    return 1;
                                }
                                else if(b.fechas?.length == 0 && a.fechas?.length > 0){
                                    return -1;
                                }
                                else if(b.fechas?.length == 0 && a.fechas?.length == 0){
                                    return -1;
                                }
                                else{
                                    return 0;
                                }
                            } )
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }

            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script>

<style scoped>

    .thumbnails-container{
        transition: all 0.5s;
    }

    .thumbnail-slide{
        /* position:absolute; */
        transform: translate(0px);
        transition: all 0.5s;
    }

    @media (min-width: 768px) {
        .text-columns{
            column-count: 2;
            /* height: 50vh; */
        }
        .carousel-spainhn{
            height:528px; 
            width:auto; 
            /* max-width: 100vw; */
        }
    }

    @media (max-width: 768px){
        .text-columns{
            column-count: 2;
            /* height: 50vh; */
        }
        .carousel-homeslide{
            height:300px !important; 
        }

        .carousel-spainhn{
            height:100%; 
            width:100% !important; 
            max-width: 100vw;
        }    

    }

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}

    .html-container{
        position: relative;
        overflow: hidden;
        max-height: 320px;
    }

    .html-container p span{
        color:black !important;
    }

    .html-container p{
        color:black !important;
    }

    .read-more-btn{
        width: 85px;
        height: 35px;
        flex-shrink: 0;
        border-radius: 20px;
        /* background: #D9D9D9;
        color: #FFF; */
        text-align: center;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
        padding: 0;
    }
    .loader-component {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba( 255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }


</style>