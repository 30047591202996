var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "d-flex mb-0 mt-0 pt-0",
          class: { "d-none": _vm.mostrarBuscador },
          staticStyle: {
            position: "static",
            width: "100%",
            "background-color": "rgba(0,0,0,0)",
            height: "auto",
            "z-index": "1",
          },
        },
        [
          _c("img", {
            staticStyle: {
              "z-index": "0",
              position: "static",
              width: "100%",
              "max-height": "720px",
            },
            attrs: { src: require("../assets/IMAGENDEFONDO3.jpg") },
          }),
          _c(
            "div",
            {
              staticClass: "d-flex w-100",
              staticStyle: { position: "absolute", "z-index": "1" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex w-100",
                  staticStyle: { position: "static" },
                },
                [
                  _c("div", { staticClass: "ms-3 mt-md-5 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column align-items-center" },
                      [
                        _c(
                          "mdb-btn",
                          {
                            staticClass:
                              "spainhn-button py-md-3 px-md-4 py-2 px-1 mb-md-3 mb-lg-5 mb-sm-5",
                            attrs: { color: "indigo", size: "lg" },
                            on: { click: () => (_vm.mostrarBuscador = true) },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("default.HOME_BUTTON_SEARCHABLE"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", {
                          staticClass: "mt-5 mb-5 d-md-inline-block d-none",
                        }),
                        _c("div", {
                          staticClass: "mt-5 mb-5 d-sm-inline-block d-none",
                        }),
                        _c("div", {
                          staticClass: "mt-5 mb-5 d-lg-inline-block d-none",
                        }),
                        _c("div", {
                          staticClass: "mt-5 mb-5 d-xl-inline-block d-none",
                        }),
                        _c(
                          "mdb-btn",
                          {
                            staticClass:
                              "spainhn-button py-md-3 px-md-4 py-2 px-1 mt-4",
                            attrs: {
                              tag: "a",
                              href: "https://www.youtube.com/watch?v=wR1iYYlnliw&ab_channel=ReddeCastillosyPalaciosdeEspa%C3%B1a",
                              target: "_blank",
                              color: "indigo",
                              size: "lg",
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("default.HOME_BUTTON_VIDEO"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm.mostrarBuscador == true ? _c("mapa") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }