var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex py-3",
        staticStyle: { height: "auto", "min-height": "2rem", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillos },
            },
            [_vm._v(_vm._s(_vm.monumentWebName) + ">> ")]
          ),
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToRestaurantes },
            },
            [_vm._v(_vm._s(_vm.tipo) + ">> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.info.nombres[_vm.selectedIdiomaIndex]?.texto))]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "container-fluid px-md-5 px-2 margen-estandard" },
      [
        _c(
          "div",
          { staticClass: "d-flex justify-content-around pt-4 mb-3 px-lg-5" },
          [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { width: "100%" } },
              [
                _c(
                  "icon-base",
                  { attrs: { width: 55, height: 55, iconColor: "rgb(0,0,0)" } },
                  [_c("icon-casttle")],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
                  [
                    _c(
                      "p",
                      { staticClass: "mb-0 mt-auto spainhn-subtitle-2-black" },
                      [_vm._v(_vm._s(_vm.monumentWebName))]
                    ),
                    _c(
                      "small",
                      { staticClass: "mb-0 mt-auto spainhn-subtitle-4-black" },
                      [_vm._v(_vm._s(_vm.address))]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "container-fluid px-lg-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "text-columns text-start spainhn-text pt-3 pe-3 col-md-8 col-12",
              },
              [
                _c("h5", { staticClass: "mb-0" }, [
                  _c("strong", [
                    _c("small", [_vm._v(_vm._s(_vm.info.webName))]),
                  ]),
                ]),
                _c("div", {
                  staticClass: "w-100 border-bottom mt-0 mb-1",
                  staticStyle: {
                    height: "2vh",
                    "border-color": "rgb(242,90,85) !important",
                  },
                }),
                _c("div", { staticClass: "spainh-small-text" }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.info.webDescription) },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column pt-0 pb-0 px-3 text-wrap col-md-4 col-12",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column",
                    staticStyle: { "border-radius": "10px" },
                  },
                  [
                    _c("h5", { staticClass: "mb-3" }, [
                      _c("strong", [
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "default.ACTIVITIES_RESTAURANT_ADDRESS_CONTACT"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c("span", {
                      staticClass: "text-start px-3",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.info.webContact || _vm.info.webInfo
                        ),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row ps-lg-4 pe-0 gx-2" }, [
            _c(
              "div",
              {
                staticClass:
                  "text-start spainhn-text pt-3 pe-0 mb-5 col-xxl-8 col-12",
                staticStyle: { height: "100%" },
              },
              [
                _c("h5", { staticClass: "mb-3" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("default.TICKET_DETAILS_IMAGES"))),
                  ]),
                ]),
                _c(
                  "b-carousel",
                  {
                    staticStyle: { "text-shadow": "1px 1px 2px #333" },
                    attrs: {
                      id: "monumentosShow",
                      interval: 4000,
                      controls: "",
                      indicators: "",
                      "img-width": "940",
                      "img-height": "528",
                    },
                    on: {
                      "sliding-start": _vm.onSlideStart,
                      "sliding-end": _vm.onSlideEnd,
                    },
                    model: {
                      value: _vm.slide,
                      callback: function ($$v) {
                        _vm.slide = $$v
                      },
                      expression: "slide",
                    },
                  },
                  [
                    _vm._l(_vm.imagenes, function (imagen, index) {
                      return _c("b-carousel-slide", {
                        key: index,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "img",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-3 d-flex",
                                      staticStyle: {
                                        height: "528px",
                                        width: "940px",
                                        "max-width": "100%",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "d-block my-auto mx-auto carousel-spainhn",
                                        staticStyle: {
                                          height: "100%",
                                          width: "auto",
                                        },
                                        attrs: {
                                          src: `${_vm.imagenesUrl}/${_vm.tipo}/${_vm.folderOldId}/${imagen.name}`,
                                          alt: imagen.altText,
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "carousel-control-prev",
                        attrs: {
                          "aria-controls": "monumentosShow___BV_inner_",
                          role: "button",
                          "data-slide": "prev",
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-black",
                          attrs: { icon: "fa-circle", size: "3x" },
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Previous"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "carousel-control-next",
                        attrs: {
                          role: "button",
                          "data-slide": "next",
                          "aria-controls": "monumentosShow___BV_inner_",
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-black",
                          attrs: { icon: "fa-circle", size: "3x" },
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Previous"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "overflow-hidden mx-auto",
                    staticStyle: {
                      height: "94.5px",
                      width: "940px",
                      "max-width": "72vw",
                      position: "relative",
                    },
                    attrs: { id: "imageSelectorContainer" },
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "imageSelector",
                        staticClass: "d-flex thumbnails-container",
                        staticStyle: {
                          height: "94.5px",
                          width: "940px",
                          "max-width": "100%",
                          position: "relative",
                        },
                        attrs: { id: "imageSelector" },
                      },
                      _vm._l(_vm.imagenes, function (imagen, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "thumbnail-slide",
                            staticStyle: { height: "94.5px", width: "140px" },
                          },
                          [
                            _c("img", {
                              staticClass: "px-1",
                              staticStyle: {
                                height: "100%",
                                width: "140px",
                                "object-fit": "cover",
                                "border-radius": "15px",
                              },
                              attrs: {
                                role: "button",
                                src: `${_vm.imagenesUrl}/${_vm.tipo}/${_vm.folderOldId}/${imagen.name}`,
                                alt: imagen.altText,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeSlide(index)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column pt-3 ps-3 pe-3 col-md-4 col-12 d-none",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "h-100 pt-5 spainhn-bg-gris-cajas ps-3 pe-0 spainhn-text d-flex flex-column",
                    staticStyle: { "border-radius": "10px" },
                  },
                  [
                    _c("h5", { staticClass: "mb-3" }, [
                      _c("strong", [
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.$t("default.TICKET_DETAILS_DETAILS"))
                          ),
                        ]),
                      ]),
                    ]),
                    _c("span", {
                      staticClass: "text-start px-3",
                      domProps: {
                        innerHTML: _vm._s(_vm.info.webRecommendations),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }