var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "b-card",
        { staticClass: "d-flex pe-0 w-100 container" },
        [
          _c("div", { staticClass: "d-flex my-auto pb-3" }, [
            _c(
              "div",
              { staticClass: "text-start h-100" },
              [
                _c("font-awesome-icon", {
                  staticClass: "fa-2x ms-2 my-auto",
                  attrs: { icon: "fa-mouse-pointer" },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex my-auto",
                class: { "d-none": _vm.selectedSeats.zonaName != null },
              },
              [
                _c("p", { staticClass: "my-auto ms-3 fw-bold" }, [
                  _vm._v(_vm._s(_vm.$t("default.RESERVATION_STEP_4"))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "d-flex ms-auto",
                class: { "d-none": _vm.selectedSeats.zonaName == null },
              },
              [
                _c("div", { staticClass: "d-flex my-auto" }, [
                  _c("div", { staticClass: "ms-auto d-flex" }, [
                    _c("p", { staticClass: "text-start my-auto" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.selectedSeats.zonaName) +
                            " - " +
                            _vm._s(_vm.selectedSeats.asientosResumen)
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ms-3" },
                    [
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.seats",
                            modifiers: { seats: true },
                          },
                        ],
                        staticClass: "circle-icon shadow",
                        attrs: { role: "button", icon: "fa-pen" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _c(
            "b-collapse",
            {
              staticClass: "border-top mt-2",
              staticStyle: {
                "border-color": "rgb(165, 61, 57) !important",
                "border-width": "2px !important",
              },
              attrs: { id: "seats" },
              model: {
                value: _vm.expand,
                callback: function ($$v) {
                  _vm.expand = $$v
                },
                expression: "expand",
              },
            },
            [
              _c(
                "mdb-container",
                [
                  _c(
                    "mdb-row",
                    [
                      _c(
                        "mdb-col",
                        {
                          staticClass: "order-md-1 order-2 my-auto",
                          attrs: { md: "4", col: "12" },
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "text-start font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.RESERVATION_STEP_4_1"))
                              ),
                            ]
                          ),
                          _vm._l(_vm.zonasDisponibles, function (zona, index) {
                            return _c(
                              "mdb-btn",
                              {
                                key: index,
                                staticClass: "d-block text-white",
                                style: `width: 200px; background-color: ${zona.color}  !important;`,
                                on: {
                                  click: function ($event) {
                                    return _vm.getZoneInfo(zona)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(zona.name) + " "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "text-italic",
                                    class: { "d-none": true },
                                  },
                                  [_vm._v("(" + _vm._s(zona.asientos) + ")")]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "mdb-col",
                        {
                          staticClass: "order-md-2 order-1",
                          attrs: { col: "10", md: "6" },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticClass: "float-rigth img-fluid",
                              staticStyle: { "max-height": "500px" },
                              attrs: { src: _vm.imagenUrl },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("seats-picker", {
                attrs: {
                  Zona: _vm.zonaInfo ? _vm.zonaInfo.name : "",
                  filas: _vm.filasConfig,
                  personas: _vm.numeroDePersonas,
                },
                on: { finalizarCompra: _vm.finalizarCompra },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex pe-0 w-100" }, [
      _c("div", {
        staticClass: "text-start pt-3 pe-3",
        staticStyle: { width: "100%" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }