<template>
    <div class="d-flex px-0 tool-component">
        <FilterComponentTool style="flex: 1" @toggleFilter="showFilter" :active="filterOn"/>
        <div style="width: 3px;">

        </div>
        <MapComponentTool style="flex: 1" @toggleFilter="showMap" :active="mapOn"/>

        <FilterMobile id-filter="main-filter" @hideFilter="showFilter" :searchBlockStatus="filterOn"/>

        <mdb-modal style="overscroll-behavior: contain" :show="mapOn" class="px-0 px-sm-5 modalTools" @close="() => mapOn=false" size="fluid">
            <mdb-modal-header>

            </mdb-modal-header>
            <mdb-modal-body class="p-0">
                <MiniMap idFilter="tools-filter" />
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import FilterComponentTool from './FilterComponentTool.vue'
import MapComponentTool from './MapComponentTool.vue';
import FilterMobile from '../FilterMobile.vue';
import MiniMap from '../MiniMap'
export default {
    components: {
        FilterComponentTool,
        MapComponentTool,
        FilterMobile,
        MiniMap
    },
    data(){
        return{
            filterOn: false,
            mapOn: false
        }
    },
    methods:{
        showFilter(){
            this.filterOn ? this.filterOn = false : this.filterOn = true;
        },
        showMap(){
            this.mapOn ? this.mapOn = false: this.mapOn = true;
        },
    }
}
</script>

<style scoped>
    .tool-component{
        height: 45px;
        width: 100%;
        background-color: white;
    }
</style>

<style>
.modalTools > .modal-dialog {
    border-radius: 10px !important;
    overflow: hidden !important;
}
</style>