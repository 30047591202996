var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "mdb-row",
        {},
        [
          _c(
            "mdb-col",
            { staticClass: "text-center" },
            [
              _c(
                "mdb-btn",
                {
                  staticClass: "red darken-3 text-white mx-auto",
                  attrs: { disabled: _vm.cantidad == 0, color: "red" },
                  on: { click: _vm.quitarPersonas },
                },
                [_c("font-awesome-icon", { attrs: { icon: "fa-minus" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "mdb-col",
            { staticClass: "my-auto bg-white py-0 text-center h-100 mx-auto" },
            [_c("span", {}, [_vm._v(_vm._s(_vm.cantidadBoletos))])]
          ),
          _c(
            "mdb-col",
            { staticClass: "text-center" },
            [
              _c(
                "mdb-btn",
                {
                  staticClass: "red darken-3 text-white mx-auto",
                  attrs: { disabled: _vm.noIncrement, color: "red" },
                  on: { click: _vm.agregarPersonas },
                },
                [_c("font-awesome-icon", { attrs: { icon: "fa-plus" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }