var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "infoCard" }, [
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c("p", { staticClass: "pb-0 mb-0 spainhn-lg-text-red text-wrap" }, [
        _vm._v(" " + _vm._s(_vm.getPopoverTitle) + " "),
      ]),
      _c("br"),
      _vm.currentFilter != "" || _vm.servicioCheck
        ? _c("div", { staticClass: "text-small text-wrap" }, [
            _c(
              "button",
              {
                staticClass: "btn-accordion",
                on: { click: _vm.toggleServicios },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "start-icon",
                  attrs: { icon: "fa-solid fa-bell-concierge" },
                }),
                _c(
                  "span",
                  { staticClass: "text-small text-wrap accordion-title" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "default.MAIN_MENU_MINIMAP_POPOVER_SERVICES_TITLE"
                        )
                      )
                    ),
                  ]
                ),
                _c("font-awesome-icon", {
                  staticClass: "start-icon",
                  attrs: {
                    icon: _vm.showServicios ? "chevron-up" : "chevron-down",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showServicios && (_vm.currentFilter != "" || _vm.servicioCheck)
        ? _c("ul", { staticClass: "list-group service-list" }, [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "list-group-item list-container pack-container",
                  attrs: { href: _vm.getVisitUrl, target: "_blank" },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "start-icon",
                    attrs: { icon: "fa-solid fa-ticket" },
                  }),
                  _c("span", { staticClass: "label-service" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.translations(
                            "default.MAP_POPOVER_AVAILABLE_VISITS"
                          )
                        )
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm.monumento.alojamiento
              ? _c(
                  "li",
                  { staticClass: "list-group-item list-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "hotel" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "label-service" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.translations(
                              "default.MAIN_MENU_SERVICES_ALOJAMIENTO"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.monumento.restaurante
              ? _c(
                  "li",
                  { staticClass: "list-group-item list-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-utensils" },
                    }),
                    _c("span", { staticClass: "label-service" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.translations(
                              "default.MAIN_MENU_SERVICES_RESTAURANTES"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.monumento.eventos
              ? _c(
                  "li",
                  { staticClass: "list-group-item list-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-calendar" },
                    }),
                    _c("span", { staticClass: "label-service" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.translations(
                              "default.MAIN_MENU_SERVICES_EVENTOS"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.monumento.experiencias
              ? _c(
                  "li",
                  { staticClass: "list-group-item list-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-wand-magic-sparkles" },
                    }),
                    _c("span", { staticClass: "label-service" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.translations(
                              "default.MAIN_MENU_SERVICES_EXPERIENCIAS"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.monumento.boda
              ? _c(
                  "li",
                  { staticClass: "list-group-item list-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-place-of-worship" },
                    }),
                    _c("span", { staticClass: "label-service" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.translations("default.MAIN_MENU_SERVICES_BODA")
                          )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.monumento.espacios
              ? _c(
                  "li",
                  { staticClass: "list-group-item list-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-mug-saucer" },
                    }),
                    _c("span", { staticClass: "label-service" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.translations(
                              "default.MAIN_MENU_SERVICES_ESPACIOS"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.paqueteFilter &&
      _vm.monumento.tipoDeMonumento === "Paquete" &&
      _vm.matchingPacks.length > 0 &&
      !_vm.monumento.showLinkButton
        ? _c("div", { staticClass: "text-small text-wrap" }, [
            _vm.separatePacks.packsMonumento.length > 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn-accordion",
                    on: { click: _vm.togglePaqueteMonumento },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-chess-rook" },
                    }),
                    _c(
                      "span",
                      { staticClass: "text-small text-wrap accordion-title" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("default.MINI_MAP.TOURISTIC_PACKS"))
                        ),
                      ]
                    ),
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: {
                        icon: _vm.showPaqueteMonumento
                          ? "chevron-up"
                          : "chevron-down",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showPaqueteMonumento
              ? _c(
                  "ul",
                  { staticClass: "list-group pack-list" },
                  _vm._l(_vm.separatePacks.packsMonumento, function (pack) {
                    return _c("li", { key: pack._id }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "list-group-item list-container pack-container",
                          attrs: { href: pack.link, target: "_blank" },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "start-icon",
                            attrs: { icon: "fa-solid fa-box-open" },
                          }),
                          _c("span", { staticClass: "label-service" }, [
                            _vm._v(" " + _vm._s(pack.nombre)),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.separatePacks.packsOperador.length > 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn-accordion",
                    on: { click: _vm.togglePaqueteOperador },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: { icon: "fa-solid fa-box-open" },
                    }),
                    _c(
                      "span",
                      { staticClass: "text-small text-wrap accordion-title" },
                      [_vm._v(_vm._s(_vm.$t("default.MINI_MAP.TOURS")))]
                    ),
                    _c("font-awesome-icon", {
                      staticClass: "start-icon",
                      attrs: {
                        icon: _vm.showPaqueteOperador
                          ? "chevron-up"
                          : "chevron-down",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showPaqueteOperador
              ? _c(
                  "ul",
                  { staticClass: "list-group pack-list" },
                  _vm._l(_vm.separatePacks.packsOperador, function (pack) {
                    return _c("li", { key: pack._id }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "list-group-item list-container pack-container",
                          attrs: { href: pack.link, target: "_blank" },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "start-icon",
                            attrs: { icon: "fa-solid fa-box-open" },
                          }),
                          _c("span", { staticClass: "label-service" }, [
                            _vm._v(" " + _vm._s(pack.nombre)),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _vm.monumento.showLinkButton
      ? _c(
          "a",
          {
            staticClass: "spainhn-button text-white mt-0 spainhn-lg-text p-2",
            attrs: { href: _vm.link },
          },
          [_c("strong", [_vm._v(_vm._s(_vm.$t("default.MINI_MAP.SHOW_SITE")))])]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }