var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillos },
            },
            [_vm._v(" " + _vm._s(_vm.monumentoNombre) + ">> ")]
          ),
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text",
              attrs: { role: "button" },
            },
            [_vm._v(_vm._s(_vm.$t("default.NAVEGADOR_ALOJAMIENTOS_CERCANOS")))]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "container-fluid px-md-5 px-1" },
      [
        _c("div", { staticClass: "d-flex justify-content-around pt-1 mb-1" }, [
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { width: "100%" } },
            [
              _c(
                "icon-base",
                { attrs: { width: 55, height: 55, iconColor: "rgb(0,0,0)" } },
                [_c("icon-casttle")],
                1
              ),
              _c(
                "div",
                { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
                [
                  _c(
                    "p",
                    { staticClass: "mb-0 mt-auto spainhn-subtitle-2-black" },
                    [_vm._v(_vm._s(_vm.monumentoNombre))]
                  ),
                  _c(
                    "small",
                    { staticClass: "mb-0 mt-auto spainhn-subtitle-4-black" },
                    [_vm._v(_vm._s(_vm.address))]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("filtro-hoteles", {
          attrs: {
            "monumento-nombre": _vm.monumentoNombre,
            latitude: _vm.coordinates.lat,
            longitude: _vm.coordinates.lng,
          },
          on: {
            hotelesEncontrados: (evt) => _vm.refreshHotels(evt),
            loading: (evt) => {
              _vm.loading = evt
            },
          },
        }),
        _vm.loading
          ? _c(
              "mdb-container",
              [
                _c("img", {
                  staticClass: "d-block mx-auto",
                  staticStyle: {
                    width: "300px",
                    height: "300px",
                    color: "brown !important",
                  },
                  attrs: { src: require("@/assets/SimboloCastillo.svg") },
                }),
                _c("mdb-spinner", {
                  attrs: { color: "red", big: "", crazy: "" },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "mdb-container",
          { staticClass: "flex" },
          [
            _c(
              "mdb-row",
              _vm._l(_vm.hotelesCercanos, function (hotel, index) {
                return _c(
                  "mdb-col",
                  {
                    key: index,
                    staticClass: "mt-3",
                    attrs: { lg: "4", md: "3", col: "12" },
                  },
                  [
                    _c("hoteles-card", {
                      attrs: {
                        "main-image-path": hotel.banner?.path,
                        name: hotel.name,
                        distance: hotel.distance,
                        category: hotel.categoryType,
                        minRate: hotel.minRate
                          ? parseFloat(hotel.minRate).toLocaleString("es-ES", {
                              style: "currency",
                              currency: hotel.currency,
                            })
                          : "",
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm.showPanelQuery
          ? _c("div", { staticClass: "container-fluid mb-5" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "h-100 pt-5 pb-5 spainhn-bg-gris-cajas",
                    staticStyle: { "border-radius": "10px" },
                  },
                  _vm._l(_vm.hotelBedsQuery, function (room, index) {
                    return _c("card-hotelbeds", {
                      key: index,
                      attrs: { room: room },
                      on: {
                        buyRoom: function ($event) {
                          return _vm.abrirFormularioComprar($event)
                        },
                        openModal: function ($event) {
                          return _vm.abrirModal($event)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.showFormHolder
          ? _c("b-container", [
              _c("div", { staticClass: "container-fluid mb-5" }, [
                _c(
                  "div",
                  { staticClass: "h-100 pt-5 pb-5 spainhn-bg-gris-cajas" },
                  [
                    _c("h5", [_vm._v("Información de Habitación")]),
                    _c("p", [
                      _vm._v(
                        "Tipo de Habitiación: " +
                          _vm._s(_vm.roomSelected.boardName)
                      ),
                    ]),
                    _c("p", [
                      _vm._v("Adultos: " + _vm._s(_vm.roomSelected.adults)),
                    ]),
                    _c("p", [
                      _vm._v(
                        "No. de cuartos: " + _vm._s(_vm.roomSelected.rooms)
                      ),
                    ]),
                    _c("p", [_vm._v("Politicias de cancelacion:")]),
                    _vm._l(
                      _vm.roomSelected.cancellationPolicies,
                      function (cancelPolicy, index2) {
                        return _c("ul", { key: index2 }, [
                          _vm._v(
                            " " +
                              _vm._s(cancelPolicy.amount) +
                              " € - Costo de cancelación si cancelas antes del: " +
                              _vm._s(
                                new Date(cancelPolicy.from).toLocaleDateString(
                                  "es-ES",
                                  {
                                    timeZone: "UTC",
                                    date: "DD-MM-YYY",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              ) +
                              " "
                          ),
                        ])
                      }
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { sm: "3" } }, [
                          _c("label", { attrs: { for: "name" } }, [
                            _vm._v("Nombre(s)"),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { sm: "8" } },
                          [_c("b-form-input")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { sm: "3" } }, [
                          _c("label", { attrs: { for: "surname" } }, [
                            _vm._v("Apellido(s)"),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { sm: "8" } },
                          [_c("b-form-input")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { sm: "3" } }, [
                          _c("label", { attrs: { for: "remarks" } }, [
                            _vm._v("Observaciones o Peticiones especiales"),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { sm: "8" } },
                          [_c("b-form-textarea")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "2" } },
                          [
                            _c(
                              "mdb-btn",
                              [
                                _c(
                                  "icon-base",
                                  { attrs: { height: 25, width: 25 } },
                                  [_c("icon-carrito")],
                                  1
                                ),
                                _c("span", [_vm._v("Comprar")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }