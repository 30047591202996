<template>
    <div class="breadcrumb-container">
        <div class="text-container">
            <span class="breadcrumb-text mt-auto text-uppercase">
                {{ title }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }    
}
</script>

<style scoped>
    .breadcrumb-container{
        height: 60px;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        background: linear-gradient(180deg, rgba(191, 91, 91, 0.80) 0.52%, rgba(185, 185, 185, 0.51) 31.97%, rgba(229, 229, 229, 0.40) 63.11%, rgba(217, 217, 217, 0.00) 100%);
    }

    .text-container{
        display: flex;
        width: 370px;
        height: 100%;
        transform: rotate(-0.079deg);
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
    }

    .breadcrumb-text{
        color: #850B0B;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

</style>