var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-container", class: { "w-auto": _vm.autoWidth } },
    [
      _c("div", { staticClass: "d-flex", attrs: { role: "button" } }, [
        _c(
          "div",
          {
            staticClass: "image-container",
            attrs: { role: "button" },
            on: { click: () => _vm.$emit("goTo", _vm.nombreUrl) },
          },
          [
            _c("img", { staticClass: "d-block", attrs: { src: _vm.image } }),
            _c(
              "small",
              {
                staticClass: "fw-bold",
                class: _vm.labelClass,
                staticStyle: {
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  "z-index": "10",
                  width: "100%",
                  color: "white",
                  "font-size": "8px",
                },
              },
              [
                _c("strong", { staticClass: "spainhn-text my-auto" }, [
                  _vm._v(
                    " + " +
                      _vm._s(_vm.$t("default.SPAINHN_BUTTON_MORE_INFO")) +
                      " "
                  ),
                ]),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "info-container" }, [
          _c(
            "div",
            {
              staticClass: "text-container pt-1",
              class: { "w-auto": _vm.autoWidth },
              attrs: { role: "button" },
              on: { click: () => _vm.$emit("goTo", _vm.nombreUrl) },
            },
            [
              _c("div", { staticClass: "text-wrapper" }, [
                _c("span", { staticClass: "info-title-text" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ]),
                _c("span", { staticClass: "ms-auto" }, [_vm._t("default")], 2),
              ]),
              _c("div", { staticClass: "one-line-container" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "info-none-text text-uppercase px-1 py-0 rounded-3 overflow-hidden",
                    class: _vm.priceDescriptionClass,
                  },
                  [_vm._v(_vm._s(_vm.priceDescription))]
                ),
              ]),
              _c("div", { staticClass: "one-line-container" }, [
                _c(
                  "span",
                  {
                    staticClass: "info-none-text mt-auto",
                    class: { "d-none": _vm.text === null },
                  },
                  [_vm._v(_vm._s(_vm.text))]
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "info-dates-text mt-auto mb-0",
                  class: { "d-none": _vm.fecha.length === 0 },
                },
                [_vm._v(_vm._s(_vm.fechasEvento))]
              ),
              _vm.showDefault
                ? _c(
                    "p",
                    {
                      staticClass: "info-none-text mt-auto mb-0",
                      class: { "d-none": _vm.fecha.length > 0 },
                    },
                    [_vm._v(_vm._s(_vm.fechasEvento))]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "span",
            {
              attrs: { role: "button" },
              on: { click: () => _vm.$emit("buy") },
            },
            [
              _vm.showBuyButton
                ? _c("icon-ticket", { staticClass: "buy-icon" })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }