var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { staticClass: "mt-3 border-top" },
    [
      _c("h4", [_vm._v("Habitación 1")]),
      _c(
        "mdb-row",
        [
          _c("mdb-col", [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "adults" } },
              [_vm._v("Adultos")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.adults,
                    expression: "adults",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "range",
                  min: "1",
                  max: "10",
                  name: "adults",
                  id: "adults",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.adults = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.updateOccupancie,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
                _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
              ]
            ),
          ]),
          _c("mdb-col", [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "children" } },
              [_vm._v("Niños")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.children,
                    expression: "children",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "range",
                  min: "0",
                  max: "4",
                  name: "children",
                  id: "children",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.children = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }