var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { staticClass: "mt-4" },
    [
      _c(
        "mdb-card",
        { staticClass: "bg-light", attrs: { wide: "" } },
        [
          _c(
            "mdb-view",
            [
              _c(
                "mdb-row",
                {
                  staticClass:
                    "justify-content-md-center spainhn-solid-red-background px-4 py-2 mb-0",
                },
                [
                  _c(
                    "mdb-col",
                    {
                      staticClass: "spainhn-subtitle-text-white",
                      staticStyle: { "font-size": "24px !important" },
                      attrs: { col: "12" },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.item.information.itemMonumento)),
                      ]),
                    ]
                  ),
                  _c(
                    "mdb-col",
                    { attrs: { col: "12" } },
                    [
                      _c(
                        "mdb-row",
                        [
                          _c(
                            "mdb-col",
                            { attrs: { col: "12", md: "6" } },
                            [
                              _c(
                                "mdb-row",
                                [
                                  _c("mdb-col", { attrs: { col: "12" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-start spainhn-subtitle-text-white mt-2 mb-2",
                                        staticStyle: {
                                          "font-size": "20px !important",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.item.information?.itemVisita
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "text-start" }, [
                                      _vm._v(
                                        _vm._s(_vm.fecha) +
                                          " - " +
                                          _vm._s(_vm.horario)
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-start",
                                        class: {
                                          "d-none":
                                            !_vm.item.information
                                              .numeroDeAsientos,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("default.SEATS_UI.SEATS", {
                                                msg: `${_vm.item.information.numeroDeAsientos} - ${_vm.item.information.zonaDeBoletos}`,
                                              })
                                            ) +
                                            " Asientos: " +
                                            _vm._s(
                                              _vm.item.information
                                                .numeroDeAsientos
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm.item.information.zonaDeBoletos
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "mdb-row",
                                [
                                  _c(
                                    "mdb-col",
                                    { attrs: { col: "2", md: "1" } },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "fa-ticket-alt",
                                          inverse: "",
                                          size: "2x",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("mdb-col", { attrs: { col: "auto" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "spainhn-text-lg fw-bold my-auto ms-3",
                                      },
                                      [_vm._v(_vm._s(_vm.totalDePersonas))]
                                    ),
                                  ]),
                                  _c(
                                    "mdb-col",
                                    { attrs: { col: "5", md: "4" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "spainhn-text-lg fw-bold my-auto ms-2",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.totalDeLaVenta)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "mdb-col",
                            {
                              staticClass: "mt-3 mt-md-0",
                              attrs: { col: "12", md: "6" },
                            },
                            [
                              _c(
                                "mdb-row",
                                { staticClass: "my-auto" },
                                [
                                  _c(
                                    "mdb-col",
                                    { attrs: { md: "12", col: "6" } },
                                    [
                                      _c(
                                        "mdb-btn",
                                        {
                                          staticClass:
                                            "spainhn-button float-md-right",
                                          staticStyle: {
                                            "background-color":
                                              "rgb(67,67,67) !important",
                                            color: "white !important",
                                          },
                                          on: { click: _vm.quitarReserva },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("default.BUTTONS.REMOVE")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "mdb-col",
                                    {
                                      staticClass: "my-auto pe-4",
                                      attrs: { md: "12", col: "6" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "my-auto float-md-right",
                                          attrs: { role: "button" },
                                          on: {
                                            click: () =>
                                              (_vm.mostrarDetalles =
                                                _vm.mostrarDetalles == true
                                                  ? false
                                                  : true),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("default.CART.DETAILS")
                                              )
                                          ),
                                          _c("font-awesome-icon", {
                                            staticClass: "ms-2",
                                            attrs: {
                                              icon: "angle-double-right",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "mdb-card-body",
            {
              staticClass: "fadeIn animated spainhn-bg-gris-cajas",
              class: { "d-none": !_vm.mostrarDetalles },
            },
            [
              _c(
                "h5",
                { staticClass: "text-start spainhn-text-lg ms-2 text-black" },
                [_vm._v(_vm._s(_vm.$t("default.CART.DETAILS")))]
              ),
              _vm._l(_vm.item.items, function (details) {
                return _c(
                  "div",
                  { key: details.itemID, staticClass: "my-2" },
                  [
                    _c(
                      "mdb-row",
                      { staticClass: "text-left" },
                      [
                        _c("mdb-col", { attrs: { col: "12" } }, [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "text-color-spainhn font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(details.itemVisita) + " ")]
                          ),
                        ]),
                        _c("mdb-col", { attrs: { col: "12" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.fecha) +
                              " - " +
                              _vm._s(_vm.prettyHour(details.itemHorario)) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("b-table", {
                      staticClass: "spainhn-text text-center text mb-0",
                      attrs: {
                        fields: _vm.resumenFields,
                        items: details.itemTickets,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(precio)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.value.toLocaleString("es-ES", {
                                          style: "currency",
                                          currency: "EUR",
                                        })
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(total)",
                            fn: function (data) {
                              return [
                                _c("div", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.value.toLocaleString("es-ES", {
                                          style: "currency",
                                          currency: "EUR",
                                        })
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }