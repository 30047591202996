<template>
  <mdb-container class="mt-3 border-top">
    <h4>Habitación 1</h4>
    <mdb-row>
        <mdb-col>
            <label class="form-label" for="adults">Adultos</label>
            <select v-model="adults" class="form-control" type="range" min="1" max="10" name="adults" id="adults" @change="updateOccupancie">
                <option value="1">1</option>
                <option value="2" >2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
            </select>
        </mdb-col>

        <mdb-col>
            <label class="form-label" for="children">Niños</label>
            <select v-model="children" class="form-control" type="range" min="0" max="4" name="children" id="children">
                <option value="0" >0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
            </select>
        </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
export default {
    props: {
        room: Number,
    },
    data(){
        return{
            children: 0,
            adults: 2,
        }
    },
    methods:{
        updateOccupancie(){
            let $vm = this;
            $vm.$emit('updateOccupancie', { children: $vm.children, adults: $vm.adults, room: $vm.room } );
        }
    },
    created(){
        this.updateOccupancie()
    }
}
</script>

<style>

</style>