<template>
    <default-layout>
        <!-- <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos" v-html="`${monumentoNombre} >>`"></p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"> {{ $t('default.NAVBAR_TICKET') }} </p>
            </div>
        </div> -->

        <white-loader :loading="loading"/>
        <minimalist-breadcrumb :title="monumentoNombre" style="margin-bottom: 15px;"/>

        <div style="min-height: 500px;" v-if="loading">
        </div>

        <div class="container-fluid px-5">
            <!-- <div class="d-flex justify-content-around pt-4 mb-3" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" 
                        :iconColor="'rgb(0,0,0)'" >
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black" v-html="monumentoNombre"></p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div> -->

            
            <!-- <b-spinner v-bind:class="{'d-none':!loading}" variant="danger" size="2x" style="width:5rem; height:5rem;"></b-spinner> -->            
            <div>
                <p class="spainhn-sections-title">{{ $t("default.MICROSITES_BOOKING") }}</p>
            </div>

            <!-- This is shown only in medium size screens -->
            <div class="d-md-flex d-none mb-4" v-if="entradas">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="!clorian">
                    <cards v-for="(entrada, index) in entradas" v-bind:key="index" @goToEntrada="goToEntrada($event)"
                        :entradaId="entrada.visitaId"
                        :monumentoNombreURL="monumentoUrl"
                        :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                        :apiDeVentasUrl="apiDeVentasActive ? `${apiDeVentasUrl}?idProducto=${entrada.oldId}`: ''"
                        :title="entrada.webName" :monument="entrada.monumentName" 
                        :precioOrientativo="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(entrada.precioOrientativo)}): ''"
                        ></cards>    
                </div>

                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="clorian">
                    <cards v-for="(entrada, index) in entradas" v-bind:key="index" @goToEntrada="goToEntrada($event)"
                        :tipo="'Evento'"
                        :entradaId="entrada.productId"
                        :monumentoNombreURL="monumentoUrl"
                        :imageUrl="entrada.image"
                        :apiDeVentasUrl="apiDeVentasActive ? `${apiDeVentasUrl}?idProducto=${entrada.oldId}`: ''"
                        :title="entrada.webName" 
                        :monument="monumentoNombre" 
                        :precioOrientativo="entrada.basePrice ? $t('default.CARDS.START_FROM', {msg: getPrice(entrada.basePrice)}): ''"></cards>    

                </div>
            </div>

            <!-- This is the new mobile version of the web design -->
            <div class="mobile-cards-container d-md-none">
                <recommendations-card v-for="(entrada, index) of entradas" 
                    v-bind:key="index"
                    :title="entrada.webName"
                    :showDefault="false"
                    :text="$t('default.CARDS.PLACE', { msg: entrada.monumentName})"
                    :price-description="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(entrada.precioOrientativo)} ):''"
                    :image-url="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                    :showBuyButton="true"
                    @goTo="goToEntrada( { entradaId: entrada.visitaId } )"
                    @buy="() => goToBuy( entrada.visitaId, entrada.oldId )"
                    />
            </div>

            <div v-bind:class="{'d-none':(paquetes.length == 0)}">
                <p class="spainhn-sections-title">Tours</p>
            </div>

            <div class="d-md-flex d-none mb-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <ResultsCards
                        v-for="paqueteM in paquetes" :key="paqueteM._id"
                        class="mx-auto" 
                        :title="paqueteM.name" 
                        :entradaId="paqueteM._id"
                        :showDefault="false"
                        :monumentoNombreURL="paqueteM.name"
                        :monument="$t('default.CARDS.PLACE', {'msg': paqueteM.monumentoId.nombre})"
                        :precioOrientativo="paqueteM?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(paqueteM?.precioOrientativo)}) : ''"
                        
                        :imageUrl="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                        tipo="paquetes_monumentos"
                        @goToEntrada="() => goToMicrositioPaqueteMonumento({ name: paqueteM.name, id: paqueteM._id })"
                        @buy="() => goToBuy(paqueteM, true)">
>

                    </ResultsCards>
                </div>
            </div>

            <div class="mobile-cards-container d-md-none mb-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <recommendations-card
                        v-for="(paqueteM,index) in paquetes" :key="index"
                        :title="paqueteM.name"
                        :showDefault="false"
                        :text="$t('default.CARDS.PLACE', {'msg': paqueteM.monumentoId.nombre})"
                        :price-description="paqueteM.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(paqueteM.precioOrientativo)}) : ''"
                        :image-url="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                        :showBuyButton="true"
                        tipoDeItem="paquetes_monumentos"
                        @goTo="() => goToMicrositioPaqueteMonumento( { name: paqueteM.name, id: paqueteM._id } )"
                        @buy="() => goToBuy2( paqueteM, true )"
                        />
                </div>
            </div>

            <div v-bind:class="{'d-none':(eventos.length == 0)}">
                <p class="spainhn-sections-title">{{ $t("default.MAIN_MENU_SERVICES_EVENTOS") }}</p>
            </div>

            <div class="d-md-flex d-none mb-4" v-if="(!clorian && eventos)">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards v-for="(entrada, index) in eventos" v-bind:key="index" @goToEntrada="goToEntrada($event)"
                        :tipo="'Evento'"
                        :entradaId="entrada.visitaId"
                        :monumentoNombreURL="monumentoUrl"
                        :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.imagen}`"
                        :title="entrada.webName" 
                        :apiDeVentasUrl="apiDeVentasActive ? `${apiDeVentasUrl}?idProducto=${entrada.oldId}`: ''"
                        :monument="monumentoNombre" :precioOrientativo="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(entrada.precioOrientativo)}): ''"
                        :date="entrada.fechas"
                        ></cards>    
                </div>
            </div>

                        <!-- This is the new mobile version of the web design -->
            <div class="mobile-cards-container d-md-none" v-if="(!clorian && eventos)">
                <recommendations-card v-for="(evento, index) of eventos" 
                    v-bind:key="index"
                    :title="evento.webName"
                    :showDefault="false"
                    :text="$t('default.CARDS.PLACE', {msg: monumentoNombre})"
                    :price-description="evento.precioOrientativo ? $t('default.CARDS.START_FROM', {'msg': getPrice(evento.precioOrientativo)}) : ''"
                    :image-url="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`"
                    :showBuyButton="true"
                    @goTo="goToEntrada( { entradaId: evento.visitaId } )"
                    @buy="() => goToBuy( evento.visitaId, evento.oldId )"
                    />
            </div>


        </div>

        <mdb-container fluid class="mt-3">
            <ComponentsCloud />
        </mdb-container>
        <!-- <trade-spoting v-if="monumentoUrl == 'castillo-de-belmonte'"></trade-spoting> -->
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
// import IconBase from '../components/IconBase.vue'
// import IconCasttle from '../components/icons/IconCasttle.vue';
import Cards from '../components/Cards.vue'
import ClorianService from '../services/ClorianService';
import MinimalistBreadcrumb from '../components/Breadcrumbs/MinimalistBreadcrumb'
import WhiteLoader from '../components/Loaders/WhiteLoader'
import RecommendationsCard from '../components/RecommendationsCard'
import ComponentsCloud from '../components/ComponentsCloud';
import ResultsCards from "../components/ResultsCards";
// import TradeSpoting from '../components/TradeSpoting.vue';

export default {
    components:{
        DefaultLayout,
        ResultsCards,
        // IconBase,
        // IconCasttle,
        Cards,
        MinimalistBreadcrumb,
        WhiteLoader,
        RecommendationsCard,
        ComponentsCloud
        // TradeSpoting
    },
    data(){
        return{
            address: null,
            // monumentoNombre: null,
            monumentoNombres:[],
            monumentoUrl: null,
            monumentoId: null,
            entradas: [],
            imagenesUrl: null,
            eventos: [],
            clorian: false,
            apiDeVentasActive: false,
            apiDeVentasUrl: "",
            loading: false,
            clorianPos: null,
            paquetes: [],
            monumentName: "",
        }
    },
    setup() {
        
    },
    methods:{
        encodeUrl(name = '') {
            let rawName = name.replaceAll(',', '');
            rawName = rawName.replaceAll('.', '');

            let sanitizedName = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
            let sanitizedNameLowercase = sanitizedName.toLowerCase().replace(/[\u0020]/g, '-');

            return sanitizedNameLowercase
        },
        goToMicrositioPaqueteMonumento( { name , id } ){
            console.log('🚀 ~ goToMicrositioPaqueteMonumento ~ name:', name)
            this.$router.push( { name: 'MicrositioPaqueteMonumento', params: { paqueteNombre: this.encodeUrl(name) || 'name', paqueteId: id } } )
        },
        goToBuy2( data, paqueteMonumento=false ) {
            if( data.apiDeVentasActive && data.apiDeVentasUrl !== 'clorian' ){
                this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
                setTimeout( () => {
                    window.open(`${data.apiDeVentasUrl}?idProducto=${data.oldId}`, '_blank');
                }, 2000 )
            }else{

                if( data.imageLabel === 'paquetes_prestadores'){
                    this.$router.push({ name: "ReservarPackExperiencias",  query: { tipo: 'paquete', id: data._id } } );
                }
                else if(data.imageLabel === "servicios_prestadores"){
                    this.$router.push({ name: "ReservarPackExperiencias",  query: { tipo: 'servicio', id: data._id } } );
                }
                else if( paqueteMonumento ){
                    this.$router.push({ name: "ReservarPackMonumentos",  query: { tipo: 'monumentos', id: data._id } } );
                }
                else{
                    this.$router.push(`/castillos/reservar/idvisita:${data.entradaId}`)
                }
            }
        },
        goToBuy( visitaId, oldId ){
            if( this.apiDeVentasActive && this.apiDeVentasUrl === 'clorian'){
                console.log("Comprar en clorian");
            }
            else if( this.apiDeVentasActive ){
                this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
                setTimeout( () => {
                    window.open(`${this.apiDeVentasUrl}?idProducto=${oldId}`, '_blank');
                    this.movingToCart = false;
                }, 2000 )
            }else{
                this.$router.push(`/castillos/reservar/idvisita:${visitaId}`)
            }
        },
        goToEntrada(event){
            if( this.monumentoUrl === 'castillo-de-san-pedro-de-jaca-(ciudadela)'){
                window.open(process.env.VUE_APP_JACA_URL);
            }
            else{
                this.$router.push('/castillos/tarifas/'+this.monumentoUrl+'/'+event.entradaId)
            }
        },
        goToEvento(event){
            this.$router.push('/castillos/eventos/'+this.monumentoUrl+'/'+event.entradaId)
        },
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.monumentoUrl)
        },
        getPrice( precioOrientativo ){
            if( precioOrientativo ){
                if( isNaN(Number(precioOrientativo)) ){
                    return '';
                }else{
                    return `${parseFloat(Number(precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}`
                }
            }else{
                return '';
            }
        },
    },
    computed:{
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            else if($vm.$i18n.locale == 'ja'){
                idiomaIndex = 4;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        monumentoNombre(){
            let $vm = this;
            // console.log($vm.monumentoNombres)
            try{
                return $vm.monumentName;
            }
            catch(err){
                return "";
            }
        },

    },
    async mounted(){
        let $vm = this;
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
        // $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;
        $vm.loading = true;
        const _language = this.$store.getters.getIdioma;
        ApiService.getEdificacion(_language, $vm.monumentoUrl)
            .then( result => {
                let data = result.data;
                $vm.monumentoNombres = result.data.nombres;
                $vm.address = data.direccion;
                $vm.monumentoId = data.id;
                this.monumentName = data.webName;
                $vm.clorianPos = data.clorianPos;
                // $vm.edificios = [...result.data];
                this.apiDeVentasActive = data.apiDeVentasActive;
                this.apiDeVentasUrl = data.apiDeVentasUrl;
                if(data.apiDeVentasActive == true && data.apiDeVentasUrl == 'clorian'){
                    $vm.clorian = true;
                    ClorianService.consultarProductos( data.nombreNormalizado, $vm.clorianPos)
                        .then( response => {
                            $vm.loading = false;
                            $vm.entradas = [...response.data.products];
                            // console.log($vm.entradas);
                        })
                        .catch( err => {
                            console.log("Error en la respuesta de clorian")
                            $vm.loading = false;
                            console.log(err);
                        })
                }
                else{
                    ApiService.getVisitasByEdificacion(_language, $vm.monumentoId)
                        .then( result => {
                            $vm.loading = false;
                            $vm.entradas = [...result.data.filter( e => e.restringirVentaIndividual !== true )];
                        })
                        .catch( err=> {
                            console.log("Error en las visitas")
                            $vm.loading = false;
                            console.log(err);
                        })
                    ApiService.getEventosByMonumento(_language, $vm.monumentoId )
                        .then( result => {
                            $vm.eventos = result.data.filter( resultado => resultado.fechas.length > 0).filter( e => e.restringirVentaIndividual !== true );
                        } )
                        .catch(err => {
                            console.log("Error en los eventos")
                            console.log(err);
                        })
                    ApiService.getPaquetes( this.monumentoId )
                        .then( result => {
                            console.log( result );
                            this.paquetes = result.data.filter( r => r.tipo_de_paquete === 'pack_monumentos');
                            console.log( this.paquetes );
                        })
                        .catch( err => {
                            console.log( err );
                        })

                }
            })
            .catch(err => {
                console.log("Error consultado info de edificación")
                console.log(err);
            })
    },
}
</script>

<style scoped>
.mobile-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
}
</style>