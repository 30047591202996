var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card my-auto mx-2 mt-5",
      staticStyle: { backgroundRepeat: "no-repeat" },
      style: { backgroundImage: _vm.image },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column mt-auto h-100 justify-content-end",
            },
            [
              _c("strong", { staticClass: "spainhn-text mt-5" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("small", { staticClass: "spainhn-small-text" }, [
                _vm._v(_vm._s(_vm.monument)),
              ]),
              _c("strong", { staticClass: "spainhn-text" }, [
                _vm._v(_vm._s(_vm.date)),
              ]),
              _c(
                "mdb-btn",
                {
                  staticClass: "spainhn-button mt-2",
                  attrs: { color: "indigo", size: "sm" },
                  on: { click: _vm.goTo },
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("default.SPAINHN_BUTTON_ROUTE"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }