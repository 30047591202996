var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-container position-relative my-2" }, [
    _vm.buyBtn
      ? _c(
          "span",
          {
            staticClass: "position-absolute w-100 start-0 text-white",
            class: _vm.tipoDeResultado,
            staticStyle: { "z-index": "1 !important" },
            attrs: { role: "button" },
            on: { click: () => _vm.$emit("goToBuy") },
          },
          [
            _vm._v(
              " + " + _vm._s(_vm.$t("default.SPAINHN_BUTTON_MORE_INFO")) + " "
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "d-flex flex-column", attrs: { role: "button" } },
      [
        _c(
          "div",
          {
            staticClass: "image-container",
            on: { click: () => _vm.$emit("goToUrl") },
          },
          [_c("img", { staticClass: "d-block", attrs: { src: _vm.image } })]
        ),
        _vm.buyBtn
          ? _c("buy-component", {
              staticClass: "mt-4 opacity-75",
              staticStyle: { position: "absolute" },
              attrs: { showText: true, paquete: "" },
              on: {
                goTo: function ($event) {
                  return _vm.$emit("goToUrl")
                },
              },
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "info-container position-relative px-2 py-1",
            class: { "bg-corp": _vm.type === "monumento" },
            on: { click: () => _vm.$emit("goToUrl") },
          },
          [
            _c(
              "div",
              {
                staticClass: "text-container pt-0",
                class: {
                  "d-flex flex-column justify-content-evenly align-content-center":
                    _vm.type === "monumento",
                },
                staticStyle: { "box-sizing": "border-box" },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "info-title-text text-uppercase text-center p-1 align-content-center",
                    class: {
                      "spainhn-gray-background": _vm.type === "monumento",
                    },
                    staticStyle: {
                      "border-radius": "10px",
                      "min-height": "32px",
                      "overflow-y": "scroll !important",
                      "scrollbar-color":
                        "var(--spainhn-rojo-corporativo) #ffffff99",
                      "scrollbar-width": "thin",
                    },
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm.showSubtitle === true
                  ? _c(
                      "p",
                      {
                        staticClass: "info-subtitle-text text-center",
                        class: { "text-white": _vm.type === "monumento" },
                      },
                      [_vm._v(_vm._s(_vm.subtitle))]
                    )
                  : _vm._e(),
                _c(
                  "p",
                  {
                    staticClass: "info-none-text text-center mb-0",
                    class: { "fw-bold": _vm.bold, "text-secondary": _vm.muted },
                  },
                  [_vm._v(" " + _vm._s(_vm.content) + " ")]
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }