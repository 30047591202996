var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spainhn-navigation-bar" }, [
    _c(
      "div",
      { staticClass: "spainhn-navigation-bar-container" },
      [
        _vm._l(_vm.navigationLinks, function (link, index) {
          return _c(
            "span",
            {
              key: index,
              staticClass: "my-auto",
              staticStyle: { flex: "0 0 auto" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "my-auto",
                  class: { "navigation-item": link.navigation === true },
                  staticStyle: { "vertical-align": "middle" },
                  on: { click: () => _vm.navigateToLink(link.navigationLink) },
                },
                [_vm._v(" " + _vm._s(link.text) + " ")]
              ),
              index < _vm.navigationLinks.length - 1
                ? _c(
                    "svg",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "2",
                        height: "14",
                        viewBox: "0 0 2 14",
                        fill: "none",
                      },
                    },
                    [_c("path", { attrs: { d: "M1 0V13.5", stroke: "white" } })]
                  )
                : _vm._e(),
            ]
          )
        }),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              flex: "1 1 auto",
              "overflow-x": "auto",
              "white-space": "nowrap",
            },
          },
          [_vm._t("default")],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }