var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid flex flex-column px-0" }, [
    _c("div", { staticClass: "collapsable-header spainhn-gray-background" }, [
      _c("div", { staticClass: "header-title-container" }, [
        _c(
          "span",
          {
            staticClass: "text-uppercase",
            class: {
              "header-active-typhograpy": _vm.showContent,
              "header-typhograpy": !_vm.showContent,
            },
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _c(
          "span",
          {
            staticStyle: { width: "30px", height: "30px" },
            attrs: { role: "button" },
            on: { click: () => _vm.toggleContent() },
          },
          [
            _c(
              "svg",
              {
                class: { "d-none": _vm.showContent },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "30",
                  height: "30",
                  viewBox: "0 0 30 30",
                  fill: "none",
                },
              },
              [
                _c("circle", {
                  attrs: { cx: "15", cy: "15", r: "15", fill: "white" },
                }),
                _c("path", {
                  attrs: {
                    d: "M7 15H23",
                    stroke: "#B9B9B9",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M15 23L15 7",
                    stroke: "#B9B9B9",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
              ]
            ),
            _c(
              "svg",
              {
                class: { "d-none": !_vm.showContent },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "30",
                  height: "30",
                  viewBox: "0 0 30 30",
                  fill: "none",
                },
              },
              [
                _c("circle", {
                  attrs: { cx: "15", cy: "15", r: "15", fill: "white" },
                }),
                _c("path", {
                  attrs: {
                    d: "M7 15H23",
                    stroke: "#850B0B",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "animated fadeIn",
          class: { "d-none": !_vm.showContent },
          staticStyle: { width: "100%", "margin-top": "0" },
        },
        [
          _c("hr", {
            class: { "d-none": _vm.noDivide },
            staticStyle: { color: "black", "margin-top": "0" },
          }),
          _c("div", { staticStyle: { "text-align": "start !important" } }, [
            _c("span", {
              staticStyle: { height: "auto", width: "100%" },
              domProps: { innerHTML: _vm._s(_vm.htmlContent) },
            }),
          ]),
          _vm._t("default"),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }