<template>
    <default-layout>
        <!-- <header-image/> -->
        <!-- <div class="spainhn-solid-red-background d-flex align-items-center" style="height:auto; min-height:5vh; width:100%">
            <div class="ps-3 me-3">
                <icon-base :width="80" :height="80" class=""
                    :iconColor="'rgb(255,255,255)'" >
                    <icon-routes></icon-routes>
                </icon-base>
            </div>
            <h3 class="my-auto">{{tipo}}</h3>
        </div> -->

        <SpainhnNavigationBreadcrumb :navigation-links="links"/>
        
        <div 
            class="image-header-container"
            v-bind:style="{'backgroundImage': `url('${imagenesUrl}/rutas/logo/${microsite.rutaId}`}" 
            style="background-position: top; background-size: cover; background-repeat: no-repeat">
            <!-- <div class="container-fluid h-100">
                <div class="row h-100">
                    
                    <div class="pt-0 col-lg-7 col-12 px-lg-5 px-1 text h-100 d-flex flex-column align-middle my-auto" style="min-height:80vh; color:white !importante; background-color: rgba(0,0,0,0.7);">
                        <strong class="spainhn-subtitle2 mt-auto mb-3" style="font-size:1.5rem !important; color:white;">{{nombre}}</strong>
                        <span class="text-start mb-auto spainhn-small-text" v-html="descripcion" style="font-size:0.9rem !important; font-weight:600; color:white !important; opacity:1 !important;"></span>
                    </div>
                    
                </div>
            </div> -->
        </div>

        <div class="container mt-2">
            <div class="text-start">
    
                <div class="spainhn-header-info mt-auto mb-3 d-flex">
                    <div style="margin-right: 10px">
                        <icon-route-red />                        
                    </div>
                    <div>
                        {{microsite.webName}}
                    </div>
                </div>
                <span v-html="`<div class='text-content'>${microsite.webDescription}</div>`">
    
                </span>
            </div>
        </div>

        <div class="container-fluid px-0 px-md-3 px-lg-5 margen-estandard mt-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="pt-3 h-100 pe-3 col-xxl-8 col-12 order-md-first">
                        <div class="d-flex flex-column">
                            <span class="spainhn-sections-title text-uppercase mb-2">Mapa</span>
                            <div class="mb-5 d-flex align-items-center justify-content-center d-flex mx-auto" >
                                <!-- <youtube class="me-auto" :video-id="videoId" ref="youtube" width="100%" height="240"></youtube> -->
                                <img style=" width:100%; max-width:90vw;" class="align-items-center mx-auto" 
                                :src="`${imagenesUrl}/rutas/mapa/${microsite.rutaId}`"/>
                            </div>
                        </div>
                    </div>

                    <div class="d-md-flex d-none flex-column pt-3 h-100 col-xxl-4 col-12 order-md-last">
                        <div class="flex-grow spainhn-bg-gris-cajas ps-4 pe-4 pt-3 d-flex flex-column  text-start spainhn-text pb-5 pt-5">
                            <div class="d-flex mb-3 text-center">
                                <icon-base :width="80" :height="80" class="my-auto me-2"
                                    :iconColor="'rgb(0,0,0)'" >
                                    <icon-casttle></icon-casttle>
                                </icon-base>
                                <h3 class="d-flex fw-bold spainhn-text mt-4" style="font-size:20pt; font-weight:700">{{$t('default.ROUTES_DETAILS_WHERE_TITLE')}}</h3>
                            </div>

                            <div class="spinhn-text text-start w-100">
                                <p class="spainhn-text" style="list-style-type:decimal !important; font-size:15pt; " v-html="microsite.webLocations"></p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="d-flex d-md-none">
                    <collapsable-element :title="$t('default.ROUTES_DETAILS_WHERE_TITLE')" :content="microsite.webLocations"/>
                </div>
            </div>

            <div class="container-fluid mb-2" v-if="microsite.rutaEnlazada">
                <div class="row text-start">
                    <div class="col-12 mx-auto">
                        <div class="text-center pt-0 w-100 mx-auto">
                            <strong class="spainhn-subtitle-black" style="font-size:20pt">{{ $t("default.MENSAJE_RUTA_ENLAZA") }}</strong>
                        </div>
                        <div class="w-100 border-bottom mb-0" style="height:2vh; border-color:var(--spainhn-rojo-corporativo) !important; border-width: medium !important;"></div>
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                            <cards-3
                                @goTo="goToRuta($event)"
                                :title="microsite.rutaEnlazada.webName"
                                :linkId="microsite.rutaEnlazada.oldId"
                                :imageUrl="`${imagenesUrl}/rutas/logo/${rutaOldIdImage}`"
                            > 
                            </cards-3>
                        </div>
                    </div>

                </div>
            </div>    

            <div class="container d-md-none" style="margin-top: 10px;">
                <div class="spainhn-sections-title">
                    MONUMENTOS DE LA RUTA
                </div>
                <div class="d-flex flex-wrap justify-content-center">
                    <recommendations-card 
                        v-for="(edificio, index) in edificios" v-bind:key="index"
                        :title="edificio.webName"
                        class="my-2"
                        :nombreUrl="edificio._id"
                        @goTo="(evt) => goToCastillo(evt, edificio.nombreNormalizado)"
                        :image-url="`${imagenesUrl}/monumentos/${edificio.oldId}/${edificio.banner}`"
                        :showDefault="false"
                        />
                </div>
            </div>

            
            <div class="container-fluid mb-5 mt-4 d-md-flex flex-wrap d-none">
                <div class="row w-100">
                    <div class="col-12 ps-4 ps-md-0 mx-auto">
                        <div class="text-center pt-0  mx-auto">
                            <strong class="spainhn-subtitle-black" style="font-size:20pt">{{$t('default.ROUTES_DETAILS_MONUMENTS')}}</strong>
                        </div>
                        <div class="w-100 border-bottom mb-3" style="height:2vh; border-color:var(--spainhn-rojo-corporativo) !important; border-width: medium !important;"></div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                            <cards-2 v-for="(edificio, index) in edificios" v-bind:key="index"
                                :monument="edificio.webName"
                                :imageUrl="`${imagenesUrl}/monumentos/${edificio.oldId}/${edificio.banner}`"
                                :text="edificio.webDescription"
                                :id="edificio._id"
                                :provincia="edificio.provincia"
                                :municipio="edificio.municipio"
                                :nombreUrl="edificio.nombreNormalizado"
                                role="button"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
import IconBase from '../components/IconBase.vue'
import IconRoutes from '../components/icons/IconRoutes.vue'
import IconCasttle from '../components/icons/IconCasttle.vue';
import ApiService from '../services/ApiService';
import Cards2 from '../components/Cards2.vue';
import Cards3 from '../components/Cards3.vue';
import SpainhnNavigationBreadcrumb from '../components/SpainhnNavigationBreadcrumb.vue';
import IconRouteRed from '../components/icons/IconRouteRed'
import CollapsableElement from '../components/CollapsableElement';
import RecommendationsCard from '../components/RecommendationsCard';

export default {
    components:{
        DefaultLayout, 
        IconBase,
        // IconRoutes,
        IconCasttle,
        Cards2,
        Cards3,
        SpainhnNavigationBreadcrumb,
        IconRouteRed,
        CollapsableElement,
        RecommendationsCard
    },
    data(){
        return{
            rutaId:"",
            descripcion: null,
            edificios:[],
            edificiosResumen:[],
            imagenes:[],
            ubicacion:null,
            nombre:null,
            castilloId: null,
            nombreUrl: null,
            monumentoOldId: null,
            tipo:null,
            imagen:null,
            rutaEnlazada: null,
            rutaEnlazadaNombre: "",
            rutaEnlazadaId:"0",
            rutaEnlazadaOldId: null,
            imagenesUrl: null,
            links: [
                { text: this.$t("default.ROUTES.HEADER") }
            ],
            microsite: {
                webName: "",
                webDescription: "",
                webLocations: ""
            }
        }
    },
    methods:{
        goToRuta(rutaId){
            this.$router.push({path:`/ruta/${rutaId}`})
        },
        getPageInfo(rutaId){
            let $vm = this;
            const _language = this.$store.getters.getIdioma;
            ApiService.getRuta( _language, rutaId)
            .then( result => {
                let data = result.data;

                this.microsite = {...result.data };
                $vm.tipo = data.tipo;
                $vm.edificiosResumen = data.castillos;
                $vm.descripcion = data.descripcion;
                $vm.ubicacion = data.ubicacion;
                $vm.nombre = data.nombre;
                $vm.imagen = data.oldId;

                $vm.rutaEnlazada = data.rutaEnlazada;

                if($vm.rutaEnlazada){
                    $vm.rutaEnlazadaNombre = this.rutaEnlazada.webName
                    $vm.rutaEnlazadaId = $vm.rutaEnlazada._id;
                    $vm.rutaEnlazadaOldId = $vm.rutaEnlazada.rutaId;
                }
                else{
                    $vm.rutaEnlazadaNombre = ""
                }

                if($vm.tipo === "Historical"){
                    $vm.tipo = "Rutas Históricas"
                }
                else if($vm.tipo === "Hicking"){
                    $vm.tipo = "Rutas de Senderísmo"
                }
                else if($vm.tipo === "Autor"){
                    $vm.tipo = "Rutas de Autor"
                }
                else if($vm.tipo == "Collector"){
                    $vm.tipo = "Rutas de Coleccionista"
                }

                let edificiosIds = []
                $vm.edificiosResumen.forEach(element => {
                    edificiosIds.push(element._id);
                });
                ApiService.getMonumentosById( _language, edificiosIds)
                    .then(res => {
                        $vm.edificios = [...res.data];
                    })
                    .catch(err=>{
                        console.log(err);
                    })
            })
            .catch(err=>{
                console.log(err);
            })
        },

        encodeUrl( name ){
            let rawName =  name.replaceAll(',', '');
            rawName =  rawName.replaceAll('.', '');

            let sanitizedName = rawName.normalize("NFD").replace( /[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g,'')
            let sanitizedNameLowercase = sanitizedName.toLowerCase().replace(/[\u0020]/g, '-');

            return sanitizedNameLowercase
        },

        goToCastillo( evt, nombre ){
            const encodedName = this.encodeUrl( nombre );            
            this.$router.push(`/castillos/${encodedName}`);
        }
    },
    setup() {
        
    },
    mounted(){
        let $vm = this;
        $vm.rutaId = $vm.$route.params.rutaId;
        // console.log($vm.rutaId);
        this.getPageInfo($vm.rutaId);
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    computed: {
        player() {
            return this.$refs.youtube.player
        },
        isCastillosSelected(){
            let $vm = this;
            if($vm.selectedType === "Castillos"){
                $vm.castillosColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.castillosDescriptionText');
                return true;
            }
            else{
                $vm.castillosColor = $vm.disabledColor;
                return false;  
            } 
        },
        selectedIdioma(){
            const _idioma = this.$store.getters.getIdioma;            
            return _idioma;
        },
        rutaOldIdImage(){
            let $vm = this;
            console.log($vm.rutaEnlazadaOldId);
            return $vm.rutaEnlazadaOldId;
        }
    }
}
</script>


<style scoped>
    @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){
        .text-columns{
        column-count: 2;
        /* height: 50vh; */
        }
        .image-container{
            height: 10rem !important;
        }
    }

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        }
        .image-container{
            height: 10rem !important;
        }
    }
    .image-header{
        position: absolute;
        height: 55vh;
        width: 100%;
        background-position: center;
        /* background-image: url('../assets//MONUMENTOS_.jpg'); */
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(133, 11, 11, 0.85) 100% );
    }
    .image-header-container{
        position: relative;
        width: auto;
        height: 55vh;
    }
    .text-content{
        color: #000 !important;
        font-family: Montserrat;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
    }
</style>