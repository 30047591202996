var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-card",
    { staticClass: "px-0", staticStyle: { width: "320px" } },
    [
      _c("mdb-view", [
        _c("img", {
          staticStyle: {
            width: "320px",
            height: "320px",
            "object-fit": "cover",
          },
          attrs: {
            src: `${_vm.imagePath}/${_vm.mainImagePath}`,
            alt: `${_vm.name} image cap`,
          },
        }),
      ]),
      _c(
        "mdb-card-body",
        [
          _c("mdb-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.name) + " "),
          ]),
          _c("mdb-container", [
            _c(
              "div",
              { staticClass: "d-flex text-warning" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: `${_vm.stars < 1 ? "far" : "fa"} fa-star` },
                }),
                _c("font-awesome-icon", {
                  attrs: { icon: `${_vm.stars < 2 ? "far" : "fa"} fa-star` },
                }),
                _c("font-awesome-icon", {
                  attrs: { icon: `${_vm.stars < 3 ? "far" : "fa"} fa-star` },
                }),
                _c("font-awesome-icon", {
                  attrs: { icon: `${_vm.stars < 4 ? "far" : "fa"} fa-star` },
                }),
                _c("font-awesome-icon", {
                  attrs: { icon: `${_vm.stars < 5 ? "far" : "fa"} fa-star` },
                }),
              ],
              1
            ),
          ]),
          _c("mdb-container", [
            _c("div", { staticClass: "d-flex" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(
                    _vm.distance >= 0.01
                      ? Math.floor(_vm.distance * 100)
                      : " menos de 1"
                  ) + " km"
                ),
              ]),
            ]),
          ]),
          _c("mdb-container", [
            _c("div", { staticClass: "d-flex" }, [
              _c("h4", [_vm._v(_vm._s(_vm.minRate))]),
            ]),
          ]),
          _c(
            "mdb-container",
            { staticClass: "mt-2" },
            [
              _c("mdb-btn", { attrs: { color: "danger" } }, [
                _vm._v("VER DETALLES"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }