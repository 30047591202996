<template>
    <default-layout>
        <header-image-rutas/>

        <white-loader :loading="loading"/>
        <div class="continaer-fluid d-md-none">
            <tools-component style="width: 100%;"/>
        </div>
        <div class="d-md-none container-fluid">

            <span class="spainhn-sections-title my-2 text-start text-uppercase">
                {{ $t('default.ROUTES_TITLE') }}
            </span>

            <div class="row d-md-none px-1">
                <div class="col px-0" v-for="(ruta, index) in rutas" v-bind:key="index">
                    <cards-general 
                        :showSubtitle="false"
                        :title="ruta.webName" 
                        :image-url="`${imagenesUrl}/rutas/logo/${ruta?.oldId}`"
                        @goToUrl="() => goToUrl(ruta._id)"
                        />
                </div>
            </div>

        </div>

        <div class="container-fluid px-5 rutas-main d-md-inline-block d-none">

            <p class="spainhn-subtitle-2 mb-0 mt-auto text-start">{{ $t('default.ROUTES_TITLE') }}</p>

            <div class="d-md-flex justify-content-around pt-0 mt-0 mb-5 d-none">
                <div class="row">
                    <div class="ms-0 col-md-4 text-start spainhn-text pe-2">
                        {{$t('default.ROUTES_DESCRIPTION_TEXT')}}
                    </div>                
                    <div class="col-md-8">
                        <div class="ms-0 h-100 d-flex flex-column">
                            <p class="spainhn-subtitle-3 mb-0 text-start mt-0">{{$t('default.ROUTES_FILTER_TEXT')}}</p>
                            <div class="row">
                                    <div class="col-md-6 col-12 pt-3 align-items-start justify-content-letf">
                                        <mdb-btn color="indigo" @click="setHistorical" class="spainhn-button d-block w-75 ">{{$t('default.ROUTES_FILTER_HISTORICAL')}}</mdb-btn>
                                    </div>
                                    <div class="col-md-6 col-12 pt-3 align-items-start">
                                        <mdb-btn color="indigo" class="spainhn-button d-block w-75" @click="setAutor">{{$t('default.ROUTES_FILTER_AUTOR')}}</mdb-btn>
                                    </div>
                                    <div class="col-md-6 col-12 pt-3 align-items-start">
                                        <mdb-btn color="indigo" @click="setHicking" class="spainhn-button d-block w-75">{{$t('default.ROUTES_FILTER_HICKING')}}</mdb-btn>
                                    </div>
                                    <div class="col-md-6 col-12 pt-3 align-items-start">
                                        <mdb-btn color="indigo" @click="setColeccionista" class="spainhn-button d-block w-75">{{$t('default.ROUTES_FILTER_COLLECTOR')}}</mdb-btn>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div>

            </div>

            <div class="d-md-flex mb-5 mt-0 d-none">
                <div class="d-flex justify-content-md-around px-0 mx-auto w-100 flex-wrap">
                    <cards-3 v-for="(ruta, index) in rutas" v-bind:key="index" 
                        @goTo="goToRuta($event)"
                        :title="ruta.webName"
                        :linkId="ruta._id"
                        v-bind:class="{'d-none':(ruta.tipo != filter && filter != null) }"
                        :imageUrl="`${imagenesUrl}/rutas/logo/${ruta.oldId}`" 
                        />
                </div>
            </div>    
        </div>
        <ComponentsCloud />
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
// import HeaderImage from '../components/HeaderImage.vue'
import HeaderImageRutas from '../components/HeaderImageRutas.vue'
import ComponentsCloud from '../components/ComponentsCloud.vue';
import Cards3 from '../components/Cards3.vue'
import ApiService from '../services/ApiService';
import ToolsComponent from '../components/Tools/ToolsComponent.vue';
import CardsGeneral from '../components/CardsGeneral.vue';
import WhiteLoader from "../components/Loaders/WhiteLoader.vue"

export default {
    name:'Rutas recomendadas',
    components:{
    DefaultLayout, Cards3, HeaderImageRutas,
    ToolsComponent,
    CardsGeneral,
    ComponentsCloud,
    WhiteLoader
},
    data(){
        return{
            historicasFilter: null,
            autorFilter: null,
            senderismoFilter: null,
            coleccionistaFilter: null,
            selectedText: "Castillos",
            historicasOptions:[
                {'value':null, 'text':"Rutas Históricas"}
            ],
            autorOptions:[
                {'value':null, 'text':"Rutas de Autor"}
            ],            
            coleccionistaOptions:[
                {'value':null, 'text':"Rutas de Coleccionista"}
            ],
            senderismoOptions:[
                {'value':null, 'text':'Rutas Senderismo'}
            ],
            descripciones: this.$t('default.castillosDescriptionText'),
            rutas:[],           
            filter:null,
            imagenesUrl:null,
            loading: false
        }
    },
    setup() {
        
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    methods:{
        goToUrl( rutaId ){
            this.$router.push('/ruta/' + rutaId);
        },
        goToRuta(rutaId){
            this.$router.push('/ruta/' + rutaId)
        },
        setColeccionista(){
            this.filter = "Collector"
        },
        setHicking(){
            this.filter = "Hicking";
        },
        setAutor(){
            this.filter = "Autor"
        },
        setHistorical(){
            this.filter = "Historical"
        }
    },
    created(){
        this.loading = true;
        const _language = this.$store.getters.getIdioma ?? "es";
        this.$i18n.locale = this.$store.getters.getIdioma ?? "es";
        ApiService.getAllRutas(_language)
            .then(res =>{
                this.rutas = res.data;
            })
            .catch(err=>{
                console.log(err);
            })
            .finally( () => {
                this.loading = false;
            })
    },
    computed:{
    }
}
</script>

<style scoped>
    @media (max-width: 1440px){
        .rutas-main .spainhn-subtitle-2{
            font-size: 22pt;
        }
    }
    @media (max-width: 991px){
        .rutas-main .spainhn-subtitle-2{
            font-size: 20pt;
        }
        .rutas-main .spainhn-button{
            width: 100% !important;
        }
        .rutas-main{
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }
    }
    @media (max-width: 768px){
        .rutas-main .spainhn-subtitle-2{
            font-size: 16pt;
        }
    }
    @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }
    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>