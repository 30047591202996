<template>
  <div class="card-container" :class="{'w-auto': autoWidth}">
    <div class="d-flex" role="button">
      <div class="image-container" role="button" @click="() => $emit('goTo', nombreUrl)">
        <img :src="image" class="d-block" style="">
        <small class="fw-bold" :class="labelClass"
          style="position: absolute; bottom: 0; left: 0; z-index: 10; width: 100%; color: white; font-size: 8px;">
          <strong class="spainhn-text my-auto">
            + {{ $t('default.SPAINHN_BUTTON_MORE_INFO') }}
          </strong>
        </small>
      </div>
      <div class="info-container">
        <div class="text-container pt-1" :class="{'w-auto': autoWidth}" role="button" @click="() => $emit('goTo', nombreUrl )">
          <div class=" text-wrapper">
            <span class="info-title-text">
              {{ title }}
            </span>
            <span class="ms-auto">
              <slot></slot>
            </span>
          </div>
          <div class="one-line-container">
            <span 
              class="info-none-text text-uppercase px-1 py-0 rounded-3 overflow-hidden" 
              v-bind:class="priceDescriptionClass">{{
              priceDescription }}</span>
          </div>
          <div class="one-line-container">
            <span class="info-none-text mt-auto" v-bind:class="{'d-none': text === null}">{{ text }}</span>
          </div>

          <p class="info-dates-text mt-auto mb-0" v-bind:class="{'d-none':fecha.length === 0}">{{ fechasEvento }}</p>
          <p class="info-none-text mt-auto mb-0" v-bind:class="{'d-none':fecha.length > 0}" v-if="showDefault">{{
            fechasEvento }}</p>
        </div>
        <span role="button" @click="() => $emit('buy')">
          <icon-ticket v-if="showBuyButton" class="buy-icon" />
        </span>
      </div>
    </div>
  </div>
</template>
  
  <script>

  import IconTicket from '../components/icons/IconTicket.vue'
  export default {
    name: 'RecommendationsCard',
    components:{
      IconTicket
    },
    props:{
      imageUrl: String,
      title: String,
      monument: String,
      text: String,
      nombreUrl: String,
      priceDescription: String,
      tipo: String,
      fecha: { type: Array, default: () => [] },
      showDefault: { type: Boolean, default: true },
      showBuyButton: { type: Boolean, default: false },
      autoWidth: { type: Boolean, default: false },
      showInfo: {type: Boolean, default: true},
      tipoDeItem: { type: String, default: 'visitas'}
      // date: String
    },
    data(){
      return{
        // image: `url(${require('@/assets/img_avatar.png')})`
        // image: `url(${this.imageUrl})`,
        status: false
        // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
      }
    },
    methods:{
      showText(){
        if(this.status == true)
          this.status = false;
        else
          this.status = true;
      },
      goTo(){
        // console.log(this.monument);
        let $vm = this;
        if($vm.tipo != "Entrada"){
          $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre + '/restaurantes-y-actividades/' + $vm.tipo +'/' + $vm.nombreUrl);        
        }
        else{
          // console.log(this.nombreUrl)
          this.$emit('goTo', this.nombreUrl);
        }
      }
    },
    mounted(){
      // console.log(this.monument);
      // console.log(this.nombreUrl);
    },
    setup() {
  
    },
    computed:{
        image(){
            let urlEnconded = encodeURI(this.imageUrl)
            return `${urlEnconded}`;
        },
        labelClass(){
          let classesToReturn = ''
          if( !this.showInfo ){
            classesToReturn += 'd-none '
          }
          if( this.tipoDeItem === 'paquetes_monumentos'){
            classesToReturn += 'spainhn-label-paquete'
          }else{
            classesToReturn += 'spainhn-label-visit'
          }
          return classesToReturn;
        },
        priceDescriptionClass(){
          let classesToReturn = '';

          if(this.priceDescription === null || this.priceDescription === '' || this.priceDescription === undefined || this.priceDescription.includes('NaN')){
            classesToReturn += 'd-none';
          }
          if( this.tipoDeItem === 'paquetes_monumentos'){
            classesToReturn += 'spainhn-label-paquete text-white'
          }else{
            classesToReturn += 'spainhn-label-visit text-white'
          }
          return classesToReturn;
        },
        fechasEvento(){
    
            if(this.fecha){
    
                if(this.fecha.length == 0){
                    return this.$t('default.PROXIMAMENTE_TEXT');
                }
                else{
                    let fechasString = "";
        
                    let tempFechas = this.fecha;
        
                    tempFechas.sort( function(a, b){
                        return new Date(a) - new Date(b)
                    })
                    
                    if(this.fecha.length > 1){
                    fechasString = new Date(tempFechas[0]).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'}) + " - ";
                    let lastFecha = new Date(tempFechas[ tempFechas.length - 1] ).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'});
                    fechasString += lastFecha;
                    return fechasString;
                    }
        
                    else{
                    tempFechas.forEach( date => {
                        fechasString += new Date(date).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'})
                    })
                    return fechasString;
                    }
                }
            }
            else return ""
        }
    }
  }
</script>
  
<style scoped>
  img {
      border-radius: 10px 1px 0 0;
  }
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .card-container {
    width: 360px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--DEGRA, #E5E5E5);
  }

  .image-container{
    width: 100px;
    /* height: 120px; */
    flex-shrink:0;
    position: relative;
  }

  .image-container > img{
    width: 100%;
    /* height: 120px; */
    object-fit: cover;
    border-radius: 10px 0px 0px 10px;
  }
  
  .info-container{
    width: 100%;
    display: flex;
    padding-left: 20px;
    justify-content: left;
    align-items: space-around;
    position: relative;
  }

  .text-wrapper{
    display: flex;
    flex-direction: row;
    /* width: 330px; */
  }
  .info-title-text{
    text-align: start;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
    text-overflow: ellipsis; 
  }

  .info-dates-text{
    text-align: start;
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .one-line-container{
    width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
  .info-none-text{
    text-align: start;
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text-container{
    /* width: 320px; */
    height: 80px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: var(--spainhn-rojo-spainhn);
  }

  .buy-icon-container{
    position: absolute;
  }

  .buy-icon{
    position: absolute;
    bottom: 0%;
    flex-shrink: 0;
  }

  
  @media (min-width: 768px) {
  .card-container {
    width: 520px;
    height: 120px;
  }
  .image-container {
    width: 150px;
    height: 120px;
  }
  .image-container > img {
    height: 120px;
  }
  .text-container {
    width: 350px;
    height: 120px;
  }

  .text-wrapper {
    width: 330px;
  }
  .info-title-text {
    font-size: 18px;
  }
  .info-dates-text,
  .info-none-text {
    font-size: 15px;
  }
  .buy-icon{
    right: 10px;
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 480px) {
  .card-container {
    width: 250px;
    height: 70px;
  }

  .image-container > img {
    height: 70px;
  }

  .image-container {
    width: 70px;
    height: 70px;
  }
  .text-container {
    width: 180px;
    height: 70px;
  }
  .info-title-text {
    font-size: 10px;
  }
  .info-dates-text,
  .info-none-text {
    font-size: 8px;
  }
  .buy-icon{
    right: 20px;
    width: 35px;
    height: 35px;
  }

  .text-wrapper {
    width: 150px;
  }

}
</style>