import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
// import { uuid as uuid } from "vue-uuid";
import { v4 as uuidv4 } from 'uuid';

import ApiService from "../services/ApiService";

import moment from "moment";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	reducer: (state) => ({
		cartUuid: state.cartUuid,
		cartExp: state.cartExp,
		cart: state.cart,
		seguroDeCancelacion: state.seguroDeCancelacion,
		idiomaSelected: state.idiomaSelected || 'es',
		location: state.location,
		paramsFilter: state.paramsFilter,
		searchData: state.searchData,
	}),
});

export default new Vuex.Store({
	state: {
		plazasDisponibles: 0,
		limitarNumeroDePlazas: false,
		plazasAgregadasPorElCliente: 0,
		totalDeLaReserva: 0,
		cart: [],
		cartUuid: null,
		cartExp: null,
		cartAnimation: false,
		idiomaSelected: 'es',
		seguroDeCancelacion: false,
		accessToken: null,
		clorianToken: null,
		location: null,
		paramsFilter: null,
		comunidadesAutonomas: [
			{
				value: null,
				text: "Seleccione una Comunidad Autónoma",
				selected: false,
			},
			{ value: "Andalucía", text: "Andalucía", selected: false },
			{ value: "Aragón", text: "Aragón", selected: false },
			{
				value: "Asturias, Principado de",
				text: "Principado de Asturias",
				selected: false,
			},
			{
				value: "Balears, Illes",
				text: "Islas Baleares",
				selected: false,
			},
			{ value: "Canarias", text: "Canarias", selected: false },
			{ value: "Cantabria", text: "Cantabria", selected: false },
			{
				value: "Castilla - La Mancha",
				text: "Castilla - La Mancha",
				selected: false,
			},
			{
				value: "Castilla y León",
				text: "Castilla y León",
				selected: false,
			},
			{ value: "Catalunya", text: "Catalunya", selected: false },
			{ value: "Ceuta", text: "Ceuta", selected: false },
			{
				value: "Comunitat Valenciana",
				text: "Comunidad Valenciana",
				selected: false,
			},
			{ value: "Extremadura", text: "Extremadura", selected: false },
			{ value: "Galicia", text: "Galicia", selected: false },
			{ value: "Madrid, Comunidad de", text: "Madrid", selected: false },
			{
				value: "Ciudad de Melilla",
				text: "Ciudad de Melilla",
				selected: false,
			},
			{
				value: "Murcia, Región de",
				text: "Región de Murcia",
				selected: false,
			},
			{
				value: "Navarra, Comunidad Foral de",
				text: "Comunidad Foral de Navarra",
				selected: false,
			},
			{ value: "País Vasco", text: "País Vasco", selected: false },
			{ value: "Rioja, La", text: "La Rioja", selected: false },
		],

		provincias: [
			{ value: null, text: "Seleccione provincia *", selected: true },
			{
				value: "01",
				text: "Álava",
				selected: false,
				comunidad: "País Vasco",
			},
			{
				value: "02",
				text: "Albacete",
				selected: false,
				comunidad: "Castilla - La Mancha",
			},
			{
				value: "03",
				text: "Alicante",
				selected: false,
				comunidad: "Comunitat Valenciana",
			},
			{
				value: "04",
				text: "Almería",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "33",
				text: "Asturias",
				selected: false,
				comunidad: "Asturias, Principado de",
			},
			{
				value: "05",
				text: "Avila",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "06",
				text: "Badajoz",
				selected: false,
				comunidad: "Extremadura",
			},
			{
				value: "08",
				text: "Barcelona",
				selected: false,
				comunidad: "Cataluña",
			},
			{
				value: "48",
				text: "Vizcaya",
				selected: false,
				comunidad: "País Vasco",
			},
			{
				value: "09",
				text: "Burgos",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "10",
				text: "Cáseres",
				selected: false,
				comunidad: "Extremadura",
			},
			{
				value: "11",
				text: "Cádiz",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "39",
				text: "Cantabria",
				selected: false,
				comunidad: "Cantabria",
			},
			{
				value: "12",
				text: "Castellón",
				selected: false,
				comunidad: "Comunitat Valenciana",
			},
			{ value: "51", text: "Ceuta", selected: false, comunidad: "Ceuta" },
			{
				value: "13",
				text: "Ciudad Real",
				selected: false,
				comunidad: "Castilla - La Mancha",
			},
			{
				value: "14",
				text: "Córdoba",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "16",
				text: "Cuenca",
				selected: false,
				comunidad: "Castilla - La Mancha",
			},
			{
				value: "20",
				text: "Gipuzcoa",
				selected: false,
				comunidad: "País Vasco",
			},
			{
				value: "17",
				text: "Girona",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "18",
				text: "Granada",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "19",
				text: "Guadalajara",
				selected: false,
				comunidad: "Castilla - La Mancha",
			},
			{
				value: "21",
				text: "Huelva",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "22",
				text: "Huesca",
				selected: false,
				comunidad: "Aragón",
			},
			{
				value: "07",
				text: "Islas Baleares",
				selected: false,
				comunidad: "Balears, Illes",
			},
			{
				value: "23",
				text: "Jaén",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "15",
				text: "La Coruña",
				selected: false,
				comunidad: "Galicia",
			},
			{
				value: "26",
				text: "La Rioja",
				selected: false,
				comunidad: "Rioja, La",
			},
			{
				value: "35",
				text: "Las Palmas",
				selected: false,
				comunidad: "Canarias",
			},
			{
				value: "24",
				text: "León",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "25",
				text: "Lleida",
				selected: false,
				comunidad: "Cataluña",
			},
			{
				value: "27",
				text: "Lugo",
				selected: false,
				comunidad: "Galicia",
			},
			{
				value: "28",
				text: "Madrid",
				selected: false,
				comunidad: "Madrid, Comunidad de",
			},
			{
				value: "29",
				text: "Málaga",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "52",
				text: "Melilla",
				selected: false,
				comunidad: "Ciudad de Melilla",
			},
			{
				value: "30",
				text: "Murcia",
				selected: false,
				comunidad: "Murcia, Región de",
			},
			{
				value: "31",
				text: "Navarra",
				selected: false,
				comunidad: "Navarra, Comunidad Foral de",
			},
			{
				value: "32",
				text: "Ouresense",
				selected: false,
				comunidad: "Galicia",
			},
			{
				value: "34",
				text: "Palencia",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "36",
				text: "Pontevedra",
				selected: false,
				comunidad: "Galicia",
			},
			{
				value: "37",
				text: "Salamanca",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "38",
				text: "Santa Cruz, Tenerife",
				selected: false,
				comunidad: "Canarias",
			},
			{
				value: "40",
				text: "Segovia",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "41",
				text: "Sevilla",
				selected: false,
				comunidad: "Andalucía",
			},
			{
				value: "42",
				text: "Soria",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "43",
				text: "Tarragona",
				selected: false,
				comunidad: "Cataluña",
			},
			{
				value: "44",
				text: "Teruel",
				selected: false,
				comunidad: "Aragón",
			},
			{
				value: "45",
				text: "Toledo",
				selected: false,
				comunidad: "Castilla - La Mancha",
			},
			{
				value: "46",
				text: "Valencia",
				selected: false,
				comunidad: "Comunitat Valenciana",
			},
			{
				value: "47",
				text: "Valladolid",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "49",
				text: "Zamora",
				selected: false,
				comunidad: "Castilla y León",
			},
			{
				value: "50",
				text: "Zaragoza",
				selected: false,
				comunidad: "Aragón",
			},
		],

		timerId: null,
		timeCounter: null,
		totalBoletos: 0,
		boletosPorProducto: {},
		searchData: {
			range: {
				start: null,
				end: null,
			},
			buscadorInput: "",
		},
	},
	getters: {

		// Métodos nuevos para procesar los carritos
		// Get the uui n case it exists
		getCartUuid: ( state ) => {
			return state.cartUuid;
		},
		cartStatus: (state) => {
			let statusObj = { uuid: state.cartUuid, exp: state.cartExp };
			return statusObj;
		},
		getExp: (state) => {
			return state.cartExp;
		},
		clorianToken: (state) => {
			return state.clorianToken;
		},
		cartAnimation: (state) => {
			return state.cartAnimation;
		},
		total: (state) => {
			if (state.cart.length > 0) {
				// console.log(state.cart);
				// let entradas = state.cart;
				return state.cart
					.map((item) => item.itemTotal)
					.reduce((total, amount) => total + amount, 0);
			} else {
				return 0;
			}
		},
		totalCancelacion: (state) => {
			if (state.cart.length > 0) {
				// console.log(state.cart);
				// let entradas = state.cart;
				return (
					state.cart
						.filter((item) => item.cancelable)
						.map((item) => item.itemTotal)
						.reduce((total, amount) => total + amount, 0) * 0.1
				);
			} else {
				return 0;
			}
		},
		itemsInCart: (state) => {
			return state.cart;
		},
		itemsInCartByGroup: ( state ) => {
			const cart = state.cart;

			const itemsAgrupados = cart.reduce( ( acc, item ) => {
				if( item.nombreDelGrupo ) {
					if( !acc[item.group] ){
						acc[ item.group ] = {
							items: [],
							information: {}
						}
						acc[ item.group ].information = {
							itemMonumento: item.itemMonumento ? item.itemMonumento : null,
							itemFecha: item.itemFecha,
							itemHorario: [],
							itemTickets: [],
							itemVisita: item.itemVisita,
						};
					}
					acc[item.group].information.itemHorario.push( item.itemHorario );
					acc[item.group].information.itemTickets = acc[item.group].information.itemTickets.concat( item.itemTickets );
					acc[ item.group ].items.push( item )
				}else{
					if( !acc[item.itemUuid] ){
						acc[ item.itemUuid ] = {
							items: [],
							information: {}
						}
						acc[ item.itemUuid ].information = {
							itemMonumento: item.itemMonumento ? item.itemMonumento : null,
							itemFecha: item.itemFecha,
							itemTickets: [],
							itemVisita: item.itemVisita,
						};
					}
					acc[item.itemUuid].information.itemTickets = acc[item.itemUuid].information.itemTickets.concat( item.itemTickets );
					acc[ item.itemUuid ].items.push( item )
				}
				return acc;
			} , {} )
			
			return Object.values(itemsAgrupados);
		},
		numberOfTickets: (state) => {
			return state.cart.length;
		},
		idiomaIndex: (state) => {
			if (state.idiomaSelected === "es") {
				return 0;
			} else if (state.idiomaSelected === "en") {
				return 1;
			} else if (state.idiomaSelected === "fr") {
				return 2;
			} else if (state.idiomaSelected === "de") {
				return 3;
			} else {
				return 0;
			}
		},
		getIdioma: (state) => {
			return state.idiomaSelected ?? "es";
		},
		seguroDeCancelacion: (state) => {
			return state.seguroDeCancelacion;
		},
		comunidadesAutonomas: (state) => {
			return state.comunidadesAutonomas;
		},
		provincias: (state) => {
			return state.provincias;
		},
		getAccessToken: (state) => {
			return state.accessToken;
		},
		/**
		 * Verificamos que los items que se encuentran en el carrito aún sean válidas.
		 * Si ya pasaron, se borrarán del carrito
		 * @param {Object} state
		 */
		validarTickets(state) {
			// Aquí guardaremos los items que serán eliminados
			let items = [];
			try {
				items = state.cart.filter((ticket) => {
					// console.log(ticket);
					return true;
				});
			} catch (err) {
				console.log(err);
			}

			return items;
		},

		espacioParaBoletos(state) {
			if (state.plazasDisponibles == null) {
				return null;
			} else if (state.plazasDisponibles) {
				return state.plazasDisponibles;
			} else {
				return 0;
			}
		},

		boletosAgregados(state) {
			return state.totalBoletos;
		},

		getCouponsInCart(state) {
			const cupones = state.cart.length
				? state.cart
						.filter((item) => item.cupon != null)
						.map((item) => item.cupon)
				: [];

			return cupones;
		},
		getLocation(state) {
			return state.location;
		},
		searchData(state) {
			return state.searchData;
		},
		cuponExistente(state) {
			return state.cart
				.filter((i) => i.itemCouponCode)
				.map((item) => item.itemCouponCode)
				.reduce((acc, curr) => (acc = curr), "");
		},
		descuentoPorPromociones(state) {
			return state.cart
				.filter((i) => i.itemCouponCode)
				.map((i) => {
					if (i.itemPromotionDiscountType === 2) {
						const discount = Number(
							((i.itemTotal * i.itemPromotion) / 100).toFixed(2)
						);
						return discount;
					} else {
						return i.itemPromotion > i.itemTotal
							? i.itemTotal
							: i.itemPromotion;
					}
				})
				.reduce((acc, curr) => acc + curr, 0);
		},

		limitarNumeroDePlazas(state) {
			return state.limitarNumeroDePlazas;
		},
		espaciosDisponibles(state) {
			return state.plazasDisponibles - state.totalBoletos;
		},
		boletosEnCadaProducto(state) {
			return state.boletosPorProducto;
		},
		totalDeLaReserva(state) {
			return state.totalDeLaReserva;
		},
	},
	mutations: {
		registerCart( state, payload ){
			state.cartUuid = payload.uuidToRegister;
			state.cartExp = payload.expireAt;
			state.cartAnimation = true;
			if( !state.cart ){
				state.cart = [];
			}
			state.cart.push( payload.carritoItem )

			console.log("Se creó carrito");
		},
		addProductsToCart( state, payload ){
			state.cartExp = payload.expireAt;
			state.cartAnimation = true;
			state.cart.push( payload.carritoItem );
			setTimeout( () => {
				state.cartAnimation = false;
			}, 500 )
		},
		setTicketsLimit(state, payload) {
			state.limitarNumeroDePlazas = payload.limitar ?? false;
			state.plazasDisponibles = payload.limitar
				? payload.nuevoNumeroDePlazas
				: 0;
			return true;
		},
		resetBoletos(state) {
			state.totalBoletos = 0;
			state.plazasDisponibles = 0;
			state.limitarNumeroDePlazas = false;
			state.plazasAgregadasPorElCliente = 0;
			state.boletosPorProducto = {};
			state.totalDeLaReserva = 0;
			return state.totalBoletos;
		},
		quitarBoleto(state, payload) {
			state.totalBoletos -= payload;

			return state.totalBoletos;
		},
		agregarBoleto(state, payload) {
			state.totalBoletos += payload;
			return state.totalBoletos;
		},
		// Este método se utiliza en los
		actualizarNumeroDeBoletos(state, payload) {
			state.totalBoletos = payload.total;

			const _boletosPorProducto = {...state.boletosPorProducto };

			_boletosPorProducto[ payload.productoId ] = payload.totalDeBoletosEnElProducto;
			// state.boletosPorProducto ( payload.productoId, payload.totalDeBoletosEnElProducto )
			// state.boletosPorProducto[payload.productoId] =
			// 	payload.totalDeBoletosEnElProducto;
			state.boletosPorProducto = {..._boletosPorProducto};
			return state.totalBoletos;
		},
		actualizarTotalDeLaReserva(state, payload) {
			state.totalDeLaReserva = payload;
			return state.totalDeLaReserva;
		},
		guardarPlazas(state, payload) {
			state.plazasDisponibles = payload;
			return state.plazasDisponibles;
		},
		addReservationId(state, payload) {
			const { itemUuid, reservationId } = payload;
			// let entrada = state.cart.filter( cart => cart.itemUuid == itemUuid );
			// console.log( entrada );

			// entrada[0].reservationId = reservationId;

			console.log("Buscando reserva para agregar el local");
			state.cart.forEach((c) => {
				if (c.itemUuid == itemUuid) {
					console.log("Se modificó el localizador de la reserva");
					c.reservationId = reservationId;
				}
			});

			return state.cart;
		},
		addToCart(state, payload) {
			state.cartAnimation = true;

			return state.cart.push(payload);
		},

		removeFromCart(state, payload) {

			const itemsRestantes = state.cart.filter( i => i.itemUuid !== payload );

			state.cart = itemsRestantes;

			return state.cart;
			// let itemIndex = state.cart.findIndex(
			// 	(item) => item.itemUuid == payload
			// );

			// if (itemIndex != -1) {
			// 	if (state.cart.length == 1) {
			// 		state.cartUuid = null;
			// 		state.cartExp = null;
			// 		state.timeCounter = null;
			// 		state.seguroDeCancelacion = false;
			// 	}
			// 	state.cartAnimation = false;

			// 	return state.cart.splice(itemIndex, 1);
			// } else return state.cart;
		},

		removeOldItemsFromCart(state, payload) {
			let itemsValidos = state.cart.filter((item) => {
				return !payload.includes(item.itemUuid);
			});

			state.cart = itemsValidos;

			return state.cart;
		},
		removeAllItems(state) {
			state.seguroDeCancelacion = false;
			state.cartUuid = null;
			state.cartExp = null;
			return (state.cart = []);
		},
		setIdioma(state, payload) {
			// console.log(payload);
			state.idiomaSelected = payload;
			// return (state.idiomaSelected = payload);
		},
		addCancelToItems(state) {
			state.seguroDeCancelacion = true;
			state.cart
				.filter((items) => items.cancelable)
				.map((item) => {
					item.seguroDeCancelacion = true;
					item.montoCancelacion = item.itemTotal * 0.1;
				});
			// console.log(state.cart)
			return state.cart;
		},
		removeCancelToItems(state) {
			state.seguroDeCancelacion = false;
			state.cart
				.filter((items) => items.cancelable)
				.map((item) => {
					item.seguroDeCancelacion = false;
					item.montoCancelacion = 0;
				});
			console.log(state.cart);
			return state.cart;
		},
		updateAccessToken(state, accessToken) {
			state.accessToken = accessToken;
			return accessToken;
		},
		setLocation(state, payload) {
			state.location = { lat: payload.lat, lng: payload.lng };
			return state.location;
		},
		setParamsFilter(state, payload) {
			state.paramsFilter = { ...payload };
			return state.paramsFilter;
		},
		updateSearchData(state, payload) {
			state.searchData = payload;
		},
		addCouponToCartItem(state, payload) {
			const tempItems = state.cart;

			let itemSobreElQueSeAplicaLaPromocion = null;
			// Si la promoción es de tipo valor absoluto, hay que encontrar cual es el item de mayor valor, y aplicar la
			// promoción sobre ese item nadamas
			if (payload.discountType === 1) {
				if (payload.eventoId) {
					const itemsValidos = tempItems.filter(
						(i) => i.itemIDVisita === payload.eventoId
					);

					if (itemsValidos.length > 0) {
						itemsValidos.sort((a, b) => {
							return a.itemTotal - b.itemTotal;
						});
					}
					itemSobreElQueSeAplicaLaPromocion = itemsValidos[0];
				}
			}
			tempItems.forEach((i) => {
				if (itemSobreElQueSeAplicaLaPromocion) {
					if (i.itemID === itemSobreElQueSeAplicaLaPromocion.itemID) {
						i.itemCouponCode = payload.couponCode;
						if (i.itemTotal <= payload.promotion) {
							i.itemPromotion = payload.itemTotal;
						} else {
							i.itemPromotion = i.itemTotal;
						}
						i.itemPromotionDiscountType = payload.discountType;
						i.itemPromotionType = payload.couponType;
						i.itemCouponId = payload.couponId;
					}
				} else {
					if (payload.eventoId) {
						if (i.itemIDVisita === payload.eventoId) {
							i.itemCouponCode = payload.couponCode;
							i.itemPromotion = payload.promotion;
							i.itemPromotionDiscountType = payload.discountType;
							i.itemPromotionType = payload.couponType;
							i.itemCouponId = payload.couponId;
						}
					} else {
						if (i.itemMonumentoId === payload.monumentoId) {
							i.itemCouponCode = payload.couponCode;
							i.itemPromotion = payload.promotion;
							i.itemPromotionDiscountType = payload.discountType;
							i.itemPromotionType = payload.couponType;
							i.itemCouponId = payload.couponId;
						}
					}
				}
			});

			state.cart = [...tempItems];
		},
		removeCouponToCartItem(state, payload) {
			console.log(payload);
			console.log(state.cart);

			const tempItems = state.cart;

			tempItems.forEach((i) => {
				if (i.itemCouponCode === i.itemCouponCode) {
					i.itemCouponId = null;
					i.itemCouponCode = null;
					i.itemPromotion = null;
					i.itemPromotionDiscountType = null;
					i.itemPromotionType = null;
				}
			});
			state.cart = [...tempItems];
		},
	},
	actions: {
		fetchAccessToken({ commit }) {
			commit("updateAccessToken", localStorage.getItem("accessToken"));
		},
		removeAccessToken({ commit }) {
			localStorage.removeItem("accessToken");
			commit("updateAccessToken", null);
		},
		async addToCart({ commit, state, dispatch }, products) {
			if (state.cartUuid) {
				let preReservas = {
					uuid: products.itemUuid,
					pax: products.itemTickets
						.map((item) => item.cantidad)
						.reduce((acc, curr) => acc + curr, 0),
					eventoId: products.itemID,
					monumentoId: products.itemMonumentoId,
					eventoClorianId: products.itemClorianId,
					asientos: products.asientos,
					tipo: products.tipo ?? 'monumentos',
					group: products.group
				};

				let result = await ApiService.updateCarrito( state.cartUuid, preReservas )        
				state.cartExp = result.data.cartExp;
  
			} else {
				const payload = {
					uuid: uuidv4(),
					preReservas: [
						{
							uuid: products.itemUuid,
							pax: products.itemTickets
								.map((item) => item.cantidad)
								.reduce((acc, curr) => acc + curr, 0),
							eventoId: products.itemID,
							monumentoId: products.itemMonumentoId,
							eventoClorianId: products.itemClorianId,
							asientos: products.asientos,
							tipo: products.tipo ?? 'monumentos',
							group: products.group
						},
					],
					status: false,
				};

				let result = await ApiService.createCarrito( payload );
				state.cartUuid = result.data.uuid;
				state.cartExp = result.data.expireAt;
			}
			products.carritoUuid = state.cartUuid;
			commit( 'addToCart', products );
		},

		async reservaDelete({ commit, state }, reserva) {
			commit("removeFromCart", reserva);
			await ApiService.removeFromCarrito(state.cartUuid, reserva);

			return true;
		},
		async triggerTimer({ commit, state }) {
			if (!state.timerId) {
				// state.carritoTime = moment.utc( state.cartExp?? 0 );
				state.timerId = setInterval(() => {
					let now = moment();
					// console.log(now)
					let diffTime = moment.utc(state.cartExp ?? 0) - now;

					// console.log(diffTime)
					let duration = moment.duration(
						diffTime - 1000,
						"milliseconds"
					);

					state.timeCounter =
						duration.minutes() + ":" + duration.seconds();

					console.log(state.timeCounter);
					if (duration <= 0) {
						state.timeCounter = null;
						clearInterval(state.timerId);
						state.timerId = null;
						commit("removeAllItems");
					}

					return state.timeCounter;
				}, 1000);
			}
		},
		async getCartInfo({ commit, state, dispatch }) {
			if (state.cartUuid) {
				const result = await ApiService.getCartInfo(state.cartUuid);
				return result.data;
			}

			return {};
		},
		// async addToCarrito( { commit, state } ){
		//   if( state.cartUuid == null ){
		//     const cartUuid = uuid.v4()
		//     let result = await ApiService.registerCart( { cartUuid: cartUuid } );
		//     console.log(result);
		//   }
		// }
	},
	modules: {},
	plugins: [vuexLocal.plugin],
});
