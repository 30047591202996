var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "aria-label": "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "border px-5 py-5 border-success mb-3",
              staticStyle: { "background-color": "rgba(252, 248, 134, 0.5)" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mb-3" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "my-auto text-warning me-2",
                        attrs: { icon: "fa-exclamation-circle", size: "3x" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "p",
                    { staticClass: "spainhn-subtitle-small text-center" },
                    [
                      _c("strong", { staticClass: "text-spainhn" }, [
                        _vm._v(_vm._s(_vm.mostrarAlerta)),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "d-flex w-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mx-auto d-flex flex-wrap justify-content-around",
                  },
                  [
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "spainhn-button",
                        attrs: { color: "indigo" },
                        on: { click: _vm.cerrarModal },
                      },
                      [_vm._v("ACEPTAR")]
                    ),
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "spainhn-button",
                        class: { "d-none": !_vm.cancel },
                        attrs: { color: "indigo" },
                        on: { click: _vm.cancelarEvent },
                      },
                      [_vm._v("CANCELAR")]
                    ),
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "mt-2 mt-md-0 spainhn-button",
                        class: { "d-none": !_vm.carrito },
                        attrs: { color: "indigo" },
                        on: { click: _vm.verCarrito },
                      },
                      [_vm._v("VER CARRITO")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }