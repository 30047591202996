var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card my-auto mt-2 mx-1 mt-0",
      style: { backgroundImage: _vm.image },
      attrs: { role: "button" },
      on: { click: _vm.goToRestaurantMicrosite },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "card-body w-100",
            class: {
              "active-background": _vm.status,
              "disabled-background": !_vm.status,
            },
          },
          [
            _c("strong", { staticClass: "d-inline-block spainhn-text" }, [
              _vm._v(_vm._s(_vm.monument)),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }