<template>
    <default-layout>
        <div class="container-fluid px-3 px-md-5 singular-main">

            <div class="spainhn-solid-red-background d-flex py-3" style="height:auto; min-height:75px; width:100%">
                <p class="my-auto ps-4 spainhn-subtitle-2 text-white">{{ $t("default.HOME_NEXT_EVENTS_TITLE") }}</p>
            </div>

            <div class="d-flex justify-content-around pt-4 mb-3" style="">
                <div class="d-flex" style="width:100%">
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <small class="mb-0 spainhn-subtitle-4 mt-4">{{ $t("default.ROUTES_FILTER_TEXT") }}</small>
                    </div>
                </div>
            </div>

            <div class="d-lg-flex mb-5">
                <b-select class="mt-2 spainhn-custom-input me-lg-3 w-100" @change="filterMonuments" v-model="localizacionFilter" :options="comunidadesAutonomas"></b-select>
                <b-select class="mt-2 spainhn-custom-input mx-lg-1 w-100 overflow-hidden" @change="filterMonuments" v-model="rutaFilter" :options="rutasOptionsFiltered"></b-select>
                <b-select class="mt-2 spainhn-custom-input ms-lg-3 w-100" @change="filterMonuments" v-model="castilloFilter" :options="sortMonumentos"></b-select>
            </div>

            <b-spinner class="d-flex mx-auto" variant="danger" v-bind:class="{'d-none':!loading}"></b-spinner>

            <div class="d-flex mb-5">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards v-for="(evento, index) in eventosFiltered" v-bind:key="index"
                    :tipo="tipo"
                    :title="evento.nombres[selectedIdiomaIndex]?.texto"
                    :entradaId="evento?._id"
                    :monument="evento.castilloNombres[selectedIdiomaIndex]?.texto"
                    :monumentoNombreURL="evento.castilloNombres[0]?.texto"
                    @goToEntrada="goToEntrada($event)"
                    :imageUrl="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.banner}`"
                    :date="evento.fechas"
                    :proxDate="evento.fechaInicioEvento"
                    role="button"/>
                        <!-- :date="evento.fecha.split('T')[0]" -->

                </div>
            </div>    
        
            <ComponentsCloud />

        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ComponentsCloud from '../components/ComponentsCloud.vue';
import Cards from '../components/Cards.vue'

import ApiService from '../services/ApiService';

export default {
    components:{
        DefaultLayout, 
        Cards,
        ComponentsCloud
    },
    data(){
        return{
            eventos:[],
            selectedType: "Castillos",
            localizacionFilter: null,
            castilloFilter: null,
            rutaFilter: null,
            
            localizacionOptions:[
                {'value':null, 'text':"Búsqueda por localización"}
            ],
            rutasOptions:[
                {'value':null, 'text':"Búsqueda por ruta"}
            ],
            rutasOptionsFiltered:[

            ],
            castillosOptions:[
                {'value':null, 'text':"Búsqueda por monumento"}
            ],
            castillosOptionsFiltered:[],
            descripciones: this.$t('default.castillosDescriptionText'),
            imagenesUrl: null,            
            loading: false,
            comunidadesAutonomas: [],
            eventosFiltered: [],
            tipo:"Evento",
        }
    },
    methods:{
        showContent(){
            console.log('click en castillo');
        },
        filterMonuments(){
            let $vm = this;
            var tempEventos = [];

            if($vm.localizacionFilter != null){
                $vm.rutasOptionsFiltered = $vm.rutasOptions;
                $vm.castillosOptionsFiltered = $vm.castillosOptions;
                // console.log($vm.localizacionFilter)
                // console.log($vm.eventos);
                tempEventos = $vm.eventos.filter( evento => {
                    // console.log(evento.monumentoId)
                    if(evento.monumentoId != null){
                        if(evento.monumentoId.comunidad == $vm.localizacionFilter){
                            // console.log("Evento en esta comunidad")
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                })
                // console.log(tempEventos);
                $vm.eventosFiltered = tempEventos
                let tempMonumentos = $vm.eventosFiltered.map( evento => {
                    return evento.monumentoId._id;
                })
                // console.log(tempMonumentos);

                let tempRutas = $vm.rutasOptionsFiltered.filter( rutaValue => {

                    if(rutaValue.value == null){
                        return true;
                    }
                    else{
                        var castillosInRuta = rutaValue.value.map( rutaValue2 => {
                            return rutaValue2._id;
                        })

                        // console.log(castillosInRuta)
                        let rutaValida = false;
                        tempMonumentos.forEach( monumentoId => {
                            // console.log(monumentoId)
                            if(castillosInRuta.includes(monumentoId)){
                                console.log("Castillo en esta ruta");
                                rutaValida = true;
                                return true;
                            }
                        })

                        return rutaValida;
                    }

                })

                let tempMonumentosOptions = $vm.castillosOptions.filter( castillo => {
                    if(castillo.value == null){
                        return castillo;
                    }
                    else{

                        let castilloValido = false;

                        tempMonumentos.forEach( monumentoId => {
                            if(monumentoId == castillo.value){
                                castilloValido = true;
                                return true
                            }
                        })

                        return castilloValido;
                    }
                })

                // console.log(tempMonumentosOptions);
                // console.log(tempRutas);
                $vm.rutasOptionsFiltered = tempRutas;
                $vm.castillosOptionsFiltered = tempMonumentosOptions;
            }

            else{
                tempEventos = $vm.eventos;
                $vm.eventosFiltered = $vm.eventos;
                $vm.rutasOptionsFiltered = $vm.rutasOptions;
                $vm.castillosOptionsFiltered = $vm.castillosOptions;
            }

            if( $vm.rutaFilter != null ){
                // console.log($vm.rutaFilter);
                let castillosEnruta = $vm.rutaFilter.map( ruta => {
                    return ruta._id;
                })

                let tempEventosMonumentos = [];

                tempEventosMonumentos  = tempEventos.filter( evento => {
                    // console.log(evento);
                    try{
                        if( castillosEnruta.includes(evento.monumentoId._id))
                            return true;
                        else
                            return false;
                    }
                    catch(err){
                        return false;
                    }
                })
                $vm.eventosFiltered = tempEventosMonumentos;
                tempEventos = $vm.eventosFiltered;

                let tempMonumentos = $vm.eventosFiltered.map( evento => {
                    return evento.monumentoId._id;
                })

                let tempMonumentosOptions = $vm.castillosOptions.filter( castillo => {
                    if(castillo.value == null){
                        return castillo;
                    }
                    else{

                        let castilloValido = false;

                        tempMonumentos.forEach( monumentoId => {
                            if(monumentoId == castillo.value){
                                castilloValido = true;
                                return true
                            }
                        })

                        return castilloValido;
                    }
                })

                // console.log(tempMonumentos);
                $vm.castillosOptionsFiltered = tempMonumentosOptions;
            }
            else{
                if( $vm.localizacionFilter == null ){
                    $vm.castillosOptionsFiltered = $vm.castillosOptions;
                }
            }

            if($vm.castilloFilter != null){
                let tempEventosMonumentos = tempEventos.filter( evento => {
                    try{
                        if(evento.monumentoId._id){
                            console.log("Estoy aquí")
                            if(evento.monumentoId._id == $vm.castilloFilter){
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                        else{
                            return false;
                        }
                    }
                    catch(err){
                        console.log(err);
                        return false;
                    }
                })
                console.log(tempEventosMonumentos);
                $vm.eventosFiltered = tempEventosMonumentos;
            }
        },
        goToEntrada(evento){
            let $vm = this;
            // console.log(evento)
            if( evento.monumentoUrl === 'castillo-de-san-pedro-de-jaca-(ciudadela)'){
                window.open(process.env.VUE_APP_JACA_URL);
            }
            else{
                this.$router.push('/castillos/tarifas/'+evento.monumentoUrl+'/'+evento.entradaId)
            }

            // $vm.$router.push("/castillos/tarifas/" + evento.monumentoUrl + '/' + evento.entradaId)
        },
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;

        ApiService.getFiltrosParaEventos()
            .then( res => {
                let rutasTemp = res.data.rutasTemp;
                let monumentosTemp = res.data.edificacionesTemp;
                
                this.castillosOptions = monumentosTemp.map( monumento => {
                    return {value:monumento._id, text: monumento.nombres[this.selectedIdiomaIndex].texto}
                })
                this.castillosOptions.unshift({value:null, text:"Todos los monumentos"});

                this.castillosOptionsFiltered = this.castillosOptions;

                this.rutasOptions = rutasTemp.map( ruta => {
                    return {value:ruta.castillos, text:ruta.nombres[this.selectedIdiomaIndex].texto}
                })

                this.rutasOptions.unshift({value:null, text:"Todas las rutas"});
                this.rutasOptionsFiltered = this.rutasOptions;
                // monumentosTemp.
            })
            .catch( err => {
                console.log(err);
            })
    },
    setup() {
        
    },
    created(){
        let $vm = this;
        $vm.loading = true;
        ApiService.getAllEventos()
            .then( result => {
                $vm.loading = false;
                $vm.eventos = [...result.data.filter( e => e.restringirVentaIndividual !== true )];

                $vm.eventos.sort( function(a,b){
                    if(b.fechas.length > 0 && a.fechas.length > 0){
                        return new Date(a.fechas[0]) - new Date(b.fechas[0])
                    }
                    else if(b.fechas.length > 0 && a.fechas.length == 0){
                        return 1;
                    }
                    else if(b.fechas.length == 0 && a.fechas.length > 0){
                        return -1;
                    }
                    else if(b.fechas.length == 0 && a.fechas.length == 0){

                        if(a.fechaInicioEvento === undefined && b.fechaInicioEvento ){
                            return 1;
                        }
                        else if(a.fechaInicioEvento && b.fechaInicioEvento){
                            return  new Date( a.fechaInicioEvento ) - new Date( b.fechaInicioEvento );

                        }
                        else{
                            return -1;
                        }
                        // return new Date( a.fechaInicioEvento ) - new Date( b.fechaInicioEvento )
                        // return -1;
                    }
                    else{
                        return 0;
                    }
                })
                
                // const firstProxEvent = $vm.eventos.findIndex( (evento) => evento.fechas.length == 0 );


                // let proxEventos = $vm.eventos.slice( firstProxEvent );
                // console.log(firstProxEvent);
                $vm.eventosFiltered = $vm.eventos;

            })
            .catch(err => {
                $vm.loading = false;
                console.log(err);
            })
        $vm.comunidadesAutonomas = $vm.$store.getters.comunidadesAutonomas;
    },
    computed: {
        
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        sortMonumentos(){
            let $vm = this;
            $vm.castillosOptionsFiltered.shift();
            let tempCastillos2 = []
            if($vm.castillosOptionsFiltered.length){
                tempCastillos2 = $vm.castillosOptionsFiltered.sort( (a,b) => 0.5 - Math.random() );
            }
            tempCastillos2.unshift( {value:null, text:"Todos los monumentos"} )
            return tempCastillos2;
        }

    }
}
</script>

<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    @media (max-width: 1440px){
        .singular-main .spainhn-subtitle-2{
            font-size: 24px;
        }
    }
</style>