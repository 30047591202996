<template>
  <div :style="{ 'backgroundImage': image }" class="card my-auto mx-2 mt-2" >
    <div class="container">
      <div class="card-body">
        <small class="m-0 position-absolute text-center w-auto" style="top:5%; left: 20%; transform: translateX(-50%)">
          <mdb-badge class="buy-ticket" :class="tipoDeResultado">{{ precioReferencia }}</mdb-badge>
        </small>
        <!-- <icon-ticket style="width: 100px; height: 100px;"/> -->

        <div class="overflow-hidden d-flex pt-auto" style="height:150px;">
          <strong class="mt-auto mx-auto spainhn-text">{{ title }}</strong>
        </div>
        <small class="spainhn-small-text d-block text-decoration-underline">{{ monument }}</small>
        <!-- <strong class="d-block spainhn-text" >{{precioReferencia}}</strong> -->
        <strong class="d-block spainhn-text" v-bind:class="{ 'd-none': !evento }">{{ fechaEvento }}</strong>
        <!-- <strong class="d-block spainhn-text" v-bind:class="{ 'd-none': ( !evento || (fechaEvento !=''&& !proxDate )) }">{{proxDate ? ( new Date( proxDate ).toLocaleDateString('es-ES', {timeZone: 'UTC', month:"2-digit", day:"2-digit", year:"numeric"}) ):null }}</strong> -->
        <strong class="d-block spainhn-text" v-bind:class="{ 'd-none': (!evento || (fechaEvento != '')) }">{{ proxDate ?
          (new Date(proxDate).toLocaleDateString('es-ES', {
            timeZone: 'UTC', month: "2-digit", day: "2-digit",
            year:"numeric"}) ): ''}}</strong>

        <div class="d-flex">

          <mdb-btn color="indigo"
            class="spainhn-button spainheritage-background mt-1 mb-2 mx-auto align-items-center d-flex px-lg-2"
            size="sm" @click="goToEntrada">

            <div class="d-flex justify-content-center my-auto" v-bind:class="{ 'd-none': false }">
              <div class="me-1 my-auto">
                <font-awesome-icon icon="fa-plus"></font-awesome-icon>
              </div>
              <strong class="spainhn-text my-auto">
                {{ $t('default.SPAINHN_BUTTON_MORE_INFO') }}
              </strong>
            </div>

            <!-- <div class="d-flex justify-content-center align-items-center" v-bind:class="{ 'd-none': ventaDisponible }">
              <strong>{{ $t('default.PROXIMAMENTE_TEXT') }}</strong>
            </div> -->

          </mdb-btn>
          <mdb-btn color="indigo" class="spainhn-button mt-1 mb-2 mx-auto align-items-center d-flex" size="sm"
            @click="goToBuy" v-bind:class="{ 'd-none': experiencia }">
            <div class="d-flex justify-content-center my-auto" v-bind:class="{ 'd-none': !ventaDisponible }">
              <div class="">
                <icon-base :height="25" :width="25">
                  <icon-carrito />
                </icon-base>
              </div>
              <strong class="spainhn-text my-auto">
                {{ $t('default.SPAINHN_BUTTON_BUY_TICKET') }}
              </strong>
            </div>

            <div class="d-flex justify-content-center align-items-center" v-bind:class="{ 'd-none': ventaDisponible }">
              <strong>{{ $t('default.PROXIMAMENTE_TEXT') }}</strong>
            </div>

          </mdb-btn>

          <mdb-btn color="indigo" class="spainhn-button mt-1 mb-2 mx-auto" size="sm" @click="goToBuy"
            v-bind:class="{ 'd-none': !experiencia }">
            <div class="d-flex justify-content-center align-items-center">
              <div class="my-auto me-1">
                <font-awesome-icon icon="fa-calendar-check"></font-awesome-icon>
              </div>
              <strong class="spainhn-text my-auto">
                {{ $t('default.SPAINHN_BUTTON_EXPERIENCES') }}
              </strong>
            </div>
          </mdb-btn>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '../components/IconBase.vue';
import IconCarrito from '../components/icons/IconCarrito.vue'
export default {
  components: {
    IconBase,
    IconCarrito
  },
  props: {
    imageUrl: String,
    title: String,
    monument: String,
    date: Array,
    precioOrientativo: String,
    entradaId: String,
    monumentoNombreURL: String,
    tipo: {type: String, default: 'Tarifa'},
    proxDate: String,
    apiDeVentasUrl: { type: String, default: "" }

  },
  data() {
    return {
      movingToCart: false
      // image: `url(${require('@/assets/img_avatar.png')})`
      // image: `url(${this.imageUrl})`,
      // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
    }
  },
  setup() {

  },
  methods: {
    goToEntrada() {
      this.$emit('goToEntrada', { entradaId: this.entradaId, monumentoUrl: this.monumentoNombreURL })
    },
    goToBuy() {
      if (this.apiDeVentasUrl) {
        this.movingToCart = true;
        this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
        setTimeout(() => {
          window.open(`${this.apiDeVentasUrl}`, '_blank');
          this.movingToCart = false;
        }, 2000)

      } else {
        if (this.tipo === 'paquetes_prestadores') {
            this.$router.push({ name: "ReservarPackExperiencias", query: { tipo: 'paquete', id: this.entradaId } });
        }
        else if (this.tipo === "servicios_prestadores") {
            this.$router.push({ name: "ReservarPackExperiencias", query: { tipo: 'servicio', id: this.entradaId } });
        }
        else if (this.tipo === 'paquetes_monumentos') {
            this.$router.push({ name: "ReservarPackMonumentos", query: { tipo: 'monumentos', id: this.entradaId } });
        }
        else{
          this.$router.push(`/castillos/reservar/idvisita:${this.entradaId}`)
        }
      }
    }
  },
  computed: {

    ventaDisponible() {
      if (this.tipo == 'Evento') {
        if (this.date) {
          if (this.date.length > 0) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }
    },

    image() {
      let urlEnconded = encodeURI(this.imageUrl);
      return `url(${urlEnconded})`;
    },
    evento() {
      if (this.tipo == 'Evento') {
        // console.log("Es evento")
        return true;
      }
      else {
        return false;
      }
    },
    experiencia() {
      if (this.tipo == "Experiencia") {
        return true;
      }
      else {
        return false;
      }
    },
    precioReferencia() {
      return this.precioOrientativo
    },
    fechaEvento() {
      if (this.date != null && this.date != undefined) {
        if (this.date.length == 0) {
          return "";
        }
        else {
          let fechasString = "";

          let tempFechas = this.date;

          tempFechas.sort(function (a, b) {
            return new Date(a) - new Date(b)
          })

          if (this.date.length > 1) {
            fechasString = new Date(tempFechas[0]).toLocaleDateString('es-ES', { year: '2-digit', month: '2-digit', day: '2-digit' }) + " - ";
            let lastFecha = new Date(tempFechas[tempFechas.length - 1]).toLocaleDateString('es-ES', { year: '2-digit', month: '2-digit', day: '2-digit' });
            fechasString += lastFecha;
            return fechasString;
          }

          else {
            tempFechas.forEach(date => {
              fechasString += new Date(date).toLocaleDateString('es-ES', { year: '2-digit', month: '2-digit', day: '2-digit' })
            })

            return fechasString;
          }
          // fechasString = this.fecha.reduce( (acc, prev) => acc + ',' + new Date(prev).toLocaleDateString() )
        }
      }
      else {
        return ""
      }
    },
    tipoDeResultado(){
      if( this.tipo === 'paquetes_monumentos'){
        return 'label-paquete'
      }else{
        return 'label-visit'
      }
    }
  }
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  /* background-image: url('../assets/img_avatar.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  /* height:55vh;  */
  /* width:45%; */
  height: 20rem;
  width: 20rem;
  min-width: 250px;
  padding: 0;
}

img {
  border-radius: 10px 1px 0 0;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 0px;
  height: 100%;
  border-radius: 10px;
}

.card-body {
  height: 50%;
  padding: 0% 5%;
  padding-bottom: 2%;
  padding-top: 5%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
  /* -ms-flex-item-align:end; */
}

.title-text {
  width: 100%;
  height: 100%;
  white-space: wrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  margin-top: auto;
}

.buy-ticket {
  /* background-color: var(--spainhn-rojo-spainhn) !important; */
  /* color: var(--spainhn-rojo-spainhn); */
  color: white;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.label-visit {
  background-color: var(--spainhn-rojo-spainhn) !important;
}

.label-paquete {
  background-color: var(--spainhn-pack-color) !important;
}
</style>