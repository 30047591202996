<template>
    <div class="mb-2 d-flex flex-column" style="padding: 0px;" v-bind:class="{'d-none':videoId==''}">
        <span class="spainhn-sections-title mb-2 text-uppercase">
            {{ $t("default.MICROSITES_MULTIMEDIA") }}
        </span>
        <div class="multimedia-container mx-auto">
            <youtube class="me-auto" :video-id="videoId" ref="youtube" width="100%" height="100%" style="border-radius: 10px !important;"></youtube>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            videoId: String,
        },
        data(){
            return{

            }
        },
        computed:{
            player() {
                return this.$refs.youtube.player
            },
        }
    }
</script>

<style scoped>
    .multimedia-container{
        border-radius: 10px;
        width: 350px;
        height: 200px;
        flex-shrink: 0;
    }

</style>