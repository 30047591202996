import clorianHttp from '../clorian-common';

class ClorianApiService{
    consultarProductos(monumento, clorianPos){
        return clorianHttp.get('/productos', {
            params:{
                monumento: monumento,
                pointOfSale: clorianPos,
            },
            headers:{
                
            }
        })
    }

    consultarDetallesProducto(productoId, monumento, pointOfSale){
        return clorianHttp.get('/detalles-producto', {
            params:{
                productId: productoId,
                monumento: monumento,
                pointOfSale: pointOfSale
            },
            headers:{
                
            }
        })
    }

    /**
     * Consulta las fechas disponibles para este producto
     * @param {number} productoId - Id del producto 
     * @param {String} monumento - Nombre del monumento al que pertenece este producto.
     * @returns 
     */
    consultarFechasProducto(productoId, monumento, year, month){
        return clorianHttp.get('/fechas-producto',{
            params:{
                productId: productoId,
                monumento: monumento,
                year: year,
                month: month
            },
            headers:{

            }
        })
    }

    /**
     * Consulta la lista de eventos para un producto en específico
     * @param {number} productoId - Id del producto 
     * @param {String} date - Fecha de interes que se consultará.
     * @returns 
     */
    consultarListaDeEventos(productoId, date, monumento){
        return clorianHttp.get('/lista-eventos',{
            params:{
                productId: productoId,
                date: date,
                monumento: monumento
            },
            headers:{

            }
        })
    }

    /**
     * Consulta los precios para un producto en particular
     * @param {number} productoId - Id del producto 
     * @param {String} date - Fecha de interes que se consultará.
     * @returns 
     */
    getPricingForEvent(productoId, date, monumento){
        return clorianHttp.get('/pricing-list',{
            params:{
                productId: productoId,
                date: date,
                monumento: monumento
            },
            headers:{

            }
        })
    }

    /**
     * Crear reserva
     * @param {number} productoId - Id del producto 
     * @param {String} date - Fecha de interes que se consultará.
     * @returns 
     */
     createReservation(productoId, monumento, buyerTypes, events, uuid){

        let body = {
            productId: productoId,
            monumento: monumento,
            buyerTypes: buyerTypes,
            events: events,
            uuid: uuid
        }
        return clorianHttp.post('/reservation', body, {
            headers:{

            }
        })
    }

    
}

export default new ClorianApiService();