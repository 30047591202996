<template>
    <div class="card-container w-100">
        <div class="d-flex" role="button" @click="() => $emit('goToRutas')">
            <div class="image-container">
                <img :src="image" class="d-block">
            </div>
            <div class="text-container">
                <p class="info-title-text">{{ title }}</p>
                <p class="info-none-text mt-auto mb-0">{{ fechasEvento }}</p>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RutaCard',
    props:{
      imageUrl: String,
      title: String,
      text: String,
      nombreUrl: String,
      tipo: String,
      fecha: Array
      // date: String
    },
    data(){
      return{
        // image: `url(${require('@/assets/img_avatar.png')})`
        // image: `url(${this.imageUrl})`,
        status: false
        // backgroundImage: {backgroundImage: "url(../assets/img_avatar.png)"}
      }
    },
    methods:{
      showText(){
        if(this.status == true)
          this.status = false;
        else
          this.status = true;
      },
      goTo(){
        // console.log(this.monument);
        let $vm = this;
        if($vm.tipo != "Entrada"){
          $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre + '/restaurantes-y-actividades/' + $vm.tipo +'/' + $vm.nombreUrl);        
        }
        else{
          // console.log(this.nombreUrl)
          this.$emit('goTo', this.nombreUrl);
        }
      }
    },
    mounted(){
      // console.log(this.monument);
      // console.log(this.nombreUrl);
    },
    setup() {
  
    },
    computed:{
        image(){
            let urlEnconded = encodeURI(this.imageUrl)
            return `${urlEnconded}`;
        },
        fechasEvento(){
    
            if(this.fecha){
    
                if(this.fecha.length == 0){
                    return this.$t('default.PROXIMAMENTE_TEXT');
                }
                else{
                    let fechasString = "";
        
                    let tempFechas = this.fecha;
        
                    tempFechas.sort( function(a, b){
                        return new Date(a) - new Date(b)
                    })
                    
                    if(this.fecha.length > 1){
                    fechasString = new Date(tempFechas[0]).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'}) + " - ";
                    let lastFecha = new Date(tempFechas[ tempFechas.length - 1] ).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'});
                    fechasString += lastFecha;
                    return fechasString;
                    }
        
                    else{
                    tempFechas.forEach( date => {
                        fechasString += new Date(date).toLocaleDateString('es-ES', {year:'2-digit', month:'2-digit', day:'2-digit'})
                    })
                    return fechasString;
                    }
                }
            }
            else return ""
        }
    }
  }
  </script>
  
  <style scoped>
  img {
    border-radius: 10px 1px 0 0;
  }
  /* On mouse-over, add a deeper shadow */

  /* Add some padding inside the card container */
  .card-container {
    max-height: 150px;
    max-width: 360px;
    box-sizing: content-box;
    border-radius: 10px;
    background: linear-gradient(181deg, #000 1.94%, #E50E0E 46.7%, #BF5B5B 99.37%);
  }

  .image-container{
    width: 150px;
    height: 150px;
    flex-shrink:0;
    position: relative;
  }

  .image-container > img{
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px 0px 0px 10px;
  }
  
  .info-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-title-text{
    text-align: start;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .info-dates-text{
    text-align: start;
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .info-none-text{
    text-align: start;
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    color: #FFF;
  }

  .content{
    z-index: 1;
    background-color: var(--spainhn-gris-caja);
    position: absolute;
    height: 100%;
    width: 100%;
    top:0%;
    /* padding-top:5%; */
    padding: 5% 8% 5% 8%;
    padding-bottom: 20%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    transition: 0.3s;
  }
  
  .card-body{
    height: 30%;
    padding: 5% 5%;
    padding-bottom: 10%;
    /* background-color: rgba(0,0,0,0.7); */
    color:white;
    border-radius: 0px 0px 10px 10px;
    z-index: 2;
    overflow: hidden;
  }
  
  .active-background{
    background-color: var(--spainhn-rojo-claro);
  }
  
  .disabled-background{
    background-color: rgba(0,0,0,0.7);
  }
  </style>