var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 200 200" },
      attrs: {
        version: "1.1",
        id: "Capa_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        width: _vm.width,
        height: _vm.height,
        viewBox: "50 40 200 200",
        "aria-labelledby": _vm.iconName,
        "xml:space": "preserve",
        role: "presentation",
      },
    },
    [
      _c("title", { attrs: { id: _vm.iconName, lang: "en" } }, [
        _vm._v(_vm._s(_vm.iconName) + " icon"),
      ]),
      _c("g", { attrs: { fill: _vm.iconColor } }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }