var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillo },
            },
            [_vm._v(_vm._s(_vm.monumentWebName) + ">> ")]
          ),
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text",
              attrs: { role: "button" },
            },
            [_vm._v("actividades ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-5" }, [
      _c("div", { staticClass: "d-flex justify-content-around pt-4 mb-3" }, [
        _c(
          "div",
          { staticClass: "d-flex", staticStyle: { width: "100%" } },
          [
            _c(
              "icon-base",
              { attrs: { width: 55, height: 55, iconColor: "rgb(0,0,0)" } },
              [_c("icon-casttle")],
              1
            ),
            _c(
              "div",
              { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
              [
                _c(
                  "p",
                  { staticClass: "mb-0 mt-auto spainhn-subtitle-2-black" },
                  [_vm._v(_vm._s(_vm.monumentWebName))]
                ),
                _c(
                  "small",
                  { staticClass: "mb-0 mt-auto spainhn-subtitle-4-black" },
                  [_vm._v(_vm._s(_vm.address))]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "d-flex mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
          },
          _vm._l(_vm.actividades, function (actividad, index) {
            return _c("cards-thumbnails", {
              key: index,
              attrs: {
                monument: actividad.webName,
                nombreUrl: actividad._id,
                tipo: "actividades",
                imageUrl:
                  `${_vm.filesUrl}/actividades/` +
                  actividad.oldId +
                  "/" +
                  actividad.banner,
              },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }