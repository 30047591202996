import { render, staticRenderFns } from "./IconRoutes.vue?vue&type=template&id=86872130&scoped=true&"
var script = {}
import style0 from "./IconRoutes.vue?vue&type=style&index=0&id=86872130&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86872130",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\beto_\\Documents\\creandotec\\Spain-heritage-frontend\\spainheritagenetwork-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('86872130')) {
      api.createRecord('86872130', component.options)
    } else {
      api.reload('86872130', component.options)
    }
    module.hot.accept("./IconRoutes.vue?vue&type=template&id=86872130&scoped=true&", function () {
      api.rerender('86872130', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/IconRoutes.vue"
export default component.exports