var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-container w-100" }, [
    _c(
      "div",
      {
        staticClass: "d-flex",
        attrs: { role: "button" },
        on: { click: () => _vm.$emit("goToRutas") },
      },
      [
        _c("div", { staticClass: "image-container" }, [
          _c("img", { staticClass: "d-block", attrs: { src: _vm.image } }),
        ]),
        _c("div", { staticClass: "text-container" }, [
          _c("p", { staticClass: "info-title-text" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("p", { staticClass: "info-none-text mt-auto mb-0" }, [
            _vm._v(_vm._s(_vm.fechasEvento)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }