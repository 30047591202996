<template>
    <div>
        <b-modal size="lg" v-model="show" aria-label="" hide-header hide-footer no-close-on-backdrop>
            <div class="border px-5 py-5 border-success mb-3" style="background-color:rgba(252, 248, 134, 0.5)">
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="d-flex justify-content-center">
                            <font-awesome-icon class="my-auto text-warning me-2" icon="fa-exclamation-circle" size="3x"></font-awesome-icon>
                        </div>
                    </div>
                    <div class="col-12">
                            <p class="spainhn-subtitle-small text-center"><strong class="text-spainhn">{{mostrarAlerta}}</strong></p>
                    </div>
                </div>
                <div class="d-flex w-100">
                    <div class="mx-auto d-flex flex-wrap justify-content-around">
                        <mdb-btn color="indigo" @click="cerrarModal" class="spainhn-button">ACEPTAR</mdb-btn>
                        <mdb-btn color="indigo" @click="cancelarEvent" class="spainhn-button" v-bind:class="{'d-none':!cancel}">CANCELAR</mdb-btn>
                        <mdb-btn color="indigo" @click="verCarrito" class="mt-2 mt-md-0 spainhn-button" v-bind:class="{'d-none':!carrito}">VER CARRITO</mdb-btn>
                    </div>
                </div>
            </div>                   
        </b-modal>
    </div>
</template>

<script>
export default {
    props:{
        mensaje: String,
        show: Boolean,
        cancel: {type: Boolean, default: false},
        carrito: {type: Boolean, default: false}
    },
    methods: {
        cerrarModal(){
            this.$emit('close')
            // this.$refs['alerta-modal'].hide();
            
        },
        cancelarEvent(){
            this.$emit('cancelar')
        },
        verCarrito(){
            this.$router.push('/carrito')
        }
    },
    computed:{
        mostrarAlerta(){
            return this.mensaje;
        }
    }
}
</script>