<template>
    <div>
      <div :id="carouselId" class="carousel slide" data-ride="carousel">
        
        <div class="carousel-inner mx-auto">
          <a class="left carousel-control my-auto prev" data-slide="prev" @click="prev">
            <font-awesome-icon icon="fa-circle-chevron-left" size="3x" class="text-white"></font-awesome-icon>
          </a>

            <transition name="fade" mode="out-in">
          <div v-for="i in [currentIndex]" :key="i" class="d-flex flex-row my-auto mx-auto">
              <Cards 
                v-for="(entrada, index) in currentImg" v-bind:key="index" class="fade-leave-active"
                :date = "entrada.fechas"
                :proxDate = "entrada.fechaInicioEvento"
                :entradaId="entrada._id" @goToEntrada="goToEntrada($event)"
                :monumentoNombreURL="entrada.monumentUrl"
                :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                :title="entrada.webName" :monument="entrada.monumentName"
                :tipo="tipo" 
              />
              <!-- <cards-strech v-for="(entrada, index) in currentImg" v-bind:key="index" class="fade-leave-active"
                :date = "entrada.fechas"
                :proxDate = "entrada.fechaInicioEvento"
              :entradaId="entrada._id" @goToEntrada="goToEntrada($event)"
                :monumentoNombreURL="entrada.castilloNombres[0].texto"
                :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                :title="entrada.nombres[selectedIdiomaIndex].texto" :monument="entrada.castilloNombres[selectedIdiomaIndex].texto"
                :tipo="tipo"
              >
              </cards-strech> -->
          </div>
            </transition>
          <a class="right carousel-control next" data-slide="next" @click="next">
            <font-awesome-icon icon="fa-circle-chevron-right" size="3x" class="text-white"></font-awesome-icon>
          </a>
        </div>

        <!-- Left and right controls -->
    </div>

    </div>
</template>

<script>
import CardsStrech from './CardsStrech.vue';
import Cards from '../components/Cards.vue'

export default {
  components: { Cards },
    name: "Slider",
    props:{
      images:Array,
      carouselId: String,
      tipo:String
    },
    data() {
        return {
        // images: [
            
        // ],
        timer: null,
        currentIndex: 0,
        imagenesUrl: null
        };
    },

    mounted: function() {
      let $vm = this;
      this.startSlide();
      this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;              
    },

    methods: {
        startSlide: function() {
        this.timer = setInterval(this.next, 10000);
        },

        next: function() {
          this.currentIndex += 1;
        },
        prev: function() {
          this.currentIndex -= 1;
        },
        goToEntrada(event){
          // console.log(event);
          let $vm = this;
          $vm.$router.push('/castillos/tarifas/'+event.monumentoUrl+'/'+event.entradaId);
        },
    },

    computed: {
        currentImg: function() {
        return this.images[Math.abs(this.currentIndex) % this.images.length];
        },
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  /* transition:all 2s ease; */
  transition: 300ms opacity ease-in-out;
  /* transform: translate(-100%, 0); */
  /* overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1; */
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;/* 
  transition: 400ms opacity ease-in-out;
  transition-delay: 200ms; */
}

img {
  height:600px;
  width:100%
}

.carousel-inner{
  display: flex;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
  z-index: 2;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.1);
}
@media(max-width:767px){
  .carousel-inner svg{
    font-size: 30px;
  }
  .carousel-inner .next,.carousel-inner .prev{
    padding: 0;
  }
}
@media(max-width:424px){
  .carousel-inner .prev{
      left: 20px;
  }
  .carousel-inner .next{
      right: 20px;
  }
  .carousel-inner svg{
    font-size: 24px;
  }
}
</style>