<template>
    <div class="spainhn-navigation-bar">
        <div class="spainhn-navigation-bar-container">
                <span v-for="(link, index) of navigationLinks" :key="index" style="flex: 0 0 auto;" class="my-auto">
                    <span class="my-auto" style="vertical-align: middle;" 
                        :class="{'navigation-item':  link.navigation === true}"
                        @click="() => navigateToLink(link.navigationLink)">
                        {{ link.text }}
                    </span>
                    <svg v-if="index < (navigationLinks.length - 1)" class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="14" viewBox="0 0 2 14" fill="none">
                        <path d="M1 0V13.5" stroke="white"/>
                    </svg>
                </span>
                <div style="display: flex; flex: 1 1 auto; overflow-x: auto; white-space: nowrap;">
                    <slot>
    
                    </slot>
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            navigationLinks: { type: Array, default: () => [] }
        }, 
        methods:{
            navigateToLink( navigationLink ){
                console.log( navigationLink );
                if( navigationLink ){
                    this.$router.push(`${navigationLink}`)
                }else if( navigationLink === "" ){
                    this.$router.go( -1 );
                }
            }
        }
    }

</script>

<style scoped>
    .navigation-item{
        cursor: pointer;
    }
</style>